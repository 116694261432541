import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { toLower } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import SupervisorDebitModel from '../admin/Agent/SupervisorDebitModel';
import Label from '../components/label/Label';
import { UserContext } from '../contexts/UserContext';
import { MY_GLOBAL_CONST } from '../utils/GlobalConstant';
import { fetchDataWithAuth, postData } from '../utils/api';
import { fNumber } from '../utils/formatNumber';
import {
  ToastError,
  ToastSuccess,
  formatNumber,
  generateHash,
  getPermissionActions,
  getTimeStamp,
  showCurrency,
} from '../utils/helper';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
};
const schema = yup.object().shape({
  amount: yup
    .string()
    // .moreThan(0, 'Amount must be greater than 0')
    .required('Balance is required')
    .matches(/^\d+$/, 'Only numbers are allowed'),
  description: yup
    .string()
    .required('Description is required')
    .matches(/^[^\s].*/, 'starting spaced is not allowed'),
  request_type: yup.string().required('Status is required'),
});

const BalanceControlCenter = () => {
  const { user, updateBalance, fetchBalance } = useContext(UserContext);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sortData, setSortData] = useState('DESC');
  const [sortBy, setSortBY] = useState('');
  const [list, setList] = useState([]);
  const [extra_data, setExtraData] = useState([]);
  const [loading, SetLoading] = useState(true);
  const [disable, setDisable] = useState(false);
  const [open, setOpen] = useState(false);
  const [RowId, setRowId] = useState(0);
  const [disableReject, setDisableReject] = useState(null);
  const [select, setSelect] = useState();
  const [loaderMain, SetLoaderMain] = useState(false);
  const [supervisors, setSupervisors] = useState([]);
  const [suploading, setSupLoading] = useState(false);
  const [fundopen, setFundOpen] = useState(false);
  const [current_id, setCurrentId] = useState();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const actions = getPermissionActions(user.role_responsibility, 'Balance Control Center');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchDataFromApi(sortBy, sortBy ? sortData : '', newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    fetchDataFromApi(sortBy, sortBy ? sortData : '', 0, event.target.value);
  };

  const fetchDataFromApi = async (sortBy, sortData, page, rowsPerPage) => {
    const data = {
      user_token: user.token,
      data: {
        type: '',
        request_type: '',
        order_key: sortBy !== '' ? sortBy : '',
        order_value: sortData,
      },
    };
    try {
      const fetchedData = await postData(`/${user.type}/cash/request/list?page=${page + 1}&size=${rowsPerPage}`, data);
      setList(fetchedData.data);
      setExtraData(fetchedData.extra_data);
      SetLoading(false);
    } catch (error) {
      console.log('fetchedData error :>> ', error);
    }
    fetchBalance();
  };

  const getStatus = (status) => {
    switch (status) {
      case 1:
        return <Label color={'warning'}>Pending</Label>;
      case 2:
        return <Label color={'success'}>Approved</Label>;
      case 3:
        return <Label color={'error'}>Rejected</Label>;
      default:
        return <></>;
    }
  };
  const handleRequestType = async (type, id) => {
    SetLoaderMain(true);
    setRowId(id);
    setDisableReject(type === 1 ? 'Approve' : 'Reject');
    const data = {
      user_token: user.token,
      data: {
        transfer_request_id: id,
        action_type: type === 1 ? 1 : 2,
        date_time: getTimeStamp(),
      },
    };
    const hashSignature = generateHash(data.data);
    data.data.hash = hashSignature;
    try {
      const BalanceRequest = await postData(`/${user.type}/cash/request/action`, data);
      await fetchDataFromApi(sortBy, '', page, rowsPerPage);
      await updateBalance(BalanceRequest.data[0].balance);
      ToastSuccess(BalanceRequest.message);
    } catch (error) {
      console.log('BalanceRequest error :>> ', error);
      ToastError(error.response.data.message);
    } finally {
      await fetchBalance();
      setDisableReject(null);
      SetLoaderMain(false);
    }
  };
  const handleSort = (sortBy) => {
    setSortBY(sortBy);
    setSortData(sortData === 'ASC' ? 'DESC' : 'ASC');
    fetchDataFromApi(sortBy, sortData === 'ASC' ? 'DESC' : 'ASC', page, rowsPerPage);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    reset();
  };
  const isNotFound = !list.length;

  const onSubmit = async (requestVal) => {
    setDisable(true);
    toast.dismiss();
    const data = {
      user_token: user.token,
      data: {
        request_type: Number(requestVal.request_type),
        amount: requestVal.amount,
        description: requestVal.description,
        date_time: getTimeStamp(),
      },
    };
    const hashSignature = generateHash(data.data);
    data.data.hash = hashSignature;
    try {
      const BalanceRequest = await postData(`/${user.type}/cash/request`, data);

      //   setList(BalanceRequest.data);
      //   setExtraData(BalanceRequest.extra_data);
      // updateBalance(BalanceRequest.data[0].balance);
      if (BalanceRequest.status_code === 200) {
        setOpen(false);
        reset();
      }
      ToastSuccess(BalanceRequest.message);
      setDisable(false);
    } catch (error) {
      console.log('BalanceRequest error :>> ', error);
      ToastError(error.response.data.message);
      setDisable(false);
    } finally {
      await fetchBalance();
      await fetchDataFromApi(sortBy, '', page, rowsPerPage);
    }
  };

  const BalanceRequestTypes = [
    {
      id: 1,
      label: `Transfer money to ${toLower(user?.shop[0]?.role_name)}`,
    },
    {
      id: 2,
      label: `Receive money from ${toLower(user?.shop[0]?.role_name)}`,
    },
  ];

  const handleChange = (event) => {
    setSelect(event.target.value);
  };

  const getSupervisors = async () => {
    setSupLoading(true);
    const data = {
      user_token: user.token,
      data: {},
    };
    const response = await fetchDataWithAuth(`/${user.type}/shop/assign/supervisor/list`, data);
    const supervisorData = response.data;
    setSupervisors(supervisorData);
    setSupLoading(false);
  };

  useEffect(() => {
    fetchDataFromApi(sortBy, '', page, rowsPerPage);
    if (supervisors.length === 0) getSupervisors();
  }, []);

  const getRandomColor = () =>
    // Generate a random hexadecimal color code
    `#${Math.floor(Math.random() * 16777215).toString(16)}`;

  const handleSupOpen = (supervisor_id) => {
    setFundOpen(true);
    setCurrentId(supervisor_id);
  };
  const handleSupClose = () => {
    setFundOpen(false);
    setCurrentId();
  };
  return (
    <>
      <Helmet>
        <title> Balance Control Center | {`${MY_GLOBAL_CONST.main_title}  ${user.type}`} </title>
      </Helmet>
      <SupervisorDebitModel
        open={fundopen}
        handleClose={handleSupClose}
        supervisor_id={current_id}
        refreshSupervisor={getSupervisors}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component={'form'} onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h5">Cash Request</Typography>

            <IconButton aria-label="close">
              <CloseIcon onClick={handleClose} />
            </IconButton>
          </Stack>

          <Stack direction="row">
            <Box sx={{ width: '60%', mr: 1 }}>
              <FormControl sx={{}} fullWidth className="slectInput">
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                  {...register('request_type')}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  onChange={handleChange}
                >
                  <MenuItem value="">Select Type</MenuItem>
                  {BalanceRequestTypes.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box width="40%">
              <TextField
                {...register('amount')}
                name="amount"
                fullWidth
                type="number"
                variant="outlined"
                label="Amount"
                placeholder="Amount"
                disableUnderline
              />
              <Typography variant="body2" color="error">
                {errors.amount?.message}
              </Typography>
            </Box>
          </Stack>

          <Stack mt={2} alignItems="flex-end">
            <Box width="100%">
              <TextField
                fullWidth
                multiline
                rows={3}
                placeholder="Add description"
                {...register('description')}
                name="description"
                label="Description"
              />
              <Typography variant="body2" color="error">
                {errors.description?.message}
              </Typography>
            </Box>
            <Box mt={2}>
              <Button disabled={!select || disable} type="submit" size="large" sx={{ ml: 2 }} variant="contained">
                {select === 1 ? 'Transfer' : 'Request'}
              </Button>
            </Box>
          </Stack>
        </Box>
      </Modal>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon
              icon="material-symbols-light:balance"
              style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }}
            />
            <Typography variant="h4" gutterBottom>
              Balance Control Center
            </Typography>
          </Box>
          {actions.find((action) => action.name === 'request') ? (
            <Button onClick={handleOpen} variant="contained">
              Add Request
            </Button>
          ) : (
            <></>
          )}
        </Stack>
        <Grid
          container
          className="boxGrid nomarginLR"
          spacing={3}
          justifyContent="stretch"
          sx={{
            '& .MuiPaper-root': {
              margin: 1,
              border: '1px solid ',
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#fff',
            },
            // '& .MuiTypography-root': { color: '#061B64' },
          }}
        >
          {suploading ? (
            <CircularProgress />
          ) : (
            supervisors.length > 0 &&
            supervisors.map((supervisor, index) => (
              <Card key={index}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: getRandomColor, borderRadius: '10px' }} aria-label="recipe">
                      {supervisor.username.charAt(0).toUpperCase()}
                    </Avatar>
                  }
                  action={
                    <Stack direction="row">
                      <Tooltip title="Debit Balance">
                        <IconButton aria-label="settings" onClick={() => handleSupOpen(supervisor.id)}>
                          <Icon
                            icon="hugeicons:money-remove-02"
                            style={{ color: '#FF4842', fontSize: '23px', marginRight: '5px' }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  }
                  title={supervisor.username}
                  subheader={`${fNumber(supervisor.balance)} ${showCurrency()}`}
                  sx={{
                    padding: '16px',
                  }}
                />
              </Card>
            ))
          )}
        </Grid>
        {loaderMain ? (
          <Box
            sx={{
              display: 'flex',
              height: '300px',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#f9fafb',
            }}
          >
            <CircularProgress size={35} />
          </Box>
        ) : (
          <Card>
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell onClick={() => handleSort('id')} sx={{ cursor: 'pointer' }} align="center">
                      ID
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell
                      onClick={() => handleSort('request_by.username')}
                      sx={{ cursor: 'pointer' }}
                      align="center"
                    >
                      Supervisor (Requesting)
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell
                      onClick={() => handleSort('request_to.username')}
                      sx={{ cursor: 'pointer' }}
                      align="center"
                    >
                      Agent/Operator (Providing)
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell onClick={() => handleSort('amount')} sx={{ cursor: 'pointer' }} align="center">
                      Amount
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell onClick={() => handleSort('description')} sx={{ cursor: 'pointer' }} align="center">
                      Description
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell onClick={() => handleSort('status')} sx={{ cursor: 'pointer' }} align="center">
                      Status
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    {actions.find((action) => action.name === 'approve') ? (
                      <TableCell align="center">Action</TableCell>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{item.id}</TableCell>
                      <TableCell align="center">{item.request_by.username}</TableCell>
                      <TableCell align="center">{item.request_to.username}</TableCell>
                      <TableCell align="center">
                        {formatNumber(item.amount)} {showCurrency()}
                      </TableCell>
                      <TableCell align="center">{item.description}</TableCell>
                      <TableCell align="center">{getStatus(item.status)}</TableCell>
                      {actions.find((action) => action.name === 'approve') ? (
                        <TableCell align="center">
                          {item.status === 1 ? (
                            <>
                              <Button
                                variant="contained"
                                color="error"
                                size="small"
                                sx={{ fontWeight: 'normal' }}
                                startIcon={<CloseIcon />}
                                disabled={disableReject === 'Reject' && RowId === item.id}
                                onClick={() => handleRequestType(2, item.id)}
                              >
                                Reject
                              </Button>

                              <Button
                                variant="contained"
                                size="small"
                                startIcon={<CheckIcon />}
                                sx={{ marginLeft: '8px', fontWeight: 'normal' }}
                                onClick={() => handleRequestType(1, item.id)}
                                disabled={disableReject === 'Approve' && RowId === item.id}
                              >
                                Approve
                              </Button>
                            </>
                          ) : (
                            <>
                              <Typography align="center">N/A</Typography>
                            </>
                          )}
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
                {loading && (
                  <TableRow>
                    <TableCell align="center" colSpan={12}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                {isNotFound && !loading && (
                  <TableRow>
                    <TableCell align="center" colSpan={6}>
                      <Typography variant="h6"> data not found</Typography>
                    </TableCell>
                  </TableRow>
                )}
                {!isNotFound && (
                  <TableFooter sx={{}}>
                    <TableRow>
                      <TableCell colSpan={3} align="left" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                        Total Entries : {extra_data ? extra_data.current_page_items : 0}
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                        {list.length > 0
                          ? formatNumber(
                              list
                                .reduce((accumulator, object) => parseFloat(accumulator) + parseFloat(object.amount), 0)
                                .toFixed(2)
                            )
                          : 0.0}{' '}
                        {showCurrency()}
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        align="center"
                        sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}
                      />
                    </TableRow>
                  </TableFooter>
                )}
              </Table>
            </CardContent>
            {list.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={extra_data ? extra_data.total_items : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Card>
        )}
      </Container>
    </>
  );
};
export default BalanceControlCenter;
