import { yupResolver } from '@hookform/resolvers/yup';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Button, IconButton, Modal, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { roundto2 } from '../../utils/helper';

const schema = yup.object().shape({
  multiplier: yup
    .string()
    // .moreThan(0, 'Amount must be greater than 0')
    .required('Multiplier is required')
    .test('greater-than-zero', 'Multiplier must be greater than 0', (value) => value > 0)
    .test('greater-than-one', 'Multiplier must be greater than 1', (value) => value > 1)
    .test('less-than-hundred', 'Multiplier must be less than 100', (value) => value < 100)
    .matches(/^\d+(\.\d{1,2})?$/, 'Only numbers with upto 2 decimals are allowed'),
});

const CreditApprovalModal = ({open, handleClose, handleRequestApproval, amount, disable}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [credit, setCredit] = useState(0);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    padding: '20px',
    borderRadius: '8px',
  };

  const handleChange = (newvalue) => {
    setCredit(roundto2(newvalue * amount));
  }

  useEffect(() => {
    reset()
    setCredit(0)
  }, [open])

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
          reset();
          setCredit(0)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h5">Credit Multiplier</Typography>
            <IconButton
              aria-label="Cancel"
              onClick={() => {
                handleClose();
                reset();
                setCredit(0)
              }}
            >
              <CancelIcon />
            </IconButton>
          </Stack>

          <Stack direction="row" justifyContent="space-between" alignItems="center" className="slectInput">
            <Box sx={{ flexBasis: '30%' }}>
              <TextField
                name="amount"
                fullWidth
                type="number"
                variant="outlined"
                label="Amount"
                placeholder="Amount"
                disableunderline="true"
                value={amount}
                disabled
              />
            </Box>
            <Box sx={{ flexBasis: '35%' }}>
              <TextField
                style={{ marginTop: errors.multiplier !== undefined ? '25px' : '0' }}
                {...register('multiplier')}
                name="multiplier"
                fullWidth
                type="number"
                variant="outlined"
                label="Multiplier(X)"
                placeholder="Multiplier"
                disableunderline="true"
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
              />
              <Typography variant="body2" color="error">
                {errors.multiplier?.message}
              </Typography>
            </Box>
            <Box sx={{ flexBasis: '30%' }}>
              <TextField
                name="credit"
                fullWidth
                type="number"
                variant="outlined"
                label="Credits"
                placeholder="Credits"
                disableunderline="true"
                value={credit}
                disabled
              />
            </Box>
          </Stack>
          <Stack mt={2} alignItems="flex-end">
            <Box mt={2}>
              <Button size="large" variant="contained" onClick={handleSubmit(handleRequestApproval)} disabled={disable}>
                Approve
              </Button>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default CreditApprovalModal;
