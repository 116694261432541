import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import {
  Box,
  Card,
  CircularProgress,
  Container,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
// components
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Iconify from '../../components/iconify';
import Label from '../../components/label';
import Scrollbar from '../../components/scrollbar';
import { UserListToolbar } from '../../sections/@dashboard/user';
import TableHeadSection from '../../sections/TableHeadSection';
// sections
// mock
import { UserContext } from '../../contexts/UserContext';
import { deleteDataWithAuth, postData } from '../../utils/api';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { formatDate, formatNumber, showCurrency, ToastError, ToastSuccess } from '../../utils/helper';
import UserDialog from './UserDialog';

/* eslint-disable react/no-unknown-property */
// ----------------------------------------------------------------------

const UserList = ({ model }) => {
  const [open, setOpen] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);

  const [currentId, setCurrentId] = useState(null);

  const [page, setPage] = useState(0);

  const [filterName, setFilterName] = useState(null);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [users, setUsers] = useState([]);

  const [extra_data, setExtraData] = useState([]);

  const { user } = useContext(UserContext);
  const [sortData, setSortData] = useState('DESC');
  const [sortBy, setSortBY] = useState('');
  const [loading, SetLoading] = useState(true);

  const handleClickOpen = () => {
    setDialogOpen(true);
    setOpen(null);
  };

  const handleOpenMenu = (id, event) => {
    setOpen(event.currentTarget);
    setCurrentId(id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setCurrentId(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchUsers(sortBy, sortBy ? sortData : '', newPage, rowsPerPage, filterName);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    fetchUsers(sortBy, sortBy ? sortData : '', 0, event.target.value, filterName);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
    fetchUsers(sortBy, sortBy ? sortData : '', 0, rowsPerPage, event.target.value);
  };

  const isNotFound = !users.length && !!filterName;
  const isNotFoundData = !users.length;

  const fetchUsers = async (sortBy, sortData, page, rowsPerPage, filterName) => {
    try {
      const data = {
        user_token: user.token,
        data: {
          search: filterName !== null ? filterName : '',
          status: '',
          order_key: sortBy !== '' ? sortBy : '',
          order_value: sortData,
        },
      };
      SetLoading(true);
      const response = await postData(`/admin/${model}/list?page=${page + 1}&size=${rowsPerPage}`, data);
      setUsers(response.data);
      setExtraData(response.extra_data);
      SetLoading(false);
    } catch (error) {
      console.log('admin list error :>> ', error);
    }
  };
  useEffect(() => {
    fetchUsers(sortBy, '', page, rowsPerPage, filterName);
  }, []);

  const handleDeleteAdminUser = async (confirm) => {
    toast.dismiss();
    if (confirm) {
      try {
        const data = {
          user_token: user.token,
          data: {},
        };
        const UserData = await deleteDataWithAuth(`/admin/${model}/delete/${currentId}`, data);
        if (UserData.status_code === 200) {
          ToastSuccess(UserData.message);
        } else {
          toast.warning(UserData.response.data.message, {
            position: 'top-center',
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            theme: 'colored',
          });
        }
        setTimeout(() => {
          toast.dismiss();
          setDialogOpen(false);
          setOpen(null);
          setCurrentId(null);
          setPage(0);
          fetchUsers(sortBy, sortBy ? sortData : '', page, rowsPerPage, filterName);
        }, 800);
      } catch (error) {
        console.log('error2 :>> ', error);
        ToastError(error.response.data.message);
      }
    } else {
      setDialogOpen(false);
    }
  };

  const TABLE_HEAD = [
    { id: 'id', label: 'ID', alignRight: false },
    { id: 'username', label: 'Username', alignRight: false },
    { id: 'first_name', label: 'First Name', alignRight: false },
    { id: 'last_name', label: 'Last Name', alignRight: false },
    // { id: 'email', label: 'Email', alignRight: false },
    { id: 'operator.username', label: 'Assigned To (Operator/Agent)', alignRight: false },
    // { id: 'country_code', label: 'Country', alignRight: false },
    { id: 'country_code', label: 'Phone', alignRight: false },
    { id: 'balance', label: 'Balance', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'created_at', label: 'Created At', alignRight: false },
    { id: '' },
  ];

  const handleSort = (sortBy) => {
    if (!isNotFoundData && !loading) {
      setSortBY(sortBy);
      setSortData(sortData === 'ASC' ? 'DESC' : 'ASC');
      fetchUsers(sortBy, sortData === 'ASC' ? 'DESC' : 'ASC', page, rowsPerPage);
    }
  };

  return (
    <>
      <UserDialog setDialog={setDialogOpen} open={dialogOpen} handleDeleteUser={handleDeleteAdminUser} />
      <Helmet>
        <title> User | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon icon="mdi:users-outline" style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }} />
            <Typography variant="h4" gutterBottom>
              User
            </Typography>
          </Box>

          {/* <Button
            to={`/admin/${model}/create`}
            variant="contained"
            component={Link}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New User
          </Button> */}
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={0}
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeHolder={'Search Users...'}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, maxHeight: 540 }}>
              <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table" margin={1}>
                <TableHead>
                  <TableRow>
                    <TableHeadSection
                      handleSort={handleSort}
                      sortData={sortData}
                      setSortData={setSortData}
                      tableHead={TABLE_HEAD}
                    />
                  </TableRow>
                </TableHead>
                {/* {users.length ? ( */}
                {!isNotFound && !loading && (
                  <TableBody>
                    {users.map((row) => (
                      <TableRow hover key={row.id} tabIndex={-1}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell component="td" scope="row" padding="none">
                          {row.username}
                        </TableCell>
                        <TableCell align="left">{row.first_name}</TableCell>
                        <TableCell align="left">{row.last_name}</TableCell>
                        <TableCell align="left">
                          {row.operator ? <Label color={'success'}>{row.operator?.username}</Label> : <></>}
                        </TableCell>
                        {/* <TableCell align="left">{row.email}</TableCell> */}
                        <TableCell align="left">
                          +{row.country_code}
                          &nbsp;
                          {row.phone}
                        </TableCell>
                        <TableCell align="left">{formatNumber(row.balance)} {showCurrency()}</TableCell>

                        <TableCell align="left">
                          <Label color={row.status === 0 ? 'error' : 'success'}>
                            {row.status === 0 ? 'Inactive' : 'Active'}
                          </Label>
                        </TableCell>

                        <TableCell align="left">{formatDate(row.created_at, 'ticket datetime')}</TableCell>

                        <TableCell align="right">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(e) => {
                              handleOpenMenu(row.id, e);
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
                {/* ) : ( */}
                <TableBody>
                  {isNotFound && !loading && (
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph textTransform={'capitalize'}>
                            data not found !
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typing or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                {/* )} */}
                {loading && !isNotFound && (
                  <TableRow>
                    <TableCell align="center" colSpan={12}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                {!isNotFound && !loading && (
                  <TableFooter sx={{}}>
                    <TableRow>
                      <TableCell colSpan={6} align="left" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                        Total Entries : {extra_data ? extra_data.current_page_items : 0}
                      </TableCell>
                      <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                        {users.length > 0
                          ? formatNumber(
                              users
                                .reduce(
                                  (accumulator, object) => parseFloat(accumulator) + parseFloat(object.balance),
                                  0
                                )
                                .toFixed(2)
                            )
                          : 0.0}{' '}
                        {showCurrency()}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          {!isNotFound && !loading && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={extra_data ? extra_data.total_items : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem component={Link} to={`/admin/${model}/edit/${currentId}`}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={handleClickOpen}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
};

export default UserList;
