import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
// routes
import { useContext, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import 'react-toastify/dist/ReactToastify.css';
import AutoLogout from './AutoLogout';
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { UserContext } from './contexts/UserContext';

// ----------------------------------------------------------------------

export default function App() {
  const { checkuser } = useContext(UserContext);

  // Dispatch the checkUser action on component mount
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (userData) checkuser(userData);
  }, [checkuser]);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ToastContainer />
          <ScrollToTop />
          <StyledChart />
          <Router />
          <AutoLogout />
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
