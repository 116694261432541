import { Icon } from '@iconify/react';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';
import { fNumber } from '../../../utils/formatNumber';
import { formatNumber, showCurrency } from '../../../utils/helper';

const TopAccount = () => {
  const { user, fetchBalance } = useContext(UserContext);
  // const [allNull,allSame] = commonAgentLogic(user);
  // const is_agent_supervisor = user.type === 'supervisor' ? (!!(allSame === true && allNull === false)) : false;
  // const is_agent_cashier = user.type === 'cashier' && user.shop[0].operator_id !== null && user.shop[0].role_name === 'Agent';
  return (
    <>
      <Box color="black" width="100%" sx={{ padding: '0 16px' }}>
        <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
          <Stack justifyContent="center" alignItems="center" sx={{ flexDirection: 'row' }}>
            {user.type === 'cashier' && user.shop.length > 0 ? (
              <Typography variant="body1" fontWeight="600" component="h6" sx={{ fontSize: '18px' }}>
                {user.shop[0].name}
              </Typography>
            ) : (
              <>
                <Typography variant="body1" fontWeight="600" component="h6" sx={{ fontSize: '18px' }}>
                  {user.username}
                </Typography>
              </>
            )}
          </Stack>
          {/* {user.type} {user.type !== 'aviator' && user.type !== 'aviator-operator' ? 'TRUE' : 'FALSE'} */}

          <Stack direction="row" sx={{ marginLeft: 'auto' }}>
            <Tooltip title="Profile">
              <IconButton aria-label="profile" component={Link} to={`/${user.type}/profile`}>
                <PersonIcon />
              </IconButton>
            </Tooltip>
            {user.type === 'aviator' || user.type === 'aviator-operator' ? (
              <></>
            ) : (
              <Tooltip title="Refresh Balance" className="refreshBtn">
                <IconButton
                  aria-label="settings"
                  onClick={() => fetchBalance()}
                  sx={{ marginRight: '-10px', '&:hover': {} }}
                >
                  <Icon icon="mynaui:refresh" style={{ fontSize: '23px', marginRight: '5px' }} />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </Stack>
        {/* row 2 */}

        {/* {user.type === 'agent' ||  is_agent_supervisor || is_agent_cashier?  */}

        {user.type === 'aviator' || user.type === 'aviator-operator' ? (
          <></>
        ) : user.type === 'agent' ? (
          <Stack sx={{ marginRight: '25px' }} direction="column" justifyContent="center" alignItems="center">
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Typography sx={{ marginRight: '5px' }}>Cash balance: </Typography>
              <Typography fontWeight="800">{`${formatNumber(user.balance)} ${showCurrency()}`}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="flex-start" alignItems="center">
              <Typography>Credit balance: </Typography>
              <Typography marginRight="70px" fontWeight="800">
                {fNumber(user.credit)}
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start">
              <Typography sx={{ marginRight: '10px' }}>Cash balance: </Typography>
              <Typography fontWeight="800">{`${formatNumber(user.balance)} ${showCurrency()}`}</Typography>
            </Stack>
            {user?.type === 'cashier' && user?.shop[0]?.role_name === 'Agent' && (
              <Stack direction="row" justifyContent="center" alignItems="center" sx={{ marginRight: '30px' }}>
                <Typography sx={{ marginRight: '10px' }}>Credit balance: </Typography>
                <Typography fontWeight="800">{fNumber(user.credit)}</Typography>
              </Stack>
            )}
          </Stack>
        )}
      </Box>
      <Divider sx={{ pb: 2 }} />
    </>
  );
};

export default TopAccount;
