import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { UserContext } from '../contexts/UserContext';
import { MY_GLOBAL_CONST } from '../utils/GlobalConstant';
import { fetchDataWithAuth, postData } from '../utils/api';
import { ToastError, ToastSuccess, generateHash, getTimeStamp, showCurrency } from '../utils/helper';
import OtpModel from './OtpModel';

const schema = yup.object().shape({
  amount: yup
    .string()
    .required('Balance is required')
    .test('greater-than-zero', 'Value must be greater than 0', (value) => value > 0)
    .matches(/^\d+(\.\d{1,2})?$/, 'Only numbers with upto 2 decimals are allowed'),
  note: yup.string().required('note is required').min(2),
  // transaction_type: yup.string().required('Transaction type is required'),
});
const BalanceTransfer = () => {
  const [player, setPlayer] = useState(null);
  const [open, setOpen] = useState(false);
  const [otpVal, setOtpVal] = useState('');
  const [disable, setDisable] = useState(false);
  const [processBtnDisable, setProcessBtnDisable] = useState(false);
  const [seconds, setSeconds] = useState(5 * 60);
  const [otpResponse, setOtpResponse] = useState([]);
  const [users, setUsers] = useState([]);
  const [amount, setAmount] = useState('');
  const [note, SetNote] = useState('');
  const [type, setType] = useState(1);
  const [error, setError] = useState('');

  const { user, updateBalance, fetchBalance } = useContext(UserContext);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  
  const onSubmit = async (formvalue) => {
    toast.dismiss();
    setProcessBtnDisable(true)
    setPlayer(null);
    reset();
    const data = {
      user_token: user.token,
      data: {
        user_id: player.id,
        amount: formvalue.amount,
        transaction_type: type,
        note: formvalue.note,
        date_time: getTimeStamp(),
      },
    };
    const hashSignature = generateHash(data.data);
    data.data.hash = hashSignature;
    try {
      const balanceTransfer = await postData(`/${user.type}/transfer/balance`, data);
      setOtpResponse(balanceTransfer.data[0]);
      setTimeout(async () => {
        if (balanceTransfer.status_code === 200) {
          if (type === '2') setOpen(true);
          else await updateBalance(balanceTransfer.data[0].cashier_balance);
        }
      }, 700);
      ToastSuccess(balanceTransfer.message);
      setPlayer(null);
      setType(type);
      SetNote('');
      setAmount('');
    } catch (error) {
      console.log('balance request:> ', error);
      ToastError(error.response.data.message);
    }
    setProcessBtnDisable(false)
    fetchBalance();
  };
  const handlePlayerChange = async (event, newValue) => {
    if(newValue != null) {
      const data = {
        user_token: user.token,
        data: {
          user_id: newValue.id
        },
      };
      const response = await postData(`/${user.type}/user/balance`, data);
      newValue.balance = response.data[0].balance;
    }
    setPlayer(newValue);
  };
  // otp method start
  const handleClose = async () => {
    toast.dismiss();

    const data = {
      user_token: user.token,
      data: {
        user_id: otpResponse.user_id,
        shop_transaction_id: otpResponse.shop_transaction_id,
        user_transaction_id: otpResponse.user_transaction_id,
      },
    };
    try {
      const cancleRequestRespone = await postData(`/${user.type}/transfer/cancel`, data);

      if (cancleRequestRespone.status_code === 200) {
        setOpen(false);
        setPlayer(null);
        setType(type);
        SetNote('');
        setAmount('');

        setError('');
        setTimeout(() => {
          setSeconds(5 * 60);
          setDisable(false);
        }, 500);
      }
      ToastSuccess(cancleRequestRespone.message);
      setOpen(false);
    } catch (error) {
      console.log('otp error:>> ', error);
    }
  };
  useEffect(() => {
    const getUsers = async () => {
      const data = {
        user_token: user.token,
        data: {},
      };
      const response = await fetchDataWithAuth(`/${user.type}/user/list`, data);
      const userData = response.data;
      setUsers(userData);
    };
    getUsers();
  }, [setUsers]);

  const onSubmitOtp = async () => {
    toast.dismiss();

    try {
      if (otpVal !== '') {
        if (otpVal.length !== 6) {
          setError('OTP should be 6 digits long.');
          return;
        }

        if (!/^\d+$/.test(otpVal)) {
          setError('OTP should contain only numbers.');
          return;
        }
      } else {
        setError('Please Enter OTP');
        return;
      }
      const data = {
        user_token: user.token,
        data: {
          isBalanceTransfer: true,
          user_id: otpResponse.user_id,
          otp: otpVal,
          shop_transaction_id: otpResponse.shop_transaction_id,
          user_transaction_id: otpResponse.user_transaction_id,
          date_time: getTimeStamp(),
        },
      };
      const hashSignature = generateHash(data.data);
      data.data.hash = hashSignature;
      const otpRespone = await postData(`/${user.type}/verify/otp`, data);

      if (otpRespone.status_code === 200) {
        setOpen(false);
        setPlayer(null);
        setType(type);
        SetNote('');
        setAmount('');
      }
      await updateBalance(otpRespone.data[0].cashier_balance);
      ToastSuccess(' balance transfer successfully');
    } catch (error) {
      console.log('otp error:>> ', error);
      ToastError(error.response.data.message);
    }
    fetchBalance();
  };
  const handleOtpChange = (newValue) => {
    setOtpVal(newValue);
    setError('');
  };
  const onResendOtp = async () => {
    const data = {
      user_token: user.token,
      data: {
        user_id: otpResponse.user_id,
        isBalanceTransfer: true,
        date_time: getTimeStamp(),
      },
    };
    const hashSignature = generateHash(data.data);
    data.data.hash = hashSignature;
    try {
      const profileData = await postData(`/${user.type}/resent/otp`, data);
      setDisable(true);
      setTimeout(() => {
        setDisable(false);
      }, 300000);

      setSeconds(5 * 60);
    } catch (error) {
      console.log('resend errror:>> ', error);
    }
  };

  // otp method End

  const handleTransactionType = (newValue) => {
    setType(newValue);
    setValue('transaction_type', newValue);
  };
  const onHandleChange = async (event) => {
    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/\D/g, '');
    setAmount(sanitizedValue);
  };
  return (
    <>
      <Helmet>
        <title> Balance Transfer | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>
      <Container maxWidth="sm">
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Box display="flex">
              <Icon
                icon="mingcute:transfer-line"
                style={{ fontSize: '23px', marginRight: '3px', alignSelf: 'center' }}
              />
              <Typography variant="h4">Balance Transfer</Typography>
            </Box>
          </Stack>

          <Card>
            <CardContent>
              <Stack flexDirection="row" sx={{ mb: 1 }}>
                <Box width="100%">
                  <Autocomplete
                    fullWidth
                    disablePortal
                    id="player_box"
                    options={users}
                    autoHighlight
                    value={player}
                    onChange={handlePlayerChange}
                    getOptionLabel={(option) => option.username}
                    renderInput={(params) => <TextField {...params} value={player} label="Player" />}
                  />
                </Box>
              </Stack>
              {player ? (
                <Box sx={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px' }}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography fontWeight="bold" variant="body1" sx={{ width: '20%' }}>
                      Player ID <Typography variant="body1">{player.id}</Typography> 
                    </Typography>
                    <Typography fontWeight="bold" variant="body1" sx={{ width: '20%' }}>
                      Username <Typography variant="body1">{player.username}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" variant="body1" sx={{ width: '20%' }}>
                      Balance <Typography variant="body1">{player.balance} <b>{showCurrency()}</b></Typography>
                    </Typography>
                  </Stack>
                </Box>
              ) : (
                ''
              )}
              <Stack sx={{ mb: 1 }}>
                <FormControl variant="standard">
                  <RadioGroup
                    aria-labelledby="demo-error-radios"
                    name="quiz"
                    // value={type}
                    defaultValue={1}
                    sx={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <FormControlLabel
                      {...register('transaction_type')}
                      disabled={!player}
                      control={<Radio />}
                      onChange={(e) => handleTransactionType(e.target.value)}
                      value="2"
                      label="Withdrawal"
                    />

                    <FormControlLabel
                      {...register('transaction_type')}
                      onChange={(e) => handleTransactionType(e.target.value)}
                      disabled={!player}
                      control={<Radio />}
                      value="1"
                      // defaultChecked={1}
                      label="Deposit"
                    />
                  </RadioGroup>
                  <Typography variant="body2" color="error">
                    {errors.transaction_type?.message}
                  </Typography>
                </FormControl>
              </Stack>

              <Stack sx={{ mb: 2 }}>
                {/* <TextField
                  sx={{}}
                  {...register('amount')}
                  onChange={(e) => setAmount(e.target.value)}
                  disabled={!player}
                  id="outlined-basic"
                  type="number"
                  placeholder="Amount"
                  variant="outlined"
                  fullWidth
                  label="Amount"
                /> */}
                <TextField
                  sx={{}}
                  {...register('amount')}
                  onChange={onHandleChange}
                  disabled={!player}
                  id="outlined-basic"
                  value={amount}
                  // type="number"
                  placeholder="Amount"
                  variant="outlined"
                  fullWidth
                  label="Amount"
                />

                <Typography variant="body2" color="error">
                  {errors.amount?.message}
                </Typography>
              </Stack>

              <Stack sx={{ mb: 1 }}>
                <TextField
                  style={{}}
                  {...register('note')}
                  disabled={!amount}
                  placeholder="Note..."
                  onChange={(e) => SetNote(e.target.value)}
                  multiline
                  minRows={3}
                  label="Note"
                />
                <Typography variant="body2" color="error">
                  {errors.note?.message}
                </Typography>
              </Stack>
              <Button
                disabled={!note || processBtnDisable}
                variant="contained"
                type="submit"
                sx={{ display: 'flex', justifyContent: 'right', marginLeft: 'auto' }}
              >
                proceed
              </Button>
            </CardContent>
          </Card>
        </Box>
      </Container>
      <OtpModel
        open={open}
        handleClose={handleClose}
        onSubmitOtp={onSubmitOtp}
        handleOtpChange={handleOtpChange}
        otpVal={otpVal}
        onResendOtp={onResendOtp}
        setSeconds={setSeconds}
        seconds={seconds}
        disable={disable}
        error={error}
      />
    </>
  );
};

export default BalanceTransfer;
