import { Box, Modal, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import Iconify from '../components/iconify/Iconify';

const CloneBetDialog = ({ open, handleClose, pbet_uid }) => (
  <div>
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          height: 'auto',
          width: 'auto',
          p: 2,
        }}
      >
        <Link to="#" onClick={() => handleClose()} className="popCloseicon" style={{ color: grey[900] }}>
          <Iconify icon={'mdi:close-circle'} />
        </Link>
        <Box textAlign="center">
          <Box>
            <img src="/images/modalLogo.png" className="maxWith100" alt="" />
          </Box>

          <Typography component="h3" variant="h6" sx={{ marginTop: '10px', marginBottom: '10px' }}>
            Your bet is copied
          </Typography>
          <Typography component="h3" variant="h6" sx={{ marginTop: '10px', marginBottom: '10px' }}>
            Here is your temp ticket number
          </Typography>
          <Typography component="h1" variant="h5" fontWeight="600">
            {pbet_uid}
          </Typography>
        </Box>
      </Box>
    </Modal>
  </div>
);

export default CloneBetDialog;
