const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  apiUrl: process.env.REACT_APP_API_URL,
  apiAdminUrl: process.env.REACT_APP_ADMIN_URL,
  apiAviatorUrl: process.env.REACT_APP_AVIATOR_API_URL,
  secretKey: process.env.REACT_APP_SECRET_KEY,
  currency: 'NGN',
  Domain: 'Bet Monkey'
};

export default config;
