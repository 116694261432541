import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { UserContext } from '../../contexts/UserContext';
import { fetchDataWithAuth, postData } from '../../utils/api';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { ToastError, ToastSuccess, formatDate, generateHash, getTimeStamp } from '../../utils/helper';

const schema = yup.object().shape({
  first_name: yup
    .string()
    .required('First Name is required')
    .matches(/^[^\s].*/, 'starting spaced is not allowed'),
  last_name: yup
    .string()

    .required('Last Name is required')
    .matches(/^[^\s].*/, 'starting spaced is not allowed'),
  username: yup
    .string()

    .required('Username is required')
    .matches(/^[^\s].*/, 'starting spaced is not allowed'),
  // email: yup
  //   .string()

  //   .required('Email is required')
  //   .matches(/^[^\s].*/, 'starting spaced is not allowed')
  //   .email('Invalid email'),

  email: yup.lazy((value) => {
    if (value) {
      return yup
        .string()
        .required('Email is required')
        .matches(/^[^\s].*/, 'starting spaced is not allowed')
        .email('Invalid email');
    }
    return yup.string();
  }),
  phone: yup
    .string()
    // .moreThan(0, 'Amount must be greater than 0')
    .required('Phone Number is required')
    .min(7, 'Please enter minimum 7 numbers')
    .max(12, 'Please enter maximum 12 numbers'),
  balance: yup
    .string()
    .required('Balance is required')
    .matches(/^[^\s].*/, 'starting spaced is not allowed')
    .matches(/^\d+(\.\d+)?$/, 'Only numbers are allowed'),

  // address: yup
  //   .string()
  //   .required('Address is required')
  //   .matches(/^[^\s].*/, 'starting spaced is not allowed'),
  address: yup.lazy((value) => {
    if (value) {
      return yup.string();
    }
    return yup.string();
  }),
  status: yup.string().required('Status is required'),
  password: yup.lazy((value) => {
    if (value) {
      return yup.string().min(6, 'Password must be at least 6 characters');
      // .required('Password is required')
      // .matches(/^[^\s].*/, 'starting spaced is not allowed')
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/,
      //   'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      // );
    }
    return yup.string();
  }),
  dob: yup.lazy((value) => {
    if (value) {
      return (
        yup
          .string()
          // .nullable('dfdsffvdfsgdfhggdg')
          // .required('Date of Birth is required')
          .max(new Date(), 'Date of Birth cannot be in the future')
          .test('is-adult', 'You must be at least 18 years old', (value) => {
            if (!value) return false; // Invalid date
            const today = new Date();
            const dob = new Date(value);
            const age = today.getFullYear() - dob.getFullYear();
            if (age > 18) return true;
            if (age === 18) {
              // Check month and day for 18th birthday
              if (today.getMonth() > dob.getMonth()) return true;
              if (today.getMonth() === dob.getMonth() && today.getDate() >= dob.getDate()) return true;
            }
            return false;
          })
      );
    }
    return yup.string();
  }),
});

const UserForm = ({ action }) => {
  const Redirect = useNavigate();
  const params = useParams();
  const [date, setDate] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { user } = useContext(UserContext);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [selectValue, setSelectValue] = useState(null);
  const [disable, setDisable] = useState(false);
  const [loading,setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getUser();
  }, [setValue]);

  const getUser = async () => {
    setLoading(true)
    if (params.id) {
      // Fetch user data from the API based on the userId
      const data = {
        user_token: user.token,
        data: {},
      };
      const response = await fetchDataWithAuth(`/admin/user/get/${params.id}`, data);
      const userData = await response.data[0];
      // Set form values using setValue
      setValue('first_name', userData.first_name);
      setValue('last_name', userData.last_name);
      setValue('username', userData.username);
      setValue('email', userData.email);
      setValue('balance', userData.balance);
      setValue('address', userData.address);
      setValue('dob', userData.dob);
      setValue('country_code', userData.country_code);
      setValue('phone', userData.phone);
      setValue('status', userData.status);
      setPhoneNumber(userData.phone);
      setCountryCode(userData.country_code);
      setSelectValue(userData.status);
      setDate(dayjs(formatDate(userData.dob, 'datepicker')));
    }
    setLoading(false)
  };

  const handleOnChange = (value, data) => {
    const country_code = data.dialCode;
    const phone_no = value.slice(data.dialCode.length);
    setValue('phone', phone_no, true);
    setValue('country_code', country_code);
  };
  const today = new Date();
  const todaydate = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear() - 18;

  const onSubmit = async (userData) => {
    setLoading(true)
    setDisable(true)
    toast.dismiss();
    let data = null;
    let UserData = null;
    try {
      if (params.id) {
        data = {
          user_token: user.token,
          data: {
            id: params.id,
            first_name: userData.first_name,
            last_name: userData.last_name,
            username: userData.username,
            email: userData.email,
            country_code: userData.country_code,
            phone: userData.phone,
            balance: userData.balance,
            address: userData.address,
            dob: userData.dob,
            status: selectValue,
            date_time: getTimeStamp(),
            password: userData.password,
          },
        };
        const hashSignature = generateHash(data.data);
        data.data.hash = hashSignature;
        UserData = await postData('/admin/user/update', data);
      } else {
        data = {
          user_token: user.token,
          data: {
            first_name: userData.first_name,
            last_name: userData.last_name,
            username: userData.username,
            email: userData.email,
            country_code: userData.country_code,
            phone: userData.phone,
            balance: userData.balance,
            address: userData.address,
            dob: userData.dob,
            status: userData.status,
            date_time: getTimeStamp(),
            password: userData.password,
          },
        };
        const hashSignature = generateHash(data.data);
        data.data.hash = hashSignature;
        UserData = await postData('/admin/user/add', data);
      }
      ToastSuccess(UserData.message);
      setTimeout(() => {
        toast.dismiss();
        Redirect('/admin/user');
      }, 800);
      setDisable(false)
    } catch (error) {
      console.log('error2 :>> ', error);
      ToastError(error.response.data.message);
      setDisable(false)
    }
    setLoading(false)
  };

  const onchangedate = async (e) => {
    if (e !== null) {
      const start_date = new Date(e);
      const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
      await trigger('dob');
      setValue('dob', utcstartDate);
      setDate(dayjs(formatDate(e, 'datepicker')));
    }
  };

  return (
    <>
      <Helmet>
        <title> User {action} | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>

      <Container maxWidth="lg">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User {action}
          </Typography>
        </Stack>
        <Card>
          <CardContent>
            {loading ?  
         <Stack display="flex" flexDirection="row" justifyContent="center">
         <CircularProgress size={35} />
       </Stack> : <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="row">
                <Box padding={2} width="50%">
                  <TextField
                    label="Username"
                    {...register('username')}
                    placeholder="Username"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                      classes: { root: 'input-label' },
                    }}
                    InputProps={{ classes: { root: 'input-field' } }}
                  />
                  <Typography variant="body2" color="error">
                    {errors.username?.message}
                  </Typography>
                </Box>

                <Box padding={2} width="50%">
                  <TextField
                    label="First Name"
                    {...register('first_name')}
                    placeholder="First Name"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                      classes: { root: 'input-label' },
                    }}
                    InputProps={{ classes: { root: 'input-field' } }}
                  />
                  <Typography variant="body2" color="error">
                    {errors.first_name?.message}
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row">
                <Box padding={2} width="50%">
                  <TextField
                    label="Last Name"
                    {...register('last_name')}
                    placeholder="Last Name"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                      classes: { root: 'input-label' },
                    }}
                    InputProps={{ classes: { root: 'input-field' } }}
                  />
                  <Typography variant="body2" color="error">
                    {errors.last_name?.message}
                  </Typography>
                </Box>

                <Box padding={2} width="50%">
                  <TextField
                    label="Email"
                    {...register('email')}
                    placeholder="Email"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                      classes: { root: 'input-label' },
                    }}
                    InputProps={{ classes: { root: 'input-field' } }}
                  />
                  <Typography variant="body2" color="error">
                    {errors.email?.message}
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row">
                <Box padding={2} width="50%">
                  <PhoneInput
                    color="black"
                    inputProps={{
                      name: 'phone',
                      required: true,
                      autoFocus: true,
                      ref: { ...register('phone') },
                    }}
                    InputProps={{ classes: { root: 'input-field' } }}
                    value={`${countryCode}${phoneNumber}`}
                    InputLabelProps={{
                      height: '56px',
                      shrink: true,
                      classes: { root: 'input-label' },
                    }}
                    variant="outlined"
                    placeholder="Phone No"
                    fullWidth
                    country={`${MY_GLOBAL_CONST.country_short_code}`}
                    onChange={handleOnChange}
                  />
                  <Typography variant="body2" color="error">
                    {errors.phone?.message}
                  </Typography>
                </Box>

                <Box padding={2} width="50%">
                  <TextField
                    label="Balance"
                    type="text"
                    {...register('balance')}
                    placeholder="Balance"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                      classes: { root: 'input-label' },
                    }}
                    InputProps={{ classes: { root: 'input-field' } }}
                  />
                  <Typography variant="body2" color="error">
                    {errors.balance?.message}
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row">
                <Box padding={2} width="50%">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      name="dob"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          sx={{ width: '100%' }}
                          maxDate={dayjs(`${month}-${todaydate}-${year}`)}
                          value={date}
                          onChange={(newValue) => {
                            field.onChange(newValue);
                            onchangedate(newValue);
                          }}
                          format="DD-MM-YYYY"
                          slotProps={{
                            textField: {
                              error: false,
                            },
                          }}
                          // label="Date Of Birth"
                          renderInput={(params) => <TextField {...params} size="medium" marginBottom="20px" />}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <Typography variant="body2" color="error">
                    {errors.dob?.message}
                  </Typography>
                </Box>

                <Box display="flex" padding={2} width="50%" className="slectInput">
                  <FormControl fullWidth sx={{ textAlign: 'left' }}>
                    <InputLabel id="status-label">{!params.id ? 'Status' : ''}</InputLabel>
                    <Select
                      labelId="status-label"
                      id="status"
                      name="status"
                      {...register('status')}
                      // error={!!errors.status}
                      value={selectValue}
                      onChange={(event) => setSelectValue(event.target.value)}
                    >
                      <MenuItem value={1}>Active</MenuItem>
                      <MenuItem value={0}>Inactive</MenuItem>
                    </Select>
                    <Typography variant="body2" color="error">
                      {errors.status?.message}
                    </Typography>
                  </FormControl>
                </Box>
              </Stack>
              <Stack direction="row">
                <Box padding={2} width="50%">
                  <TextField
                    placeholder="Enter Password"
                    {...register('password')}
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    sx={{}}
                    fullWidth
                    variant="outlined"
                    label="Your Password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Typography variant="body2" color="error">
                    {errors.password?.message}
                  </Typography>
                </Box>

                <Box padding={2} width="50%">
                  <TextField
                    multiline
                    rows={4}
                    label="Address"
                    {...register('address')}
                    placeholder="Address"
                    // error={!!errors.address}
                    InputLabelProps={{
                      shrink: true,
                      classes: { root: 'input-label' },
                    }}
                    InputProps={{ classes: { root: 'input-field' } }}
                    // helperText={errors.address?.message}
                    fullWidth
                  />
                  <Typography variant="body2" color="error">
                    {errors.address?.message}
                  </Typography>
                </Box>
              </Stack>

              <Box display="flex" padding={2} width="50%">
                <Button type="submit" variant="contained" size="large" disabled={disable}>
                  {action} User
                </Button>
              </Box>
            </form>}
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default UserForm;
