import { Card, CardContent, Container, Grid, Stack, Typography } from '@mui/material';
import ApexCharts from 'apexcharts';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';

<Helmet>
  <title> Shop Stats | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
</Helmet>;

function generateRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function generateRandomArray(length, min, max) {
  const array = Array.from({ length }, () => generateRandomNumber(min, max));
  return array;
}

const ShopStats = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      const length = Math.floor(Math.random() * 9991) + 10;
      const newData = generateRandomArray(length, 10, 10000);
      setData(newData);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const chartOptions = {
      series: [{ data }],
      chart: {
        height: 350,
        type: 'line',
        animations: {
          enabled: false, // Disable animations for real-time updating
        },
        toolbar: {
          show: false,
        },
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'numeric',
        labels: {
          show: false,
        },
      },
      yaxis: {
        min: 0,
        max: 10000,
      },
    };

    const chart = new ApexCharts(document.getElementById('chart'), chartOptions);
    chart.render();

    return () => chart.destroy();
  }, [data]);

  return (
    <>
      <Container maxWidth="md">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Shop Stats
          </Typography>
        </Stack>

        <Card>
          <CardContent>
            <Grid item xs={12} md={6} lg={8}>
              <div id="chart" />
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default ShopStats;
