import { Icon } from '@iconify/react';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { UserContext } from '../../contexts/UserContext';
import { postData } from '../../utils/api';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { ToastSuccess, formatDate, formatNumber, showCurrency } from '../../utils/helper';

const PlayerRTP = () => {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [open, setOpen] = useState(false);
  const [dateDay, setdateDay] = useState('custom');
  const currentDate = new Date();
  const Today_Date = dayjs(currentDate);
  const [selectedDateRange, setSelectedDateRange] = useState(['', '']);
  const [UserRTPList, setUserRTPList] = useState([]);
  const [sortData, setSortData] = useState('ASC');
  const [sortBy, setSortBY] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, SetLoading] = useState(false);
  const [actionLoader, SetActionLoader] = useState(false);
  const [extra_data, setExtraData] = useState([]);
  const [openRowId, setOpenRowId] = useState(null);
  const [filterName, setFilterName] = useState(null);
  const [RowId, setRowId] = useState(0);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const { user } = useContext(UserContext);

  const fetchUsersRTP = async (sortBy, sortData, start_Date, end_Date, page, rowsPerPage, filterName) => {
    SetLoading(true);
    const start_date = new Date(start_Date === '' ? currentDate : start_Date);
    const endDate = new Date(end_Date === '' ? Today_Date : end_Date);
    // setSelectedDateRange([dayjs(start_date), dayjs(endDate)]);
    //   const hours = endDate.getHours();
    //   const minutes = endDate.getMinutes();
    //  const seconds = endDate.getSeconds();
    //  if(hours===0 || minutes===0 || seconds===0){
    //   endDate.setHours(23, 59, 59);
    //  }
    const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);

    try {
      const data = {
        user_token: user.token,
        data: {
          search: filterName === null ? '' : filterName,
          order_key: sortBy !== '' ? sortBy : '',
          order_value: sortData !== '' ? sortData : '',
          start_date: start_Date === '' ? '' : formatDate(utcstartDate, 'datepicker'),
          end_date: end_Date === '' ? '' : formatDate(utcEndDate, 'datepicker'),
        },
      };
      const response = await postData(`/${user.type}/reports/risk/rtp?page=${page + 1}&size=${rowsPerPage}`, data);
      setUserRTPList(response.data);
      setExtraData(response.extra_data);
      SetLoading(false);
    } catch (error) {
      console.log('error :>> ', error);
      SetLoading(false);
    }
  };
  const handleSort = (sortBy) => {
    setSortBY(sortBy);
    setSortData(sortData === 'ASC' ? 'DESC' : 'ASC');
    fetchUsersRTP(
      sortBy,
      sortData === 'ASC' ? 'DESC' : 'ASC',
      selectedDateRange[0],
      selectedDateRange[1],
      page,
      rowsPerPage,
      filterName
    );
  };

  useEffect(() => {
    fetchUsersRTP(sortBy, '', selectedDateRange[0], selectedDateRange[1], page, rowsPerPage, filterName);
    // setSelectedDateRange([dayjs(currentDate.setHours(12, 0, 0)), Today_Date]);
    setSelectedDateRange(['', '']);
  }, []);

  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  const lastSevenDays = new Date(currentDate);
  lastSevenDays.setDate(currentDate.getDate() - 7);

  const lastMonth = new Date(currentDate);
  lastMonth.setMonth(currentDate.getMonth() - 1);

  const onchange = (event) => {
    setdateDay(event.target.value);
    switch (event.target.value) {
      case 'Today':
        return setSelectedDateRange([dayjs(currentDate), Today_Date]);
      case 'Yesterday':
        return setSelectedDateRange([dayjs(yesterday), dayjs(yesterday)]);
      case 'Last 7 days':
        return setSelectedDateRange([dayjs(lastSevenDays), Today_Date]);
      case 'Last 30 days':
        return setSelectedDateRange([dayjs(lastMonth), Today_Date]);
      case 'custom':
        return setSelectedDateRange(['', '']);
      default:
        return setSelectedDateRange([Today_Date, Today_Date]);
    }
  };
  const isNotFound = !UserRTPList.length;

  const handleDateChange = (newValue) => {
    setSelectedDateRange([newValue[0] !== null ? newValue[0] : '', newValue[1] !== null ? newValue[1] : '']);
    if (newValue[0] && newValue[1]) {
      setSelectedDateRange(newValue);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchUsersRTP(
      sortBy,
      sortBy ? sortData : '',
      selectedDateRange[0],
      selectedDateRange[1],
      newPage,
      rowsPerPage,
      filterName
    );
  };

  const handleChangeRowsPerPage = (event) => {
    // setPage(0);
    setRowsPerPage(event.target.value);
    fetchUsersRTP(
      sortBy,
      sortBy ? sortData : '',
      selectedDateRange[0],
      selectedDateRange[1],
      page,
      event.target.value,
      filterName
    );
  };

  const onSearch = (details) => {
    setPage(0);
    // if (filterName !== null || selectedDateRange[0] !== '' || selectedDateRange[1] !== '') {
    fetchUsersRTP(
      sortBy,
      sortBy ? sortData : '',
      selectedDateRange[0],
      selectedDateRange[1],
      0,
      rowsPerPage,
      filterName
    );
    // }
  };

  const clearFilter = () => {
    // setSelectedDateRange([dayjs(currentDate.setHours(12, 0, 0)), Today_Date]);
    setSelectedDateRange(['', '']);
    setSortBY('');
    setPage(0);
    setFilterName('');
    setdateDay('custom');
    // setdateDay('Today');
    // if (filterName !== null || filterName !== '' || selectedDateRange[0] !== '' || selectedDateRange[1] !== '') {
    fetchUsersRTP('', '', '', '', 0, rowsPerPage, null);
    // }
  };

  const handleRowClick = (userId) => {
    setOpenRowId((prevId) => (prevId === userId ? null : userId));
  };

  const handleChange = async (event, id) => {
    setRowId(id);
    const newStatus = event.target.checked ? 1 : 0;
    const data = {
      user_token: user.token,
      data: {
        user_id: Number(id),
        status: newStatus,
      },
    };
    try {
      SetActionLoader(true);
      const response = await postData(`/${user.type}/user/action`, data);
      if (response.status_code === 200) {
        const updateStatus = UserRTPList.map((data) => {
          if (Number(data.user_id) === Number(id)) {
            data.user_status = newStatus;
          }
          return data;
        });
        setUserRTPList(updateStatus);
        ToastSuccess(response.message);
        SetActionLoader(false);
      }
    } catch (error) {
      console.error('Error changing user status:', error);
      SetActionLoader(false);
    }
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
    // fetchAdminUsers(sortBy, sortBy ? sortData : '', 0, rowsPerPage, event.target.value);
  };

  return (
    <>
      <Helmet>
        <title> Player RTP | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>
      <Container maxWidth="xl" className="cardDesign">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon icon="uil:user-square" style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }} />
            <Typography variant="h4" gutterBottom>
              Player RTP
            </Typography>
          </Box>
        </Stack>

        <form onSubmit={handleSubmit(onSearch)}>
          <Stack display="flex" flexDirection="row" className="temptranstaionStack pd0_mrg0">
            <Box mt={3} sx={{ flexBasis: '20%' }} className="slectInput width_100 pd0_mrg0">
              <FormControl sx={{ minWidth: 150 }} fullWidth>
                <InputLabel id="demo-simple-select-label">Select Dates</InputLabel>
                <Select name="dates" id="dates" value={dateDay} onChange={onchange}>
                  <MenuItem value={'Today'}>Today</MenuItem>
                  <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                  <MenuItem value={'Last 7 days'}>Last 7 days</MenuItem>
                  <MenuItem value={'Last 30 days'}>Last 30 days</MenuItem>
                  <MenuItem value={'custom'}>Custom</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box padding={2} sx={{ flexBasis: '40%' }} className="slectInput width_100 pd0_mrg0">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DesktopDateRangePicker']}>
                  <DemoItem component="DesktopDateRangePicker">
                    <DesktopDateRangePicker
                      localeText={{ start: 'Start Date', end: 'End Date' }}
                      size="small"
                      maxDate={Today_Date}
                      value={selectedDateRange}
                      onChange={handleDateChange}
                      disabled={dateDay !== 'custom'}
                      calendars={1}
                      format="DD-MM-YYYY"
                      slotProps={{
                        textField: {
                          error: false,
                        },
                      }}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </Box>
          </Stack>
          <Card
            sx={{
              minWidth: 275,
              height: 'auto',
              marginTop: '45px',
            }}
          >
            <CardContent>
              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                className="temptranstaionStack pd0_mrg0"
              >
                <Box display="flex" flexDirection="row" width="20%">
                  <Box className="slectInput width_100 pd0_mrg0" flex={1}>
                    <TextField value={filterName} type="text" onChange={handleFilterByName} placeholder="Search..." />
                  </Box>
                </Box>
                <Box px={1} className="width_100" paddingLeft={2}>
                  <Stack display="flex" flexDirection="row" justifyContent="flex-end">
                    <Stack display="flex" flexDirection="row">
                      <Box margin={2} className="slectInput width_100 pd0_mrg0">
                        <Button
                          onClick={clearFilter}
                          color="secondary"
                          variant="outlined"
                          size="large"
                          disableElevation
                          disabled={loading}
                        >
                          Clear
                        </Button>
                      </Box>
                      <Box margin={2} className="slectInput width_100 pd0_mrg0">
                        <Button
                          type="submit"
                          color="secondary"
                          variant="contained"
                          size="large"
                          disabled={loading}
                          disableElevation
                        >
                          Search
                        </Button>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
              </Stack>

              <Box sx={{ overflow: 'auto' }}>
                <TableContainer component={Paper} sx={{ height: 540 }}>
                  <Table sx={{ minWidth: '2000px' }} stickyHeader aria-label="simple table" margin={1}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{ width: '250px', padding: '0px' }}
                          onClick={() => handleSort('user_id')}
                        >
                          User ID
                          <IconButton aria-label="toggle password visibility">
                            {/* <ArrowDownwardOutlinedIcon sx={{ fontSize: '16px !important' }} /> */}
                            {sortData === 'ASC' ? (
                              <ArrowUpwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            ) : (
                              <ArrowDownwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            )}
                          </IconButton>
                        </TableCell>

                        <TableCell align="center" sx={{ width: '250px' }} onClick={() => handleSort('username')}>
                          User Name
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? (
                              <ArrowUpwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            ) : (
                              <ArrowDownwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '250px' }} onClick={() => handleSort('phone_number')}>
                          Phone No
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? (
                              <ArrowUpwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            ) : (
                              <ArrowDownwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            )}
                          </IconButton>
                        </TableCell>

                        <TableCell align="center" sx={{ width: '250px' }} onClick={() => handleSort('bet_count')}>
                          No of bets
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? (
                              <ArrowUpwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            ) : (
                              <ArrowDownwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            )}
                          </IconButton>
                        </TableCell>

                        <TableCell align="center" sx={{ width: '250px' }} onClick={() => handleSort('stack_amount')}>
                          Bet Amount
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? (
                              <ArrowUpwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            ) : (
                              <ArrowDownwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            )}
                          </IconButton>
                        </TableCell>

                        <TableCell align="center" sx={{ width: '250px' }} onClick={() => handleSort('win_count')}>
                          No of Winnings
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? (
                              <ArrowUpwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            ) : (
                              <ArrowDownwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            )}
                          </IconButton>
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ width: '250px', padding: '10px' }}
                          onClick={() => handleSort('win_amount')}
                        >
                          Winning Amount
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? (
                              <ArrowUpwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            ) : (
                              <ArrowDownwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            )}
                          </IconButton>
                        </TableCell>

                        <TableCell align="center" sx={{ width: '250px' }} onClick={() => handleSort('user_RTP')}>
                          RTP %
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? (
                              <ArrowUpwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            ) : (
                              <ArrowDownwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            )}
                          </IconButton>
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ width: '250px' }}
                          onClick={() => handleSort('withdrawal_amount')}
                        >
                          Withdrawal
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? (
                              <ArrowUpwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            ) : (
                              <ArrowDownwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: '250px' }}
                          onClick={() => handleSort('deposited_amount')}
                        >
                          Deposit
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? (
                              <ArrowUpwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            ) : (
                              <ArrowDownwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            )}
                          </IconButton>
                        </TableCell>
                        {/* <TableCell align="center" sx={{ width: '250px' }} onClick={() => handleSort('register_bonus')}>
                          Deposit Bonus
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? (
                              <ArrowUpwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            ) : (
                              <ArrowDownwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            )}
                          </IconButton>
                        </TableCell> */}
                        <TableCell align="center" sx={{ width: '250px' }} onClick={() => handleSort('register_bonus')}>
                          Total Bonus
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? (
                              <ArrowUpwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            ) : (
                              <ArrowDownwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            )}
                          </IconButton>
                        </TableCell>

                        <TableCell align="center" sx={{ width: '250px' }} onClick={() => handleSort('current_balance')}>
                          Current Balance
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? (
                              <ArrowUpwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            ) : (
                              <ArrowDownwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                            )}
                          </IconButton>
                        </TableCell>

                        <TableCell align="center" sx={{ width: '250px' }} onClick={() => handleSort('user_status')}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {!loading ? (
                      <TableBody>
                        {UserRTPList?.map((item) => (
                          <>
                            {' '}
                            <TableRow key={item.user_id} padding={0}>
                              <TableCell align="left" sx={{ width: '250px' }}>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => handleRowClick(item.user_id)}
                                >
                                  {openRowId === item?.user_id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>{' '}
                                {item?.user_id}
                              </TableCell>

                              <TableCell align="center" sx={{ width: '250px' }}>
                                {item?.username}
                              </TableCell>
                              <TableCell align="center" sx={{ width: '250px' }}>
                                {item?.phone_number}
                              </TableCell>
                              <TableCell align="center" sx={{ width: '250px' }}>
                                {item?.bet_count}
                              </TableCell>
                              <TableCell align="center" sx={{ width: '250px' }}>
                                {formatNumber(item?.stack_amount)} {showCurrency()}
                              </TableCell>
                              <TableCell align="center" sx={{ width: '250px' }}>
                                {item?.win_count}
                              </TableCell>
                              <TableCell align="center" sx={{ width: '250px' }}>
                                {formatNumber(item?.win_amount)} {showCurrency()}
                              </TableCell>
                              <TableCell align="center" sx={{ width: '250px' }}>
                                {item?.user_rtp} %
                              </TableCell>
                              <TableCell align="center" sx={{ width: '250px' }}>
                                {formatNumber(item?.withdrawal_amount)} {showCurrency()}
                              </TableCell>
                              <TableCell align="center" sx={{ width: '250px' }}>
                                {formatNumber(item?.deposited_amount)} {showCurrency()}
                              </TableCell>
                              <TableCell align="center" sx={{ width: '250px' }}>
                                {formatNumber(item?.register_bonus)} {showCurrency()}
                              </TableCell>
                              <TableCell align="center" sx={{ width: '250px' }}>
                                {formatNumber(item?.current_balance)} {showCurrency()}
                              </TableCell>
                              <TableCell align="center" sx={{ width: '250px' }}>
                                <Box display="flex" padding={2} sx={{ flexBasis: '50%' }}>
                                  {actionLoader && RowId === item?.user_id ? (
                                    <CircularProgress size={30} />
                                  ) : (
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={item?.user_status}
                                          onChange={(event) => handleChange(event, item?.user_id)}
                                        />
                                      }
                                    />
                                  )}
                                </Box>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={11} sx={{ width: '100%', padding: '0', backgroundColor: '#fff' }}>
                                <Collapse in={openRowId === item.user_id} timeout="auto" unmountOnExit>
                                  <Box sx={{ padding: 2 }}>
                                    <Typography variant="body2" gutterBottom component="div">
                                      SportsBook
                                    </Typography>
                                    <Table size="small" aria-label="purchases" sx={{ width: '50%' }}>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>No of bets</TableCell>
                                          <TableCell>Bet Amount</TableCell>
                                          <TableCell>No of winnings </TableCell>
                                          <TableCell>Winning Amount</TableCell>
                                          <TableCell>RTP</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <TableRow>
                                          <TableCell>{item?.sportbook_bet_count}</TableCell>
                                          <TableCell>
                                            {formatNumber(item?.sportbook_stack_amount)} {showCurrency()}{' '}
                                          </TableCell>
                                          <TableCell>{item?.sportbook_win_count}</TableCell>
                                          <TableCell>
                                            {formatNumber(item?.sportbook_win_amount)} {showCurrency()}{' '}
                                          </TableCell>
                                          <TableCell> {item?.sportbook_user_rtp} %</TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </Box>
                                  {/* 2 */}

                                  <Box sx={{ padding: 2 }}>
                                    <Typography variant="body2" gutterBottom component="div">
                                      Virtual games
                                    </Typography>
                                    <Table size="small" aria-label="purchases" sx={{ width: '50%' }}>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>No of bets</TableCell>
                                          <TableCell>Bet Amount</TableCell>
                                          <TableCell>No of winnings </TableCell>
                                          <TableCell>Winning Amount</TableCell>
                                          <TableCell>RTP</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <TableRow>
                                          <TableCell>{item?.virtual_bet_count}</TableCell>
                                          <TableCell>
                                            {formatNumber(item?.virtual_stack_amount)} {showCurrency()}
                                          </TableCell>
                                          <TableCell> {item?.virtual_win_count}</TableCell>
                                          <TableCell>
                                            {formatNumber(item?.virtual_win_amount)} {showCurrency()}{' '}
                                          </TableCell>
                                          <TableCell> {item?.virtual_user_rtp} %</TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    ) : (
                      <TableCell align="center" colSpan={8}>
                        <CircularProgress />
                      </TableCell>
                    )}
                    {!loading && UserRTPList.length <= 0 && (
                      <TableCell align="center" colSpan={8}>
                        <Typography variant="h6" textTransform={'capitalize'}>
                          {' '}
                          data not found !
                        </Typography>
                      </TableCell>
                    )}
                  </Table>
                  {!loading && UserRTPList.length > 0 && (
                    <TableFooter sx={{}}>
                      <TableRow>
                        <TableCell
                          colSpan={3}
                          align="left"
                          sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}
                        >
                          Total Entries : {extra_data ? extra_data.current_page_items : 0}
                        </TableCell>
                        <TableCell align="center" sx={{ width: '200px' }}>
                          &nbsp;
                        </TableCell>

                        <TableCell align="center" sx={{ width: '150px' }}>
                          &nbsp;
                        </TableCell>
                        <TableCell align="center" sx={{ width: '150px' }}>
                          &nbsp;
                        </TableCell>

                        <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                          {UserRTPList.length > 0
                            ? formatNumber(
                                UserRTPList.reduce(
                                  (accumulator, object) => parseFloat(accumulator) + parseFloat(object.stack_amount),
                                  0
                                ).toFixed(2)
                              )
                            : 0.0}{' '}
                          {showCurrency()}
                        </TableCell>

                        <TableCell align="center" sx={{ width: '150px' }}>
                          &nbsp;
                        </TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                          {UserRTPList.length > 0
                            ? formatNumber(
                                UserRTPList.reduce(
                                  (accumulator, object) => parseFloat(accumulator) + parseFloat(object.win_amount),

                                  0
                                ).toFixed(2)
                              )
                            : 0.0}{' '}
                          {showCurrency()}
                        </TableCell>
                        <TableCell align="center" sx={{ width: '195px' }}>
                          &nbsp;
                        </TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                          {UserRTPList.length > 0
                            ? formatNumber(
                                UserRTPList.reduce(
                                  (accumulator, object) =>
                                    parseFloat(accumulator) + parseFloat(object.withdrawal_amount),

                                  0
                                ).toFixed(2)
                              )
                            : 0.0}{' '}
                          {showCurrency()}
                        </TableCell>
                        <TableCell align="center" sx={{ width: '60px' }}>
                          &nbsp;
                        </TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                          {UserRTPList.length > 0
                            ? formatNumber(
                                UserRTPList.reduce(
                                  (accumulator, object) =>
                                    parseFloat(accumulator) + parseFloat(object.deposited_amount),

                                  0
                                ).toFixed(2)
                              )
                            : 0.0}{' '}
                          {showCurrency()}
                        </TableCell>
                        <TableCell align="center" sx={{ width: '50px' }}>
                          &nbsp;
                        </TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                          {UserRTPList.length > 0
                            ? formatNumber(
                                UserRTPList.reduce(
                                  (accumulator, object) => parseFloat(accumulator) + parseFloat(object.register_bonus),

                                  0
                                ).toFixed(2)
                              )
                            : 0.0}{' '}
                          {showCurrency()}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  )}

                  {/* </>
                  )} */}
                </TableContainer>
                {!loading && UserRTPList.length > 0 && (
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={extra_data ? extra_data?.total_items : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              </Box>
            </CardContent>
          </Card>
        </form>
      </Container>
    </>
  );
};

export default PlayerRTP;
