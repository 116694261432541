import { Box, Button, Container, Stack, Typography } from '@mui/material';
import Card from '@mui/material/Card';

const PlaceBetTicketCode = ({ receivedData }) => {
  //     const [Data, setData] = useState();

  const data = [
    {
      leage: '[822]ITF Women - Greece o2A',
      title: '1 Way',
      bat: '1',
      points: '1.93',
      date: '13/05/2023 ',
      time: '11:50:30',
      address: 'Tenis:gueymard Wayenburg,Sascha-Mayot,Harold',
    },
    {
      leage: '[658]international - Ukrain Win',
      title: '2 Way',
      bat: '2',
      points: '2.77',
      date: '20/05/2023 ',
      time: '12:40:30',
      address: 'Tenis:Cayre,Paul-Gadamauri,Buvaysar',
    },
  ];
  return (
    <>
      <Container>
        <Stack>
          <Typography variant="h4" gutterBottom>
            Bet Ticket Code
          </Typography>
          <Card
            sx={{
              minWidth: 275,
              height: 'auto',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Box width="450px" mb={7}>
              <Typography mt={2} textAlign="center" variant="h3" textTransform="uppercase">
                Logos
              </Typography>

              <hr />
              <Typography variant="h5" textAlign="center" sx={{ fontWeight: 'bold' }}>
                Bet Ticket
              </Typography>
              <Stack paddingX="10px" direction="row" alignItems="center" justifyContent="space-between">
                <Typography fontWeight="bold" fontSize="13px">
                  Date
                </Typography>
                <Typography fontWeight="bold" fontSize="13px">
                  13/05/2023 11:50:30
                </Typography>
              </Stack>
              <hr />
              <Stack paddingX="10px" direction="row" alignItems="center" justifyContent="space-between">
                <Typography fontWeight="bold" fontSize="13px">
                  Bet ID
                </Typography>
                <Typography fontWeight="bold" fontSize="13px">
                  12345
                </Typography>
              </Stack>
              <Stack paddingX="10px" direction="row" alignItems="center" justifyContent="space-between">
                <Typography fontSize="13px">Player</Typography>
                <Typography fontSize="13px">player1</Typography>
              </Stack>
              <hr />
              {data.map((item) => {
                return (
                  <>
                    <Stack paddingX="10px">
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography fontSize="12px">{item.address}</Typography>
                        <Typography fontSize="13px">
                          {item.date}
                          {item.time}
                        </Typography>
                      </Box>
                      <Typography fontWeight="bold" fontSize="13px">
                        {item.leage}
                      </Typography>
                      <Stack mt={1} direction="row" alignItems="center" justifyContent="space-between">
                        <Typography sx={{ fontWeight: 'bold', fontSize: '13px', color: 'black' }}>
                          {item.title}
                        </Typography>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '13px', color: 'black' }}>
                          {item.bat}
                        </Typography>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '13px', color: 'black' }}>
                          {item.points}
                        </Typography>
                      </Stack>
                    </Stack>
                    <hr />
                  </>
                );
              })}
              <Stack paddingX="10px" direction="row" alignItems="center" justifyContent="space-between">
                <Typography fontSize="13px">Amount</Typography>
                <Typography fontSize="13px">20.20</Typography>
              </Stack>
              <Stack paddingX="10px" direction="row" alignItems="center" justifyContent="space-between">
                <Typography fontWeight="bold" fontSize="13px">
                  Total Odd
                </Typography>
                <Typography fontWeight="bold" fontSize="13px">
                  4.11
                </Typography>
              </Stack>
              <Stack paddingX="10px" direction="row" alignItems="center" justifyContent="space-between">
                <Typography fontWeight="bold" fontSize="13px">
                  Possible Win
                </Typography>
                <Typography fontWeight="bold" fontSize="13px">
                  30
                </Typography>
              </Stack>
              <hr />
              <Typography fontWeight="bold" fontSize="13px" mt={1} textAlign="center">
                Total Win
              </Typography>
              <Typography fontWeight="bold" fontSize="13px" textAlign="center">
                62.20
              </Typography>
              <Typography fontWeight="bold" fontSize="13px" textAlign="center">
                QR code image
              </Typography>
              <Box>
                <Button
                  sx={{
                    backgroundColor: 'black',
                    color: 'white',
                    marginTop: '15px',
                  }}
                  size="small"
                  fullWidth
                  onClick={() => alert('developing mode')}
                  variant="contained"
                  type="submit"
                >
                  http://localhost:3006/dashboard/app
                </Button>
              </Box>
              <Typography mt={1} fontSize="13px" textAlign="center">
                *Terms and Conditions apply as set Bet in Prime
              </Typography>
            </Box>
          </Card>
        </Stack>
      </Container>
    </>
  );
};
export default PlaceBetTicketCode;
