import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  Divider,
  Grid,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import config from '../config';
import { UserContext } from '../contexts/UserContext';
import { MY_GLOBAL_CONST } from '../utils/GlobalConstant';
import { formatDate, formatDateTimeUTC, formatNumber, showCurrency } from '../utils/helper';

const BetDetailDialog = ({ open, setOpen, BetDetail, handleClose, BetBarcode }) => {
  const REACT_APP_API_URL = config.apiUrl.slice(0, -4);
  const { user, systemSetting } = useContext(UserContext);

  const barCodeImage = `${REACT_APP_API_URL}${BetBarcode}`;

  const tableStyle = {
    border: 0,
    paddingTop: '2px',
    paddingBottom: '2px',
  };
  const incomeTaxPercentage = Number(systemSetting['Income Tax (%)']);
  const vatPercentage = Number(systemSetting['VAT (%)']);

  const getOddStatus = (status) => {
    switch (status) {
      case 0:
        return 'beslip_open';
      case -1:
      case 4:
      case 3:
        return 'beslip_canceled';
      case 1:
        return 'beslip_lost';
      case 5:
        return 'besliphalf_win';
      case 2:
        return 'beslip_win';
      default:
        return 'beslip_open';
    }
  };

  const getSelectedOdd = (fixture) => {
    const oddData = Object.prototype.hasOwnProperty.call(fixture, 'Market')
      ? fixture.Market.Odds[0]
      : fixture.Markets[0].Odds[0];
    if (oddData.name === '1') {
      return (
        <>
          <Grid item flexGrow="1" md={5}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              {fixture.Participants_home.name}
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              {oddData.price.toFixed(2)}
            </Typography>
          </Grid>
        </>
      );
    }
    if (oddData.name === 'X') {
      return (
        <>
          <Grid item flexGrow="1" md={5}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              Draw
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              {oddData.price.toFixed(2)}
            </Typography>
          </Grid>
        </>
      );
    }
    if (oddData.name === '2') {
      return (
        <>
          <Grid item flexGrow="1" md={5}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              {fixture.Participants_away.name}
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              {oddData.price.toFixed(2)}
            </Typography>
          </Grid>
        </>
      );
    }
    if (oddData.name === '1X') {
      return (
        <>
          <Grid item flexGrow="1" md={5}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              {fixture.Participants_home.name} or Draw
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              {oddData.price.toFixed(2)}
            </Typography>
          </Grid>
        </>
      );
    }
    if (oddData.name === 'X2') {
      return (
        <>
          <Grid item flexGrow="1" md={5}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              Draw or {fixture.Participants_away.name}
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              {oddData.price.toFixed(2)}
            </Typography>
          </Grid>
        </>
      );
    }
    if (oddData.name === '12') {
      return (
        <>
          <Grid item flexGrow="1" md={5}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              No Draw
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              {oddData.price.toFixed(2)}
            </Typography>
          </Grid>
        </>
      );
    }
    return (
      <>
        <Grid item flexGrow="1" md={5}>
          <Typography variant="body2" fontWeight="bold" mt="20px">
            {oddData.name} {oddData.line !== '' ? oddData.line : ''}
          </Typography>
        </Grid>
        <Grid item md={1}>
          <Typography variant="body2" fontWeight="bold" mt="20px">
            {oddData.price.toFixed(2)}
          </Typography>
        </Grid>
      </>
    );
  };

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return BetDetail.win_amount;
      case 1:
        return Number(0.0).toFixed(2);
      case 2:
        return BetDetail.win_amount;
      case 3:
        return Number(0.0).toFixed(2);
      default:
        return BetDetail.win_amount;
    }
  };

  const actualwining = BetDetail ? parseFloat(BetDetail.win_amount) + parseFloat(BetDetail.tax_amount) : 0.0;
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        className="tktPrievePop"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 2,
          textAlign: 'center',
          width: 800,
        }}
      >
        <div className="modalContentHolder">
          <div className="modalContentTitle">
            <Stack direction="row" justifyContent="space-between">
              <Box className="modalLogo">
                <img src={`/assets/${MY_GLOBAL_CONST.top_Header_main_logo}`} alt="logo" />
              </Box>
              <Typography variant="h5" className="tktTitle" textAlign="center" sx={{ fontWeight: 'bold' }}>
                Bet Ticket{' '}
              </Typography>

              <Link to="#" onClick={() => setOpen(false)} className="popCloseicon" style={{ color: grey[900] }}>
                <CancelIcon fontSize="medium" />
              </Link>
            </Stack>
          </div>

          <Stack
            sx={{ px: 1 }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="bettktTxt"
          >
            <Typography variant="subtitle2">Date</Typography>
            <Typography variant="subtitle2">{formatDateTimeUTC(BetDetail?.created_at, 'MM/DD/YYYY HH:mm')}</Typography>
          </Stack>
          <Divider />
          <Stack sx={{ px: 1 }} direction="row" alignItems="center" justifyContent="space-between" className="beId">
            <Typography variant="subtitle2">Bet ID</Typography>
            <Typography variant="subtitle2">{BetDetail.pbet_uid}</Typography>
          </Stack>
          <Stack
            sx={{ px: 1 }}
            direction="row"
            alignItems="center"
            className="playerTxt"
            justifyContent="space-between"
          >
            <Typography variant="body2">Player</Typography>
            <Typography variant="body2">
              {BetDetail.user_username !== '' ? BetDetail.user_username : 'offline user'}
            </Typography>
          </Stack>
          <Box
            sx={{ backgroundColor: 'primary.main', color: '#fff' }}
            display="flex"
            alignItems="center"
            className="akoTitleDv"
            mt={1}
            mx={1}
            pr={2}
            mb={1}
          >
            <Typography variant="button" component="h6" color="inherit" flexGrow="2" textAlign="center">
              AKO
            </Typography>
            <Typography variant="button" fontWeight="700" component="h4" color="inherit" py={0.6}>
              Total Odds
            </Typography>
          </Box>
          <div className="tktMxHeight" style={{ maxHeight: '340px', overflowY: 'auto' }}>
            {BetDetail.Fixtures?.map((item, index) => (
              <Box key={index} className={getOddStatus(item.Markets[0].Odds[0].betsettlement_status)}>
                <Box sx={{ marginLeft: '10px', marginRight: '8px' }} className="footspName">
                  <Typography
                    variant="body2"
                    // pl="16px"
                    // pt="8px"
                    mb={1}
                    sx={{ textTransform: 'uppercase', fontWeight: '500', fontSize: '13px', textAlign: 'left' }}
                  >
                    {item.sport_name} - {item.location_name} - {item.league_name}
                  </Typography>
                </Box>
                <Box
                  pt="0px"
                  pr="16px"
                  pb="2px"
                  sx={{ marginLeft: '10px', marginRight: '8px' }}
                  className="sportNametXt"
                >
                  <Grid container spacing={2} alignItems="flex-start" wrap="no-wrap">
                    <Grid item md={1}>
                      <Typography variant="body2" fontWeight="700" mb={1}>
                        {item.id}
                      </Typography>
                    </Grid>
                    <Grid item flexGrow="2" md={5}>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: '600', textTransform: 'uppercase', textAlign: 'left' }}
                      >
                        {item.Participants_home.name} - {item.Participants_away.name}
                      </Typography>
                      <Typography variant="body1" fontSize="13px" sx={{ textAlign: 'left' }}>
                        {Object.prototype.hasOwnProperty.call(item, 'Market')
                          ? 'Winner'
                          : item.Markets[0].name === '1X2'
                          ? '1X2 (Match Results)'
                          : item.Markets[0].name}
                      </Typography>
                      <Box display="flex">
                        <Typography fontSize="13px">Start at </Typography>
                        <Typography fontSize="13px" ml="10px" fontWeight="500">
                          {formatDate(item.start_date, 'ticketpreview')}
                        </Typography>
                      </Box>
                    </Grid>
                    {getSelectedOdd(item)}
                  </Grid>
                </Box>
                <Divider className="oddDevider" />
              </Box>
            ))}
          </div>
          <Box className="prntMrg">
            <TableContainer container className="depositTable" component="Box" margin={2}>
              <Table maxWidth>
                <TableHead>
                  <TableRow>
                    <TableCell style={tableStyle}>
                      <Typography>Deposit</Typography>{' '}
                    </TableCell>
                    <TableCell style={tableStyle} align="right">
                      <Typography>{formatNumber(BetDetail.stake_amount)} {showCurrency()} </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody fullWidth>
                  <TableRow fullWidth>
                    <TableCell style={tableStyle}>
                      <Typography variant="body2">VAT {vatPercentage}%</Typography>
                    </TableCell>
                    <TableCell style={tableStyle} align="right">
                      <Typography variant="body2"> {formatNumber(BetDetail.vat_amount)} {showCurrency()} </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={tableStyle}>
                      <Typography variant="body2">Placed Bet</Typography>
                    </TableCell>
                    <TableCell style={tableStyle} align="right">
                      <Typography variant="body2"> {formatNumber(BetDetail.total_amount)} {showCurrency()} </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={tableStyle}>
                      <Typography variant="body2" fontWeight="bold">
                        Total Odds
                      </Typography>
                    </TableCell>
                    <TableCell style={tableStyle} align="right">
                      <Typography variant="body2" fontWeight="bold">
                        {formatNumber(BetDetail.total_odds)}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {BetDetail.tax_amount > 0 ? (
                    <>
                      <TableRow>
                        <TableCell style={tableStyle}>
                          <Typography variant="body2"> Win </Typography>
                        </TableCell>
                        <TableCell style={tableStyle} align="right">
                          <Typography variant="body2">{formatNumber(actualwining.toFixed(2))} {showCurrency()}</Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={tableStyle}>
                          <Typography variant="body2"> - {incomeTaxPercentage}% Income Tax </Typography>
                        </TableCell>
                        <TableCell style={tableStyle} align="right">
                          <Typography variant="body2"> {formatNumber(BetDetail.tax_amount)} {showCurrency()} </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={tableStyle}>
                          <Typography fontWeight="bold" variant="body2">
                            Net Win / Payout
                          </Typography>
                        </TableCell>
                        <TableCell style={tableStyle} align="right">
                          <Typography fontWeight="bold" variant="body2">
                            {formatNumber(getStatus(BetDetail.playerbet_status))} {showCurrency()}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  ) : (
                    <TableRow>
                      <TableCell style={tableStyle}>
                        <Typography fontWeight="bold" variant="body2">
                          Win
                        </Typography>
                      </TableCell>
                      <TableCell style={tableStyle} align="right">
                        <Typography fontWeight="bold" variant="body2">
                          {formatNumber(getStatus(BetDetail.playerbet_status))} {showCurrency()}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Stack>
            <Stack alignItems="center">
              <Typography variant="h6" sx={{ mb: 1 }}>
                Scan QR Code
              </Typography>
              <img crossOrigin="anonymous" src={barCodeImage} alt="qrCode" />
            </Stack>
          </Stack>
          {/* <Button fullWidth color="primary" className="urlBtn" variant="contained" sx={{ textTransform: 'lowercase' }}>
            www.primebetin.com
          </Button> */}
          <Box
            sx={{
              textTransform: 'lowercase',
              textAlign: 'center',
              backgroundColor: '#2065D1',
              padding: '7px',
              color: '#fff',
            }}
          >
            {MY_GLOBAL_CONST.website_url}
          </Box>
        </div>
      </Box>
    </Modal>
  );
};
export default BetDetailDialog;
