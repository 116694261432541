export const MY_GLOBAL_CONST = {
    currency: 'NGN',
    country_short_code: 'ng',
    country_code: '234',
    top_Header_main_logo:'primeLogo.png',
    main_title:'BetMonkey',
    website_url: 'www.betmonkey.ng'
};


// primeLogo

export const StaticMenuArray = [
    {
      id:1,
      name:'sportsbook',
      is_visible:true,
      position:1,
  },
    {
        id:2,
        name:'live',
        is_visible:true,
        position:2,
    },
    // {
    //     id:3,
    //     name:'top-leagues',
    //     is_visible:false,
    //     position:3,
    // },
    
    {
        id:3,
        name:'slot',
        is_visible:true,
        position:3,
    },
    {
        id:4,
        name:'aviator',
        is_visible:true,
        position:4,
    }
    ,
    {
        id:5,
        name:'virtuals',
        is_visible:true,
        position:5,
    },
    
]



export const SportsData = [
    {
        "sports": [
            {
                "id": 1,
                "name": "Football"
            },
            {
                "id": 5,
                "name": "Basketball"
            },
            {
                "id": 8,
                "name": "Tennis"
            }
        ],
        "locations": [
            {
                "id": 30288,
                "name": "Albania",
                "countryflagimage": "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/al.svg",
                "sport_id": [
                    1,
                    13
                ]
            },
            {
                "id": 50,
                "name": "Algeria",
                "countryflagimage": "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/dz.svg",
                "sport_id": [
                    1,
                    5
                ]
            },
            {
                "id": 60375,
                "name": "Andorra",
                "countryflagimage": "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/ad.svg",
                "sport_id": [
                    1
                ]
            },
            {
                "id": 60373,
                "name": "Angola",
                "countryflagimage": "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/ao.svg",
                "sport_id": [
                    1
                ]
            },
            {
                "id": 71,
                "name": "Argentina",
                "countryflagimage": "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/ar.svg",
                "sport_id": [
                    13,
                    1,
                    8,
                    5,
                    0,
                    6046
                ]
            },
            {
                "id": 125,
                "name": "Armenia",
                "countryflagimage": "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/am.svg",
                "sport_id": [
                    1
                ]
            },
            {
                "id": 60383,
                "name": "Aruba",
                "countryflagimage": "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/aw.svg",
                "sport_id": [
                    1
                ]
            },
            {
                "id": 1,
                "name": "Australia",
                "countryflagimage": "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/au.svg",
                "sport_id": [
                    1,
                    8,
                    0,
                    5,
                    3
                ]
            },
            {
                "id": 60,
                "name": "Austria",
                "countryflagimage": "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/at.svg",
                "sport_id": [
                    5,
                    13,
                    1,
                    8
                ]
            },
            {
                "id": 129,
                "name": "Azerbaijan",
                "countryflagimage": "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/az.svg",
                "sport_id": [
                    1
                ]
            },
            {
                "id": 60382,
                "name": "Bahrain",
                "countryflagimage": "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/bh.svg",
                "sport_id": [
                    5,
                    1
                ]
            },
            {
                "id": 73,
                "name": "Bangladesh",
                "countryflagimage": "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/bd.svg",
                "sport_id": []
            },
            {
                "id": 95,
                "name": "Barbados",
                "countryflagimage": "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/bb.svg",
                "sport_id": [
                    1
                ]
            }
        ],
        "leagues": [
            {
                "id": 60126,
                "name": " ATP Challenger Tour -  Rennes (France) - Doubles",
                "sport_id": 8,
                "location_id": 54
            },
            {
                "id": 60406,
                "name": " Friendly Club Tournament",
                "sport_id": 13,
                "location_id": 16
            },
            {
                "id": 60099,
                "name": " Israel Cup",
                "sport_id": 5,
                "location_id": 111
            },
            {
                "id": 60449,
                "name": " ITF Kyoto | Women | Doubles",
                "sport_id": 8,
                "location_id": 55
            },
            {
                "id": 46288,
                "name": " Southeast Asian V League Women",
                "sport_id": 13,
                "location_id": 4
            },
            {
                "id": 60303,
                "name": " Super Cup Women",
                "sport_id": 1,
                "location_id": 109
            },
            {
                "id": 59737,
                "name": "1 Division W",
                "sport_id": 1,
                "location_id": 44
            },
            {
                "id": 26178,
                "name": "Brasileiro U20",
                "sport_id": 1,
                "location_id": 63
            },
            {
                "id": 59810,
                "name": "Brasileiro W",
                "sport_id": 1,
                "location_id": 63
            },
            {
                "id": 60010,
                "name": "Brasiliense W",
                "sport_id": 1,
                "location_id": 63
            },
            {
                "id": 60307,
                "name": "Brazil - Paulista Men",
                "sport_id": 13,
                "location_id": 63
            },
            {
                "id": 745,
                "name": "Brazil Campeonato Baiano Women",
                "sport_id": 1,
                "location_id": 63
            },
            {
                "id": 26847,
                "name": "Brazil Campeonato Carioca U23",
                "sport_id": 5,
                "location_id": 63
            },
            {
                "id": 59895,
                "name": "Brazil Campeonato Carioca Women U23",
                "sport_id": 5,
                "location_id": 63
            },
            {
                "id": 5,
                "name": "Esoccer Adriatic League (E) - 10 Mins Play",
                "sport_id": 1,
                "location_id": 4
            },
            {
                "id": 1,
                "name": "ESoccer GT Leagues (E) - 12 mins play",
                "sport_id": 1,
                "location_id": 4
            },
            {
                "id": 60464,
                "name": "Estonian-Latvian Basketball League",
                "sport_id": 5,
                "location_id": 4
            }
            
        ],
        "markets": [
            {
                "id": 79,
                "name": "12"
            },
            {
                "id": 29,
                "name": "1X2"
            }
        ]
    }
  ]

  export const slotGameList = [
    {
        id:700,
        gameName :'solarQueen'
    },
    {
        id:701,
        gameName :'burningWins'
    },
    {
        id:702,
        gameName :'bookOfGold'
    },
    {
        id:703,
        gameName :'bookOfGold703'
    },
    {
        id:704,
        gameName :'buffaloPower'
    },
    {
        id:705,
        gameName :'burningWinsTwo'
    },
    {
        id:706,
        gameName :'diamondFortunator'
    },
    {
        id:707,
        gameName :'diamondWins'
    },
    {
        id:708,
        gameName :'eaglePower'
    },
    {
        id:709,
        gameName :'fruits_jokers'
    },
    {
        id:710,
        gameName :'fruit_jokers'
    },
    {
        id:711,
        gameName :'hotCoins'
    },
    {
        id:712,
        gameName :'imperialFruits'
    },
    {
        id:713,
        gameName :'jokerCoins'
    },
    {
        id:714,
        gameName :'legendOfCleopatra'
    },
    {
        id:715,
        gameName :'royalCoins'
    },
  ]