import { yupResolver } from '@hookform/resolvers/yup';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Button, IconButton, Modal, Stack, TextField, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { UserContext } from '../../contexts/UserContext';
import { postData } from '../../utils/api';
import { ToastError, ToastSuccess, generateHash, getTimeStamp } from '../../utils/helper';

const schema = yup.object().shape({
  amount: yup
    .string()
    .required('Amount is required')
    .test('greater-than-zero', 'Amount must be greater than 0', (value) => value > 0)
    .matches(/^\d+$/, 'Only numbers are allowed'),
  description: yup
    .string()
    .required('Description is required')
    .matches(/^[^\s].*/, 'starting spaced is not allowed'),
});

const CashierDebitModel = ({open, handleClose, cashier_id, refreshCashier}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { user,fetchBalance } = useContext(UserContext);
  const [disable,setDisable] = useState(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    padding: '20px',
    borderRadius: '8px',
  };

  const onSubmit = async (requestVal) => {
    setDisable(true);
    toast.dismiss();
    const data = {
      user_token: user.token,
      data: {
        cashier_id,
        amount: requestVal.amount,
        description: requestVal.description,
        date_time: getTimeStamp(),
      },
    };
    const hashSignature = generateHash(data.data);
    data.data.hash = hashSignature;
    try {
        const FundRequest = await postData(`/${user.type}/manage/cashier/debit/balance`, data);
        ToastSuccess(FundRequest.message);
        setDisable(false)
    } catch (error) {
      console.log('FundRequest error :>> ', error);
      ToastError(error.response.data.message);
      setDisable(false)
    }
    fetchBalance()
    refreshCashier();
    handleClose();
    reset();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
          reset();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography
              variant="h5"
              sx={{
                textTransform: 'capitalize',
              }}
            >
              Debit Cashier
            </Typography>
            <IconButton
              aria-label="Cancel"
              onClick={() => {
                handleClose();
                reset();
              }}
            >
              <CancelIcon />
            </IconButton>
          </Stack>

          <Stack direction="row" justifyContent="space-between" alignItems="center" className="slectInput">
            <Box sx={{ flexBasis: '40%' }}>
              <TextField
                {...register('amount')}
                name="amount"
                fullWidth
                type="number"
                variant="outlined"
                label="Amount"
                placeholder="Amount"
                disableunderline="true"
              />
              <Typography variant="body2" color="error">
                {errors.amount?.message}
              </Typography>
            </Box>
          </Stack>
          <Stack mt={2} alignItems="flex-end">
            <Box width="100%">
              <TextField
                fullWidth
                multiline
                rows={3}
                placeholder="Add description"
                {...register('description')}
                name="description"
                label="Description"
              />
              <Typography variant="body2" color="error">
                {errors.description?.message}
              </Typography>
            </Box>
            <Box mt={2}>
              <Button
                size="large"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                disabled={disable}
                sx={{
                  textTransform: 'capitalize',
                }}
              >
                Debit Cashier
              </Button>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default CashierDebitModel;
