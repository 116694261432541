import { Icon } from '@iconify/react';
import { Box, Button, Card, CardContent, Container, Divider, Stack, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { UserContext } from '../contexts/UserContext';
import { MY_GLOBAL_CONST } from '../utils/GlobalConstant';
import { postData } from '../utils/api';
import { formatDate } from '../utils/helper';
import PrintCashierSummary from './PrintCashierSummary';

const PrintSummaryReport = () => {
  const CardTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
      },
    })
  );
  const [date, setDate] = useState(['', '']);

  const [data, SetData] = useState([]);
  const [error, SetError] = useState('');
  const [disable, setDisable] = useState(false);
  const { user } = useContext(UserContext);

  const min_date = new Date();
  const minDate = dayjs(min_date);

  const yesterday = dayjs().subtract(1, 'day');
  const lastSevenDays = dayjs().subtract(7, 'day');
  const lastMonth = dayjs().subtract(1, 'month');

  const getPrintReportData = async (startDate, endDate) => {
    setDisable(true)
    try {
    const data = {
      user_token: user.token,
      data: {
        start_date: startDate ? formatDate(startDate, 'datepicker') : formatDate(minDate, 'datepicker'),
        end_date: endDate ? formatDate(endDate, 'datepicker') : formatDate(minDate, 'datepicker'),
      },
    };
    const response = await postData(`/cashier/summary/report`, data);
    SetData(response.data[0]);
    setDisable(false)
  } catch (error) {
    console.error("Error fetching/printing report:", error);
    setDisable(false)
  }
  };

  const handleDateChange = (newValue) => {
    if(newValue[0]===null){
      SetError('Please select the start Date');
    }
    if(newValue[1]===null){
      SetError('Please select the end Date');
    }
    setDate(newValue);
    if(newValue[0] && newValue[1]){
    const start_date = new Date(newValue[0]);
    const endDate = new Date(newValue[1]);
    const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);

    if (start_date < min_date && endDate < min_date) {
      SetError('');
      getPrintReportData(utcstartDate, utcEndDate);
    } else {
      SetError('A Future Date is not permitted.');
    }
  }
  };

  useEffect(() => {
    getPrintReportData();
  }, []);

  const printReport = () => {
    setDisable(true)
    getPrintReportData(date[0], date[1]);
    setTimeout(() => {
      const content = document.getElementById('summaryreportprint');
      const pri = document.getElementById('ifmsummaryreportprint').contentWindow;
      pri.document.open();
      pri.document.write(content.innerHTML);
      pri.document.close();
      pri.focus();
      pri.print();
    }, 500);
    setTimeout(() => {
      setDate(['', '']);
      SetError('');
    }, 1500);
  };
  return (
    <>
      <Helmet>
        <title> Cashier Summary Report | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>

      <Container maxWidth="md">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon
              icon="material-symbols:print-outline"
              style={{ fontSize: '23px', marginRight: '3px', marginTop: '15px' }}
            />
            <Typography mt={1} variant="h4" gutterBottom>
              Cashier Summary Report
            </Typography>
          </Box>
        </Stack>

        <Card className="transtionCard">
          <CardContent>
            <Stack mb={3} className="temptranstaionStack">
              <Box padding={2} width="100%">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DesktopDateRangePicker']}>
                    <DemoItem component="DesktopDateRangePicker">
                      <DesktopDateRangePicker
                        localeText={{ start: 'Start Date', end: 'End Date' }}
                        className=""
                        size="small"
                        maxDate={minDate}
                        value={date}
                        onChange={handleDateChange}
                        calendars={1}
                        format="DD-MM-YYYY"
                        slotProps={{
                          textField: {
                            error: false,
                          },
                        }}
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
              <Typography sx={{ marginTop: '-15px', marginLeft: '12px' }} variant="body2" color="error">
                {error}
              </Typography>
            </Stack>
            {/* row 2 */}
            <Divider />
            <Typography color="GrayText" mt={1} ml={1} variant="h5" gutterBottom>
              Suggestions :-
            </Typography>
            <Stack flexDirection="row" className="dateStack">
              <Box margin={2} className="dateBtn">
                <Button
                  sx={{}}
                  onClick={() => {
                    SetError('');
                    setDate([minDate, minDate]);
                  }}
                  variant="outlined"
                  startIcon={<Icon icon="ic:outline-date-range" />}
                  disableElevation
                >
                  Today
                </Button>
              </Box>
              <Box margin={2} className="dateBtn">
                <Button
                  sx={{}}
                  onClick={() => {
                    SetError('');
                    setDate([yesterday, yesterday]);
                  }}
                  variant="outlined"
                  startIcon={<Icon icon="ic:outline-date-range" />}
                  disableElevation
                >
                  Yesterday
                </Button>
              </Box>
              <Box margin={2} className="dateBtn">
                <CardTooltip title="Last 7 Days">
                  <Button
                    sx={{}}
                    onClick={() => {
                      SetError('');
                      setDate([lastSevenDays, minDate]);
                    }}
                    variant="outlined"
                    startIcon={<Icon icon="ic:outline-date-range" />}
                    disableElevation
                  >
                    Last Week
                  </Button>
                </CardTooltip>
              </Box>
              <Box margin={2} className="dateBtn">
                <CardTooltip title="Last 7 Days">
                  <Button
                    sx={{}}
                    onClick={() => {
                      SetError('');
                      setDate([dayjs(lastMonth), minDate]);
                    }}
                    variant="outlined"
                    startIcon={<Icon icon="ic:outline-date-range" />}
                    disableElevation
                  >
                    Last Month
                  </Button>
                </CardTooltip>
              </Box>
            </Stack>

            {/* row 3 */}
            <Stack flexDirection="row" justifyContent="flex-end" width={'100%'} className="mrgtop_20 pdbtm">
              <Box>
                <PrintCashierSummary
                  error={error}
                  printReport={printReport}
                  disable={disable}
                  data={data}
                  minDate={minDate}
                  date={date}
                />
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default PrintSummaryReport;
