import { useContext, useState } from 'react';
import config from '../config';
import { UserContext } from '../contexts/UserContext';
import { formatDate, formatNumber, getBaseImageFromUrl, showCurrency } from '../utils/helper';

const PrintBetSlip = ({ BetDetail, BetBarcode }) => {
  const REACT_APP_API_URL = config.apiUrl.slice(0, -4);
  const admin_Url = config.apiAdminUrl;
  const [imageurl, setImage] = useState(`${admin_Url}/assets/betmonkey.png`);
  getBaseImageFromUrl(`${admin_Url}/assets/betmonkey.png`)
  .then((base64Image) => {
    setImage(base64Image);
  })
  .catch((error) => {
    console.error('Error:', error);
  });
  // useEffect(() => {
  //   const content = document.getElementById('betslipprint');
  //   const pri = document.getElementById('ifmcontentstoprint').contentWindow;
  //   pri.document.open();
  //   pri.document.write(content.innerHTML);
  //   setTimeout(() => {
  //     pri.document.close();
  //     pri.focus();
  //     pri.print();
  //   }, 250);
  // }, [BetDetail, BetBarcode]);

  const { user, systemSetting } = useContext(UserContext);
  const incomeTaxPercentage = Number(systemSetting['Income Tax (%)']);
  const vatPercentage = Number(systemSetting['VAT (%)']);

  const actualwining = parseFloat(BetDetail.win_amount) + parseFloat(BetDetail.tax_amount);

  const getSelectedOdd = (fixture) => {
    const oddData = Object.prototype.hasOwnProperty.call(fixture, 'Market')
      ? fixture.Market.Odds[0]
      : fixture.Markets[0].Odds[0];

    const oddStyle = {
      float: 'left',
      width: '80px',
      fontSize: '9px',
      fontWeight: '600',
    };

    const odsStyle = {
      float: 'right',
      width: '30px',
      fontSize: '9px',
      fontWeight: '600',
    };

    if (oddData.name === '1') {
      return (
        <>
          <div style={oddStyle} id="winner-team">
            <p>{fixture.Participants_home.name}</p>
          </div>

          <div style={odsStyle} id="ods">
            <p>{formatNumber(oddData.price)}</p>
          </div>
        </>
      );
    }
    if (oddData.name === 'X') {
      return (
        <>
          <div style={oddStyle} id="winner-team">
            <p>Draw</p>
          </div>

          <div style={odsStyle} id="ods">
            <p>{formatNumber(oddData.price)}</p>
          </div>
        </>
      );
    }
    if (oddData.name === '2') {
      return (
        <>
          <div style={oddStyle} id="winner-team">
            <p>{fixture.Participants_away.name}</p>
          </div>

          <div style={odsStyle} id="ods">
            <p>{formatNumber(oddData.price)}</p>
          </div>
        </>
      );
    }
    if (oddData.name === '1X') {
      return (
        <>
          <div style={oddStyle} id="winner-team">
            <p>{fixture.Participants_home.name} or Draw</p>
          </div>

          <div style={odsStyle} id="ods">
            <p>{formatNumber(oddData.price)}</p>
          </div>
        </>
      );
    }
    if (oddData.name === 'X2') {
      return (
        <>
          <div style={oddStyle} id="winner-team">
            <p>Draw or {fixture.Participants_away.name}</p>
          </div>

          <div style={odsStyle} id="ods">
            <p>{formatNumber(oddData.price)}</p>
          </div>
        </>
      );
    }
    if (oddData.name === '12') {
      return (
        <>
          <div style={oddStyle} id="winner-team">
            <p>No Draw</p>
          </div>

          <div style={odsStyle} id="ods">
            <p>{formatNumber(oddData.price)}</p>
          </div>
        </>
      );
    }
    return (
      <>
        <div style={oddStyle} id="winner-team">
          <p>
            {oddData.name} {oddData.line !== '' ? oddData.line : ''}
          </p>
        </div>

        <div style={odsStyle} id="ods">
          <p>{formatNumber(oddData.price)}</p>
        </div>
      </>
    );
  };

  // Render the modified HTML
  const renderHTML = () => (
    <html lang="en" id="betslipprint">
      <head>
        <style>
          {`
            body {
              font-family: Arial, Helvetica, sans-serif;
            }

            * {
              margin: 0;
              padding: 0;
            }

            .logoRow {
              clear: both;
            }

            .logoLeft {
              float: left;
              width: 40px;
            }

            .clearboth {
              clear: both;
            }

            .clearboth:after {
              display: table;
              clear: both;
            }

            .loopRow {
              border-bottom: 1px solid #ccc;
            }

            @media print and (width: 80mm) and (min-height: 297mm) {
              body {
                font-size: 12pt; /* Adjust font size for printing */
              }

              h1 {
                font-size: 16pt; /* Adjust heading font size for printing */
              }

              table {
                width: 100%;
              }

              th,
              td {
                padding: 6px; /* Adjust padding for printing */
              }
            }
          `}
        </style>
        <title>Print BetSlip</title>
      </head>
      <body style={{ backgroundColor: '#ccc' }}>
        <div style={{ backgroundColor: '#fff', width: '303px', padding: '10px' }}>
          <div className="logoRow">
            <div className="logoLeft">
              <img crossOrigin="anonymous" src={imageurl} width="100" alt="logopooio" />
            </div>
            <div className="bettktrow" style={{ float: 'right', textAlign: 'right' }}>
              <p style={{ fontSize: '12px', fontWeight: '500' }}>Bet Ticket</p>
              <p style={{ fontSize: '9px', fontWeight: '400' }} id="date-time">
                {formatDate(BetDetail.created_at, 'ticketpreview')}
              </p>
            </div>
            <div className="clearboth" />
          </div>

          {/* <!-- betshop  --> */}
          {user.shop.length > 0 ?
            <div style={{ clear: 'both', marginTop: '8px' }}>
              <div style={{ float: 'left' }}>
                <p style={{ fontSize: '9px' }}>Betshop :</p>
              </div>
              <div style={{ float: 'left', marginLeft: '5px' }}>
                <p style={{ fontSize: '10px', fontWeight: 400 }}>{user.shop[0].address}</p>
              </div>
              <div className="clearboth" />
            </div> : <></>
          }
          {/* <!-- issuer  --> */}

          <div style={{ clear: 'both', marginBottom: '5px' }}>
            <div style={{ float: 'left' }}>
              <p style={{ fontSize: '9px' }}>Issuer :</p>
            </div>
            <div style={{ float: 'left', marginLeft: '5px' }}>
              <p style={{ fontSize: '10px', fontWeight: 400 }}>
                {user.first_name} {user.last_name} ({user.username})
              </p>
            </div>
            <div className="clearboth" />
          </div>
          {/* end betshop and issuer */}
          <div style={{ clear: 'both' }}>
            <div style={{ float: 'left' }}>
              <p style={{ fontSize: '9px' }}>Bet ID</p>
            </div>
            <div style={{ float: 'right' }}>
              <p style={{ fontSize: '10px', fontWeight: '700' }}>{BetDetail.pbet_uid}</p>
            </div>
            <div className="clearboth" />
          </div>

          <div
            style={{
              clear: 'both',
              padding: '5px',
              marginTop: '5px',
              borderTop: '1px solid #ccc',
              borderBottom: '1px solid #ccc',
            }}
          >
            <div style={{ float: 'left' }}>
              <p style={{ fontSize: '10px' }}>AKO</p>
            </div>
            <div style={{ float: 'right' }}>
              <p style={{ fontSize: '10px' }}>Total Odds</p>
            </div>
            <div className="clearboth" />
          </div>
          {BetDetail.Fixtures.map((item, index) => (
            <div key={index} id="loop-content">
              {/* Rest of the content */}
              <div className="loopRow">
                <div style={{ clear: 'both', marginTop: '5px' }}>
                  <div style={{ float: 'left', width: '260px' }}>
                    <p style={{ fontSize: '9px', textTransform: 'uppercase', fontWeight: '500' }} id="league-name">
                      {item.sport_name} - {item.location_name} - {item.league_name}
                    </p>
                  </div>

                  <div className="clearboth" />
                </div>
                {/* Rest of the content */}
                <div style={{ clear: 'both', marginTop: '5px' }}>
                  <div style={{ float: 'left', width: '172px' }}>
                    <p
                      style={{
                        fontSize: '8px',
                        textTransform: 'uppercase',
                        fontWeight: '400',
                        marginBottom: '2px',
                      }}
                      id="team-name"
                    >
                      {item.Participants_home.name} - {item.Participants_away.name}
                    </p>

                    <p
                      style={{
                        fontSize: '9px',
                        fontWeight: '400',
                        marginBottom: '2px',
                      }}
                      id="match-result"
                    >
                      {Object.prototype.hasOwnProperty.call(item, 'Market')
                        ? item.Market.name
                        : item.Markets[0].name === '1X2'
                        ? '1X2 (Match Results)'
                        : item.Markets[0].name === '12'
                        ? '12 (Match Results)'
                        : item.Markets[0].name}
                    </p>
                    <p
                      style={{
                        fontSize: '9px',
                        fontWeight: '400',
                        marginBottom: '2px',
                      }}
                      id="start-date"
                    >
                      Start at {formatDate(item.start_date, 'ticketpreview')}
                    </p>
                    {/* Rest of the content */}
                  </div>
                  <div style={{ float: 'left', width: '120px', textTransform: 'uppercase' }}>
                    {' '}
                    {getSelectedOdd(item)}
                  </div>

                  <div className="clearboth" />
                </div>
              </div>
            </div>
          ))}
          <div style={{ backgroundColor: '#f4f4f4', padding: '5px', marginTop: '5px' }} id="balance-table">
            <table style={{ width: '100%' }}>
              <tr>
                <td style={{ textAlign: 'left', width: '50%', fontSize: '9px', fontWeight: '500' }}>Deposit</td>
                <td style={{ textAlign: 'right', width: '50%', fontSize: '9px', fontWeight: '500' }} id="deposit">
                  {formatNumber(BetDetail.stake_amount)} {showCurrency()}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: 'left', width: '50%', fontSize: '9px', fontWeight: '500' }}>
                  VAT {vatPercentage}%
                </td>
                <td style={{ textAlign: 'right', width: '50%', fontSize: '9px', fontWeight: '500' }} id="vat">
                  {formatNumber(BetDetail.vat_amount)} {showCurrency()}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: 'left', width: '50%', fontSize: '9px', fontWeight: '700' }}>Placed Bet</td>
                <td style={{ textAlign: 'right', width: '50%', fontSize: '9px', fontWeight: '700' }} id="total-ods">
                  {formatNumber(BetDetail.total_amount)} {showCurrency()}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: 'left', width: '50%', fontSize: '9px', fontWeight: '700' }}>Total Odds</td>
                <td style={{ textAlign: 'right', width: '50%', fontSize: '9px', fontWeight: '700' }} id="total-ods">
                  {formatNumber(BetDetail.total_odds)}
                </td>
              </tr>

              {BetDetail.tax_amount > 0 ? (
                <>
                  <tr>
                    <td style={{ textAlign: 'left', width: '50%', fontSize: '9px', fontWeight: '700' }}>Win</td>
                    <td style={{ textAlign: 'right', width: '50%', fontSize: '9px', fontWeight: '700' }} id="win">
                      {formatNumber(actualwining)} {showCurrency()}
                    </td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: 'left', width: '50%', fontSize: '9px' }}>
                      - {incomeTaxPercentage}% Income Tax
                    </td>
                    <td style={{ textAlign: 'right', width: '50%', fontSize: '9px', fontWeight: '700' }} id="tax">
                      {formatNumber(BetDetail.tax_amount)} {showCurrency()}
                    </td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: 'left', width: '50%', fontSize: '9px', fontWeight: '700' }}>
                      Net Win / Payout
                    </td>
                    <td style={{ textAlign: 'right', width: '50%', fontSize: '9px', fontWeight: '700' }} id>
                      {formatNumber(BetDetail.win_amount)} {showCurrency()}
                    </td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td style={{ textAlign: 'left', width: '50%', fontSize: '9px', fontWeight: '700' }}>Win</td>
                  <td style={{ textAlign: 'right', width: '50%', fontSize: '9px', fontWeight: '700' }} id>
                    {formatNumber(BetDetail.win_amount)} {showCurrency()}
                  </td>
                </tr>
              )}
            </table>
          </div>

          <div className="barcode" style={{ width: '110px', margin: '0 auto', marginTop: '10px' }}>
            <img
              crossOrigin="anonymous"
              src={`${REACT_APP_API_URL}${BetBarcode}`}
              width="110"
              height="22"
              alt="barcode"
            />
          </div>
        </div>
      </body>
    </html>
  );

  // Return the HTML structure
  return (
    <iframe
      id="ifmcontentstoprint"
      title="betslip"
      style={{ height: '0px', width: '0px', display: 'none', position: 'absolute' }}
    >
      {renderHTML()}
    </iframe>
  );
};

export default PrintBetSlip;
