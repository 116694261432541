import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
// import Icon from '../components/color-utils/Icon';
import Menu from '@mui/material/Menu';
import { alpha, styled } from '@mui/material/styles';
import config from '../../config';
import { UserContext } from '../../contexts/UserContext';
import { fetchDataWithAuth, postData } from '../../utils/api';
import { fNumber } from '../../utils/formatNumber';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { ToastError, formatDate, formatNumber, showCurrency } from '../../utils/helper';

const default_filters = {
  id: '',
  start_date: '',
  end_date: '',
  // start_price: '',
  // end_price: '',
};
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));
const CashierReport = () => {
  const REACT_APP_API_URL = config.apiUrl.slice(0, -4);

  const [cashierReportList, setCashierReportList] = useState([
    {
      shop_balance: '',
      total_bets: '',
      total_deposit: '',
      total_payout: '',
      total_withdraw: '',
      balance: '',
    },
  ]);
  const [selectedDateRange, setSelectedDateRange] = useState(['', '']);
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState(default_filters);
  const [BetDetail, setBetDetail] = useState(null);
  const [BetBarcode, setBetBarcode] = useState(null);
  // const [open, setOpen] = useState(false);
  const [selectCashier, setSelectCashier] = useState('');
  const [loading, SetLoading] = useState(true);
  const [disable, setDisable] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [selectVal, setSelectVal] = useState(1);


  // slider

  const { user } = useContext(UserContext);

  // const handleRangeChange = (event, newValue) => {
  //   setRangeValues(newValue);
  //   setValue('start_price', newValue[0]);
  //   setValue('end_price', newValue[1]);
  // };
  const maxCashier = cashierReportList.reduce((max, current) => parseFloat(max.shop_balance) > parseFloat(current.shop_balance) ? max : current, {});
  // const [rangeValues, setRangeValues] = useState([10, 500000]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const fetchCashierReport = async () => {
    try {
      const data = {
        user_token: user.token,
        data: filter,
      };
      SetLoading(true);
      const response = await postData(`/admin/reports/cashier/summary?page=1&size=50`, data);
      setCashierReportList(response.data);
      SetLoading(false);
    } catch (error) {
      console.log('error :>> ', error);
      ToastError(error.response.data.message);
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      const data = {
        user_token: user.token,
        data: {},
      };
      const response = await fetchDataWithAuth(`/admin/cashier/list`, data);
      const userData = response.data;
      setUsers(userData);
    };
    getUsers();
    fetchCashierReport();
  }, []);

  const onSearch = async (betFilter) => {
    const startDate = new Date(selectedDateRange[0] !== '' ? selectedDateRange[0] : '');
    const endDate = new Date(selectedDateRange[1] !== '' ? selectedDateRange[1] : '');
    const utcstartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);

    const data = {
      user_token: user.token,
      data: {
        id: betFilter.id ? betFilter.id : '',
        start_date: selectedDateRange[0] === '' ? '' : formatDate(utcstartDate, 'datepicker'),
        end_date: selectedDateRange[1] === '' ? '' : formatDate(utcEndDate, 'datepicker'),
        // start_price: betFilter.start_price ? betFilter.start_price : '',
        // end_price: betFilter.end_price ? betFilter.end_price : '',
      },
    };
    toast.dismiss();
    try {
      SetLoading(true);
      const response = await postData(`/admin/reports/cashier/summary?page=1&size=50`, data);
      setFilter(data.data);
      setCashierReportList(response.data);
      SetLoading(false);
    } catch (error) {
      console.log('error :>> ', error);
      ToastError(error.response.data.message);
    }
  };

  const handleDateChange = (newValue) => {
    setSelectedDateRange([newValue[0] !== null ? newValue[0] : '', newValue[1] !== null ? newValue[1] : '']);
  };

  // const handleClose = () => {
  //   setOpen(false);
  //   setBetDetail(null);
  //   setBetBarcode(null);
  // };

  const clearFilter = async () => {
    reset();
    setSelectedDateRange(['', '']);
    setFilterData([])
    // setRangeValues([10, 500000]);
    setSelectCashier('');
    if (selectCashier || selectedDateRange) {
      const data = {
        user_token: user.token,
        data: default_filters,
      };
      SetLoading(true);
      const response = await postData(`/admin/reports/cashier/summary?page=1&size=50`, data);
      setFilter(default_filters);
      setCashierReportList(response.data);
      SetLoading(false);
    }
  };
  const onchangeCashier = (event) => {
    setSelectCashier(event.target.value);
  };

  // export start
const [anchorEl, setAnchorEl] = useState(null);
const open = Boolean(anchorEl);

const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};
const saveBlob = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};

const handleFileTypeDownload = async (filetype) => {
  setDisable(true);
  const startDate = new Date(selectedDateRange[0] !== '' ? selectedDateRange[0] : '');
  const endDate = new Date(selectedDateRange[1] !== '' ? selectedDateRange[1] : '');
  const utcstartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
  const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
  const data = {
    user_token: user.token,
    data: {
      id: selectCashier !== '' ? selectCashier : '',
      page: 1,
      row_per_page: 50,
      file_type: filetype,
      // start_price: filterData.start_price ? filterData.start_price : '',
      // end_price: filterData.end_price ? filterData.end_price : '',
      start_date: selectedDateRange[0] === '' ? '' : formatDate(utcstartDate, 'datepicker'),
      end_date: selectedDateRange[1] === '' ? '' : formatDate(utcEndDate, 'datepicker'),
      // order_key: sortBy || '',
      // order_value: sortData || '',
      headers: [
        'Cashier Name',
        `Cashier Balance (${showCurrency()})`,
        'Assigned Shop Name',
        'Total No. Of Bets',
        `Total Bets Stake Amount (${showCurrency()})`,
        'Total No. Of Awarded Payouts',
        `Total Awarded Payouts (${showCurrency()})`,
        `Total Refunded Amount (${showCurrency()})`,
        `Total Deposited Amount (${showCurrency()})`,
        `Total Withdrawn Amount (${showCurrency()})`,
        `Total Transferred Amount (${showCurrency()})`,
        `Total Received Amount (${showCurrency()})`,
        `Total Awarded Signup Bonuses (${showCurrency()})`,
        `Total Awarded Deposit Bonuses (${showCurrency()})`,
      ],
    },
  };
  try {
    const HighestPayout = await postData(`/admin/reports/cashier/summary/export`, data);

    let mimeType;
    let fileExtension;

    if (filetype === 2) {
      mimeType = 'text/csv';
      fileExtension = '.csv';
    } else if (filetype === 3) {
      mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      fileExtension = '.xlsx';
    } else if (filetype === 1) {
      mimeType = 'application/json';
      fileExtension = '.json';
    } else {
      // Handle unsupported file types

      return;
    }

    const blob = await fetch(`${REACT_APP_API_URL}${HighestPayout.data[0].file_path}`).then((response) =>
      response.blob()
    );

    const filename = `CashierReport${fileExtension}`;
    saveBlob(blob, filename);
    setDisable(false);
  } catch (error) {
    console.log('error :>> ', error);
    ToastError(error.response.data.message);
    setDisable(false);
  }
  setAnchorEl(null);
};
const handleSelectChange = (event) => {
  setSelectVal(event.target.value);
};
// export end

  const min_date = new Date();
  const minDate = dayjs(min_date);

  return (
    <>
      <Helmet>
        <title>Cashier Report | {`${MY_GLOBAL_CONST.main_title}`}  Admin </title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon icon="game-icons:cash" style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }} />
            <Typography variant="h4" gutterBottom>
              Cashier Report
            </Typography>
          </Box>
        </Stack>
        <Card className="transtionCard">
          <CardContent>
            <form onSubmit={handleSubmit(onSearch)}>
              <Stack display="flex" flexDirection="row" alignItems="self-end" className="temptranstaionStack">
                <Box padding={2} sx={{ flexBasis: '25%' }} className="slectInput width_100">
                  <Typography variant="body1" mb={1}>
                    Select Cashier
                  </Typography>
                  <FormControl sx={{ minWidth: 150 }} fullWidth>
                    <InputLabel id="demo-simple-select-label">Search All Cashier </InputLabel>
                    <Select name="id" {...register('id')} id="id" value={selectCashier} onChange={onchangeCashier}>
                      <MenuItem key={''} value={' '}>
                        Select Cashier
                      </MenuItem>
                      {users.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.username}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box padding={2} className="width_100">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DesktopDateRangePicker']}>
                      <DemoItem component="DesktopDateRangePicker">
                        <DesktopDateRangePicker
                          localeText={{ start: 'Start Date', end: 'End Date' }}
                          size="small"
                          maxDate={minDate}
                          // {...register('start_date')}
                          // name="selectedDateRange"
                          value={selectedDateRange}
                          onChange={handleDateChange}
                          calendars={1}
                          format="DD-MM-YYYY"
                          slotProps={{
                            textField: {
                              error: false,
                            },
                          }}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
                <Box className="width_100" padding={2}>
                  <Stack display="flex" flexDirection="row" justifyContent="flex-end">
                    <Stack display="flex" flexDirection="row">
                      <Box marginRight={2} marginLeft={2} alignSelf="center">
                        <Button
                          onClick={clearFilter}
                          color="secondary"
                          variant="outlined"
                          size="large"
                          disabled={loading}
                          disableElevation
                          sx={{ padding: '27px' }}
                        >
                          Clear
                        </Button>
                      </Box>
                      <Box alignSelf="center">
                        <Button
                          type="submit"
                          color="secondary"
                          variant="contained"
                          size="large"
                          sx={{ padding: '27px' }}
                          disabled={loading}
                          disableElevation
                        >
                          Search
                        </Button>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
                <Box className="width_100" padding={2}>
                  <Stack sx={{ width: '100%' }} justifyContent={'end'} display="flex" flexDirection="row">
                    <Box marginRight={2}>
                      <FormControl size="small" sx={{ minWidth: 180 }}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectVal}
                          defaultValue={1}
                          onChange={handleSelectChange}
                        >
                          <MenuItem value={1}>Select All</MenuItem>
                          <MenuItem value={2}>Current Page</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box>
                      <Button
                        id="demo-customized-button"
                        aria-controls={open ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        open={open}
                        onClick={handleClick}
                      >
                        Export
                      </Button>
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => setAnchorEl(null)}
                      >
                        <MenuItem onClick={() => handleFileTypeDownload(1)} disabled={disable} disableRipple>
                          <Icon
                            icon="bxs:file-json"
                            style={{ color: '#323436db', fontSize: '23px', marginRight: '5px' }}
                          />
                          JSON
                        </MenuItem>
                        <Divider sx={{ my: 0.5 }} />
                        <MenuItem onClick={() => handleFileTypeDownload(2)} disabled={disable} disableRipple>
                          <Icon
                            icon="material-symbols:csv-outline"
                            style={{ color: '#323436db', fontSize: '23px', marginRight: '5px' }}
                          />
                          CSV
                        </MenuItem>
                        <Divider sx={{ my: 0.5 }} />

                        <MenuItem onClick={() => handleFileTypeDownload(3)} disabled={disable} disableRipple>
                          <Icon
                            icon="file-icons:microsoft-excel"
                            style={{ color: '#323436db', fontSize: '20px', marginRight: '5px' }}
                          />
                          EXCEL
                        </MenuItem>
                      </StyledMenu>
                    </Box>
                  </Stack>
                </Box>
                {/* <Box padding={2} sx={{ flexBasis: '25%' }} className="width_100">
                  <Box>
                    <Typography id="range-slider" gutterBottom>
                      Balance Range
                    </Typography>
                    <Slider
                      value={rangeValues}
                      onChange={handleRangeChange}
                      valueLabelDisplay="off"
                      min={10}
                      max={500000}
                      aria-labelledby="range-slider"
                    />
                    <Stack direction="row" justifyContent="space-between">
                      <Typography fontWeight="bold">{fNumber(rangeValues[0])} {showCurrency()}</Typography>
                      <Typography>To</Typography>
                      <Typography fontWeight="bold">{fNumber(rangeValues[1])} {showCurrency()}</Typography>
                    </Stack>
                  </Box>
                </Box> */}
                {/* <Stack display="flex" flexDirection="row" justifyContent="space-between">
                  <Stack display="flex" flexDirection="row">
                    <Box marginLeft={2} marginRight={2} alignSelf="center">
                      <Button onClick={clearFilter} color="secondary" variant="outlined" size="large" disabled={loading} disableElevation>
                        Clear
                      </Button>
                    </Box>
                    <Box alignSelf="center">
                      <Button type="submit" color="secondary" variant="contained" size="large" disabled={loading} disableElevation>
                        Search
                      </Button>
                    </Box>
                  </Stack>
                </Stack> */}
              </Stack>
            </form>
          </CardContent>

              {loading && (
                <Typography textAlign="center" margin="20px">
                  <CircularProgress />
                </Typography>
              )}
            {!cashierReportList.length && !loading && (
                <Typography padding={'50px'} textAlign={'center'} variant="h6" textTransform={'capitalize'}>
                  data not found !
                </Typography>
            )}
            <Stack padding={6} display="flex" flexDirection="row" justifyContent={'flex-start'} flexWrap={'wrap'} className="pd0_mrg0">
            {cashierReportList?.map((item, index) => (
              <Box>
                {!loading && (
                  <Stack
                    key={index}
                    display="flex"
                    flexDirection="row"
                    justifyContent={'space-between'}
                    ml={5}
                    mr={5}
                    m={4}
                    className="pd0_mrg0" sx={{ width:'600px'}}
                  >
                    <Grid container spacing={2} className="gridlayoutContainer">
                      <>
                        <Grid item xs={6}>
                          <Typography sx={{ fontWeight: 700 }} textAlign={'center'}>
                            Cashier Name :-
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography textTransform={'capitalize'}>{item.name}</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'center'}>
                          <Typography sx={{ fontWeight: 700 }}>Cashier Balance :-</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{formatNumber(item.balance)} {showCurrency()}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography sx={{ fontWeight: 700 }} textAlign={'center'}>
                           Assigned Shop Name :-
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{item.shop_name}</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'center'}>
                        <Typography sx={{ fontWeight: 700 }}>Total No. Of Bets :-</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{fNumber(item.bet_count)}</Typography>
                      </Grid>
                      <Grid item xs={6} textAlign={'center'}>
                        <Typography sx={{ fontWeight: 700 }}>Total Bets Stake Amount :-</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{formatNumber(item.stack_amount)} {showCurrency()}</Typography>
                      </Grid>
                      <Grid item xs={6} textAlign={'center'}>
                        <Typography sx={{ fontWeight: 700 }}>Total No. Of Awarded Payouts :-</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{fNumber(item.win_count)}</Typography>
                      </Grid>
                      <Grid item xs={6} textAlign={'center'}>
                        <Typography sx={{ fontWeight: 700 }}>Total Awarded Payouts :-</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{formatNumber(item.win_amount)} {showCurrency()}</Typography>
                      </Grid>
                      <Grid item xs={6} textAlign={'center'}>
                        <Typography sx={{ fontWeight: 700 }}>Total Refunded Amount :-</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{formatNumber(item.refund_amount)} {showCurrency()}</Typography>
                      </Grid>
                      <Grid item xs={6} textAlign={'center'}>
                        <Typography sx={{ fontWeight: 700 }}>Total Deposited Amount :-</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{formatNumber(item.deposited_amount)} {showCurrency()}</Typography>
                      </Grid>
                      <Grid item xs={6} textAlign={'center'}>
                        <Typography sx={{ fontWeight: 700 }}>Total Withdrawn Amount :-</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{formatNumber(item.withdrawal_amount)} {showCurrency()}</Typography>
                      </Grid>
                      {item.signup_bonus_amount ? (
                        <>
                          {' '}
                          <Grid item xs={6} textAlign={'center'}>
                            <Typography sx={{ fontWeight: 700 }}>Total Awarded Signup Bonuses :-</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>{formatNumber(item.signup_bonus_amount)} {showCurrency()}</Typography>
                          </Grid>
                        </>
                      ) : (
                        ''
                      )}
                      {item.deposit_bonus_amount ? (
                        <>
                          {' '}
                          <Grid item xs={6} textAlign={'center'}>
                            <Typography sx={{ fontWeight: 700 }}>Total Awarded Deposit Bonuses :-</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>{formatNumber(item.deposit_bonus_amount)} {showCurrency()}</Typography>
                          </Grid>
                        </>
                      ) : (
                        ''
                      )}
                      {item.transferred_amount ? (
                        <>
                          {' '}
                          <Grid item xs={6} textAlign={'center'}>
                            <Typography sx={{ fontWeight: 700 }}>Total Transferred Amount :-</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>{formatNumber(item.transferred_amount)} {showCurrency()}</Typography>
                          </Grid>
                        </>
                      ) : (
                        ''
                      )}
                      {item.received_amount ? (
                        <>
                          {' '}
                          <Grid item xs={6} textAlign={'center'}>
                            <Typography sx={{ fontWeight: 700 }}>Total Received Amount :-</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>{formatNumber(item.received_amount)} {showCurrency()}</Typography>
                          </Grid>
                        </>
                      ) : (
                        ''
                      )}

                      </>
                    </Grid>
                  </Stack>
                )}
                </Box>
            ))}
            </Stack>
        </Card>
      </Container>
    </>
  );
};

export default CashierReport;
