import { Icon } from "@iconify/react";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Box, Button, Card, CardHeader, CircularProgress, Container, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Label from '../../components/label/Label';
import Scrollbar from "../../components/scrollbar/Scrollbar";
import { UserContext } from "../../contexts/UserContext";
import TableHeadSection from "../../sections/TableHeadSection";
import { MY_GLOBAL_CONST } from "../../utils/GlobalConstant";
import { postData } from "../../utils/api";
import { fNumber } from "../../utils/formatNumber";
import { ToastError, ToastSuccess, formatDate, generateHash, getTimeStamp, showCurrency } from "../../utils/helper";
import CreditDebitModal from "./CreditDebitModal";

const BalanceManagement = () => {
  const [operators, setOperators] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);
  const [fundopen, setFundOpen] = useState(false);
  const [current_id, setCurrentId] = useState();
  const [optype, setOpType] = useState('');
  const [requestlist, setRequestList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [extra_data, setExtraData] = useState([]);
  const [listloading, setListLoading] = useState(false);
  const [sortData, setSortData] = useState('DESC');
  const [sortBy, setSortBY] = useState('');
  const [disableReject, setDisableReject] = useState(null);
  const [RowId, setRowId] = useState(0);
  const [loaderMain, SetLoaderMain] = useState(false);
  const getOperators = async () => {
    setLoading(true);
    const data = {
      user_token: user.token,
      data: {
        search: "",
        order_key: "id",
        order_value: "ASC",
      },
    };
    const response = await postData(`/admin/manage/operator/list`, data);
    const operatorData = response.data;
    setOperators(operatorData);
    setLoading(false);
  }

  useEffect(() => {
    if(operators.length === 0) getOperators();
    fetchCreditRequests(sortBy, sortData === 'ASC' ? 'DESC' : 'ASC', page, rowsPerPage);
  }, []);

  const getRandomColor = () => 
    // Generate a random hexadecimal color code
     `#${  Math.floor(Math.random()*16777215).toString(16)}`
  ;
  const handleOpen = (operator_id, opType) => {
    setFundOpen(true);
    setCurrentId(operator_id);
    setOpType(opType);
  }
  const handleClose = () => {
    setFundOpen(false);
    setCurrentId();
    setOpType();
  }

  const fetchCreditRequests = async (sortBy, sortData, page, rowsPerPage) => {
    try {
      const data = {
        user_token: user.token,
        data: {
          status: "", 
          order_key: sortBy !== '' ? sortBy : '',
          order_value: sortData,
        },
      };
      setListLoading(true);
      const response = await postData(`/admin/manage/operator/credit/request/list?page=${page + 1}&size=${rowsPerPage}`, data);
      setRequestList(response.data);
      setExtraData(response.extra_data);
      setListLoading(false);
    } catch (error) {
      console.log('error :>> ', error);
      ToastError(error.response.data.message);
    }
  };

  const isNotFound = requestlist.length === 0;

  const TABLE_HEAD = [
    { id: 'id', label: 'ID', alignRight: false },
    { id: 'request_by.username', label: 'Request By', alignRight: false },
    { id: 'request_to.username', label: 'Request To', alignRight: false },
    { id: 'amount', label: 'Amount', alignRight: false },
    { id: 'description', label: 'Description', alignRight: false },
    { id: 'created_at', label: 'Created At', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: '', label: 'Actions', alignRight: false },
  ];

  const handleSort = (sortBy) => {
    if (!isNotFound && !listloading) {
      setSortBY(sortBy);
      setSortData(sortData === 'ASC' ? 'DESC' : 'ASC');
      fetchCreditRequests(sortBy, sortData === 'ASC' ? 'DESC' : 'ASC', page, rowsPerPage);
    }
  };

  const handleRequestType = async (type, id) => {
    SetLoaderMain(true)
    setRowId(id)
    setDisableReject(type === 1 ? 'Approve' :'Reject')
    const data = {
      user_token: user.token,
      data: {
        transfer_request_id: id,
        action_type: type === 1 ? 1 : 2,
        description: "",
        date_time: getTimeStamp(),
      },
    };
    const hashSignature = generateHash(data.data);
    data.data.hash = hashSignature;
    try {
      const BalanceRequest = await postData(`/admin/manage/operator/transfer/approval`, data);
      await fetchCreditRequests(sortBy, sortData === 'ASC' ? 'DESC' : 'ASC', page, rowsPerPage);
      await getOperators();
      ToastSuccess(BalanceRequest.message);
    } catch (error) {
      console.log('BalanceRequest error :>> ', error);
      ToastError(error.response.data.message);
    } finally{
      SetLoaderMain(false)
      setDisableReject('');
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchCreditRequests(sortBy, sortData === 'ASC' ? 'DESC' : 'ASC', newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    fetchCreditRequests(sortBy, sortData === 'ASC' ? 'DESC' : 'ASC', page, rowsPerPage);
  };

  const getStatus = (status) => {
    switch (status) {
      case 1:
        return <Label color={'warning'}>Pending</Label>;
      case 2:
        return <Label color={'success'}>Approved</Label>;
      case 3:
        return <Label color={'error'}>Rejected</Label>;
      default:
        return <></>;
    }
  };

  return (
    <>
    <CreditDebitModal open={fundopen} handleClose={handleClose} operator_id={current_id} op_type={optype} refreshOperators={getOperators}/>
    <Helmet>
      <title> Balance Management | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
    </Helmet>
    <Container maxWidth="xl" className="dashboardPage cardDesign">
      <Typography variant="h4" sx={{ mb: 3 }}>
        Balance Management
      </Typography>
      {loaderMain ? (
            <Box
              sx={{
                display: 'flex',
                height: '300px',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#f9fafb',
              }}
            >
              <CircularProgress size={35} />
            </Box>
          ) : ( 
          <>
          <Grid
        container
        className="boxGrid nomarginLR"
        spacing={3}
        justifyContent="stretch"
        sx={{
          '& .MuiPaper-root': {
            margin: 1,
            border: '1px solid ',
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#fff',
          },
          // '& .MuiTypography-root': { color: '#061B64' },
        }}
      >
          {loading ? <CircularProgress /> : operators.length > 0 && operators.map((operator, index) => (  
            <Card key={index}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: getRandomColor, borderRadius: '10px' }} aria-label="recipe">{operator.username.charAt(0).toUpperCase()}</Avatar>
                }
                action={<Stack direction="row">
                  <Tooltip title="Credit Balance">
                    <IconButton aria-label="settings" onClick={() => handleOpen(operator.id, 'credit')}>
                      <Icon icon="hugeicons:money-add-02" style={{ color: '#5C9E31', fontSize: '23px', marginRight: '5px' }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Debit Balance">
                    <IconButton aria-label="settings" onClick={() => handleOpen(operator.id, 'debit')}>
                      <Icon icon="hugeicons:money-remove-02" style={{ color: '#FF4842', fontSize: '23px', marginRight: '5px' }} />
                    </IconButton>
                  </Tooltip>
                  {/* <IconButton aria-label="settings">
                    <Icon icon="charm:menu-kebab" style={{ color: '#323436db', fontSize: '23px', marginRight: '5px' }}/>
                  </IconButton> */}
                </Stack>
                }
                title={operator.username}
                subheader={`${fNumber(operator.balance)} ${showCurrency()}`}
                sx={{
                  padding: '16px',
                }}
              />
            </Card>
          ))}
      </Grid>
      <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, maxHeight: 540 }}>
              <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table" margin={1}>
                <TableHead>
                  <TableRow>
                    <TableHeadSection
                      handleSort={handleSort}
                      sortData={sortData}
                      setSortData={setSortData}
                      tableHead={TABLE_HEAD}
                    />
                  </TableRow>
                </TableHead>

                {!isNotFound && !listloading && (
                  <TableBody>
                    {requestlist.map((row) => (
                      <TableRow hover key={row.id} tabIndex={-1}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell><b>{row.request_by.username}</b></TableCell>
                        <TableCell>{row.request_to.username}</TableCell>
                        <TableCell>{fNumber(row.amount)} {showCurrency()}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell align="left">{formatDate(row.created_at, 'ticket datetime')}</TableCell>
                        <TableCell>{getStatus(row.status)}</TableCell>
                        <TableCell align="center">
                        {row.status === 1 ? (
                          <>
                            <Button
                              variant="contained"
                              color="error"
                              size="small"
                              sx={{ fontWeight: 'normal' }}
                              startIcon={<CloseIcon />}
                              disabled={disableReject === 'Reject' && RowId === row.id}
                              onClick={() => handleRequestType(2, row.id)}
                            >
                              Reject
                            </Button>

                            <Button
                              variant="contained"
                              size="small"
                              startIcon={<CheckIcon />}
                              sx={{ marginLeft: '8px', fontWeight: 'normal' }}
                              onClick={() => handleRequestType(1, row.id)}
                              disabled={disableReject === 'Approve' && RowId === row.id}
                            >
                              Approve
                            </Button>
                          </>
                        ) : (
                          <Typography align="left">N/A</Typography>
                        )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}

                {!listloading && isNotFound && (
                  <TableRow>
                    <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph textTransform={'capitalize'}>
                          data not found !
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                )}

                {listloading && (
                  <TableRow>
                    <TableCell align="center" colSpan={12}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          {!isNotFound && !listloading && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={extra_data?.total_items ? extra_data?.total_items : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card> </>)}
    </Container>
    </>
  );
}

export default BalanceManagement;