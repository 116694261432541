import { Icon } from '@iconify/react';
import {
  Avatar,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { UserContext } from '../../contexts/UserContext';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { fetchDataWithAuth } from '../../utils/api';
import { fNumber } from '../../utils/formatNumber';
import { showCurrency } from '../../utils/helper';
import CashierDebitModel from './CashierDebitModel';

const MyCashiers = () => {
  const [cashiers, setCashiers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);
  const [fundopen, setFundOpen] = useState(false);
  const [current_id, setCurrentId] = useState();
  const getCashiers = async () => {
    setLoading(true);
    const data = {
      user_token: user.token,
      data: {},
    };
    const response = await fetchDataWithAuth(`/${user.type}/shop/assign/cashier/list`, data);
    const cashierData = response.data;
    setCashiers(cashierData);
    setLoading(false);
  };

  useEffect(() => {
    if (cashiers.length === 0) getCashiers();
  }, []);

  const getRandomColor = () =>
    // Generate a random hexadecimal color code
    `#${Math.floor(Math.random() * 16777215).toString(16)}`;

  const handleOpen = (cashier_id) => {
    setFundOpen(true);
    setCurrentId(cashier_id);
  };
  const handleClose = () => {
    setFundOpen(false);
    setCurrentId();
  };

  return (
    <>
      <CashierDebitModel
        open={fundopen}
        handleClose={handleClose}
        cashier_id={current_id}
        refreshCashier={getCashiers}
      />
      <Helmet>
        <title> MyCashiers | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>
      <Container maxWidth="xl" className="dashboardPage cardDesign">
        <Typography variant="h4" sx={{ mb: 3 }}>
          MyCashiers
        </Typography>
        <Grid
          container
          className="boxGrid nomarginLR"
          spacing={3}
          justifyContent="stretch"
          sx={{
            '& .MuiPaper-root': {
              margin: 1,
              border: '1px solid ',
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#fff',
            },
            // '& .MuiTypography-root': { color: '#061B64' },
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            cashiers.length > 0 &&
            cashiers.map((cashier, index) => (
              <Card key={index}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: getRandomColor, borderRadius: '10px' }} aria-label="recipe">
                      {cashier.username.charAt(0).toUpperCase()}
                    </Avatar>
                  }
                  action={
                    <Stack direction="row">
                      <Tooltip title="Debit Balance">
                        <IconButton aria-label="settings" onClick={() => handleOpen(cashier.id)}>
                          <Icon
                            icon="hugeicons:money-remove-02"
                            style={{ color: '#FF4842', fontSize: '23px', marginRight: '5px' }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  }
                  title={cashier.username}
                  subheader={`${fNumber(cashier.balance)} ${showCurrency()}`}
                  sx={{
                    padding: '16px',
                  }}
                />
              </Card>
            ))
          )}
        </Grid>
      </Container>
    </>
  );
};

export default MyCashiers;
