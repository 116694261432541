import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import {
  Box,
  CircularProgress,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
// import { DatePicker } from '@mui/x-date-pickers';
// import dayjs from 'dayjs';
import { Icon } from '@iconify/react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import { alpha, styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import config from '../../config';
import { UserContext } from '../../contexts/UserContext';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { postData } from '../../utils/api';
import { ToastError, formatDate, formatDateTimeUTC, formatNumber, showCurrency } from '../../utils/helper';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));
const HighestBetsPlaced = () => {
  const REACT_APP_API_URL = config.apiUrl.slice(0, -4);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [list, setList] = useState([]);
  const { user } = useContext(UserContext);
  const [extra_data, setExtraData] = useState([]);
  const [selectVal, setSelectVal] = useState(1);
  const [sortData, setSortData] = useState('DESC');
  const [sortBy, setSortBY] = useState('');
  const [loading, SetLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [searchValue, setFilterName] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(['', '']);
  const [value, SetValue] = useState('0');
  const min_date = new Date();
  const minDate = dayjs(min_date);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const fetchDataFromApi = async (
    isOnline,
    searchKey,
    searchValue,
    start_date,
    end_date,
    sortBy,
    sortData,
    page,
    rowsPerPage
  ) => {
    const startDate = new Date(start_date || '');
    const endDate = new Date(end_date || '');
    const utcstartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
    const data = {
      user_token: user.token,
      data: {
        is_online: isOnline !== '1',
        search_key: searchKey || '',
        search_value: searchValue || '',
        start_date: start_date === '' ? '' : formatDate(utcstartDate, 'datepicker'),
        end_date: end_date === '' ? '' : formatDate(utcEndDate, 'datepicker'),
        order_key: sortBy || '',
        order_value: sortData || '',
      },
    };
    try {
      SetLoading(true);
      const fetchedData = await postData(
        `/admin/report/sportsbook/highestbets?page=${page + 1}&size=${rowsPerPage}`,
        data
      );
      setList(fetchedData.data);
      setExtraData(fetchedData.extra_data);
      SetLoading(false);
    } catch (error) {
      console.log('HighestPayout error :>> ', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchDataFromApi(
      value,
      searchKey,
      searchValue,
      selectedDateRange[0],
      selectedDateRange[1],
      sortBy,
      sortBy ? sortData : '',
      newPage,
      rowsPerPage
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    fetchDataFromApi(
      value,
      searchKey,
      searchValue,
      selectedDateRange[0],
      selectedDateRange[1],
      sortBy,
      sortBy ? sortData : '',
      0,
      event.target.value
    );
  };

  useEffect(() => {
    fetchDataFromApi('0','', '', '', '', sortBy, '', page, rowsPerPage);
  }, []);

  const onSearch = (date) => {
    setPage(0);
    fetchDataFromApi(
      value,
      searchKey,
      searchValue,
      selectedDateRange[0] || '',
      selectedDateRange[1] || '',
      sortBy,
      '',
      0,
      rowsPerPage
    );
  };

  const clearFilter = () => {
    setPage(0);
    setSearchKey('');
    setSortBY('');
    setSortData('');
    SetValue('0')
    setSelectedDateRange(['', '']);
    setFilterName('');
    fetchDataFromApi('0','', '', '', '', '', '', 0, rowsPerPage);
  };
  // export start
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const saveBlob = (blob, filename) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };
  const handleFileTypeDownload = async (filetype) => {
    setDisable(true);
    const startDate = new Date(selectedDateRange[0] !== '' ? selectedDateRange[0] : '');
    const endDate = new Date(selectedDateRange[1] !== '' ? selectedDateRange[1] : '');
    const utcstartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
    const data = {
      user_token: user.token,
      data: {
        is_online: value !== '1',
        page: selectVal === 2 ? page + 1 : '',
        row_per_page: selectVal === 2 ? rowsPerPage : '',
        file_type: filetype,
        search_key: searchKey || '',
        search_value: searchValue || '',
        start_date: selectedDateRange[0] === '' ? '' : formatDate(utcstartDate, 'datepicker'),
        end_date: selectedDateRange[1] === '' ? '' : formatDate(utcEndDate, 'datepicker'),
        order_key: sortBy || '',
        order_value: sortData || '',
        headers: [
          'Bet ID',
          value === '1' ? 'Shop Name' : 'Player Name',
          `Stake Amount (${showCurrency()})`,
          `Vat Amount (${showCurrency()})`,
          `Net Bet Amount (${showCurrency()})`,
          'Status',
          'Create At',
          'Last Update At',
        ],
      },
    };
    try {
      const HighestPayout = await postData(`/admin/report/sportsbook/highestbets/export`, data);

      let mimeType;
      let fileExtension;

      if (filetype === 2) {
        mimeType = 'text/csv';
        fileExtension = '.csv';
      } else if (filetype === 3) {
        mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        fileExtension = '.xlsx';
      } else if (filetype === 1) {
        mimeType = 'application/json';
        fileExtension = '.json';
      } else {
        // Handle unsupported file types

        return;
      }

      const blob = await fetch(`${REACT_APP_API_URL}${HighestPayout.data[0].file_path}`).then((response) =>
        response.blob()
      );

      const filename = `HighestPayoutReport${fileExtension}`;
      saveBlob(blob, filename);
      setDisable(false);
    } catch (error) {
      console.log('error :>> ', error);
      ToastError(error.response.data.message);
      setDisable(false);
    }
    setAnchorEl(null);
  };
  const handleSelectChange = (event) => {
    setSelectVal(event.target.value);
  };
  // export end

  const handleSort = (sortBy) => {
    setSortBY(sortBy);
    setSortData(sortData === 'ASC' ? 'DESC' : 'ASC');
    fetchDataFromApi(
      value,
      searchKey,
      searchValue,
      selectedDateRange[0],
      selectedDateRange[1],
      sortBy,
      sortData === 'ASC' ? 'DESC' : 'ASC',
      page,
      rowsPerPage
    );
  };

  const isNotFound = !list.length;
  const Type = [
    {
      id: 'bet_id',
      label: 'Bet Ticket Id',
    },
    {
      id: value === '1' ? 'shop_name' : 'player_name',
      label: value === '1' ? 'Shop Name' :'Player Name',
    },
  ];
  const getStatus = (status) => {
    switch (status) {
      case 0:
        return <span className="status running" />;
      case 1:
        return <span className="status lost" />;
      case 2:
        return <span className="status win" />;
      case 3:
        return <span className="status cancelled" />;
      default:
        return <span className="status running" />;
    }
  };
  const onchangeType = (event) => {
    setSearchKey(event.target.value);
  };
  const handleFilterByName = (event) => {
    const inputValue = event.target.value;
    if (searchKey !== 'bet_id') {
      setFilterName(event.target.value);
    } else {
      const sanitizedValue = inputValue.replace(/\D/g, '');
      setFilterName(sanitizedValue);
    }
  };
  const handleDateChange = (newValue) => {
    setSelectedDateRange([newValue[0] !== null ? newValue[0] : '', newValue[1] !== null ? newValue[1] : '']);
  };

  const handleChange = (event, newValue) => {
    setPage(0);
    setSearchKey('');
    setSortBY('');
    setSortData('');
    setSelectedDateRange(['', '']);
    setFilterName('');
    SetValue(newValue);
    fetchDataFromApi(newValue, '', '', '', '', '', '', 0, 10);
  };
  return (
    <>
      <Helmet>
        <title> Higest Bets Report | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>
      <Container maxWidth="xl" className="transitionPage">
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon
              icon="streamline:money-graph-bar-increase-up-product-performance-increase-arrow-graph-business-chart"
              style={{ fontSize: '23px', marginRight: '5px', marginTop: '6px' }}
            />
            <Typography variant="h4" gutterBottom>
              Player Highest Payout
            </Typography>
          </Box>
        </Stack> */}
        <form onSubmit={handleSubmit(onSearch)}>
          <Stack marginBottom={10} display="flex" flexDirection="row" className="temptranstaionStack">
            <Box marginRight={2} sx={{ flexBasis: '25%' }} className="slectInput">
              <FormControl sx={{ minWidth: 150 }} fullWidth>
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                  name="searchKey"
                  // {...register('bet_place_type')}
                  // name="bet_place_type"
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={searchKey}
                  onChange={onchangeType}
                >
                  {Type.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <TextField
                value={searchValue}
                type="text"
                disabled={searchKey === ''}
                onChange={handleFilterByName}
                placeholder="Search..."
              />
            </Box>
            <Box marginLeft={2} sx={{ marginTop: '-10px', paddingTop: '5px' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DesktopDateRangePicker']}>
                  <DemoItem component="DesktopDateRangePicker">
                    <DesktopDateRangePicker
                      localeText={{ start: 'Start Date', end: 'End Date' }}
                      className=""
                      size="small"
                      maxDate={minDate}
                      value={selectedDateRange}
                      onChange={handleDateChange}
                      calendars={1}
                      format="DD-MM-YYYY"
                      slotProps={{
                        textField: {
                          error: false,
                        },
                      }}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </Box>
            <Box marginLeft={22} display="flex" flexDirection="row" justifyContent={'right'}>
              <Box marginLeft={2}>
                <Button
                  onClick={clearFilter}
                  color="secondary"
                  variant="outlined"
                  size="large"
                  sx={{ padding: '23px', height: '53px', marginTop: '5px' }}
                  disabled={loading}
                  disableElevation
                >
                  Clear
                </Button>
              </Box>
              <Box marginLeft={2}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  size="large"
                  sx={{ padding: '23px', height: '53px', marginTop: '5px' }}
                  disabled={loading}
                  disableElevation
                >
                  Search
                </Button>
              </Box>
            </Box>
          </Stack>
        </form>
        <Stack
          marginBottom={5}
          sx={{ width: '100%' }}
          justifyContent={'space-between'}
          display="flex"
          flexDirection="row"
        >
          <Box display="flex" flexDirection="row">
            <Typography variant="body1" className="statusLabel win" sx={{ mx: 3 }}>
              Winning
            </Typography>
            <Typography variant="body1" className="statusLabel lost" sx={{ mx: 3 }}>
              Lost
            </Typography>
            <Typography variant="body1" className="statusLabel running" sx={{ mx: 3 }}>
              Not Settled
            </Typography>
            <Typography variant="body1" className="statusLabel cancelled" sx={{ mx: 3 }}>
              Refund/Cancelled
            </Typography>
            <Typography variant="body1" className="statusLabel half_win" sx={{ mx: 3 }}>
              Half-Win
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box marginRight={2}>
              <FormControl size="small" sx={{ minWidth: 180 }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectVal}
                  defaultValue={1}
                  onChange={handleSelectChange}
                >
                  <MenuItem value={1}>Select All</MenuItem>
                  <MenuItem value={2}>Current Page</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box>
              <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                open={open}
                onClick={handleClick}
              >
                Export
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={() => handleFileTypeDownload(1)} disabled={disable} disableRipple>
                  <Icon icon="bxs:file-json" style={{ color: '#323436db', fontSize: '23px', marginRight: '5px' }} />
                  JSON
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={() => handleFileTypeDownload(2)} disabled={disable} disableRipple>
                  <Icon
                    icon="material-symbols:csv-outline"
                    style={{ color: '#323436db', fontSize: '23px', marginRight: '5px' }}
                  />
                  CSV
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />

                <MenuItem onClick={() => handleFileTypeDownload(3)} disabled={disable} disableRipple>
                  <Icon
                    icon="file-icons:microsoft-excel"
                    style={{ color: '#323436db', fontSize: '20px', marginRight: '5px' }}
                  />
                  EXCEL
                </MenuItem>
              </StyledMenu>
            </Box>
          </Box>
        </Stack>
        <Stack>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                sx={{
                  '& .MuiTab-root': { fontSize: '18px', fontWeight: '500', paddingLeft: 4, paddingRight: 4 },
                }}
              >
                <Tab label="Online" value="0" />
                <Tab label="Offline" value="1" />
              </TabList>
            </Box>
            <TabPanel sx={{ paddingLeft: 0 }} value="0">
              <TableContainer component={Paper} sx={{ marginTop: '15px', maxHeight: 540 }}>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table" margin={1}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('bet_id')}>
                        Bet ID
                        <IconButton aria-label="toggle password visibility">
                          {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleSort(value === '1' ? 'shop_name' : 'player_name')}
                        align="center"
                      >
                        {value === '1' ? 'Shop Name' : 'Player Name'}
                        <IconButton aria-label="toggle password visibility">
                          {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('stake_amount')} align="center">
                        Stack Amount
                        <IconButton aria-label="toggle password visibility">
                          {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('vat_amount')} align="center">
                        Vat Amount
                        <IconButton aria-label="toggle password visibility">
                          {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('net_bet_amount')} align="center">
                        Net Bet Amount
                        <IconButton aria-label="toggle password visibility">
                          {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('created_at')} align="center">
                        Create At
                        <IconButton aria-label="toggle password visibility">
                          {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleSort('last_updated_at')}
                        align="center"
                      >
                        Last Update At
                        <IconButton aria-label="toggle password visibility">
                          {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('status')} align="center">
                        Status
                        <IconButton aria-label="toggle password visibility">
                          {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {!isNotFound && !loading && (
                    <TableBody>
                      {list?.map((item) => (
                        <TableRow key={item.bet_id}>
                          <TableCell align="left">{item?.bet_id}</TableCell>
                          <TableCell align="center">{item?.shop ? item?.shop?.name : item?.user.username}</TableCell>
                          <TableCell align="center">{formatNumber(item?.stake_amount)} {showCurrency()}</TableCell>
                          <TableCell align="center">{formatNumber(item?.vat_amount)} {showCurrency()}</TableCell>
                          <TableCell align="center">{formatNumber(item?.net_bet_amount)} {showCurrency()}</TableCell>
                          <TableCell align="center">
                            {formatDateTimeUTC(item?.created_at, 'MM/DD/YYYY HH:mm')}
                          </TableCell>
                          <TableCell align="center">
                            {formatDateTimeUTC(item?.last_updated_at, 'MM/DD/YYYY HH:mm')}
                          </TableCell>
                          <TableCell align="center">{getStatus(item?.status)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                  {loading && (
                    <TableRow>
                      <TableCell align="center" colSpan={12}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                  {isNotFound && !loading && (
                    <TableRow>
                      <TableCell align="center" colSpan={12}>
                        <Typography variant="h6" textTransform={'capitalize'}>
                          {' '}
                          data not found !
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}

                  {!isNotFound && !loading && (
                    <TableFooter sx={{}}>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          align="left"
                          sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}
                        >
                          Total Entries : {extra_data ? extra_data.current_page_items : 0}
                        </TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                          {list.length > 0
                            ? formatNumber(
                                list
                                  .reduce(
                                    (accumulator, object) => parseFloat(accumulator) + parseFloat(object.stake_amount),
                                    0
                                  )
                                  .toFixed(2)
                              )
                            : 0.0}{' '}
                          {showCurrency()}
                        </TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                          {list.length > 0
                            ? formatNumber(
                                list
                                  .reduce(
                                    (accumulator, object) => parseFloat(accumulator) + parseFloat(object.vat_amount),
                                    0
                                  )
                                  .toFixed(2)
                              )
                            : 0.0}{' '}
                          {showCurrency()}
                        </TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                          {list.length > 0
                            ? formatNumber(
                                list
                                  .reduce(
                                    (accumulator, object) =>
                                      parseFloat(accumulator) + parseFloat(object.net_bet_amount),
                                    0
                                  )
                                  .toFixed(2)
                              )
                            : 0.0}{' '}
                          {showCurrency()}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  )}
                </Table>
              </TableContainer>

              {!isNotFound && !loading && (
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={extra_data.total_items ? extra_data.total_items : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </TabPanel>
            <TabPanel sx={{ paddingLeft: 0 }} value="1">
              <TableContainer component={Paper} sx={{ marginTop: '15px', maxHeight: 540 }}>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table" margin={1}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('bet_id')}>
                        Bet ID
                        <IconButton aria-label="toggle password visibility">
                          {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleSort(value === '1' ? 'shop_name' : 'player_name')}
                        align="center"
                      >
                        {value === '1' ? 'Shop Name' : 'Player Name'}
                        <IconButton aria-label="toggle password visibility">
                          {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('stake_amount')} align="center">
                        Stack Amount
                        <IconButton aria-label="toggle password visibility">
                          {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('vat_amount')} align="center">
                        Vat Amount
                        <IconButton aria-label="toggle password visibility">
                          {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('net_bet_amount')} align="center">
                        Net Bet Amount
                        <IconButton aria-label="toggle password visibility">
                          {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('created_at')} align="center">
                        Create At
                        <IconButton aria-label="toggle password visibility">
                          {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleSort('last_updated_at')}
                        align="center"
                      >
                        Last Update At
                        <IconButton aria-label="toggle password visibility">
                          {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('status')} align="center">
                        Status
                        <IconButton aria-label="toggle password visibility">
                          {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {!isNotFound && !loading && (
                    <TableBody>
                      {list?.map((item) => (
                        <TableRow key={item.bet_id}>
                          <TableCell align="left">{item?.bet_id}</TableCell>
                          <TableCell align="center">{item?.shop ? item?.shop?.name : item?.user.username}</TableCell>
                          <TableCell align="center">{formatNumber(item?.stake_amount)} {showCurrency()}</TableCell>
                          <TableCell align="center">{formatNumber(item?.vat_amount)} {showCurrency()}</TableCell>
                          <TableCell align="center">{formatNumber(item?.net_bet_amount)} {showCurrency()}</TableCell>
                          <TableCell align="center">
                            {formatDateTimeUTC(item?.created_at, 'MM/DD/YYYY HH:mm')}
                          </TableCell>
                          <TableCell align="center">
                            {formatDateTimeUTC(item?.last_updated_at, 'MM/DD/YYYY HH:mm')}
                          </TableCell>
                          <TableCell align="center">{getStatus(item?.status)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                  {loading && (
                    <TableRow>
                      <TableCell align="center" colSpan={12}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                  {isNotFound && !loading && (
                    <TableRow>
                      <TableCell align="center" colSpan={12}>
                        <Typography variant="h6" textTransform={'capitalize'}>
                          {' '}
                          data not found !
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}

                  {!isNotFound && !loading && (
                    <TableFooter sx={{}}>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          align="left"
                          sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}
                        >
                          Total Entries : {extra_data ? extra_data.current_page_items : 0}
                        </TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                          {list.length > 0
                            ? formatNumber(
                                list
                                  .reduce(
                                    (accumulator, object) => parseFloat(accumulator) + parseFloat(object.stake_amount),
                                    0
                                  )
                                  .toFixed(2)
                              )
                            : 0.0}{' '}
                          {showCurrency()}
                        </TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                          {list.length > 0
                            ? formatNumber(
                                list
                                  .reduce(
                                    (accumulator, object) => parseFloat(accumulator) + parseFloat(object.vat_amount),
                                    0
                                  )
                                  .toFixed(2)
                              )
                            : 0.0}{' '}
                          {showCurrency()}
                        </TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                          {list.length > 0
                            ? formatNumber(
                                list
                                  .reduce(
                                    (accumulator, object) =>
                                      parseFloat(accumulator) + parseFloat(object.net_bet_amount),
                                    0
                                  )
                                  .toFixed(2)
                              )
                            : 0.0}{' '}
                          {showCurrency()}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  )}
                </Table>
              </TableContainer>

              {!isNotFound && !loading && (
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={extra_data.total_items ? extra_data.total_items : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </TabPanel>
          </TabContext>
        </Stack>
      </Container>
    </>
  );
};

export default HighestBetsPlaced;
