import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
// components
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { UserListToolbar } from '../../sections/@dashboard/user';
import TableHeadSection from '../../sections/TableHeadSection';

// sections
// mock
import Label from '../../components/label';
import SvgColor from '../../components/svg-color/SvgColor';
import { UserContext } from '../../contexts/UserContext';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { deleteDataWithAuth, postData } from '../../utils/api';
import { ToastError, ToastSuccess, formatDate } from '../../utils/helper';
import MenuDialog from './MenuDialog';
/* eslint-disable react/no-unknown-property */
// ----------------------------------------------------------------------

const MenuPage = ({ model }) => {
  const [open, setOpen] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);

  const [currentId, setCurrentId] = useState(null);

  const [page, setPage] = useState(0);

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [menus, setMenus] = useState([]);

  const [extra_data, setExtraData] = useState([]);

  const { user } = useContext(UserContext);
  const [sortData, setSortData] = useState('DESC');
  const [sortBy, setSortBY] = useState('');
  const [loading, SetLoading] = useState(true);

  const handleDeleteClickOpen = () => {
    setDialogOpen(true);
    setOpen(null);
  };

  const handleOpenMenu = (id, event) => {
    setOpen(event.currentTarget);
    setCurrentId(id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setCurrentId(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchMenus(sortBy, sortBy ? sortData : '', newPage, rowsPerPage, filterName);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    fetchMenus(sortBy, sortBy ? sortData : '', 0, event.target.value, filterName);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
    fetchMenus(sortBy, sortBy ? sortData : '', 0, rowsPerPage, event.target.value);
  };

  const isNotFound = !menus.length && !!filterName;
  const isNotFoundData = !menus.length;

  const fetchMenus = async (sortBy, sortData, page, rowsPerPage, filterName) => {
    try {
      const data = {
        user_token: user.token,
        data: {
          name: filterName !== null ? filterName : '',
          slug: '',
          order_key: sortBy !== '' ? sortBy : '',
          order_value: sortData,
        },
      };
      SetLoading(true);
      const response = await postData(`/admin/${model}/list?page=${page + 1}&size=${rowsPerPage}`, data);
      setMenus(response.data);
      setExtraData(response.extra_data);
      SetLoading(false);
    } catch (error) {
      console.log('error :>> ', error);
      ToastError(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchMenus(sortBy, '', page, rowsPerPage, filterName);
  }, []);

  const handleDeleteMenu = async (confirm) => {
    toast.dismiss();
    if (confirm) {
      try {
        const data = {
          user_token: user.token,
          data: {},
        };
        const ShopData = await deleteDataWithAuth(`/admin/${model}/delete/${currentId}`, data);
        ToastSuccess(ShopData.message);
        setTimeout(() => {
          toast.dismiss();
          setDialogOpen(false);
          setOpen(null);
          setCurrentId(null);
          setPage(0);
          fetchMenus(sortBy, sortBy ? sortData : '', page, rowsPerPage, filterName);
        }, 800);
      } catch (error) {
        console.log('error2 :>> ', error);
        ToastError(error.response.data.message);
      }
    } else {
      setDialogOpen(false);
    }
  };

  const TABLE_HEAD = [
    { id: 'id', label: 'ID', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'slug', label: 'Slug', alignRight: false },
    { id: 'icon', label: 'Icon', alignRight: false },
    { id: 'action', label: 'Actions', alignRight: false },
    { id: 'created_at', label: 'Created At', alignRight: false },
    { id: '' },
  ];

  const icon = (name) => {
    if (name.includes(':')) {
      return <Iconify icon={name} sx={{ width: 20, height: 20 }} />;
    }
    return <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
  };

  const handleSort = (sortBy) => {
    if (!isNotFoundData && !loading) {
      setSortBY(sortBy);
      setSortData(sortData === 'ASC' ? 'DESC' : 'ASC');
      fetchMenus(sortBy, sortData === 'ASC' ? 'DESC' : 'ASC', page, rowsPerPage);
    }
  };

  const getActions = (action) => {
    const actions = action.split(',');
    return actions.map((item) => item !== '' ? <><Label color={'info'}>{item}</Label> </> : '');
  }

  return (
    <>
      <MenuDialog setDialog={setDialogOpen} open={dialogOpen} handleDeleteMenu={handleDeleteMenu} />
      <Helmet>
        <title> Side Menus | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon icon="tabler:menu-order" style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }} />
            <Typography variant="h4" gutterBottom>
              Side Menus
            </Typography>
          </Box>
          <Button
            to={`/admin/${model}/create`}
            variant="contained"
            component={Link}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Side Menu
          </Button>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={0}
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeHolder={'Search Menus...'}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, maxHeight: 540 }}>
              <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table" margin={1}>
                <TableHead>
                  <TableRow>
                    <TableHeadSection sortData={sortData} handleSort={handleSort} tableHead={TABLE_HEAD} />
                  </TableRow>
                </TableHead>
                {/* {menus.length ? ( */}
                {!isNotFound && !loading && (
                  <TableBody>
                    {menus.map((row) => (
                      <TableRow hover key={row.id} tabIndex={-1}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.slug}</TableCell>
                        <TableCell align="left">
                          <Icon fontSize="smalldfef">{row.icon ? icon(row.icon) : ''}</Icon>
                        </TableCell>
                        <TableCell align="left">{getActions(row.action)}</TableCell>
                        <TableCell align="left">{formatDate(row.created_at, 'ticket datetime')}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(e) => {
                              handleOpenMenu(row.id, e);
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
                {/* ) : ( */}
                <TableBody>
                  {isNotFound && !loading && (
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph textTransform={'capitalize'}>
                            data not found !
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typing or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                {/* )} */}
                {loading && !isNotFound && (
                  <TableRow>
                    <TableCell align="center" colSpan={12}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                {!isNotFound && !loading && (
                  <TableFooter sx={{}}>
                    <TableRow>
                      <TableCell colSpan={7} align="left" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                        Total Entries : {extra_data ? extra_data.current_page_items : 0}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          {!isNotFound && !loading && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={extra_data ? extra_data.total_items : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem component={Link} to={`/admin/${model}/edit/${currentId}`}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteClickOpen}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
};

export default MenuPage;
