import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, CircularProgress, Grid, Paper, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import { fetchDataWithAuth } from '../../utils/api';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { formatNumber, showCurrency } from '../../utils/helper';

<Helmet>
  <title> Live Reports | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
</Helmet>;

const Item = styled(Paper)(({ color, theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  border: `1px solid ${color ? theme.palette[color].main : '#000'}`,
}));

const TypographyCustom = styled(Typography)(({ color, theme }) => ({
  color: theme.palette[color].main,
}));

const LiveReports = () => {
  const { user, updateBalance } = useContext(UserContext);
  const [list, setList] = useState(null);
  const minBalance = 1500;

  const FetchShopWithBalance = async () => {
    const data = {
      user_token: user.token,
      data: {},
    };
    const response = await fetchDataWithAuth(`/admin/reports/live/shop/balance`, data);
    const fetchedBalances = response.data;

    const updatedBalances = fetchedBalances.map((balance) => {
      const previousBalance = list ? list.find((item) => item.id === balance.id).total_balance : balance.total_balance;

      switch (true) {
        case balance.total_balance <= minBalance:
          return { ...balance, flag: 'min' };
        case balance.total_balance > previousBalance:
          return { ...balance, flag: 'up' };
        case balance.total_balance < previousBalance:
          return { ...balance, flag: 'down' };
        case balance.total_balance === previousBalance:
          return { ...balance, flag: 'none' };
        default:
          return balance;
      }
    });
    setList(updatedBalances);
  };

  const getLiveShopBalance = async () => {
    const data = {
      user_token: user.token,
      data: {},
    };
    const response = await fetchDataWithAuth(`/admin/reports/live/shop/balance`, data);
    const fetchedBalances = response.data;

    const updatedBalances = fetchedBalances.map((balance) => {
      const previousBalance = list ? list.find((item) => item.id === balance.id).total_balance : balance.total_balance;

      switch (true) {
        case balance.total_balance <= minBalance:
          return { ...balance, flag: 'min' };
        case balance.total_balance > previousBalance:
          return { ...balance, flag: 'up' };
        case balance.total_balance < previousBalance:
          return { ...balance, flag: 'down' };
        case balance.total_balance === previousBalance:
          return { ...balance, flag: 'none' };
        default:
          return balance;
      }
    });
    setList(updatedBalances);
  };

  useEffect(() => {
    if (!list) FetchShopWithBalance();
  }, [list]);

  useEffect(() => {
    const interval = setInterval(getLiveShopBalance, 10000);
    return () => {
      clearInterval(interval); // Clean up the interval when the component unmounts
    };
  });

  return (
    <>
      <Helmet>
        <title> Live Report | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>
      <Box sx={{ flexGrow: 1, px: 2 }}>
        {
          list && list.length > 0
            ?
            <Grid container sx={{}} spacing={2}>
              {list.map((item, index) => <Shop key={index} item={item} />)}
            </Grid>
            :
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
              <CircularProgress />
            </Box>
        }
      </Box>
    </>
  );
};

export default LiveReports;

const Shop = ({ item }) => {
  const navigate = useNavigate();

  switch (item.flag) {
    case 'min':
      return (
        <>
          <Grid item sx={{ width: '170px' }}>
            <Item
              onClick={() => navigate(`/admin/report/shopreport/${item.id}`)}
              className="blinkAnimation"
              sx={{ border: '1px solid red', backgroundColor: 'red', cursor: 'pointer' }}
              elevation={2}
              textAlign="center"
            >
              <Stack direction="row" justifyContent="space-between" sx={{ position: 'relative' }}>
                <Box>
                  <Typography variant="body2" sx={{ color: '#fff' }} textAlign="center">
                    {item.name}
                  </Typography>
                  <Typography sx={{ color: '#fff' }} variant="body1" fontWeight="bold" textAlign="center">
                    {formatNumber(item.total_balance)} {showCurrency()}
                  </Typography>{' '}
                </Box>
              </Stack>
            </Item>
          </Grid>
        </>
      );
    case 'up':
      return (
        <>
          <Grid item sx={{ width: '170px', cursor: 'pointer' }}>
            <Item
              onClick={() => navigate(`/admin/report/shopreport/${item.id}`)}
              color={'success'}
              elevation={2}
              textAlign="left"
            >
              <Stack direction="row" justifyContent="flex-start" sx={{ position: 'relative' }}>
                <Box>
                  {' '}
                  <Typography variant="body2" sx={{ color: '#000' }} textAlign="center">
                    {item.name}
                  </Typography>
                  <TypographyCustom color={'success'} variant="body1" fontWeight="bold" textAlign="center">
                    {formatNumber(item.total_balance)} {showCurrency()}
                  </TypographyCustom>{' '}
                </Box>

                <Box>
                  <div id="cta">
                    <span className="arrow primera next bounceAlpha">
                      <KeyboardArrowUpIcon color={'success'} />
                    </span>
                    <span className="arrow segunda next  bounceAlpha">
                      {' '}
                      <KeyboardArrowUpIcon color={'success'} />
                    </span>
                  </div>
                </Box>
              </Stack>
            </Item>
          </Grid>
        </>
      );
    case 'down':
      return (
        <>
          <Grid item sx={{ width: '170px', cursor: 'pointer' }}>
            <Item
              onClick={() => navigate(`/admin/report/shopreport/${item.id}`)}
              color={'warning'}
              elevation={2}
              textAlign="center"
            >
              <Stack direction="row" justifyContent="space-between" sx={{ position: 'relative' }}>
                <Box>
                  {' '}
                  <Typography variant="body2" sx={{ color: '#000' }} textAlign="center">
                    {item.name}
                  </Typography>
                  <TypographyCustom color={'warning'} variant="body1" fontWeight="bold" textAlign="center">
                    {formatNumber(item.total_balance)} {showCurrency()}
                  </TypographyCustom>{' '}
                </Box>

                <Box>
                  <div id="cta">
                    <span className="arrow primera next  bounceAlphaDown">
                      <KeyboardArrowDownIcon color="warning" />
                    </span>
                    <span className="arrow segunda next   bounceAlphaDown">
                      <KeyboardArrowDownIcon color="warning" />
                    </span>
                  </div>
                </Box>
              </Stack>
            </Item>
          </Grid>
        </>
      );
    case 'none':
      return (
        <>
          {' '}
          <Grid item sx={{ width: '170px', cursor: 'pointer' }}>
            <Item
              onClick={() => navigate(`/admin/report/shopreport/${item.id}`)}
              sx={{ border: '1px solid #000' }}
              elevation={2}
              textAlign="center"
            >
              <Typography variant="body2" sx={{ color: '#000' }} textAlign="center">
                {item.name}
              </Typography>

              <Typography variant="body1" sx={{ color: '#000' }} fontWeight="bold" textAlign="center">
                {formatNumber(item.total_balance)} {showCurrency()}
              </Typography>
            </Item>
          </Grid>
        </>
      );
    default:
      return (
        <>
          {' '}
          <Grid item sx={{ width: '170px', cursor: 'pointer' }}>
            <Item
              onClick={() => navigate(`/admin/report/shopreport/${item.id}`)}
              sx={{ border: '1px solid #000' }}
              elevation={2}
              textAlign="center"
            >
              <Typography variant="body2" sx={{ color: '#000' }} textAlign="center">
                {item.name}
              </Typography>

              <Typography variant="body1" sx={{ color: '#000' }} fontWeight="bold" textAlign="center">
                {formatNumber(item.total_balance)} {showCurrency()}
              </Typography>
            </Item>
          </Grid>
        </>
      );
  }
};
