import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './contexts/UserContext';

const AutoLogout = () => {
  const [lastActivity, setLastActivity] = useState(Date.now());
  const { user, logout } = useContext(UserContext);
  const Redirect = useNavigate();
  // Update the last activity timestamp whenever there's user interaction
  const handleUserActivity = () => {
    setLastActivity(Date.now());
  };

  useEffect(() => {
    // Set up an event listener for user activity
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, []);

  useEffect(() => {
    const autoLogoutDuration = 60 * 60 * 1000; // 60 minutes in milliseconds

    const checkInactivity = setInterval(() => {
      const currentTime = Date.now();
      const timeSinceLastActivity = currentTime - lastActivity;

      if (timeSinceLastActivity >= autoLogoutDuration) {
        // Perform logout action
        logoutUser();
      }
    }, 1000); // Check every second

    return () => {
      clearInterval(checkInactivity);
    };
  }, [lastActivity]);

  const logoutUser = () => {
    logout();
    Redirect('/login');
  };
};

export default AutoLogout;
