import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Card, CardContent, CircularProgress, Container, Grid, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { UserContext } from '../contexts/UserContext';
import { fetchDataWithAuth } from '../utils/api';
import { fNumber } from '../utils/formatNumber';
import { MY_GLOBAL_CONST } from '../utils/GlobalConstant';
// components
// sections

// ----------------------------------------------------------------------

export default function CashierDashboardAppPage() {
  const CardTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
      },
    })
  );
  // const summaryData = [
  //   {
  //     key: 'Start Balance',
  //     value: '3.0',
  //   },
  //   {
  //     key: 'Deposits',
  //     value: '11.47',
  //   },
  //   {
  //     key: 'Bets',
  //     value: 9.17,
  //   },
  //   {
  //     key: 'Cancellations',
  //     value: 1.3,
  //   },
  //   {
  //     key: 'Redeemed',
  //     value: 2.6,
  //   },
  //   {
  //     key: 'Withdraws',
  //     value: 16.0,
  //   },
  //   {
  //     key: 'End Balance',
  //     value: 7.0,
  //   },
  // ];
  const [dashboardCounts, setDashboardCounts] = useState({
    total_playerbet: 0,
    total_users: 0,
    new_users: 0,
    active_users: 0,
  });
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);
  useEffect(() => {
    const getDashboard = async () => {
      const data = {
        user_token: user.token,
        data: {},
      };
      setLoading(true);
      const response = await fetchDataWithAuth(`/cashier/dashboard`, data);
      setLoading(false);
      const DashboardData = response.data[0];
      setDashboardCounts(DashboardData);
    };
    getDashboard();
  }, []);

  const theme = useTheme();

  // const handleSummaryReport = () => {
  //   const content = document.getElementById('summaryreportprint');
  //   const pri = document.getElementById('ifmsummaryreportprint').contentWindow;
  //   pri.document.open();
  //   pri.document.write(content.innerHTML);
  //   setTimeout(() => {
  //     pri.document.close();
  //     pri.focus();
  //     pri.print();
  //   }, 250);
  // };
  return (
    <>
      <Helmet>
        <title> Dashboard | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>

      <Container maxWidth="xl" className="dashboardPage cardDesign">
        <Typography variant="h4" sx={{ mb: 3 }}>
          Hi, Welcome back
        </Typography>

        {loading ? (
          <Stack display="flex" flexDirection="row" justifyContent="center">
            <CircularProgress size={35} />
          </Stack>
        ) : (
          // <Grid container spacing={3}>
          //   <Grid item xs={12} sm={6} md={3}>
          //     <CardTooltip title="Last 7 Days">
          //       <Card className="card_1">
          //         <CardContent>
          //           <Stack alignItems="center" justifyContent="center">
          //             <Box
          //               sx={{
          //                 width: '64px',
          //                 height: '64px',
          //                 borderRadius: '50%',
          //                 background: '#B7D1EE',
          //                 display: 'flex',
          //                 justifyContent: 'center',
          //                 alignItems: 'center',
          //               }}
          //             >
          //               <RadarIcon sx={{ color: '#061B64' }} />
          //             </Box>

          //             <Typography variant="h3" color="#061B64">
          //               {fNumber(dashboardCounts.total_playerbet)}
          //             </Typography>
          //             <Typography variant="body2" color="#061B64">
          //               Bets
          //             </Typography>
          //           </Stack>
          //         </CardContent>
          //       </Card>
          //     </CardTooltip>
          //   </Grid>

          //   <Grid item xs={12} sm={6} md={3}>
          //     <CardTooltip title="Last 7 Days">
          //       <Card className="card_2">
          //         <CardContent>
          //           <Stack alignItems="center" justifyContent="center">
          //             <Box
          //               sx={{
          //                 width: '64px',
          //                 height: '64px',
          //                 borderRadius: '50%',
          //                 background: '#F7E9B4',
          //                 display: 'flex',
          //                 justifyContent: 'center',
          //                 alignItems: 'center',
          //               }}
          //             >
          //               <PersonIcon sx={{ color: '#7F5408' }} />
          //             </Box>

          //             <Typography variant="h3" color="#7F5408">
          //               {fNumber(dashboardCounts.total_users)}
          //             </Typography>
          //             <Typography variant="body2" color="#7F5408">
          //               Users
          //             </Typography>
          //           </Stack>
          //         </CardContent>
          //       </Card>
          //     </CardTooltip>
          //   </Grid>

          //   <Grid item xs={12} sm={6} md={3}>
          //     <CardTooltip title="Last 7 Days">
          //       <Card className="card_3">
          //         <CardContent>
          //           <Stack alignItems="center" justifyContent="center">
          //             <Box
          //               sx={{
          //                 width: '64px',
          //                 height: '64px',
          //                 borderRadius: '50%',
          //                 background: '#F2C3BB',
          //                 display: 'flex',
          //                 justifyContent: 'center',
          //                 alignItems: 'center',
          //               }}
          //             >
          //               <PersonAddAlt1Icon sx={{ color: '#7A0C2E' }} />
          //             </Box>

          //             <Typography variant="h3" color="#7A0C2E">
          //               {fNumber(dashboardCounts.new_users)}
          //             </Typography>
          //             <Typography variant="body2" color="#7A0C2E">
          //               New Users
          //             </Typography>
          //           </Stack>
          //         </CardContent>
          //       </Card>
          //     </CardTooltip>
          //   </Grid>

          //   <Grid item xs={12} sm={6} md={3}>
          //     <CardTooltip title="Last 7 Days">
          //       <Card className="card_4">
          //         <CardContent>
          //           <Stack alignItems="center" justifyContent="center">
          //             <Box
          //               sx={{
          //                 width: '64px',
          //                 height: '64px',
          //                 borderRadius: '50%',
          //                 background: '#BBB6F8',
          //                 display: 'flex',
          //                 justifyContent: 'center',
          //                 alignItems: 'center',
          //               }}
          //             >
          //               <RecordVoiceOverIcon sx={{ color: '#2F0192' }} />
          //             </Box>

          //             <Typography variant="h3" color="#2F0192">
          //               {fNumber(dashboardCounts.active_users)}
          //             </Typography>
          //             <Typography variant="body2" color="#2F0192">
          //               Active Users
          //             </Typography>
          //           </Stack>
          //         </CardContent>
          //       </Card>
          //     </CardTooltip>
          //   </Grid>
          //   {/* <Button onClick={() => handleSummaryReport()} sx={{ margin: '30px' }} variant="contained">
          //   Print Summary Report
          // </Button>
          // <PrintSummaryReport summaryData={summaryData} /> */}
          // </Grid>
          <Grid
          container
          className="boxGrid nomarginLR"
          spacing={3}
          justifyContent="stretch"
          sx={{
            '& .MuiGrid-root': {
              margin: 1,
              border: '3px solid ',
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#fff',
            },
            // '& .MuiTypography-root': { color: '#061B64' },
          }}
        >
          {/* <Grid container spacing={3}> */}

          <Grid item xs={12} sm={6} md={2} className="green_900">
            <Card>
              <CardContent>
                <Stack sx={{}}>
                  <Typography sx={{ fontSize: '15px' }}>Bets</Typography>
                  <Typography variant="h5">{fNumber(dashboardCounts.total_playerbet)}</Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={2} className="pink_500">
            <Card>
              <CardContent>
                <Stack sx={{}}>
                  <Typography sx={{ fontSize: '15px' }}>Users</Typography>
                  <Typography variant="h5">{fNumber(dashboardCounts.total_users)}</Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={2} className="purple_500">
            <Card>
              <CardContent>
                <Stack>
                  <Typography sx={{ fontSize: '15px' }}>New Users</Typography>
                  <Typography variant="h5">
                  {fNumber(dashboardCounts.new_users)}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={2} className="coffy_300">
            <Card>
              <CardContent>
                <Stack>
                  <Typography sx={{ fontSize: '15px' }}>Active Users</Typography>
                  <Typography variant="h5">
                  {fNumber(dashboardCounts.active_users)}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        )}
      </Container>
    </>
  );
}
