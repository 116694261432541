import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Box, Button, Card, CardContent, CircularProgress, Container, Grid, Stack, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';

// components
// sections
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { useForm } from 'react-hook-form';
import { UserContext } from '../contexts/UserContext';
import { postData } from '../utils/api';
import { fNumber } from '../utils/formatNumber';
import { MY_GLOBAL_CONST } from '../utils/GlobalConstant';
import { formatDate, formatNumber, showCurrency } from '../utils/helper';
// ----------------------------------------------------------------------

export default function AdminDashboardAppPage() {
  const CardTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
      },
    })
  );
  const [type, setType] = useState('');
  // const [sub_type, setSubType] = useState(1);
  // const [bet_type, setBetType] = useState(1);
  const [SportBookCollapsed, setSportBookCollapsed] = useState(false);
  const [VirtualCollapsed, setVirtualCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showText, setShowText] = useState(false);
  const [sportBookLoader, setsportBookLoader] = useState(false);
  const [virtualLoading, setvirtualLoading] = useState(false);
  const [dashboardCounts, setDashboardCounts] = useState({
    total_bet_count: 0,
    total_bet_amount: 0,
    total_bet_winning_count: 0,
    total_bet_winning_amount: 0,
    total_vat_amount: 0,
    total_income_tax_amount: 0,
    total_user: 0,
    total_user_balance: 0,
    total_deposit_count: 0,
    total_deposit_amount: 0,
    total_withdraw_count: 0,
    total_withdraw_amount: 0,
    total_signup_bonus: 0,
    total_rtp: 0,
    total_net_bet_amount: 0,
    total_net_win_amount: 0,
    profit: 0,
    net_profit: 0,
  });
  const [sportBookDashboardCounts, setSportBookDashboardCounts] = useState({
    total_bet_count: 0,
    total_bet_amount: 0,
    total_bet_winning_count: 0,
    total_bet_winning_amount: 0,
    total_vat_amount: 0,
    total_income_tax_amount: 0,
    total_user: 0,
    total_user_balance: 0,
    total_deposit_count: 0,
    total_deposit_amount: 0,
    total_withdraw_count: 0,
    total_withdraw_amount: 0,
    total_signup_bonus: 0,
    total_rtp: 0,
    total_net_bet_amount: 0,
    total_net_win_amount: 0,
    profit: 0,
    net_profit: 0,
  });
  const [virtualsDashboardCounts, setvirtualsDashboardCounts] = useState({
    total_bet_count: 0,
    total_bet_amount: 0,
    total_bet_winning_count: 0,
    total_bet_winning_amount: 0,
    total_vat_amount: 0,
    total_income_tax_amount: 0,
    total_user: 0,
    total_user_balance: 0,
    total_deposit_count: 0,
    total_deposit_amount: 0,
    total_withdraw_count: 0,
    total_withdraw_amount: 0,
    total_signup_bonus: 0,
    total_rtp: 0,
    total_net_bet_amount: 0,
    total_net_win_amount: 0,
    profit: 0,
    net_profit: 0,
  });
  const [selectedDateRange, setSelectedDateRange] = useState(['', '']);
  const [changeText, setChangeText] = useState(['', '']);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  // const [betStats, setBetStats] = useState(null);
  // const [topShops, setTopShops] = useState(null);
  const { user } = useContext(UserContext);
  // const [selectedDateRange, setSelectedDateRange] = useState(['', '']);
  // const [selectedShopDate, setSelectedShopDate] = useState(['', '']);
  // const handleTypeChange = (event) => {
  //   setType(event.target.value);
  //   getTopShops(event.target.value, sub_type, '');
  // };
  // const lastSevenDays = dayjs().subtract(7, 'day');
  // const TodayDate = dayjs();

  const TodayDate = new Date();
  TodayDate.setHours(0, 0, 0, 0);
  const lastSevenDays = new Date(TodayDate);
  lastSevenDays.setDate(TodayDate.getDate() - 7);
  lastSevenDays.setHours(0, 0, 0, 0);

  // const handleSubTypeChange = (event) => {
  //   setSubType(event.target.value);
  //   getTopShops(type, event.target.value, selectedShopDate[0], selectedShopDate[1]);
  // };

  const getDashboard = async (type = '', start_Date, end_Date) => {
    setChangeText([start_Date, end_Date]);
    const start_date = new Date(start_Date);
    const endDate = new Date(end_Date);
    // start_date.setHours(0, 0, 0);
    // endDate.setHours(23, 59, 59);

    const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
    setLoading(true);
    const data = {
      user_token: user.token,
      data: {
        provider_id: type !== undefined ? type : '',
        start_date: start_Date ? formatDate(utcstartDate, 'datepicker') : '',
        end_date: end_Date ? formatDate(utcEndDate, 'datepicker') : '',
      },
    };
    const response = await postData(`/admin/dashboard`, data);
    if (response.status_code === 200) {
      setShowText(true);
    }
    const userData = response.data[0];
    setDashboardCounts(userData);
    setLoading(false);
  };
  const getsportbookDashboard = async (type = '', start_Date, end_Date) => {
    const start_date = new Date(start_Date);
    const endDate = new Date(end_Date);
    // start_date.setHours(0, 0, 0);
    // endDate.setHours(23, 59, 59);

    const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
    const data = {
      user_token: user.token,
      data: {
        provider_id: 1,
        start_date: start_Date ? formatDate(utcstartDate, 'datepicker') : '',
        end_date: end_Date ? formatDate(utcEndDate, 'datepicker') : '',
      },
    };
    setsportBookLoader(true);
    const response = await postData(`/admin/dashboard`, data);
    setsportBookLoader(false);
    const userData = response.data[0];
    setSportBookDashboardCounts(userData);
  };
  const getVirtualsDashboard = async (type = '', start_Date, end_Date) => {
    const start_date = new Date(start_Date);
    const endDate = new Date(end_Date);
    // start_date.setHours(0, 0, 0);
    // endDate.setHours(23, 59, 59);

    const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
    const data = {
      user_token: user.token,
      data: {
        provider_id: 2,
        start_date: start_Date ? formatDate(utcstartDate, 'datepicker') : '',
        end_date: end_Date ? formatDate(utcEndDate, 'datepicker') : '',
      },
    };
    setvirtualLoading(true);
    const response = await postData(`/admin/dashboard`, data);
    setvirtualLoading(false);
    const userData = response.data[0];
    setvirtualsDashboardCounts(userData);
  };

  // const getBetStats = async (startDate, endDate) => {
  //   const start_date = new Date(startDate !== '' ? startDate : lastSevenDays);
  //   start_date.setHours(0, 0, 0, 0);
  //   const end_date = new Date(endDate !== '' ? endDate : TodayDate);
  //   end_date.setHours(end_date.getHours() + 23);
  //   end_date.setMinutes(end_date.getMinutes() + 59);
  //   const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
  //   const utcEndDate = new Date(end_date.getTime() - end_date.getTimezoneOffset() * 60000);
  //   const data = {
  //     user_token: user.token,
  //     data: {
  //       // type: '',
  //       start_date: utcstartDate,
  //       end_date: utcEndDate,
  //     },
  //   };
  //   const response = await postData(`/admin/dashboard/betstats`, data);
  //   const betData = response.data[0];
  //   setBetStats(betData);
  // };
  // const handleDateChange = (newValue) => {
  //   if (newValue[1] !== null) {
  //     getBetStats(newValue[0], newValue[1]);
  //   }
  // };

  // const getTopShops = async (type, sub_type, startDate, endDate) => {
  //   const start_date = new Date(startDate === '' ? lastSevenDays : startDate);
  //   const end_date = new Date(endDate === '' ? TodayDate : endDate);
  //   end_date.setHours(end_date.getHours() + 23);
  //   end_date.setMinutes(end_date.getMinutes() + 59);
  //   const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
  //   const utcEndDate = new Date(end_date.getTime() - end_date.getTimezoneOffset() * 60000);

  //   const data = {
  //     user_token: user.token,
  //     data: {
  //       type: utcstartDate !== null ? '' : type, // 1=> Week, 2=> Month
  //       sub_type: sub_type !== undefined ? sub_type : 1, // 1=> Bet, 2 => Revenue, 3 => Profit, 4 => Payout
  //       start_date: utcstartDate,
  //       end_date: utcEndDate,
  //     },
  //   };
  //   const response = await postData(`/admin/dashboard/topshops`, data);
  //   const shopData = response.data[0];
  //   setTopShops(shopData);
  // };
  // const handleShopDateChange = (shopdate) => {
  //   setSelectedShopDate([shopdate[0], shopdate[1]]);
  //   if (shopdate[1]) getTopShops(type, sub_type, shopdate[0], shopdate[1]);
  // };

  useEffect(() => {
    setLoading(true);
    // getTopShops(type, sub_type, selectedShopDate[0], selectedShopDate[1]);
    // getBetStats(selectedDateRange[0], selectedDateRange[1]);
    getDashboard();
    getsportbookDashboard();
    getVirtualsDashboard();
  }, []);

  const theme = useTheme();
  // const onchangeType = (event) => {
  //   setType(event.target.value);
  //   setValue('type', event.target.value);
  //   // getDashboard(event.target.value, selectedDateRange[0], selectedDateRange[1]);
  // };

  // const handleDateChange = (newValue) => {
  //   setSelectedDateRange([dayjs(newValue[0]),dayjs(newValue[1])]);

  //   console.log("newValue[0",newValue[0]);
  //   console.log("newValue[1",newValue[1]);
  //   if (newValue[1] && newValue[0]) {
  //     setSelectedDateRange(newValue);
  //   }
  //   // if (newValue[1] !== null) getDashboard(type, newValue[0], newValue[1]);
  // };
  const handleDateChange = (newValue) => {
    setSelectedDateRange(newValue);
    if (newValue[1] && newValue[0]) {
      const start_date = new Date(newValue[0]);
      // start_date.setHours(12, 0, 0);
      const endDate = new Date(newValue[1]);
      // endDate.setHours(23, 59, 59);
      setSelectedDateRange([dayjs(start_date), dayjs(endDate)]);
      const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
      const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
      setValue('start_date', formatDate(utcstartDate.toISOString(), 'datepicker'));
      setValue('end_date', formatDate(utcEndDate.toISOString(), 'datepicker'));
    }
    // if (newValue[1] !== null) getDashboard(type, newValue[0], newValue[1]);
  };

  const min_date = new Date();
  const minDate = dayjs(min_date);
  const onSearch = (data) => {
    // if (
    //   selectedDateRange[0] !== '' &&
    //   selectedDateRange[0] !== null &&
    //   selectedDateRange[1] !== '' &&
    //   selectedDateRange[1] !== null
    // ) {
    getDashboard('', selectedDateRange[0], selectedDateRange[1]);
    getsportbookDashboard(1, selectedDateRange[0], selectedDateRange[1]);
    getVirtualsDashboard(2, selectedDateRange[0], selectedDateRange[1]);
    // }
  };
  const clearFilter = () => {
    setSelectedDateRange(['', '']);
    // if (  selectedDateRange[0] !== '' &&
    // selectedDateRange[0] !== null &&
    // selectedDateRange[1] !== '' &&
    // selectedDateRange[1] !== null) {
    setChangeText(['', '']);
    getDashboard();
    getsportbookDashboard('', '', '');
    getVirtualsDashboard('', '', '');
    // }
  };
const disabledBtn =
  selectedDateRange[1] === null ||
  selectedDateRange[1] === '' ||
  selectedDateRange[0] === null ||
  selectedDateRange[0] === '' || 
  loading

  return (
    <>
      <Helmet>
        <title> Dashboard | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>
      {/* {loading ? (
        <Container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '75vh',
          }}
          maxWidth="xl"
          className="dashboardPage"
        >
          <CircularProgress />
        </Container>
      ) : ( */}
      <Container maxWidth="xl" className="dashboardPage cardDesign">
        <Typography variant="h4" sx={{ mb: 3 }}>
          Hi, Welcome back
        </Typography>
        <form onSubmit={handleSubmit(onSearch)}>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent={'flex-end'}
            mb={2}
            className="clearBtnStck filterBorderClass"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DesktopDateRangePicker']}>
                <DemoItem component="DesktopDateRangePicker">
                  <DesktopDateRangePicker
                    localeText={{ start: 'Start Date', end: 'End Date' }}
                    className=""
                    size="small"
                    maxDate={minDate}
                    value={selectedDateRange}
                    onChange={handleDateChange}
                    calendars={1}
                    format="DD-MM-YYYY"
                    slotProps={{
                      textField: {
                        error: false,
                      },
                    }}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
            <Stack display="flex" flexDirection="row" className="mrgTop">
              <Box margin={1} className="pd0_mrg0Right mrgBtm_0">
                <Button
                  onClick={clearFilter}
                  sx={{ padding: '27px' }}
                  color="secondary"
                  variant="outlined"
                  size="large"
                  disableElevation
                  disabled={disabledBtn}
                >
                  Clear
                </Button>
              </Box>
              <Box margin={1} className="pd0_mrg0Right mrgBtm_0">
                <Button
                  type="submit"
                  color="secondary"
                  sx={{ padding: '27px' }}
                  variant="contained"
                  size="large"
                  disableElevation
                  disabled={disabledBtn}
                >
                  Search
                </Button>
              </Box>
            </Stack>
          </Stack>
          {!loading ? (
            <>
              <Grid
                container
                className="boxGrid nomarginLR"
                spacing={3}
                justifyContent="stretch"
                sx={{
                  '& .MuiGrid-root': {
                    margin: 1,
                    border: '3px solid ',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                  },
                  // '& .MuiTypography-root': { color: '#061B64' },
                }}
              >
                {/* <Grid container spacing={3}> */}
                <Grid item xs={12} sm={6} md={2} className="blue_900">
                  <Card>
                    <CardContent>
                      <Stack sx={{}}>
                        <Typography sx={{ fontSize: '15px' }}>Total Bets Counts</Typography>
                        <Typography variant="h5">{fNumber(dashboardCounts.total_bet_count)}</Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={2} className="coffy_300">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Bets Amounts</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_bet_amount)}{' '}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={2} className="coffy_500">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Bets Winning Counts</Typography>
                        <Typography variant="h5">{fNumber(dashboardCounts.total_bet_winning_count)}</Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="purple_500">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Bets Winning Amounts</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_bet_winning_amount)}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="purple_800">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Vat Amounts</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_vat_amount)}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="purple_900">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Income Tax Amounts</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_income_tax_amount)}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="green_100">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>
                          {changeText[0] !== '' &&
                          changeText[0] !== undefined &&
                          changeText[1] !== '' &&
                          changeText[1] !== undefined
                            ? 'Total Registered User'
                            : 'Total Users'}
                        </Typography>
                        <Typography variant="h5">{fNumber(dashboardCounts.total_user)}</Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="green_500">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>
                          {changeText[0] !== '' &&
                          changeText[0] !== undefined &&
                          changeText[1] !== '' &&
                          changeText[1] !== undefined
                            ? 'Total Registered Users Balance'
                            : 'Total Users Balance'}
                        </Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_user_balance)}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="green_900">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Deposits Counts</Typography>
                        <Typography variant="h5">{fNumber(dashboardCounts.total_deposit_count)}</Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="pink_300">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Deposits Amounts</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_deposit_amount)}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="pink_500">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Withdraws Counts</Typography>
                        <Typography variant="h5">{fNumber(dashboardCounts.total_withdraw_count)}</Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="lossRed">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Withdraws Amounts</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_withdraw_amount)}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="green_500">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Bonus</Typography>
                        <Typography variant="h5">
                          {fNumber(dashboardCounts.total_signup_bonus)}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={2} className="pink_300">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Net Bets Amounts</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_net_bet_amount)}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="purple_500">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Net Win Amounts</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_net_win_amount)}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="profitGreen">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Profit</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.profit)}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="theme_6">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>RTP</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_rtp)} <Typography variant="caption">%</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Box
                sx={{
                  backgroundColor: '#f3f3f3',
                  borderTop: '1px solid #f1f1f1',
                  borderRadius: '10px 10px 0 0',
                  // marginBottom: 2,
                  marginTop: 4,
                }}
              >
                <IconButton
                  disableRipple
                  sx={{ margin: '10px' }}
                  size="small"
                  onClick={() => {
                    setSportBookCollapsed(!SportBookCollapsed);
                  }}
                >
                  Sports Books {SportBookCollapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Box>

              {sportBookLoader ? (
                <Collapse in={SportBookCollapsed} sx={{ backgroundColor: '#f3f3f3' }} className="collaps_row">
                  <Box
                    sx={{
                      display: 'flex',
                      height: '300px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#f3f3f3',
                    }}
                  >
                    <CircularProgress size={35} />
                  </Box>
                </Collapse>
              ) : (
                <Collapse
                  in={SportBookCollapsed}
                  sx={{ backgroundColor: '#f3f3f3', padding: 3, paddingTop: 1 }}
                  className="collaps_row"
                >
                  <Grid
                    container
                    className="boxGrid nomarginLR"
                    spacing={3}
                    justifyContent="stretch"
                    sx={{
                      '& .MuiGrid-root': {
                        margin: 1,
                        border: '3px solid ',
                        borderRadius: 2,
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#fff',
                      },
                      // '& .MuiTypography-root': { color: '#061B64' },
                    }}
                  >
                    <Grid item xs={12} sm={6} md={2} className="blue_900">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>Total Bets Counts</Typography>
                            <Typography variant="h5">{fNumber(sportBookDashboardCounts.total_bet_count)}</Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} className="coffy_300">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>Total Bets Amounts</Typography>
                            <Typography variant="h5">
                              {formatNumber(sportBookDashboardCounts.total_bet_amount)}{' '}
                              <Typography variant="caption"> {showCurrency()}</Typography>
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} className="coffy_500">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>Total Bets Winning Counts</Typography>
                            <Typography variant="h5">
                              {fNumber(sportBookDashboardCounts.total_bet_winning_count)}
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} className="purple_500">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>Total Bets Winning Amounts</Typography>
                            <Typography variant="h5">
                              {formatNumber(sportBookDashboardCounts.total_bet_winning_amount)}
                              <Typography variant="caption"> {showCurrency()}</Typography>
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} className="purple_800">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>Total Vat Amounts</Typography>
                            <Typography variant="h5">
                              {formatNumber(sportBookDashboardCounts.total_vat_amount)}
                              <Typography variant="caption"> {showCurrency()}</Typography>
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} className="purple_900">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>Total Income Tax Amounts</Typography>
                            <Typography variant="h5">
                              {formatNumber(sportBookDashboardCounts.total_income_tax_amount)}
                              <Typography variant="caption"> {showCurrency()}</Typography>
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} className="green_100">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>Total Net Bets Amounts</Typography>
                            <Typography variant="h5">
                              {formatNumber(sportBookDashboardCounts.total_net_bet_amount)}
                              <Typography variant="caption"> {showCurrency()}</Typography>
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} className="green_500">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>Total Net Win Amounts</Typography>
                            <Typography variant="h5">
                              {formatNumber(sportBookDashboardCounts.total_net_win_amount)}
                              <Typography variant="caption"> {showCurrency()}</Typography>
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} className="green_900">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>Profit</Typography>
                            <Typography variant="h5">
                              {formatNumber(sportBookDashboardCounts.profit)}
                              <Typography variant="caption"> {showCurrency()}</Typography>
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} className="pink_300">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>RTP</Typography>
                            <Typography variant="h5">
                              {formatNumber(sportBookDashboardCounts.total_rtp)}{' '}
                              <Typography variant="caption">%</Typography>
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Collapse>
              )}

              <Box
                sx={{
                  backgroundColor: '#f3f3f3',
                  borderTop: '1px solid #f1f1f1',
                  borderRadius: '10px 10px 0 0',
                  // marginBottom: 2,
                  //  marginTop: 4,
                }}
              >
                <IconButton
                  disableRipple
                  sx={{ margin: '10px' }}
                  size="small"
                  onClick={() => {
                    setVirtualCollapsed(!VirtualCollapsed);
                  }}
                >
                  Virtuals Games {VirtualCollapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Box>
              {virtualLoading ? (
                <Collapse
                  in={VirtualCollapsed}
                  sx={{ backgroundColor: '#f3f3f3', padding: 3, paddingTop: 1 }}
                  className="collaps_row"
                >
                  <Box sx={{ display: 'flex', height: '100px', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress size={35} />
                  </Box>
                </Collapse>
              ) : (
                <Collapse
                  in={VirtualCollapsed}
                  sx={{ backgroundColor: '#f3f3f3', padding: 3, paddingTop: 1 }}
                  className="collaps_row"
                >
                  <Grid
                    container
                    className="boxGrid nomarginLR"
                    spacing={3}
                    justifyContent="stretch"
                    sx={{
                      '& .MuiGrid-root': {
                        margin: 1,
                        border: '3px solid ',
                        borderRadius: 2,
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#fff',
                      },
                      // '& .MuiTypography-root': { color: '#061B64' },
                    }}
                  >
                    <Grid item xs={12} sm={6} md={2} className="blue_900">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>Total Bets Counts</Typography>
                            <Typography variant="h5">{fNumber(virtualsDashboardCounts.total_bet_count)}</Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} className="coffy_300">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>Total Bets Amounts</Typography>
                            <Typography variant="h5">
                              {formatNumber(virtualsDashboardCounts.total_bet_amount)}{' '}
                              <Typography variant="caption">{showCurrency()}</Typography>
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} className="coffy_500">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>Total Bets Winning Counts</Typography>
                            <Typography variant="h5">
                              {fNumber(virtualsDashboardCounts.total_bet_winning_count)}
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} className="purple_500">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>Total Bets Winning Amounts</Typography>
                            <Typography variant="h5">
                              {formatNumber(virtualsDashboardCounts.total_bet_winning_amount)}
                              <Typography variant="caption"> {showCurrency()}</Typography>
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} className="purple_800">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>Profit</Typography>
                            <Typography variant="h5">
                              {formatNumber(virtualsDashboardCounts.profit)}
                              <Typography variant="caption"> {showCurrency()}</Typography>
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} className="purple_900">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>RTP</Typography>
                            <Typography variant="h5">
                              {formatNumber(virtualsDashboardCounts.total_rtp)}{' '}
                              <Typography variant="caption">%</Typography>
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Collapse>
              )}
            </>
          ) : (
            <Container
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '75vh',
              }}
              maxWidth="xl"
              className="dashboardPage"
            >
              <CircularProgress />
            </Container>
          )}

          {/* {betStats ? (
            <>
              <Grid item xs={12} md={6} lg={8}>
                <AppWebsiteVisits
                  title="Bet Statics"
                  subheader=""
                  chartLabels={betStats?.labels}
                  chartData={[
                    {
                      name: betStats?.series[0]?.type,
                      type: 'area',
                      fill: 'gradient',
                      data: betStats?.series[0]?.data,
                    },

                    {
                      name: betStats?.series[1]?.type,
                      type: 'area',
                      fill: 'gradient',
                      data: betStats?.series[1]?.data,
                    },
                    {
                      name: betStats?.series[2]?.type,
                      type: 'area',
                      fill: 'gradient',
                      data: betStats?.series[2]?.data,
                    },
                    {
                      name: betStats?.series[3]?.type,
                      type: 'area',
                      fill: 'gradient',
                      data: betStats?.series[3]?.data,
                    },
                  ]}
                  bet_type={bet_type}
                  handleBetTypeChange={handleDateChange}
                  selectedDateRange={selectedDateRange}
                />
              </Grid>
              {loading ? (
                <Grid item xs={12} md={6} lg={4}>
                  <Card>
                    <Box sx={{ display: 'flex', height: '400px', justifyContent: 'center', alignItems: 'center' }}>
                      <CircularProgress size={45} />
                    </Box>
                  </Card>
                </Grid>
              ) : (
                <Grid item xs={12} md={6} lg={4}>
                  <AppCurrentVisits
                    title="Revenue Breakdown"
                    chartData={[
                      { label: 'Profit', value: dashboardCounts?.total_profit },
                      { label: 'Payout', value: dashboardCounts?.total_payout },
                    ]}
                    chartColors={[theme.palette.success.main, theme.palette.error.main]}
                  />
                </Grid>
              )}
            </>
          ) : (
            <>
              <Grid item xs={12} md={6} lg={8}>
                <Card>
                  <Box sx={{ display: 'flex', height: '400px', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress size={45} />
                  </Box>
                </Card>
              </Grid>
            </>
          )} */}
          {/* {topShops ? (
            <Grid item xs={12} md={6} lg={8}>
              <AppConversionRates
                title="Top 10 Shops"
                // subheader="(+43%) than last year"
                chartSeries={[
                  {
                    name: topShops?.series[0].type,
                    data: topShops?.series[0].data,
                  },
                  {
                    name: topShops?.series[1].type,
                    data: topShops?.series[1].data,
                  },
                  {
                    name: topShops?.series[2].type,
                    data: topShops?.series[2].data,
                  },
                  {
                    name: topShops?.series[3].type,
                    data: topShops?.series[3].data,
                  },
                ]}
                chartLabels={topShops?.labels}
                sub_type={sub_type}
                handleSubTypeChange={handleSubTypeChange}
                type={type}
                // handleTypeChange={handleTypeChange}
                handleShopDateChange={handleShopDateChange}
                selectedDateRange={selectedShopDate}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} md={6} lg={8}>
                <Card>
                  <Box sx={{ display: 'flex', height: '400px', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress size={45} />
                  </Box>
                </Card>
              </Grid>
            </>
          )} */}
          {/* </Grid> */}
        </form>
      </Container>
    </>
  );
}
