import { createContext, useEffect, useState } from 'react';
import { fetchData, fetchDataWithAuth } from '../utils/api';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [systemSetting, setSystemSetiings] = useState([]);

  const updateuserBalance = async (userData) => {
    if (userData) {
      if (userData.type !== 'admin') {
        const data = {
          user_token: userData.token,
          data: {},
        };
        const response = await fetchDataWithAuth(`/${userData.type}/login/check`, data);
        const currentData = response.data[0];
        currentData.token = userData.token;
        setUser(currentData);
        localStorage.setItem('userData', JSON.stringify(currentData));
      } else {
        setUser(userData);
      }
    }
  };

  useEffect(() => {
    // Check if the user is stored in localStorage or sessionStorage
    const userData = JSON.parse(localStorage.getItem('userData'));
    updateuserBalance(userData);
  }, []);

  const login = async (userData) => {
    setUser(userData);
  };

  const logout = () => {
    localStorage.removeItem('userData');
    setUser(null);
  };

  const updateBalance = async (newBalance) => {
    setUser((prevUser) => ({
      ...prevUser,
      balance: newBalance,
    }));
    localStorage.setItem('userData', JSON.stringify(user));
  };

  const checkuser = (userData) => {
    if (!user) setUser(userData);
  };

  const fetchSettings = async () => {
    const Settings = await fetchData('/auth/system/setting/list');
    setSystemSetiings(Settings.data[0]);
  };

  const fetchBalance = async () => {
    if(user) {
      const data = {
        user_token: user.token,
        data: {},
      };
      const updatedBalance = await fetchDataWithAuth(`/${user.type}/fetch/balance`, data);
      setUser((prevUser) => ({
        ...prevUser,
        balance: updatedBalance.data[0].balance,
        credit: updatedBalance.data[0]?.credit,
      }));
      localStorage.setItem('userData', JSON.stringify(user));
    }
  }

  useEffect(() => {
    fetchSettings();
  }, [setSystemSetiings]);

  return (
    <UserContext.Provider value={{ user, setUser, login, logout, checkuser, updateBalance, fetchBalance, systemSetting }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };

