import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { UserContext } from '../../contexts/UserContext';
import { fetchDataWithAuth, postData } from '../../utils/api';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { generateHash, getTimeStamp, ToastError, ToastSuccess } from '../../utils/helper';

const schema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .matches(/^[^\s].*/, 'starting spaced is not allowed'),
  status: yup.string().required('Status is required'),
  role_description: yup
    .string()
    .required('Role Description is required')
    .matches(/^[^\s].*/, 'starting spaced is not allowed'),
});

const RoleForm = ({ action }) => {
  const Redirect = useNavigate();
  const params = useParams();
  const { user } = useContext(UserContext);
  const [selectValue, setSelectValue] = useState(null);
  const [loading,setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const getRole = async () => {
      setLoading(true)
      if (params.id) {
        const data = {
          user_token: user.token,
          data: {},
        };
        const response = await fetchDataWithAuth(`/admin/role/get/${params.id}`, data);
        const roleData = response.data[0];
        setValue('name', roleData.name);
        setValue('status', roleData.status);
        setSelectValue(roleData.status);
        setValue('role_description', roleData.role_description);
      }
      setLoading(false)
    };
    getRole();
  }, [params, setValue]);

  const onSubmit = async (roleData) => {
    // Perform form submission logic here
    setLoading(true)
    toast.dismiss();
    let data = null;
    let RoleData = null;
    try {
      if (params.id) {
        data = {
          user_token: user.token,
          data: {
            id: params.id,
            name: roleData.name,
            status: selectValue,
            date_time: getTimeStamp(),
            role_description: roleData.role_description,
          },
        };
        const hashSignature = generateHash(data.data);
        data.data.hash = hashSignature;
        RoleData = await postData('/admin/role/update', data);
      } else {
        data = {
          user_token: user.token,
          data: {
            name: roleData.name,
            status: roleData.status,
            date_time: getTimeStamp(),
            role_description: roleData.role_description,
          },
        };
        const hashSignature = generateHash(data.data);
        data.data.hash = hashSignature;
        RoleData = await postData('/admin/role/add', data);
      }
      setLoading(false)
      ToastSuccess(RoleData.message);
      setTimeout(() => {
        toast.dismiss();
        Redirect('/admin/role');
      }, 800);
    } catch (error) {
      console.log('error2 :>> ', error);
      ToastError(error.response.data.message);
      setLoading(false)
    }
  };

  return (
    <>
      <Helmet>
        <title> Role {action} | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>

      <Container maxWidth="md">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Role {action}
          </Typography>
        </Stack>
        <Card>
          <CardContent>
            {loading ?  
         <Stack display="flex" flexDirection="row" justifyContent="center">
         <CircularProgress size={35} />
       </Stack> : <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="row">
                <Box padding={2} width="50%">
                  <TextField
                    name="name"
                    label="Name*"
                    {...register('name')}
                    // error={!!errors.name}
                    // helperText={errors.name?.message}
                    fullWidth
                    placeholder="Name"
                    InputLabelProps={{
                      shrink: true,
                      classes: { root: 'input-label' },
                    }}
                    InputProps={{
                      classes: { root: 'input-field' },
                    }}
                    // sx={{ textAlign: 'center' }}
                  />
                  <Typography variant="body2" color="error">
                    {errors.name?.message}
                  </Typography>
                </Box>

                <Box padding={2} width="50%" className="slectInput">
                  <FormControl fullWidth sx={{ textAlign: 'left' }}>
                    <InputLabel id="status-label">{!params.id ? 'Status' : ''}</InputLabel>
                    <Select
                      labelId="status-label"
                      id="status"
                      name="status"
                      value={selectValue}
                      {...register('status')}
                      // error={!!errors.status}
                      onChange={(event) => setSelectValue(event.target.value)}
                    >
                      <MenuItem value={1}>Active</MenuItem>
                      <MenuItem value={0}>Inactive</MenuItem>
                    </Select>
                    <Typography variant="body2" color="error">
                      {errors.status?.message}
                    </Typography>
                  </FormControl>
                </Box>
              </Stack>
              <Stack direction="row">
                <Box padding={2} width="50%">
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                      classes: { root: 'input-label' },
                    }}
                    InputProps={{ classes: { root: 'input-field' } }}
                    fullWidth
                    name="role_description"
                    label="Role Description*"
                    multiline
                    rows={4}
                    placeholder="Role Description"
                    {...register('role_description')}
                    // error={!!errors.role_description}
                    // helperText={errors.role_description?.message}
                    sx={{ textAlign: 'center' }}
                  />
                  <Typography variant="body2" color="error">
                    {errors.role_description?.message}
                  </Typography>
                </Box>
              </Stack>
              <Box display="flex" padding={2} width="50%">
                <Button type="submit" variant="contained" size="large">
                  {action} Role
                </Button>
              </Box>
            </form>}
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default RoleForm;
