import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { LoadingButton } from '@mui/lab';
import { Container, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
// components
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Iconify from '../../../components/iconify';
import { UserContext } from '../../../contexts/UserContext';
import { aviatorLoginUser, loginUser } from '../../../utils/api';

// ----------------------------------------------------------------------

export default function LoginForm(porps) {
  const { user, login } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    username: '',
    password: '',
  });

  const onSubmit = async (data) => {
    toast.dismiss();
    setLoading(true);
    const userData = await loginUser('/admin/login', data);
    if (userData !== null) {
      localStorage.setItem('userData', JSON.stringify(userData));
      await login(JSON.parse(localStorage.getItem('userData')));
      setTimeout(() => {
        if (userData.type === 'supervisor') {
          navigate(`/${userData.type}/shopBalanceTransfer`);
        } else {
          navigate(`/${userData.type}/dashboard`);
        }
      }, 700);

      /** Aviator Login
       * aviator -> Aviator Admin
       * aviator-operator - > Aviator Operator
       */
      if(userData.type === 'aviator' || userData.type === 'aviator-operator'){
        const aviatorSUserData = await aviatorLoginUser('/auth/admin/login', data);
        if(aviatorSUserData){
          localStorage.setItem('aviator_token', aviatorSUserData.token);
        }
      }
      
    }
    setLoading(false);
  };

  return (
    <>
      <Container maxWidth="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <TextField
              label="Username"
              {...register('username', {
                required: true,
              })}
              autoFocus
              type="text"
              name="username"
              placeholder="Username"
            />
            {errors.username?.type === 'required' && (
              <p style={{ color: 'red', fontSize: '15px' }}>user Name is required</p>
            )}
            <TextField
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              {...register('password', {
                required: true,
              })}
              placeholder="Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.password?.type === 'required' && (
              <p style={{ color: 'red', fontSize: '15px' }}>Password is required</p>
            )}
          </Stack>

          {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <FormControlLabel label="Remember me" control={<Checkbox name="remember" label="Remember me" />} />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}
          {loading === true ? (
            <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 3 }} disabled>
              Loading <Iconify icon={'eos-icons:three-dots-loading'} width={30} />
            </LoadingButton>
          ) : (
            <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ mt: 3 }}>
              Login
            </LoadingButton>
          )}
        </form>
      </Container>
    </>
  );
}
