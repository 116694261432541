import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Card, CardContent, Container, Grid, Stack, Typography } from '@mui/material';

// components
// sections
import { aviatorApiCall } from '../utils/api';
import { fNumber } from '../utils/formatNumber';
import { MY_GLOBAL_CONST } from '../utils/GlobalConstant';
import { formatNumber, showCurrency } from '../utils/helper';
// ----------------------------------------------------------------------

export default function AviatorAdminDashboardAppPage({ userType }) {
  const [dashboardData, setDashboardData] = useState([]);

  const getDashboardData = async () => {
    try {
      const dashboardData = await aviatorApiCall('/user/dashboard', {
        startDate: '',
        endDate: '',
      });

      if (dashboardData?.data) {
        const {
          totalOperatorCount,
          totalPlayerCount,
          totalBetCount,
          totalBetAmount,
          totalWinBetCount,
          totalWinBetAmount,
          totalRTP,
          totalNetBetAmount,
          totalProfit,
          totalLostBetCount,
          totalLostBetAmount,
          totalCancelBetCount,
          totalCancelBetAmount,
          ggrPercentage,
          totalGGR,
          totalProfitAfterGGR,
        } = dashboardData.data;

        setDashboardData([
          {
            id: 0,
            key: 'Total Operator',
            value: totalOperatorCount,
            className: 'appropriate color',
            isAmount: false,
            isColumnSkip: userType !== 'aviator',
          },
          {
            id: 1,
            key: 'Total Player',
            value: totalPlayerCount,
            className: 'appropriate color',
            isAmount: false,
          },
          {
            id: 2,
            key: 'Total Bet',
            value: totalBetCount,
            className: 'appropriate color',
            isAmount: false,
          },
          {
            id: 3,
            key: 'Total Bet Amount',
            value: totalBetAmount,
            className: 'appropriate color',
            isAmount: true,
          },
          {
            id: 4,
            key: 'Total Win Bet',
            value: totalWinBetCount,
            className: 'appropriate color',
            isAmount: false,
          },
          {
            id: 5,
            key: 'Total Win Bet Amount',
            value: totalWinBetAmount,
            className: 'appropriate color',
            isAmount: true,
          },
          {
            id: 6,
            key: 'Total Lost Bet',
            value: totalLostBetCount,
            className: 'appropriate color',
            isAmount: false,
          },
          {
            id: 7,
            key: 'Total Lost Bet Amount',
            value: totalLostBetAmount,
            className: 'appropriate color',
            isAmount: true,
          },
          {
            id: 8,
            key: 'Total Cancel Bet',
            value: totalCancelBetCount,
            className: 'appropriate color',
            isAmount: false,
          },
          {
            id: 9,
            key: 'Total Cancel Bet Amount',
            value: totalCancelBetAmount,
            className: 'appropriate color',
            isAmount: true,
          },
          {
            id: 10,
            key: 'Total Net Bet Amount',
            value: totalNetBetAmount,
            className: 'appropriate color',
            isAmount: true,
          },
          {
            id: 11,
            key: 'Total Profit',
            value: totalProfit,
            className: 'appropriate color',
            isAmount: true,
          },
          {
            id: 12,
            key: 'Total RTP',
            value: totalRTP,
            className: 'appropriate color',
            isAmount: false,
            isPercentage: true,
          },
          {
            id: 13,
            key: 'GGR Percentage',
            value: ggrPercentage,
            className: 'appropriate color',
            isAmount: false,
            isPercentage: true,
            isColumnSkip: userType === 'aviator',
          },
          {
            id: 14,
            key: 'Total GGR',
            value: totalGGR,
            className: 'appropriate color',
            isAmount: true,
            isColumnSkip: userType === 'aviator',
          },
          {
            id: 15,
            key: 'Total Profit After GGR',
            value: totalProfitAfterGGR,
            className: 'appropriate color',
            isAmount: true,
            isColumnSkip: userType === 'aviator',
          },
        ]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>
      <Container maxWidth="xl" className="dashboardPage cardDesign">
        <Typography variant="h4" sx={{ mb: 3 }}>
          Hi, Welcome back Aviator
        </Typography>

        <Grid
          container
          className="boxGrid nomarginLR"
          spacing={3}
          justifyContent="stretch"
          sx={{
            '& .MuiGrid-root': {
              margin: 1,
              border: '3px solid ',
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#fff',
            },
          }}
        >
          {dashboardData.map((data) => (
            <>
              {!data.isColumnSkip && (
                <Grid key={data.id} item xs={12} sm={6} md={2} className={`blue_900 ${data.className}`}>
                  <Card>
                    <CardContent>
                      <Stack sx={{}}>
                        <Typography sx={{ fontSize: '15px' }}>{data.key}</Typography>
                        <Typography variant="h5">
                          {data.isPercentage ? formatNumber(data.value) : fNumber(data.value)}
                          {'  '}
                          {(data.isAmount && <Typography variant="caption">{showCurrency()}</Typography>) ||
                            (data.isPercentage && <Typography variant="caption">%</Typography>)}
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </>
          ))}
        </Grid>
      </Container>
    </>
  );
}
