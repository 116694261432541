import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
// components
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Iconify from '../../components/iconify';
import Label from '../../components/label';
import Scrollbar from '../../components/scrollbar';
import { UserListToolbar } from '../../sections/@dashboard/user';
import TableHeadSection from '../../sections/TableHeadSection';
// sections
// mock
import { UserContext } from '../../contexts/UserContext';
import { deleteDataWithAuth, fetchDataWithAuth, postData } from '../../utils/api';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { formatDate, getPermissionActions, ToastError, ToastSuccess } from '../../utils/helper';
import AgentAssign from './AgentAssign';
import OperatorAssign from './OperatorAssign';
import ShopAssign from './ShopAssign';
import ShopDialog from './ShopDialog';
/* eslint-disable react/no-unknown-property */
// ----------------------------------------------------------------------

const ShopPage = ({ model }) => {
  const [open, setOpen] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);

  const [assignOpen, setAssignOpen] = useState(false);

  const [opAssignOpen, setOpAssignOpen] = useState(false);
  const [agAssignOpen, setagAssignOpen] = useState(false);

  const [currentId, setCurrentId] = useState(null);

  const [operator_flag, setOperatorFlag] = useState(false);

  const [page, setPage] = useState(0);

  const [filterName, setFilterName] = useState(null);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [shops, setShops] = useState([]);

  const [extra_data, setExtraData] = useState([]);

  const { user } = useContext(UserContext);

  const [roles, setRoles] = useState([]);

  const [admin_users, setUsers] = useState([]);

  const [adminUsers, setAdminUsers] = useState([]);

  const [operators, setOperators] = useState([]);
  const [agents, setAgents] = useState([]);

  const actions = getPermissionActions(user.role_responsibility, 'Shops');
  const [sortData, setSortData] = useState('DESC');
  const [sortBy, setSortBY] = useState('');
  const [loading, SetLoading] = useState(true);

  const handleAssignOpen = () => {
    setAssignOpen(true);
    getAdminUsersList(currentId);
    getAdminUsers();
    getRoles();
    setOpen(null);
  };
  
  
  const handleAssignClose = () => {
    setAssignOpen(false);
    fetchShops(sortBy, '', page, rowsPerPage, filterName);
  };
  
  const handleOpAssignOpen = () => {
    getOperators();
    setOpAssignOpen(true);
    setOpen(null);
  };

  const handleOpAssignClose = () => {
    setOpAssignOpen(false);
    fetchShops(sortBy, '', page, rowsPerPage, filterName);
  };
  const handleAgAssignOpen = () => {
    getAgents();
    setagAssignOpen(true);
    setOpen(null);
  };

  const handleAgAssignClose = () => {
    setagAssignOpen(false);
    fetchShops(sortBy, '', page, rowsPerPage, filterName);
  };

  const handleDeleteClickOpen = () => {
    setDialogOpen(true);
    setOpen(null);
  };

  const handleOpenMenu = (id, is_operator_assigned, event) => {
    setOpen(event.currentTarget);
    setCurrentId(id);
    setOperatorFlag(is_operator_assigned);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setCurrentId(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchShops(sortBy, sortBy ? sortData : '', newPage, rowsPerPage, filterName);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    fetchShops(sortBy, sortBy ? sortData : '', 0, event.target.value, filterName);
  };
  useEffect(() => {
    fetchShops(sortBy, '', page, rowsPerPage, filterName);
  }, []);

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
    fetchShops(sortBy, sortBy ? sortData : '', 0, rowsPerPage, event.target.value);
  };

  const isNotFound = !shops.length && !!filterName;
  const isNotFoundData = !shops.length;

  const fetchShops = async (sortBy, sortData, page, rowsPerPage, filterName) => {
    try {
      const data = {
        user_token: user.token,
        data: {
          name: filterName === null || filterName === undefined ? '' : filterName,
          contact_number: '',
          order_key: sortBy !== '' ? sortBy : '',
          order_value: sortData,
        },
      };
      SetLoading(true);
      const response = await postData(`/${user.type}/${model}/list?page=${page + 1}&size=${rowsPerPage}`, data);
      setShops(response.data);
      setExtraData(response.extra_data);
      SetLoading(false);
    } catch (error) {
      console.log('error :>> ', error);
      ToastError(error.response.data.message);
    }
  };

  const getAdminUsers = async () => {
    const data = {
      user_token: user.token,
      data: {
        shop_id: currentId
      },
    };
    const response = await postData(`/${user.type}/shop/assign/adminuser/list`, data);
    setUsers(response.data);
  };

  const getRoles = async () => {
    const data = {
      user_token: user.token,
      data: {
        status: '',
        name: '',
      },
    };
    const response = await postData(`/${user.type}/role/list`, data);
    setRoles(response.data.filter((item) => item.name !== 'Operator'));
  };

  const getOperators = async () => {
    const data = {
      user_token: user.token,
      data: {},
    };
    const response = await fetchDataWithAuth(`/${user.type}/operator/list`, data);
    setOperators(response.data);
  };
  const getAgents = async () => {
    const data = {
      user_token: user.token,
      data: {},
    };
    const response = await fetchDataWithAuth(`/${user.type}/agent/list`, data);
    console.log('response :>> ', response);
    setAgents(response.data);
  };

  const getAdminUsersList = async (shopId) => {
    const data = {
      user_token: user.token,
      data: {},
    };
    const response = await fetchDataWithAuth(`/${user.type}/shop/assign/get/${shopId}`, data);
    setAdminUsers(response.data[0].admin_users_shops);
  };

  const handleDeleteShop = async (confirm) => {
    toast.dismiss();
    if (confirm) {
      try {
        const data = {
          user_token: user.token,
          data: {},
        };
        const ShopData = await deleteDataWithAuth(`/${user.type}/${model}/delete/${currentId}`, data);
        if (ShopData.status_code === 200) {
          ToastSuccess(ShopData.message);
        } else {
          toast.warning(ShopData.response.data.message, {
            position: 'top-center',
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            theme: 'colored',
          });
        }
        setTimeout(() => {
          toast.dismiss();
          setOpen(null);
          setCurrentId(null);
          setPage(0);
          fetchShops(sortBy, sortBy ? sortData : '', page, rowsPerPage, filterName);
        }, 800);
      } catch (error) {
        console.log('error2 :>> ', error);
        ToastError(error.response.data.message);
      }
    } else {
      setDialogOpen(false);
    }
  };

  const TABLE_HEAD = [
    { id: 'id', label: 'ID', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'country_code', label: 'Contact Number', alignRight: false },
    { id: 'address', label: 'Address', alignRight: false },
    { id: '', label: 'Assigned Resources', alignRight: false },
    { id: 'user.username', label: 'Added By', alignRight: false },
    { id: 'created_at', label: 'Created At', alignRight: false },
    { id: '' },
  ];

  const handleSort = (sortBy) => {
    if (!isNotFoundData && !loading) {
      setSortBY(sortBy);
      setSortData(sortData === 'ASC' ? 'DESC' : 'ASC');
      fetchShops(sortBy, sortData === 'ASC' ? 'DESC' : 'ASC', page, rowsPerPage);
    }
  };

  return (
    <>
      <ShopAssign
        open={assignOpen}
        handleClose={handleAssignClose}
        shopId={currentId}
        roles={roles}
        admin_users={admin_users}
        adminUsers={adminUsers}
        setAdminUsers={setAdminUsers}
      />
      <OperatorAssign
        open={opAssignOpen}
        handleClose={handleOpAssignClose}
        shopId={currentId}
        operators={operators}
      />  
      <AgentAssign
        open={agAssignOpen}
        handleClose={handleAgAssignClose}
        shopId={currentId}
        agents={agents}
      />  
      <ShopDialog setDialog={setDialogOpen} open={dialogOpen} handleDeleteShop={handleDeleteShop} />
      <Helmet>
        <title> Shop | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon icon="mdi:shop" style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }} />
            <Typography variant="h4" gutterBottom>
              Shops
            </Typography>
          </Box>
          {user.type === 'admin' || actions.find((action) => action.name === 'add') ? (
            <Button
              to={`/${user.type}/${model}/create`}
              variant="contained"
              component={Link}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Shop
            </Button>
          ) : (
            <></>
          )}
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={0}
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeHolder={'Search Shops...'}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, maxHeight: 540 }}>
              <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table" margin={1}>
                <TableHead>
                  <TableRow>
                    <TableHeadSection
                      handleSort={handleSort}
                      sortData={sortData}
                      setSortData={setSortData}
                      tableHead={user.type === 'supervisor' ? TABLE_HEAD.filter((th) => th.label !== 'Assigned Resources') : TABLE_HEAD}
                    />
                  </TableRow>
                </TableHead>

                {!isNotFound && !loading && (
                  <TableBody>
                    {shops.map((row) => (
                      <TableRow hover key={row.id} tabIndex={-1}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {row.name}
                          </Stack>
                        </TableCell>

                        <TableCell align="left">
                          +{row.country_code}
                          &nbsp;{row.contact_number}
                        </TableCell>
                        <TableCell align="left">{row.address}</TableCell>
                        {user.type === 'admin' ?
                        <TableCell align="left">
                          {row.is_cashier_assigned === 'Yes' ? <Tooltip title={row.cashiers_username.length > 1 ? "Cashiers 1+": `${row.cashiers_username[0]}`}><Label color={'warning'}>C</Label></Tooltip> : ''}{' '}
                          {row.is_supervisor_assigned === 'Yes' ? <Tooltip title={`${row.supervisor_username} (Supervisor)`}><Label color={'info'}>S</Label></Tooltip> : ''}{' '}
                          {row.is_operator_assigned === 'Yes' ? <Tooltip title={`${row.operator_username} (Operator)`}><Label color={'success'}>O</Label></Tooltip> : ''}
                          {row.is_agent_assigned === 'Yes' ? <Tooltip title={`${row.agent_username} (Agent)`}><Label color={'error'}>A</Label></Tooltip> : ''}
                        </TableCell> : <></>
                        }
                        <TableCell align="left">
                          <Label color={'success'}>{row.user?.username}</Label>
                        </TableCell>

                        <TableCell align="left">{formatDate(row.created_at, 'ticket datetime')}</TableCell>

                        {row.id === 1 ? (
                          <></>
                        ) : (
                          <TableCell align="right">
                            {user.type === 'admin' || actions.find((action) => action.name === 'edit') ? (
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={(e) => {
                                  handleOpenMenu(row.id, row.is_operator_assigned === 'Yes' || row.is_agent_assigned === 'Yes', e);
                                }}
                              >
                                <Iconify icon={'eva:more-vertical-fill'} />
                              </IconButton>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                )}

                {isNotFound && !loading && (
                  <TableRow>
                    <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph textTransform={'capitalize'}>
                          data not found !
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typing or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                )}

                {loading && !isNotFound && (
                  <TableRow>
                    <TableCell align="center" colSpan={12}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                {!isNotFound && !loading && (
                  <TableFooter sx={{}}>
                    <TableRow>
                      <TableCell colSpan={7} align="left" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                        Total Entries : {extra_data?.current_page_items ? extra_data?.current_page_items : 0}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          {!isNotFound && !loading && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={extra_data?.total_items ? extra_data?.total_items : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem component={Link} to={`/admin/${model}/edit/${currentId}`}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'success.main' }} onClick={handleAssignOpen}>
          <Iconify icon={'mdi:clipboard-account-outline'} sx={{ mr: 2 }} />
          Assign
        </MenuItem>

        {operator_flag === false ? 
        <>
          <MenuItem sx={{ color: 'success.main' }} onClick={handleOpAssignOpen}>
            <Iconify icon={'mdi:account-box-edit-outline'} sx={{ mr: 2 }} />
            Assign Operator
          </MenuItem> 
          <MenuItem sx={{ color: 'success.main' }} onClick={handleAgAssignOpen}>
            <Iconify icon={'mdi:account-box-plus-outline'} sx={{ mr: 2 }} />
            Assign Agent
          </MenuItem> 
        </>
        : ''
        }

        <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteClickOpen}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
};

export default ShopPage;
