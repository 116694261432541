import { Icon } from '@iconify/react';
import { Box } from '@mui/material';
import { getIconName } from '../../utils/helper';

const DragAndDropArray = ({selectedOptions,onSelect,items,setItems,MenuArray}) => {
  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('index', index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, newIndex) => {
    const index = e.dataTransfer.getData('index');
    const draggedItem = items[index];
    const newItems = [...items];
    newItems.splice(index, 1);
    newItems.splice(newIndex, 0, draggedItem);
    setItems(newItems);
  };
 

    const ActiveMenuClass = (item, index) => {
      const selectMenu = MenuArray.filter((menuItem) => menuItem.is_visible).map((menuItem) => menuItem.name);
          return !selectedOptions.length ? selectMenu.includes(item) : selectedOptions.includes(item)
        }
  return (
    <div>
      {items?.map((item, index) => (
        <Box
        sx={{display:'flex',justifyContent:'center'}}
          key={index}
          draggable
          onDragStart={(e) => handleDragStart(e, index)}
          onDragOver={(e) => handleDragOver(e)}
          className={ActiveMenuClass(item) ? 'chekboxActive'  : ''}
          onDrop={(e) => handleDrop(e, index)}
          onClick={() => onSelect(item)}
          style={{ margin: '10px', padding: '10px', border: '1px solid #ccc',borderRadius:'10px' }}
        >
         <Icon icon={getIconName(item)} fontSize="24" style={{marginRight:'5px'}}/>
         <span style={{fontWeight:'600',fontSize:'18px',textTransform:'capitalize'}}>{item}</span> 
        </Box>
      ))}
    </div>
  );
};

export default DragAndDropArray;
