import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import { IconButton } from '@mui/material';
import TableCell from '@mui/material/TableCell';

const TableHeadSection = (props) => (
  <>
    {props.tableHead.map((headCell) => (
      <TableCell
        onClick={() => {
          props.handleSort(headCell.id);
        }}
        sx={{ cursor: 'pointer'}}
        key={headCell.id}
        align={headCell.alignRight ? 'right' : 'left'}
      >
        {headCell.label}

        {headCell.id === '' ? (
          ''
        ) : (
          <IconButton aria-label="toggle password visibility">
            {props.sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
          </IconButton>
        )}
      </TableCell>
    ))}
  </>
);

export default TableHeadSection;
