import { Icon } from '@iconify/react';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import IconButton from '@mui/material/IconButton';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { aviatorGetData, aviatorPostData } from '../../utils/api';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { formatDate, formatNumber, getNestedValue, showCurrency } from '../../utils/helper';
import { fNumber } from '../../utils/formatNumber';
import config from '../../config';
import Iconify from '../../components/iconify';

const PlayerBet = ({ userType }) => {
  const columns = [
    {
      id: 0,
      name: 'Operator',
      align: 'center',
      columnName: 'operator.username',
      isOrder: false,
      isRowSkip: userType !== 'aviator',
    },
    {
      id: 1,
      name: 'Player ID',
      align: 'center',
      columnName: 'player.external_user_id',
      isOrder: false,
    },
    {
      id: 2,
      name: 'Player',
      align: 'center',
      columnName: 'player.username',
      isOrder: false,
    },
    {
      id: 3,
      name: 'Game Round ID',
      align: 'center',
      columnName: 'gameRoundId',
      isOrder: false,
    },
    {
      id: 4,
      name: 'Bet ID',
      align: 'center',
      columnName: 'placeBetId',
      isOrder: false,
    },
    {
      id: 5,
      name: 'Currency Code',
      align: 'center',
      columnName: 'player.currencyCode',
      isOrder: false,
    },
    {
      id: 6,
      name: 'Status',
      align: 'center',
      columnName: 'betStatus',
      isOrder: false,
      isStatus: true,
    },
    {
      id: 7,
      name: 'Stack Amount',
      align: 'center',
      columnName: 'betAmount',
      isOrder: false,
      isAmount: true,
    },
    {
      id: 8,
      name: 'Crash Point',
      align: 'center',
      columnName: 'cashoutTrigger',
      isOrder: false,
      isXValue: true,
    },
    {
      id: 9,
      name: 'Win Amount',
      align: 'center',
      columnName: 'winAmount',
      isOrder: false,
      isAmount: true,
    },
    {
      id: 10,
      name: 'Cancel Amount',
      align: 'center',
      columnName: 'cancelAmount',
      isOrder: false,
      isAmount: true,
    },
    {
      id: 11,
      name: 'Created At',
      align: 'center',
      columnName: 'createdAt',
      isOrder: false,
      isDate: true,
    },
  ];

  const Status = [
    {
      id: 'win',
      label: 'Win',
    },
    {
      id: 'lost',
      label: 'Lost',
    },
    {
      id: 'cancel',
      label: 'Cancel',
    },
  ];

  const getStatus = (status) => {
    switch (status) {
      case 'lost':
        return <span className="status lost" />;
      case 'win':
        return <span className="status win" />;
      case 'cancel':
        return <span className="status cancelled" />;
      default:
        return <span className="status running" />;
    }
  };

  const REACT_APP_AVIATOR_API_URL = config.apiAviatorUrl.slice(0, -6);

  const currentDate = new Date();
  const Today_Date = dayjs(currentDate);
  const [dateDay, setdateDay] = useState('custom');
  const [selectedDateRange, setSelectedDateRange] = useState(['', '']);
  const [UserRTPList, setUserRTPList] = useState([]);
  const [sortData, setSortData] = useState('ASC');
  const [sortBy, setSortBY] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, SetLoading] = useState(false);
  const [extra_data, setExtraData] = useState([]);
  const [filterName, setFilterName] = useState(null);
  const [status, setStatus] = useState(null);
  const [player, setPlayer] = useState([]);
  const [selectPlayer, setSelectPlayer] = useState(null);
  const [operator, setOperator] = useState([]);
  const [selectOperator, setSelectOperator] = useState(null);

  const { handleSubmit, register, setValue } = useForm();

  const fetchData = async ({
    start_Date = '',
    end_Date = '',
    page = 1,
    rowsPerPage = 10,
    filterName = '',
    status = '',
    playerId = '',
    operatorId = '',
  }) => {
    SetLoading(true);
    const start_date = new Date(start_Date === '' ? currentDate : start_Date);
    const endDate = new Date(end_Date === '' ? Today_Date : end_Date);
    const utcStartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);

    try {
      const data = {
        data: {
          page: page + 1,
          limit: rowsPerPage,
          search: filterName === null ? '' : filterName,
          // order_key: sortBy !== '' ? sortBy : '',
          // order_value: sortData !== '' ? sortData : '',
          startDate: start_Date === '' ? '' : formatDate(utcStartDate, 'datepicker'),
          endDate: end_Date === '' ? '' : formatDate(utcEndDate, 'datepicker'),
          status: status === '' ? '' : status,
          playerId: playerId === '' ? '' : playerId,
        },
      };

      if (userType === 'aviator') {
        data.data.operatorId = operatorId === '' ? '' : operatorId;
      }

      const response = await aviatorPostData(`/user/player/bet`, data);
      setUserRTPList(response.data.list);
      setExtraData(response.data.pagination);
      SetLoading(false);
    } catch (error) {
      console.log('error :>> ', error);
      SetLoading(false);
    }
  };

  const getOperators = async () => {
    const data = {
      data: {
        search: filterName === null ? '' : filterName,
      },
    };
    const response = await aviatorGetData(`/admin/admin-user`, data);
    const operatorData = response.data;
    setOperator(operatorData);
  };

  const getPlayers = async ({ operatorId = '' }) => {
    const data = {
      data: {
        search: filterName === null ? '' : filterName,
      },
    };

    if (userType === 'aviator') {
      data.data.operatorId = selectOperator === null ? '' : selectOperator._id;
    }

    if (operatorId !== '') {
      data.data.operatorId = operatorId;
    }

    const response = await aviatorPostData(`/user/player/get`, data);
    const playerData = response.data;
    setPlayer(playerData);
  };

  const saveBlob = (blob, filename) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const downloadExcel = async () => {
    SetLoading(true);
    const startDate = new Date(selectedDateRange[0] === '' ? currentDate : selectedDateRange[0]);
    const endDate = new Date(selectedDateRange[1] === '' ? Today_Date : selectedDateRange[1]);
    const utcStartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);

    try {
      const data = {
        data: {
          startDate: selectedDateRange[0] === '' ? '' : formatDate(utcStartDate, 'datepicker'),
          endDate: selectedDateRange[1] === '' ? '' : formatDate(utcEndDate, 'datepicker'),
          search: filterName === null ? '' : filterName,
          status: status === null ? '' : status,
          playerId: selectPlayer === null ? '' : selectPlayer._id,
          isDownloadFile: true,
        },
      };

      if (userType === 'aviator') {
        data.data.operatorId = selectOperator === null ? '' : selectOperator._id;
      }

      const response = await aviatorPostData('/user/player/bet', data);
      const blob = await fetch(`${REACT_APP_AVIATOR_API_URL}${response.filePath}`).then((response) => response.blob());
      const date = currentDate.toISOString().split('T')[0];
      const time = currentDate.toTimeString().split(' ')[0];
      const filename = `Player_Bet - ${date} at ${time}.xlsx`;
      saveBlob(blob, filename);
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    } finally {
      SetLoading(false);
    }
  };

  useEffect(() => {
    fetchData({
      sortBy,
      start_Date: selectedDateRange[0],
      end_Date: selectedDateRange[1],
      page,
      rowsPerPage,
      filterName,
    });
    if (userType === 'aviator') {
      getOperators();
    }
    getPlayers({});
    setSelectedDateRange(['', '']);
  }, []);

  const handleSort = (sortBy) => {
    setSortBY(sortBy);
    setSortData(sortData === 'ASC' ? 'DESC' : 'ASC');
    fetchData({
      sortBy,
      sortData: sortData === 'ASC' ? 'DESC' : 'ASC',
      start_Date: selectedDateRange[0],
      end_Date: selectedDateRange[1],
      page,
      rowsPerPage,
      filterName,
    });
  };

  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  const lastSevenDays = new Date(currentDate);
  lastSevenDays.setDate(currentDate.getDate() - 7);

  const lastMonth = new Date(currentDate);
  lastMonth.setMonth(currentDate.getMonth() - 1);

  const onchange = (event) => {
    setdateDay(event.target.value);
    switch (event.target.value) {
      case 'Today':
        return setSelectedDateRange([dayjs(currentDate), Today_Date]);
      case 'Yesterday':
        return setSelectedDateRange([dayjs(yesterday), dayjs(yesterday)]);
      case 'Last 7 days':
        return setSelectedDateRange([dayjs(lastSevenDays), Today_Date]);
      case 'Last 30 days':
        return setSelectedDateRange([dayjs(lastMonth), Today_Date]);
      case 'custom':
        return setSelectedDateRange(['', '']);
      default:
        return setSelectedDateRange([Today_Date, Today_Date]);
    }
  };

  const onchangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const onchangeOperator = (event, newValue) => {
    const _id = newValue?._id || null;
    setValue('operatorId', _id);
    setSelectOperator(newValue);
    getPlayers({ operatorId: _id });
    setSelectPlayer(null);
    if (!_id) {
      setValue('playerId', null);
    }
  };

  const onchangePlayer = (event, newValue) => {
    setValue('playerId', newValue?._id);
    setSelectPlayer(newValue);
  };

  const handleDateChange = (newValue) => {
    setSelectedDateRange([newValue[0] !== null ? newValue[0] : '', newValue[1] !== null ? newValue[1] : '']);
    if (newValue[0] && newValue[1]) {
      setSelectedDateRange(newValue);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchData({
      sortBy,
      sortData: sortBy ? sortData : '',
      start_Date: selectedDateRange[0],
      end_Date: selectedDateRange[1],
      page: newPage,
      rowsPerPage,
      filterName,
      status,
      playerId: selectPlayer?._id,
      operatorId: selectOperator?._id,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    fetchData({
      sortBy,
      sortData: sortBy ? sortData : '',
      start_Date: selectedDateRange[0],
      end_Date: selectedDateRange[1],
      page,
      rowsPerPage: event.target.value,
      filterName,
      status,
      playerId: selectPlayer?._id,
      operatorId: selectOperator?._id,
    });
  };

  const onSearch = () => {
    setPage(0);
    fetchData({
      sortBy,
      sortData: sortBy ? sortData : '',
      start_Date: selectedDateRange[0],
      end_Date: selectedDateRange[1],
      page: 0,
      rowsPerPage,
      filterName,
      status,
      playerId: selectPlayer?._id,
      operatorId: selectOperator?._id,
    });
  };

  const clearFilter = () => {
    setSelectedDateRange(['', '']);
    setSortBY('');
    setStatus(null);
    setPage(0);
    setFilterName('');
    setValue(('playerId', ''));
    setSelectPlayer(null);
    setValue(('operatorId', ''));
    setSelectOperator(null);
    setdateDay('custom');
    fetchData({ page: 0, rowsPerPage, filterName: null });
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title> Player Bets | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>
      <Container maxWidth="xl" className="cardDesign">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon
              icon="material-symbols:analytics-outline"
              style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }}
            />
            <Typography variant="h4" gutterBottom>
              Player Bets
            </Typography>
          </Box>
        </Stack>

        <form onSubmit={handleSubmit(onSearch)}>
          <Card
            sx={{
              minWidth: 275,
              height: 'auto',
              marginTop: '45px',
            }}
          >
            <CardContent>
              <Stack display="flex" flexDirection="row" className="temptranstaionStack pd0_mrg0">
                <Box mt={3} sx={{ flexBasis: '20%' }} className="slectInput width_100 pd0_mrg0">
                  <FormControl sx={{ minWidth: 150 }} fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Dates</InputLabel>
                    <Select name="dates" id="dates" value={dateDay} onChange={onchange}>
                      <MenuItem value={'Today'}>Today</MenuItem>
                      <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                      <MenuItem value={'Last 7 days'}>Last 7 days</MenuItem>
                      <MenuItem value={'Last 30 days'}>Last 30 days</MenuItem>
                      <MenuItem value={'custom'}>Custom</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Box padding={2} sx={{ flexBasis: '40%' }} className="slectInput width_100 pd0_mrg0">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DesktopDateRangePicker']}>
                      <DemoItem component="DesktopDateRangePicker">
                        <DesktopDateRangePicker
                          localeText={{ start: 'Start Date', end: 'End Date' }}
                          size="small"
                          maxDate={Today_Date}
                          value={selectedDateRange}
                          onChange={handleDateChange}
                          disabled={dateDay !== 'custom'}
                          calendars={1}
                          format="DD-MM-YYYY"
                          slotProps={{
                            textField: {
                              error: false,
                            },
                          }}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>

                {userType === 'aviator' && (
                  <Box padding={3} sx={{ flexBasis: '25%' }} className="slectInput width_100 pd0_mrg0">
                    <FormControl sx={{ minWidth: 150 }} fullWidth>
                      <Autocomplete
                        fullWidth
                        name="selectOperator"
                        disablePortal
                        id="operatorId"
                        {...register('operatorId')}
                        options={operator}
                        autoHighlight
                        value={selectOperator}
                        onChange={onchangeOperator}
                        getOptionLabel={(option) => `${option.username}`}
                        renderInput={(params) => (
                          <TextField {...params} value={selectOperator} selectPlayer label=" Select Operators" />
                        )}
                      />
                    </FormControl>
                  </Box>
                )}

                <Box padding={3} sx={{ flexBasis: '25%' }} className="slectInput width_100 pd0_mrg0">
                  <FormControl sx={{ minWidth: 150 }} fullWidth>
                    <Autocomplete
                      fullWidth
                      name="selectPlayer"
                      disablePortal
                      id="_id"
                      {...register('_id')}
                      options={player}
                      autoHighlight
                      value={selectPlayer}
                      onChange={onchangePlayer}
                      getOptionLabel={(option) => `${option.external_user_id} / ${option.username}`}
                      renderInput={(params) => (
                        <TextField {...params} value={selectPlayer} selectPlayer label=" Select Players" />
                      )}
                    />
                  </FormControl>
                </Box>

                <Box padding={3} sx={{ flexBasis: '25%' }} className="slectInput width_100 pd0_mrg0">
                  <FormControl sx={{ minWidth: 150 }} fullWidth>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select name="status" {...register('status')} id="status" value={status} onChange={onchangeStatus}>
                      <MenuItem key={''} value={null}>
                        Select Status
                      </MenuItem>
                      {Status.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Stack>
              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                className="temptranstaionStack pd0_mrg0"
              >
                <Box display="flex" flexDirection="row" sx={{ flexBasis: '20%' }}>
                  <Box className="slectInput width_100 pd0_mrg0" flex={1}>
                    <TextField value={filterName} type="text" onChange={handleFilterByName} placeholder="Search..." />
                  </Box>
                </Box>
                <Box px={1} className="width_100" paddingLeft={2}>
                  <Stack display="flex" flexDirection="row" justifyContent="flex-end">
                    <Stack display="flex" flexDirection="row">
                      <Box margin={2} className="slectInput width_100 pd0_mrg0">
                        <Button
                          onClick={clearFilter}
                          color="secondary"
                          variant="outlined"
                          size="large"
                          disableElevation
                          disabled={loading}
                        >
                          Clear
                        </Button>
                      </Box>
                      <Box margin={2} className="slectInput width_100 pd0_mrg0">
                        <Button
                          type="submit"
                          color="secondary"
                          variant="contained"
                          size="large"
                          disabled={loading}
                          disableElevation
                        >
                          Search
                        </Button>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
              </Stack>

              <Box sx={{ overflow: 'auto' }}>
                <Stack
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  className="temptranstaionStack pd0_mrg0"
                >
                  <Box display="flex" flexDirection="row" margin={2}>
                    <Typography variant="body1" className="statusLabel running" sx={{ mx: 4 }}>
                      Not Settled
                    </Typography>
                    <Typography variant="body1" className="statusLabel win" sx={{ mx: 4 }}>
                      Winning
                    </Typography>
                    <Typography variant="body1" className="statusLabel lost" sx={{ mx: 4 }}>
                      Lost
                    </Typography>
                    <Typography variant="body1" className="statusLabel cancelled" sx={{ mx: 4 }}>
                      Cancelled
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    margin={2}
                    sx={{ flexBasis: '20%', justifyContent: 'flex-end' }}
                    className="slectInput width_100 pd0_mrg0"
                  >
                    <Button
                      onClick={downloadExcel}
                      color="secondary"
                      variant="contained"
                      size="large"
                      disabled={loading}
                      disableElevation
                    >
                      <Iconify icon={'eva:download-outline'} />
                    </Button>
                  </Box>
                </Stack>
                <TableContainer component={Paper} sx={{ height: 540, scrollbarWidth: 'thin' }}>
                  <Table sx={{ minWidth: '2000px' }} stickyHeader aria-label="simple table" margin={1}>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <>
                            {!column.isRowSkip && (
                              <TableCell
                                align={column.align}
                                sx={{ width: '250px', padding: '10px' }}
                                onClick={() => (column.isOrder ? handleSort(column.columnName) : '')}
                              >
                                {column.name}
                                {column.isOrder ? (
                                  <IconButton aria-label="toggle password visibility">
                                    {sortData === 'ASC' ? (
                                      <ArrowUpwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                                    ) : (
                                      <ArrowDownwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                                    )}
                                  </IconButton>
                                ) : (
                                  ''
                                )}
                              </TableCell>
                            )}
                          </>
                        ))}
                      </TableRow>
                    </TableHead>

                    {!loading ? (
                      <TableBody>
                        {UserRTPList?.map((item) => (
                          <>
                            {' '}
                            <TableRow key={item._id} padding={0}>
                              {columns.map((column) => (
                                <>
                                  {!column.isRowSkip && (
                                    <TableCell align="center" sx={{ width: '250px' }}>
                                      {column.isDate
                                        ? formatDate(item?.[column.columnName], 'ticket datetime')
                                        : column.isCount
                                        ? fNumber(item?.[column.columnName])
                                        : column.isAmount
                                        ? `${fNumber(item?.[column.columnName])} ${showCurrency()}`
                                        : column.isPercentage
                                        ? `${formatNumber(item?.[column.columnName])} %`
                                        : column.isStatus
                                        ? getStatus(item?.[column.columnName])
                                        : column.isXValue
                                        ? `${formatNumber(item?.[column.columnName])} X`
                                        : getNestedValue(item, column.columnName) || '-'}
                                    </TableCell>
                                  )}
                                </>
                              ))}
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    ) : (
                      <TableCell align="center" colSpan={8}>
                        <CircularProgress />
                      </TableCell>
                    )}
                    {!loading && UserRTPList.length <= 0 && (
                      <TableCell align="center" colSpan={8}>
                        <Typography variant="h6" textTransform={'capitalize'}>
                          {' '}
                          data not found !
                        </Typography>
                      </TableCell>
                    )}
                  </Table>
                  {!loading && UserRTPList.length > 0 && (
                    <TableFooter sx={{}}>
                      <TableRow>
                        <TableCell
                          colSpan={3}
                          align="left"
                          sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}
                        >
                          Total Entries : {extra_data ? extra_data.totalCounts : 0}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  )}

                  {/* </>
                  )} */}
                </TableContainer>
                {!loading && UserRTPList.length > 0 && (
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={extra_data ? extra_data?.totalCounts : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              </Box>
            </CardContent>
          </Card>
        </form>
      </Container>
    </>
  );
};

export default PlayerBet;
