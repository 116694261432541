import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Container } from '@mui/material';
import Tab from '@mui/material/Tab';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import VirtualBettingReports from './VirtualBettingReports';
import VirtualHighestBetReport from './VirtualHighestBetReport';
import VirtualHighestPayout from './VirtualHighestPayout';

const VirtualsReports = () => {
  const [value, setValue] = useState('0');
  const params = useParams();
  const { tab } = params;

  useEffect(() => {
    if (tab === 'bettingreport') {
      setValue('0');
    } else if (tab === 'highestpayout') {
      setValue('1');
    } else if (tab === 'highestbets') {
      setValue('2');
    }
  }, [tab]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container maxWidth="xl tabContainerNoPd">
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              sx={{
                '& .MuiTab-root': { fontSize: '18px', fontWeight: '500', paddingLeft: 4, paddingRight: 4 },
              }}
            >
              <Tab
                iconPosition="start"
                icon={<AnalyticsOutlinedIcon />}
                component={Link}
                to={`/admin/report/virtuals/bettingreport`}
                label="Virtual Report"
                value="0"
              />
              <Tab
                iconPosition="start"
                icon={<BarChartOutlinedIcon />}
                component={Link}
                to={`/admin/report/virtuals/highestpayout`}
                label="Highest Payout"
                value="1"
              />
              <Tab
                iconPosition="start"
                icon={<AutoAwesomeOutlinedIcon />}
                component={Link}
                to={`/admin/report/virtuals/highestbets`}
                label="Highest Bets"
                value="2"
              />
            </TabList>
          </Box>
          <TabPanel value="0">{tab === 'bettingreport' && <VirtualBettingReports />}</TabPanel>
          {/* <TabPanel value="1"> Highest Payout </TabPanel>
          <TabPanel value="2"> Highest Bets </TabPanel> */}
          <TabPanel value="1">{tab === 'highestpayout' && <VirtualHighestPayout />}</TabPanel>
          <TabPanel value="2">{tab === 'highestbets' && <VirtualHighestBetReport />}</TabPanel>
        </TabContext>
      </Box>
    </Container>
  );
};

export default VirtualsReports;
