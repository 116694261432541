import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import Iconify from '../../components/iconify/Iconify';
import { UserContext } from '../../contexts/UserContext';
import { postData } from '../../utils/api';
import { generateHash, getTimeStamp, ToastError, ToastSuccess } from '../../utils/helper';

const ShopAssign = ({ open, handleClose, roles, admin_users, shopId, adminUsers, setAdminUsers }) => {
  const [selectedCashier, setSelectedCashier] = useState('');
  const [selectedSupervisor, setSelectedSupervisor] = useState('');
  const { user } = useContext(UserContext);
  const [disable, setDisable] = useState(false);
  
  const handleUserChange = (event) => {
    const cashier = admin_users[0]?.cashiers?.find((item) => item.id === event.target.value);
    if(cashier) setSelectedCashier(cashier);
    else setSelectedCashier('');
  };

  const handleSupervisorChange = (event) => {
    const supervisor = admin_users[0]?.supervisors?.find((item) => item.id === event.target.value);
    if(supervisor)setSelectedSupervisor(supervisor);
    else setSelectedSupervisor('');
  };

  useEffect(() => {
    if(adminUsers.length > 0) setDisable(false);
    else setDisable(true);
  }, [adminUsers])

  const handleAddCashier = () => {
    const newUser = {
      role_id: selectedCashier.role_id,
      role: {
        id: selectedCashier.role_id,
        name: roles.find((role) => role.id === selectedCashier.role_id).name,
      },
      admin_user_id: selectedCashier.id,
      admin_user: {
        id: selectedCashier.id,
        username: selectedCashier.username,
      },
    };
    const checkForDuplicate = adminUsers.find((item) => item.admin_user_id === selectedCashier.id);
    if(checkForDuplicate) {
      ToastError('This Cashier already exists in this shop');
    } else {
      setAdminUsers([...adminUsers, newUser]);
    }
    setSelectedCashier('');
  };

  const handleAddSupervisor = () => {
    const newUser = {
      role_id: selectedSupervisor.role_id,
      role: {
        id: selectedSupervisor.role_id,
        name: roles.find((role) => role.id === selectedSupervisor.role_id).name,
      },
      admin_user_id: selectedSupervisor.id,
      admin_user: {
        id: selectedSupervisor.id,
        username: selectedSupervisor.username,
      },
    };
    const checkForSupervisor = adminUsers.find((item) => item.role.name === 'Supervisor');
    if(checkForSupervisor) {
      ToastError('Supervisor already exists in this shop');
    } else {
      setAdminUsers([...adminUsers, newUser]);
    }
    setSelectedSupervisor('');
  };

  const handleRemoveUser = (index) => {
    const updatedUsers = [...adminUsers];
    updatedUsers.splice(index, 1);
    setAdminUsers(updatedUsers);
  };

  const handleShopAssign = async () => {
    setDisable(true)
    try {
      const updated = [];
      adminUsers.forEach((element) => {
        updated.push({
          role_id: element.role_id,
          admin_user_id: element.admin_user_id,
        });
      });

      const data = {
        user_token: user.token,
        data: {
          shop_id: shopId,
          admin_users: updated,
          date_time: getTimeStamp(),
        },
      };
      const hashSignature = generateHash(data.data);
      data.data.hash = hashSignature;
      const response = await postData(`/admin/shop/assign`, data);
      ToastSuccess(response.message);
      handleClose();
      setDisable(false)
    } catch (error) {
      console.log('error2 :>> ', error);
      ToastError(error.response.data.message);
      setDisable(false)
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    padding: '20px',
    borderRadius: '8px',
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h5">Assign Shops</Typography>
            <IconButton aria-label="Cancel" onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </Stack>

          <Stack direction="row" justifyContent="space-between" alignItems="center" className="slectInput">
            <Box sx={{ flexBasis: '75%' }}>
              <FormControl fullWidth>
                <InputLabel id="admin-user-select-label">Cashier</InputLabel>
                <Select
                  labelId="admin-user-select-label"
                  id="admin-user-select"
                  value={selectedCashier ? selectedCashier?.id : ''}
                  onChange={handleUserChange}
                >
                  <MenuItem key={0} value={0}>
                    Select Cashier
                  </MenuItem>
                  {admin_users && admin_users[0]?.cashiers?.length > 0 && admin_users[0].cashiers.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.username}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <Button
                size="large"
                variant="contained"
                onClick={handleAddCashier}
                disabled={!selectedCashier}
              >
                Add Cashier
              </Button>
            </Box>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" className="slectInput">
            <Box sx={{ flexBasis: '75%' }}>
              <FormControl fullWidth>
                <InputLabel id="admin-user-select-label">Supervisor</InputLabel>
                <Select
                  labelId="admin-user-select-label"
                  id="admin-user-select"
                  value={selectedSupervisor ? selectedSupervisor?.id : ''}
                  onChange={handleSupervisorChange}
                >
                  <MenuItem key={0} value={0}>
                    Select Supervisor
                  </MenuItem>
                  {admin_users && admin_users[0]?.supervisors?.length > 0 && admin_users[0].supervisors.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.username}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <Button
                size="large"
                variant="contained"
                onClick={handleAddSupervisor}
                disabled={!selectedSupervisor}
              >
                Add Supervisor
              </Button>
            </Box>
          </Stack>

          <FormControl fullWidth sx={{ mt: 4 }}>
            <FormLabel
              component="legend"
              sx={{ color: 'black', fontSize: '20px', fontWeight: 'bold', marginBottom: '20px' }}
            >
              Admin Users
            </FormLabel>
            {adminUsers.map((field, index) => (
              <Stack
                key={field.id}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{ width: '100%', borderBottom: '1px solid #efefef', mb: 2, paddingBottom: '10px' }}
              >
                <Stack>
                  <Typography variant="body1" fontWeight="bold">
                    {field.admin_user.username}
                  </Typography>
                  <Typography variant="body2"> {field.role.name}</Typography>
                </Stack>

                <IconButton size="large" color="inherit" onClick={() => handleRemoveUser(index)}>
                  <Iconify icon={'eva:trash-2-outline'} sx={{}} />
                </IconButton>
              </Stack>
            ))}
          </FormControl>
          <Button
            variant="contained"
            size="large"
            onClick={() => handleShopAssign()}
            sx={{ display: 'flex', justifyContent: 'right', marginLeft: 'auto' }}
            disabled={disable}
          >
            save
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ShopAssign;
