import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { UserContext } from '../../contexts/UserContext';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { fetchDataWithAuth, postData } from '../../utils/api';
import { ToastError, ToastSuccess } from '../../utils/helper';

const schema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .matches(/^[^\s].*/, 'starting spaced is not allowed'),
  slug: yup
    .string()
    .required('Slug is required')
    .matches(/^[^\s].*/, 'starting spaced is not allowed'),
  icon: yup
    .string()
    .required('Icon is required')
    .matches(/^[^\s].*/, 'starting spaced is not allowed'),
  actions: yup.array().required('Actions is required').typeError('Actions is required'),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const actionList = ['view', 'add', 'edit', 'delete', 'assign', 'request', 'approve'];

const MenuForm = ({ action }) => {
  const Redirect = useNavigate();
  const params = useParams();
  const theme = useTheme();
  const [actions, setActions] = useState([]);
  const { user } = useContext(UserContext);
  const [disable, setDisable] = useState(false);
  const [loading,setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const getMenu = async () => {
      setLoading(true)
      if (params.id) {
        const data = {
          user_token: user.token,
          data: {},
        };
        const response = await fetchDataWithAuth(`/admin/menu/get/${params.id}`, data);
        const menuData = response.data[0];
        setValue('name', menuData.name);
        setValue('slug', menuData.slug);
        setValue('icon', menuData.icon);
        setValue('actions', typeof menuData.action === 'string' ? menuData.action.split(',') : menuData.action);
        setActions(typeof menuData.action === 'string' ? menuData.action.split(',') : menuData.action);
      }
      setLoading(false)
    };
    getMenu();
  }, [params, setValue]);

  const onSubmit = async (menuData) => {
    // Perform form submission logic here
    setLoading(true)
    setDisable(true)
    toast.dismiss();
    let data = null;
    let MenuData = null;
    try {
      if (params.id) {
        data = {
          user_token: user.token,
          data: {
            id: params.id,

            name: menuData.name,
            slug: menuData.slug,
            icon: menuData.icon,
            action: menuData.actions.toString(),
          },
        };
        MenuData = await postData('/admin/menu/update', data);
      } else {
        data = {
          user_token: user.token,
          data: {
            name: menuData.name,
            slug: menuData.slug,
            icon: menuData.icon,
            action: menuData.actions.toString(),
          },
        };
        MenuData = await postData('/admin/menu/add', data);
      }
      ToastSuccess(MenuData.message);
      setTimeout(() => {
        toast.dismiss();
        Redirect('/admin/menu');
      }, 800);
      setDisable(false)
    } catch (error) {
      console.log('error2 :>> ', error);
      ToastError(error.response.data.message);
      setDisable(false)
    }
    setLoading(false)
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setValue('actions', typeof value === 'string' ? value.split(',') : value);
    setActions(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  function getStyles(name, actions, theme) {
    return {
      fontWeight:
        actions && actions.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  return (
    <>
      <Helmet>
        <title> Side Menu {action} | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>

      <Container maxWidth="md">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Side Menu {action}
          </Typography>
        </Stack>
        <Card>
          <CardContent>
           { loading ?  
         <Stack display="flex" flexDirection="row" justifyContent="center">
         <CircularProgress size={35} />
       </Stack> : <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="row">
                <Box padding={2} width="50%">
                  <TextField
                    label="Name"
                    placeholder='Name'
                    {...register('name')}
                    // error={!!errors.name}
                    // helperText={errors.name?.message}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                      classes: { root: 'input-label' },
                    }}
                    InputProps={{ classes: { root: 'input-field' } }}
                    disabled={params.id}
                    // sx={{ textAlign: 'center', margin: '5px' }}
                  />
                  <Typography variant="body2" color="error">
                    {errors.name?.message}
                  </Typography>
                </Box>
                <Box padding={2} width="50%">
                  <TextField
                    label="Slug"
                    {...register('slug')}
                    placeholder='Slug'
                    // error={!!errors.slug}
                    // helperText={errors.slug?.message}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                      classes: { root: 'input-label' },
                    }}
                    InputProps={{ classes: { root: 'input-field' } }}
                    // sx={{ textAlign: 'center', margin: '5px' }}
                  />
                  <Typography variant="body2" color="error">
                    {errors.slug?.message}
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row">
                <Box padding={2} width="50%">
                  <TextField
                    label="Icon"
                    placeholder='Icon'
                    {...register('icon')}
                    // error={!!errors.slug}
                    // helperText={errors.slug?.message}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                      classes: { root: 'input-label' },
                    }}
                    InputProps={{ classes: { root: 'input-field' } }}
                    // sx={{ textAlign: 'center', margin: '5px' }}
                  />
                  <Typography variant="body2" color="error">
                    {errors.icon?.message}
                  </Typography>
                </Box>
                <Box padding={2} width="50%">
                  <FormControl sx={{ width: '100%' }} className="creatMenuSelect">
                    <InputLabel id="demo-multiple-chip-label">Actions</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      {...register('actions')}
                      // error={!!errors.actions}
                      // helperText={errors.actions?.message}
                      multiple
                      value={actions}
                      onChange={handleChange}
                      input={<OutlinedInput id="select-multiple-chip" label="Actions" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {actionList.map((name) => (
                        <MenuItem key={name} value={name} style={getStyles(name, actions, theme)}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography variant="body2" color="error">
                      {errors.actions?.message}
                    </Typography>
                  </FormControl>
                </Box>
              </Stack>
              <Box padding={2}>
                {' '}
                <Button type="submit" variant="contained" color="primary" size="large" disabled={disable}>
                  {action} Menu
                </Button>
              </Box>
            </form>}
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default MenuForm;
