import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography
} from '@mui/material';
import { useContext, useState } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { postData } from '../../utils/api';
import { generateHash, getTimeStamp, ToastError, ToastSuccess } from '../../utils/helper';

const AgentAssign = ({ open, handleClose, agents, shopId }) => {
  const [selectedUser, setSelectedUser] = useState('');
  const { user } = useContext(UserContext);
  const [disable, setDisable] = useState(false);
  
  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleAgentAssign = async () => {
    setDisable(true)
    try {
      const data = {
        user_token: user.token,
        data: {
          shop_id: shopId,
          agent_id: selectedUser,
          date_time: getTimeStamp(),
        },
      };
      const hashSignature = generateHash(data.data);
      data.data.hash = hashSignature;
      const response = await postData(`/admin/shop/assign/agent`, data);
      ToastSuccess(response.message);
      handleClose();
      setDisable(false)
    } catch (error) {
      console.log('Agent assign error  :>> ', error);
      ToastError(error?.response.data.message);
      setDisable(false)
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    padding: '20px',
    borderRadius: '8px',
  };
  
  return (
    <div>
      <Modal
        open={open}
        onClose={()=>{handleClose()
            setSelectedUser('')
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h5">Assign Agent to Shop</Typography>
            <IconButton aria-label="Cancel" onClick={()=>
              {  handleClose()
                setSelectedUser('')}
                }>
              <CancelIcon />
            </IconButton>
          </Stack>

          <Stack direction="row" justifyContent="space-between" alignItems="center" className="slectInput">
            <Box sx={{ flexBasis: '40%' }}>
              <FormControl fullWidth>
                <InputLabel id="admin-user-select-label">Agent</InputLabel>
                <Select
                  labelId="admin-user-select-label"
                  id="admin-user-select"
                  value={selectedUser}
                  onChange={handleUserChange}
                >
                  {agents.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.username}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <Button
                size="large"
                variant="contained"
                onClick={handleAgentAssign}
                disabled={!selectedUser || disable}
              >
                Assign Agent
              </Button>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default AgentAssign;
