import axios from 'axios';
import config from '../config';
import { ToastError, ToastSuccess } from './helper';

// const API_DEV_URL = 'http://api.onlinegamblingtech.com/api';
// const API_DEV_URL = 'http://192.168.1.4:5001/api';
// const API_KEY = '35969c8e0fba4';

const API_DEV_URL = config.apiUrl;
const API_KEY = config.apiKey;
const aviatorApiUrl = process.env.REACT_APP_AVIATOR_API_URL;

export const fetchData = async (request_url) => {
  try {
    const response = await axios.get(`${API_DEV_URL}${request_url}`, {
      headers: {
        'Content-Type': 'application/json',
        'API-KEY': API_KEY,
      },
    });
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
    throw error;
  }
};
export const fetchDataWithAuth = async (request_url, payload) => {
  try {
    const response = await axios.get(`${API_DEV_URL}${request_url}`, {
      headers: {
        'Content-Type': 'application/json',
        'API-KEY': API_KEY,
        Authorization: `Bearer ${payload.user_token}`,
      },
    });
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
    throw error;
  }
};

export const postData = async (request_url, payload) => {
  try {
    const response = await axios.post(`${API_DEV_URL}${request_url}`, payload.data, {
      headers: {
        'Content-Type': 'application/json',
        'API-KEY': API_KEY,
        Authorization: `Bearer ${payload.user_token}`,
      },
    });
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
    throw error;
  }
};

export const postDataWithoutAuth = async (request_url, payload) => {
  try {
    const response = await axios.post(`${API_DEV_URL}${request_url}`, payload.data, {
      headers: {
        'Content-Type': 'application/json',
        'API-KEY': API_KEY,
      },
    });
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
    throw error;
  }
};

export const loginUser = async (request_url, payload) => {
  try {
    const response = await axios.post(`${API_DEV_URL}${request_url}`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'API-KEY': API_KEY,
      },
    });
    ToastSuccess(response.data.message);
    // toast.success(response.data.message, {
    //   position: 'top-center',
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeOnClick: true,
    //   theme: 'colored',
    // });
    return response.data.data[0];
  } catch (error) {
    // Handle error
    ToastError(error.response.data.message);
    // toast.error(error.response.data.message, {
    //   position: 'top-center',
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeOnClick: true,
    //   theme: 'colored',
    // });
    return null;
  }
};

export const registerUser = async (request_url, payload, navigate) => {
  try {
    const response = await axios.post(`${API_DEV_URL}${request_url}`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'API-KEY': API_KEY,
      },
    });
    if (response.status === 200) navigate('/');
    ToastSuccess(response.data.message);
    // toast.success(response.data.message, {
    //   position: 'top-center',
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeOnClick: true,
    //   theme: 'colored',
    // });
    return response.data.data[0];
  } catch (error) {
    // Handle error
    ToastError(error.response.data.message);
    // toast.error(error.response.data.message, {
    //   position: 'top-center',
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeOnClick: true,
    //   theme: 'colored',
    // });
    return error;
  }
};

export const deleteData = async (request_url) => {
  try {
    const response = await axios.delete(`${API_DEV_URL}${request_url}`, {
      headers: {
        'Content-Type': 'application/json',
        'API-KEY': API_KEY,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error deleting data:', error);
    return error;
  }
};
export const deleteDataWithAuth = async (request_url, payload) => {
  // console.log('payload', payload);
  try {
    const response = await axios.delete(`${API_DEV_URL}${request_url}`, {
      headers: {
        'Content-Type': 'application/json',
        'API-KEY': API_KEY,
        Authorization: `Bearer ${payload.user_token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting data:', error);
    return error;
  }
};

export const aviatorLoginUser = async (request_url, payload) => {
  try {
    const response = await axios.post(`${aviatorApiUrl}${request_url}`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    // Handle error
    ToastError(error.response.data.message);
    return null;
  }
};

export const aviatorApiCall = async (request_url, payload) => {
  try {
    const aviator_token = localStorage.getItem('aviator_token');
    const response = await axios.post(`${aviatorApiUrl}${request_url}`, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${aviator_token}`,
      },
    });

    return response.data;
  } catch (error) {
    // Handle error
    // ToastError(error.response.data);
    return error.response;
  }
};

export const aviatorPostData = async (request_url, payload) => {
  try {
    const aviator_token = localStorage.getItem('aviator_token');
    const response = await axios.post(`${aviatorApiUrl}${request_url}`, payload.data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${aviator_token}`,
      },
    });
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
    throw error;
  }
};

export const aviatorGetData = async (request_url) => {
  try {
    const aviator_token = localStorage.getItem('aviator_token');
    const response = await axios.get(`${aviatorApiUrl}${request_url}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${aviator_token}`,
      },
    });
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
    throw error;
  }
};
