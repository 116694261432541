import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import Menu from '@mui/material/Menu';
import { alpha, styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
// import Icon from '../components/color-utils/Icon';
import config from '../../config';
import { UserContext } from '../../contexts/UserContext';
import { fetchDataWithAuth, postData } from '../../utils/api';
import { fNumber } from '../../utils/formatNumber';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { ToastError, formatDate, formatNumber, showCurrency } from '../../utils/helper';

const default_filters = {
  id: '',
  start_date: '',
  end_date: '',
  // start_price: '',
  // end_price: '',
};
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));
const ShopReport = () => {
  const REACT_APP_API_URL = config.apiUrl.slice(0, -4);

  const [shopReportList, setShopReportList] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(['', '']);
  const [shops, setShops] = useState([]);
  const [filter, setFilter] = useState(default_filters);
  const [selectUser, setSelectUser] = useState('');
  const [loading, SetLoading] = useState(true);
  const [selectVal, setSelectVal] = useState(1);
  const [disable, setDisable] = useState(false);
  const [filterData, setFilterData] = useState([]);

  // slider
  const maxCashier = shopReportList.reduce((max, current) => parseFloat(max.shop_balance) > parseFloat(current.shop_balance) ? max : current, {});
  // const [rangeValues, setRangeValues] = useState([10, 500000]);

  const { user } = useContext(UserContext);
  const params = useParams();

  // const handleRangeChange = (event, newValue) => {
  //   setRangeValues(newValue);
  //   setValue('start_price', newValue[0]);
  //   setValue('end_price', newValue[1]);
  // };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const fetchShopReport = async () => {
    try {
      const data = {
        user_token: user.token,
        data: filter,
      };
      SetLoading(true);
      const response = await postData(`/admin/reports/shop/summary?page=1&size=50`, data);
      setShopReportList(response.data);
      SetLoading(false);
    } catch (error) {
      console.log('error :>> ', error);
      ToastError(error.response.data.message);
    }
  };

  const getShops = async () => {
    const data = {
      user_token: user.token,
      data: {},
    };
    const response = await fetchDataWithAuth(`/admin/shop/list`, data);
    const userData = response.data;
    setShops(userData);
  };

  useEffect(() => {
    getShops();
    setSelectUser(params.id);
    if (params.id) onSearch(params.id);
    if (!params.id) fetchShopReport();
  }, []);

  const onSearch = async (betFilter) => {
    const startDate = new Date(selectedDateRange[0] !== '' ? selectedDateRange[0] : '');
    const endDate = new Date(selectedDateRange[1] !== '' ? selectedDateRange[1] : '');
    const utcstartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
    setFilterData(betFilter);
    toast.dismiss();
    const data = {
      user_token: user.token,
      data: {
        id: betFilter.id ? betFilter.id : params.id ? params.id : '',
        start_date: selectedDateRange[0] === '' ? '' : formatDate(utcstartDate, 'datepicker'),
        end_date: selectedDateRange[1] === '' ? '' : formatDate(utcEndDate, 'datepicker'),
        // start_price: betFilter.start_price ? betFilter.start_price : '',
        // end_price: betFilter.end_price ? betFilter.end_price : '',
      },
    };

    try {
      SetLoading(true);
      const response = await postData(`/admin/reports/shop/summary?page=1&size=50`, data);
      setFilter(data.data);
      setShopReportList(response.data);
      SetLoading(false);
    } catch (error) {
      console.log('error :>> ', error);
      ToastError(error.response.data.message);
    }
  };

  const handleDateChange = (newValue) => {
    setSelectedDateRange([newValue[0] !== null ? newValue[0] : '', newValue[1] !== null ? newValue[1] : '']);
    // if (newValue[0] && newValue[1]) {
    //   const start_date = new Date(newValue[0]);
    //   const endDate = new Date(newValue[1]);
    //   const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
    //   const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
    //   setValue('start_date', utcstartDate);
    //   setValue('end_date', utcEndDate);
    // }
  };

  // const handleClose = () => {
  //   setOpen(false);
  //   setBetDetail(null);
  //   setBetBarcode(null);
  // };

  const clearFilter = async () => {
    reset();
    setSelectedDateRange(['', '']);
    // setRangeValues([10, 500000]);
    setSelectUser('');
    if (
      selectUser !== '' ||
      selectedDateRange[0] !== '' ||
      selectedDateRange[1] !== '' 
      // rangeValues[0] !== 10 ||
      // rangeValues[1] !== 500000
    ) {
      const data = {
        user_token: user.token,
        data: default_filters,
      };
      SetLoading(true);
      const response = await postData(`/admin/reports/shop/summary?page=1&size=50`, data);
      setFilter(default_filters);
      setShopReportList(response.data);
      SetLoading(false);
    }
  };
  const onchangeShop = (event) => {
    setSelectUser(event.target.value);
  };

  // export start
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const saveBlob = (blob, filename) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };
  const handleFileTypeDownload = async (filetype) => {
    setDisable(true);
    const startDate = new Date(selectedDateRange[0] !== '' ? selectedDateRange[0] : '');
    const endDate = new Date(selectedDateRange[1] !== '' ? selectedDateRange[1] : '');
    const utcstartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
    const data = {
      user_token: user.token,
      data: {
        id: filterData.id ? filterData.id : params.id ? params.id : '',
        page: 1,
        row_per_page: 50,
        file_type: filetype,
        // start_price: filterData.start_price ? filterData.start_price : '',
        // end_price: filterData.end_price ? filterData.end_price : '',
        start_date: selectedDateRange[0] === '' ? '' : formatDate(utcstartDate, 'datepicker'),
        end_date: selectedDateRange[1] === '' ? '' : formatDate(utcEndDate, 'datepicker'),
        // order_key: sortBy || '',
        // order_value: sortData || '',
        headers: [
          'Shop Name',
          `Shop Balance (${showCurrency()})`,
          'Total No. Of Bets',
          `Total Bets Stake Amount (${showCurrency()})`,
          'Total No. Of Awarded Payouts',
          `Total Awarded Payouts (${showCurrency()})`,
          `Total Refunded Amount (${showCurrency()})`,
          `Total Deposited Amount (${showCurrency()})`,
          `Total Withdrawn Amount (${showCurrency()})`,
          `Total Transferred Amount (${showCurrency()})`,
          `Total Received Amount (${showCurrency()})`,
          `Total Awarded Signup Bonuses (${showCurrency()})`,
          `Total Awarded Deposit Bonuses (${showCurrency()})`,
          'Cashier',
        ],
      },
    };
    try {
      const HighestPayout = await postData(`/admin/reports/shop/summary/export`, data);

      let mimeType;
      let fileExtension;

      if (filetype === 2) {
        mimeType = 'text/csv';
        fileExtension = '.csv';
      } else if (filetype === 3) {
        mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        fileExtension = '.xlsx';
      } else if (filetype === 1) {
        mimeType = 'application/json';
        fileExtension = '.json';
      } else {
        // Handle unsupported file types

        return;
      }

      const blob = await fetch(`${REACT_APP_API_URL}${HighestPayout.data[0].file_path}`).then((response) =>
        response.blob()
      );

      const filename = `ShopReport${fileExtension}`;
      saveBlob(blob, filename);
      setDisable(false);
    } catch (error) {
      console.log('error :>> ', error);
      ToastError(error.response.data.message);
      setDisable(false);
    }
    setAnchorEl(null);
  };
  const handleSelectChange = (event) => {
    setSelectVal(event.target.value);
  };
  // export end

  const min_date = new Date();
  const minDate = dayjs(min_date);
  const isNotFound = !shopReportList.length;
  return (
    <>
      <Helmet>
        <title>Shop Report | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon icon="icon-park-outline:shop" style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }} />
            <Typography variant="h4" gutterBottom>
              Shop Report
            </Typography>
          </Box>
        </Stack>
        <Card className="transtionCard">
          <CardContent>
            <form onSubmit={handleSubmit(onSearch)}>
              <Stack display="flex" flexDirection="row" alignItems="self-end" className="temptranstaionStack">
                <Box padding={2} sx={{ flexBasis: '25%' }} className="slectInput width_100">
                  <Typography variant="body1" mb={1}>
                    Select shop
                  </Typography>
                  <FormControl sx={{ minWidth: 150 }} fullWidth>
                    <InputLabel id="demo-simple-select-label">Search All shop </InputLabel>
                    <Select name="id" {...register('id')} id="id" value={selectUser} onChange={onchangeShop}>
                      <MenuItem key={''} value={null}>
                        Select shop
                      </MenuItem>
                      {shops.map((option) => (
                        <MenuItem key={option.shop_id} value={option.shop_id}>
                          {option.shop_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box className="width_100" padding={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DesktopDateRangePicker']}>
                      <DemoItem component="DesktopDateRangePicker">
                        <DesktopDateRangePicker
                          localeText={{ start: 'Start Date', end: 'End Date' }}
                          className=""
                          size="small"
                          maxDate={minDate}
                          // {...register('start_date')}
                          // name="selectedDateRange"
                          value={selectedDateRange}
                          onChange={handleDateChange}
                          calendars={1}
                          format="DD-MM-YYYY"
                          slotProps={{
                            textField: {
                              error: false,
                            },
                          }}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
                <Box className="width_100" padding={2}>
                  <Stack display="flex" flexDirection="row" justifyContent="flex-end">
                    <Stack display="flex" flexDirection="row">
                      <Box marginRight={2} marginLeft={2} alignSelf="center">
                        <Button
                          onClick={clearFilter}
                          color="secondary"
                          variant="outlined"
                          size="large"
                          disabled={loading}
                          disableElevation
                          sx={{ padding: '27px' }}
                        >
                          Clear
                        </Button>
                      </Box>
                      <Box alignSelf="center">
                        <Button
                          type="submit"
                          color="secondary"
                          variant="contained"
                          size="large"
                          sx={{ padding: '27px' }}
                          disabled={loading}
                          disableElevation
                        >
                          Search
                        </Button>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
                <Box className="width_100" padding={2}>
                  <Stack sx={{ width: '100%' }} justifyContent={'end'} display="flex" flexDirection="row">
                    <Box marginRight={2}>
                      <FormControl size="small" sx={{ minWidth: 180 }}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectVal}
                          defaultValue={1}
                          onChange={handleSelectChange}
                        >
                          <MenuItem value={1}>Select All</MenuItem>
                          <MenuItem value={2}>Current Page</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box>
                      <Button
                        id="demo-customized-button"
                        aria-controls={open ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        open={open}
                        onClick={handleClick}
                      >
                        Export
                      </Button>
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => setAnchorEl(null)}
                      >
                        <MenuItem onClick={() => handleFileTypeDownload(1)} disabled={disable} disableRipple>
                          <Icon
                            icon="bxs:file-json"
                            style={{ color: '#323436db', fontSize: '23px', marginRight: '5px' }}
                          />
                          JSON
                        </MenuItem>
                        <Divider sx={{ my: 0.5 }} />
                        <MenuItem onClick={() => handleFileTypeDownload(2)} disabled={disable} disableRipple>
                          <Icon
                            icon="material-symbols:csv-outline"
                            style={{ color: '#323436db', fontSize: '23px', marginRight: '5px' }}
                          />
                          CSV
                        </MenuItem>
                        <Divider sx={{ my: 0.5 }} />

                        <MenuItem onClick={() => handleFileTypeDownload(3)} disabled={disable} disableRipple>
                          <Icon
                            icon="file-icons:microsoft-excel"
                            style={{ color: '#323436db', fontSize: '20px', marginRight: '5px' }}
                          />
                          EXCEL
                        </MenuItem>
                      </StyledMenu>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </form>
          </CardContent>
          {isNotFound && !loading && (
            <Typography padding={'50px'} textAlign={'center'} variant="h6" textTransform={'capitalize'}>
              data not found !
            </Typography>
          )}
          {loading && (
            <Typography textAlign="center" margin="20px">
              <CircularProgress />
            </Typography>
          )}

          <Stack
            padding={6}
            display="flex"
            flexDirection="row"
            justifyContent={'flex-start'}
            flexWrap={'wrap'}
            className="pd0_mrg0"
          >
            {shopReportList?.map((item, index) => (
              <>
                {!isNotFound && !loading && (
                  <Stack
                    key={index}
                    display="flex"
                    flexDirection="row"
                    justifyContent={'space-between'}
                    ml={5}
                    mr={5}
                    m={4}
                    className="pd0_mrg0"
                    sx={{ width: '600px' }}
                  >
                    <Grid container spacing={2} className="gridlayoutContainer">
                      <>
                        <Grid item xs={6}>
                          <Typography sx={{ fontWeight: 700 }} textAlign={'center'}>
                            Shop Name :-
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography textTransform={'capitalize'}>{item.name}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography sx={{ fontWeight: 700 }} textAlign={'center'}>
                            Shop Balance :-
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          {/* {balances(item.shop_balance)} */}
                          <Typography>{formatNumber(item.balance)} {showCurrency()}</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'center'}>
                          <Typography sx={{ fontWeight: 700 }}>Total No. Of Bets :-</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{fNumber(item.bet_count)}</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'center'}>
                          <Typography sx={{ fontWeight: 700 }}>Total Bets Stake Amount :-</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{formatNumber(item.stack_amount)} {showCurrency()}</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'center'}>
                          <Typography sx={{ fontWeight: 700 }}>Total No. Of Awarded Payouts :-</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{fNumber(item.win_count)}</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'center'}>
                          <Typography sx={{ fontWeight: 700 }}>Total Awarded Payouts :-</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{formatNumber(item.win_amount)} {showCurrency()}</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'center'}>
                          <Typography sx={{ fontWeight: 700 }}>Total Refunded Amount :-</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{formatNumber(item.refund_amount)} {showCurrency()}</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'center'}>
                          <Typography sx={{ fontWeight: 700 }}>Total Deposited Amount :-</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{formatNumber(item.deposited_amount)} {showCurrency()}</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'center'}>
                          <Typography sx={{ fontWeight: 700 }}>Total Withdrawn Amount :-</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{formatNumber(item.withdrawal_amount)} {showCurrency()}</Typography>
                        </Grid>
                        {item.signup_bonus_amount ? (
                          <>
                            {' '}
                            <Grid item xs={6} textAlign={'center'}>
                              <Typography sx={{ fontWeight: 700 }}>Total Awarded Signup Bonuses :-</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography>{formatNumber(item.signup_bonus_amount)} {showCurrency()}</Typography>
                            </Grid>
                          </>
                        ) : (
                          ''
                        )}
                        {item.deposit_bonus_amount ? (
                          <>
                            {' '}
                            <Grid item xs={6} textAlign={'center'}>
                              <Typography sx={{ fontWeight: 700 }}>Total Awarded Deposit Bonuses :-</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography>{formatNumber(item.deposit_bonus_amount)} {showCurrency()}</Typography>
                            </Grid>
                          </>
                        ) : (
                          ''
                        )}
                        {item.transferred_amount ? (
                          <>
                            {' '}
                            <Grid item xs={6} textAlign={'center'}>
                              <Typography sx={{ fontWeight: 700 }}>Total Transferred Amount :-</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography>{formatNumber(item.transferred_amount)} {showCurrency()}</Typography>
                            </Grid>
                          </>
                        ) : (
                          ''
                        )}
                        {item.received_amount ? (
                          <>
                            {' '}
                            <Grid item xs={6} textAlign={'center'}>
                              <Typography sx={{ fontWeight: 700 }}>Total Received Amount :-</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography>{formatNumber(item.received_amount)} {showCurrency()}</Typography>
                            </Grid>
                          </>
                        ) : (
                          ''
                        )}

                        {item?.cashiers?.map((cashier, index) => (
                          <>
                            <Grid item xs={6} textAlign={'center'}>
                              <Typography sx={{ fontWeight: 700 }}>
                                {`Cashier ${index + 1}'s  (${cashier.name}) Balance :-`}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography>{formatNumber(cashier.balance)} {showCurrency()}</Typography>
                            </Grid>
                          </>
                        ))}
                      </>
                    </Grid>
                  </Stack>
                )}
              </>
            ))}
          </Stack>
        </Card>
      </Container>
    </>
  );
};

export default ShopReport;
