import { Icon } from '@iconify/react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useContext, useEffect, useState } from 'react';
import config from '../config';
import { UserContext } from '../contexts/UserContext';
import { MY_GLOBAL_CONST } from '../utils/GlobalConstant';
import { formatDate, formatNumber, showCurrency } from '../utils/helper';

const BetSlip = ({ BetDetail, handleSetContentToShow, BetBarcode, autoPrint }) => {
  const REACT_APP_API_URL = config.apiUrl.slice(0, -4);
  const [disable, setDisable] = useState(false);
  const { systemSetting } = useContext(UserContext);
  const vatPercentage = Number(systemSetting['VAT (%)']);
  const incomeTaxThreshold = Number(systemSetting['Income Tax Threshold']);
  const incomeTaxPercentage = Number(systemSetting['Income Tax (%)']);
  const minimumBetAmount = Number(systemSetting['Minimum Bet Amount']);
  const maximumBetAmount = Number(systemSetting['Maximum Bet Amount']);
  const maximumWinAmount = Number(systemSetting['Maximum Win Amount']);
  const dailyMaxBetAmount = Number(systemSetting['Daily Maximum Bet Amount']);
  const weeklyMaxBetAmount = Number(systemSetting['Weekly Maximum Bet Amount']);
  const monthlyMaxBetAmount = Number(systemSetting['Monthly Maximum Bet Amount']);

  const style = {
    width: '100%',
  };

  const tableStyle = {
    border: 0,
    paddingTop: '2px',
    paddingBottom: '2px',
  };
  const getSelectedOdd = (fixture) => {
    const oddData = Object.prototype.hasOwnProperty.call(fixture, 'Market')
      ? fixture.Market.Odds[0]
      : fixture.Markets[0].Odds[0];
    if (oddData.name === '1') {
      return (
        <>
          <Grid item flexGrow="1" md={5}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              {fixture.Participants_home.name}
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              {formatNumber(oddData.price)}
            </Typography>
          </Grid>
        </>
      );
    }
    if (oddData.name === 'X') {
      return (
        <>
          <Grid item flexGrow="1" md={5}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              Draw
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              {formatNumber(oddData.price)}
            </Typography>
          </Grid>
        </>
      );
    }
    if (oddData.name === '2') {
      return (
        <>
          <Grid item flexGrow="1" md={5}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              {fixture.Participants_away.name}
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              {formatNumber(oddData.price)}
            </Typography>
          </Grid>
        </>
      );
    }
    if (oddData.name === '1X') {
      return (
        <>
          <Grid item flexGrow="1" md={5}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              {fixture.Participants_home.name} or Draw
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              {formatNumber(oddData.price)}
            </Typography>
          </Grid>
        </>
      );
    }
    if (oddData.name === 'X2') {
      return (
        <>
          <Grid item flexGrow="1" md={5}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              Draw or {fixture.Participants_away.name}
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              {formatNumber(oddData.price)}
            </Typography>
          </Grid>
        </>
      );
    }
    if (oddData.name === '12') {
      return (
        <>
          <Grid item flexGrow="1" md={5}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              No Draw
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body2" fontWeight="bold" mt="20px">
              {formatNumber(oddData.price)}
            </Typography>
          </Grid>
        </>
      );
    }
    return (
      <>
        <Grid item flexGrow="1" md={5}>
          <Typography variant="body2" fontWeight="bold" mt="20px">
            {oddData.name} {oddData.line !== '' ? oddData.line : ''}
          </Typography>
        </Grid>
        <Grid item md={1}>
          <Typography variant="body2" fontWeight="bold" mt="20px">
            {formatNumber(oddData.price)}
          </Typography>
        </Grid>
      </>
    );
  };

  useEffect(() => {
    if (autoPrint === true) handlePrint();
  }, [BetDetail, BetBarcode, autoPrint]);

  const handlePrint = () => {
    setDisable(true);
    const content = document.getElementById('betslipprint');
    const pri = document.getElementById('ifmcontentstoprint').contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    setTimeout(() => {
      pri.document.close();
      pri.focus();
      pri.print();
    }, 250);
    setDisable(false);
  };

  const actualwining = parseFloat(BetDetail.win_amount) + parseFloat(BetDetail.tax_amount);
  return (
    <Card>
      <CardContent>
        <Paper className="" sx={style}>
          <div className="modalContentHolder">
            <div className="modalContentTitle">
              <Box
                maxWidth="md"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  sx={{ color: 'black', height: '30px' }}
                  startIcon={<ArrowBackIosIcon />}
                  onClick={() => handleSetContentToShow('BetSection')}
                  variant="text"
                  type="submit"
                >
                  Go Back
                </Button>
                <Box
                  sx={{
                    width: '285px',
                    height: '76px',
                  }}
                  className="ModalLogo"
                  mb={2}
                >
                  <img src={`/assets/${MY_GLOBAL_CONST.top_Header_main_logo}`} alt="logo" />
                </Box>
                <Button
                  sx={{ color: 'white', height: '30px' }}
                  onClick={() => handlePrint()}
                  variant="contained"
                  startIcon={<Icon icon="material-symbols:print-outline" />}
                  disableElevation
                  disabled={disable}
                >
                  Print
                </Button>
              </Box>
            </div>
            <Divider />
            <Typography variant="h5" textAlign="center" sx={{ fontWeight: 'bold', mt: 2 }}>
              Bet Ticket
            </Typography>

            <Stack sx={{ py: 2, px: 1 }} direction="row" alignItems="center" justifyContent="space-between">
              <Typography fontWeight="bold" variant="body1">
                Date
              </Typography>
              <Typography fontWeight="bold" variant="body1">
                {formatDate(BetDetail.created_at, 'ticketpreview')}
              </Typography>
            </Stack>
            <Divider />
            <Stack sx={{ py: 1, px: 1 }} direction="row" alignItems="center" justifyContent="space-between">
              <Typography fontWeight="bold" variant="body1">
                Bet ID
              </Typography>
              <Typography fontWeight="bold" variant="body1">
                {BetDetail.pbet_uid}
              </Typography>
            </Stack>
            {/* <Stack sx={{ px: 1, mb: 1 }} direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="body1">Player</Typography>
              <Typography variant="body1" mb={2}>
                {BetDetail.user_username}
              </Typography>
            </Stack> */}
            <Box display="flex" alignItems="center" pt="2px" mx={1} pr={2} bgcolor={grey[200]} pb="2px" mb={2}>
              <Typography variant="h6" component="h6" color="initial" flexGrow="2" textAlign="center">
                AKO
              </Typography>
              <Typography variant="body1" fontWeight="700" component="h4" color="initial">
                Total Odds
              </Typography>
            </Box>
            {BetDetail.Fixtures.map((item) => (
              <>
                <Box sx={{ marginLeft: '10px', marginRight: '8px' }}>
                  <Typography
                    variant="body2"
                    // pl="16px"
                    // pt="8px"
                    mb={1}
                    sx={{ textTransform: 'uppercase', fontWeight: '500', fontSize: '13px' }}
                  >
                    {item.sport_name} - {item.location_name} - {item.league_name}
                  </Typography>
                </Box>
                <Box pt="0px" pr="16px"  pb="2px" sx={{ marginLeft: '10px', marginRight: '8px' }}>
                  <Grid container spacing={2} alignItems="flex-start" wrap="no-wrap">
                    <Grid item className="prntbetTkt" md={1}>
                      <Typography variant="body2" fontWeight="700" mb={1}>
                        {item.id}
                      </Typography>
                    </Grid>
                    <Grid item flexGrow="2" md={5}>
                      <Typography variant="body2" sx={{ fontWeight: '600', textTransform: 'uppercase' }}>
                        {item.Participants_home.name} - {item.Participants_away.name}
                      </Typography>
                      <Typography variant="body1" fontSize="13px">
                        {Object.prototype.hasOwnProperty.call(item, 'Market')
                          ? item.Market.name
                          : item.Markets[0].name === '1X2'
                          ? '1X2 (Match Results)'
                          : item.Markets[0].name === '12'
                          ? '12 (Match Results)'
                          : item.Markets[0].name}
                      </Typography>
                      <Box display="flex">
                        <Typography fontSize="13px">Start at ,</Typography>
                        <Typography fontSize="13px" ml="10px" fontWeight="500">
                          {formatDate(item.start_date, 'ticketpreview')}
                        </Typography>
                      </Box>
                    </Grid>
                    {getSelectedOdd(item)}
                  </Grid>
                </Box>
                <Divider sx={{ my: 2, marginLeft: '10px', marginRight: '8px' }} />
              </>
            ))}

            <Box margin={5} mt="5px" className="prntMrg">
              <TableContainer container component="Box" margin={2}>
                <Table maxWidth>
                  <TableHead>
                    <TableRow>
                      <TableCell style={tableStyle}>
                        <Typography>Deposit</Typography>{' '}
                      </TableCell>
                      <TableCell style={tableStyle} align="right">
                        <Typography>{formatNumber(BetDetail.stake_amount)} {showCurrency()} </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody fullWidth>
                    <TableRow fullWidth>
                      <TableCell style={tableStyle}>
                        <Typography variant="body2">VAT {vatPercentage}%</Typography>
                      </TableCell>
                      <TableCell style={tableStyle} align="right">
                        <Typography variant="body2"> {formatNumber(BetDetail.vat_amount)} {showCurrency()} </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={tableStyle}>
                        <Typography variant="body2">Placed Bet</Typography>
                      </TableCell>
                      <TableCell style={tableStyle} align="right">
                        <Typography variant="body2"> {formatNumber(BetDetail.total_amount)} {showCurrency()} </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={tableStyle}>
                        <Typography variant="body2" fontWeight="bold">
                          {' '}
                          Total Odds
                        </Typography>
                      </TableCell>
                      <TableCell style={tableStyle} align="right">
                        <Typography variant="body2" fontWeight="bold">
                          {formatNumber(BetDetail.total_odds)}
                        </Typography>{' '}
                      </TableCell>
                    </TableRow>

                    {BetDetail.tax_amount > 0 ? (
                      <>
                        <TableRow>
                          <TableCell style={tableStyle}>
                            <Typography variant="body2"> Win </Typography>
                          </TableCell>
                          <TableCell style={tableStyle} align="right">
                            <Typography variant="body2">{formatNumber(actualwining)} {showCurrency()}</Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={tableStyle}>
                            <Typography variant="body2"> - {incomeTaxPercentage}% Income Tax </Typography>
                          </TableCell>
                          <TableCell style={tableStyle} align="right">
                            <Typography variant="body2"> {formatNumber(BetDetail.tax_amount)} {showCurrency()} </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={tableStyle}>
                            <Typography fontWeight="bold" variant="body2">
                              Net Win / Payout
                            </Typography>
                          </TableCell>
                          <TableCell style={tableStyle} align="right">
                            <Typography fontWeight="bold" variant="body2">
                              {formatNumber(BetDetail.win_amount)} {showCurrency()}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <TableRow>
                        <TableCell style={tableStyle}>
                          <Typography fontWeight="bold" variant="body2">
                            Win
                          </Typography>
                        </TableCell>
                        <TableCell style={tableStyle} align="right">
                          <Typography fontWeight="bold" variant="body2">
                            {formatNumber(BetDetail.win_amount)} {showCurrency()}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Stack>
              <Stack alignItems="center">
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Scan QR Code
                </Typography>
                <img crossOrigin="anonymous" src={`${REACT_APP_API_URL}${BetBarcode}`} alt="qrCode" />
              </Stack>
            </Stack>
            <Box
              sx={{
                color: '#fff',
                backgroundColor: '#2065D1',
                textTransform: 'lowercase',
                display: 'flex',
                borderRadius: '8px',
                padding: '7px',
                justifyContent: 'center',
                fontWeight: '550',
              }}
            >
              <Typography variant="p"> {MY_GLOBAL_CONST.website_url} </Typography>
            </Box>
          </div>
        </Paper>
      </CardContent>
    </Card>
  );
};
export default BetSlip;
