import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

const RoleDialog = ({ setDialog, open, handleDeleteRole }) => {
  const handleClose = (confirm) => {
    setDialog(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure you want to delete this role?'}</DialogTitle>
        <DialogActions>
          <Button
            onClick={(e) => {
              // handleClose(false);
              setDialog(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={(e) => {
              // handleClose(true);
              handleDeleteRole(true);
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RoleDialog;
