import { useEffect } from 'react';

const Timer = (props) => {
  // const [seconds, setSeconds] = useState(5 * 60); // 5 minutes in seconds
  useEffect(() => {
    let intervalId;

    if (props.seconds > 0) {
      intervalId = setInterval(() => {
        props.setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [props.seconds]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return <>{formatTime(props.seconds)}</>;
};

export default Timer;
