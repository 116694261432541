import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { UserContext } from '../../contexts/UserContext';
import { fetchDataWithAuth, postData } from '../../utils/api';
import { ToastError, ToastSuccess, TransformMenuArray, generateHash, getTimeStamp } from '../../utils/helper';

const schema = yup.object().shape({
  role_id: yup.number().required('Role ID is required'),
});

const RolePermissions = ({ action }) => {
  const Redirect = useNavigate();
  const params = useParams();
  const [roles, setRoles] = useState([]);
  const [access, setAccess] = useState([]);
  const [menus, setMenus] = useState([]);
  const { user } = useContext(UserContext);
  const [selectValue, setSelectValue] = useState(null);
  const [disable, setDisable] = useState(false);
  const [loading,setLoading] = useState(true)
  const getRoles = async () => {
    const data = {
      user_token: user.token,
      data: {
        status: '',
        name: '',
      },
    };
    const response = await postData(`/admin/role/list`, data);
    setRoles(response.data);
  };
  const getMenus = async () => {
    const data = {
      user_token: user.token,
      data: {
        name: '',
        slug: '',
      },
    };
    const response = await postData(`/admin/menu/list`, data);
     setLoading(false)
    setMenus(TransformMenuArray(response.data));
  };

  const getPermission = async () => {
    if (params.id) {
      const data = {
        user_token: user.token,
        data: {},
      };
      const response = await fetchDataWithAuth(`/admin/permission/get/${params.id}`, data);
      const permissionData = response.data[0];
      setValue('role_id', permissionData.role_id);
      setSelectValue(permissionData.role_id);
      setAccess(permissionData.access);
    }
  };
  useEffect(() => {
    getRoles();
    getMenus();
    getPermission();
  }, [params]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      permissions: [{ access: '' }],
    },
  });

  const onSubmit = async (permissionData) => {
    // Perform form submission logic here
    toast.dismiss();
    setDisable(true)
    let data = null;
    let PermissionData = null;
    setLoading(true)
    try {
      if (params.id) {
        data = {
          user_token: user.token,
          data: {
            id: params.id,
            date_time: getTimeStamp(),
            role_id: permissionData.role_id,
            access,
          },
        };
        const hashSignature = generateHash(data.data);
        data.data.hash = hashSignature;
        PermissionData = await postData('/admin/permission/update', data);
      } else {
        data = {
          user_token: user.token,
          data: {
            date_time: getTimeStamp(),
            role_id: permissionData.role_id,
            access,
          },
        };
        const hashSignature = generateHash(data.data);
        data.data.hash = hashSignature;
        PermissionData = await postData('/admin/permission/add', data);
      }
      ToastSuccess(PermissionData.message);
      setTimeout(() => {
        toast.dismiss();
        Redirect('/admin/permission');
      }, 800);
    } catch (error) {
      console.log('error2 :>> ', error);
      ToastError(error.response.data.message);
    } finally{
      setDisable(false)
      setLoading(false)
    }
  };

  const handleCheckboxChange = (menuId, actionIndex, checked) => {
    if (checked) {
      // Add the action to the access state
      setAccess((prevAccess) => {
        const updatedAccess = [...prevAccess];
        const menu = updatedAccess.find((item) => item.id === menuId);
        if (menu) {
          menu.action.push({ name: menus.find((item) => item.id === menuId).action[actionIndex].name });
        } else {
          updatedAccess.push({
            id: menuId,
            name: menus.find((item) => item.id === menuId).name,
            slug: menus.find((item) => item.id === menuId).slug,
            icon: menus.find((item) => item.id === menuId).icon,
            action: [{ name: menus.find((item) => item.id === menuId).action[actionIndex].name }],
          });
        }
        return updatedAccess;
      });
    } else {
      // Remove the action from the access state
      setAccess((prevAccess) => {
        const updatedAccess = [...prevAccess];
        const menu = updatedAccess.find((item) => item.id === menuId);
        if (menu) {
          menu.action = menu.action.filter(
            (action) => action.name !== menus.find((item) => item.id === menuId).action[actionIndex].name
          );
          if (menu.action.length === 0) {
            // Remove the menu if it has no action
            const menuIndex = updatedAccess.findIndex((item) => item.id === menuId);
            updatedAccess.splice(menuIndex, 1);
          }
        }
        return updatedAccess;
      });
    }
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          {action} Role Permissions
        </Typography>
      </Stack>
      <Card>
        <CardContent>
         {loading ?  
         <Stack display="flex" flexDirection="row" justifyContent="center">
         <CircularProgress size={35} />
       </Stack>
         : <form onSubmit={handleSubmit(onSubmit)} className="formRolePermision">
            <Stack direction="row">
              <FormControl fullWidth className="slectInput">
                <InputLabel id="role-select-label">{!params.id ? 'Role' : ''}</InputLabel>
                <Select
                  value={selectValue}
                  id="status"
                  name="status"
                  labelId="role-select-label"
                  {...register('role_id')}
                  onChange={(event) => setSelectValue(event.target.value)}
                  disabled={action === 'Edit'}
                >
                  {roles.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="body2" color="error">
                  {errors.role_id?.message}
                </Typography>
              </FormControl>
            </Stack>
            <Box display="flex">
              <Grid container>
                {menus.length &&
                  menus.map((menu, menuIndex) => (
                    <Grid
                      sx={{ backgroundColor: 'grey.200', mt: 1, borderRadius: 1, padding: 2 }}
                      item
                      xs={12}
                      md={12}
                      key={menuIndex}
                    >
                      <List disablePadding>
                        <ListItem disablePadding>
                          <ListItemText className="rolePermissionText" primary={menu.name} />
                        </ListItem>
                        <List disablePadding sx={{ display: 'flex' }}>
                          {menu.action.map((action, index) => (
                            <ListItem key={index} disablePadding>
                              <ListItemIcon>
                                <Checkbox
                                  checked={access?.some(
                                    (item) => item.id === menu.id && item.action?.some((a) => a.name === action.name)
                                  )}
                                  onChange={(e) => handleCheckboxChange(menu.id, index, e.target.checked)}
                                />
                              </ListItemIcon>
                              <ListItemText primary={action.name} />
                            </ListItem>
                          ))}
                        </List>
                      </List>
                    </Grid>
                  ))}
              </Grid>
            </Box>
            <Box padding={2}>
              <Button type="submit" variant="contained" color="primary" disabled={disable} >
                {action} Role Permission
              </Button>
            </Box>
          </form>}
        </CardContent>
      </Card>
    </Container>
  );
};

export default RolePermissions;
