import { css } from '@emotion/react';
import {
  Card,
  CardContent,
  CircularProgress,
  Container,
  Stack,
  Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import { useContext, useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { UserContext } from '../../contexts/UserContext';
import { fetchDataWithAuth, postData } from '../../utils/api';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import DragAndDropArray from './Drag_Drop';

const FeedManagement = () => {
  // const [value, setValue] = useState(1);
  // const [type, setType] = useState('');
  const { user ,storeMenuItems,itemsMenu} = useContext(UserContext);
  // const { register, handleSubmit,reset} = useForm({
  //   MainMenu: '',
  // });

  const DragDropStyle = {
    justifyContent: 'space-between',
    '& .MuiBox-root': css({
      padding: 1,
      border: '1px solid #f3f1f1',
      margin: '4px',
      paddingLeft: '10px',
      paddingRight: '10px',
      minWidth: '136px',
      textAlign: 'center',
      color: '#cdc7c7',
      cursor: 'pointer',
      marginBottom: '20px'
    })
  };

  // drag and drop
  const [MenuArray, setMenuArray] = useState([])
  const [items, setItems] = useState([]);
  const [DisableBtn, setDisableBtn] = useState(false);
  
  //  sport Hide Show Manage State
  // const [data, setData] = useState(SportsData)
  // const [expandSports, setExpandSports] = useState(null);
  // const [sportsSwitchBtn, setSportsSwitchBtn] = useState({});
  
  //  Location hide Show Manage State
  // const [expandlocations, setExpandLocations] = useState(null);
  // const [locationsSwitchBtn, setLocationsSwitchBtn] = useState({});

  //  League hide Show Manage State
  // const [expandleagues, setExpandLeagues] = useState(null);
  // const [leaguesSwitchBtn, setLeaguesSwitchBtn] = useState({});

  const ActiveMenu = MenuArray.filter(item => item.is_visible).map((item)=>item.name);
  const [selectedOptions, setselectedOptions] = useState(ActiveMenu);
  let MenuCheck =[];
   MenuCheck = selectedOptions.length  === 0 ? ActiveMenu : selectedOptions

  // const [inLiveTab,setInLiveTab] = useState({
  //   playing_now : false,
  //   schedule :false
  // })
  // const [virtualCheckBox,setVirtualCheckBox] =useState(false)
  // const [slotGameId,setSlotGameId] =useState([])
  // const [aviatorCheckBox,setAviatorCheckBox] =useState(false)
 
  // Fetch menuList  from the API 
  useEffect(() => {
    getMenuList();
  }, [setMenuArray]);
  
  const getMenuList = async () => {
    setDisableBtn(true)
    try {
    const data = {
      user_token: user.token,
      data: {
        filter_name:'',
        filter_value:''
      },
    };
    const response = await fetchDataWithAuth(`/admin/nav-menu/list`, data);
    const MenuarrayList = await response.data;
    const localstorageMenu = JSON.parse(localStorage.getItem('MenuItems'))
    if(localstorageMenu === null){
      const filteredMenu = selectedOptions.filter(item => MenuCheck.includes(item));
      const updatedMenuArray = MenuarrayList.map(item => {
        const visibility = MenuCheck.includes(item.name);
        const newPosition = visibility ? filteredMenu.indexOf(item.name) + 1 : 0;
        return { ...item, position: newPosition, is_visible: visibility };
      });
      const setMenu = updatedMenuArray?.map((items)=>items.name)
       setItems(setMenu)
    }else{
      setItems(localstorageMenu);
    }
    setMenuArray(MenuarrayList)
  } catch (error){
    console.log('nav-menu-list error :>> ', error);
  }
  finally{
    setDisableBtn(false)
  }
};

 // onclick select menu
const onSelect = (menu) => {
  const isSelected = MenuCheck.includes(menu);
  if (isSelected) {
    setselectedOptions(MenuCheck.filter((item) => item !== menu));
  } else {
    setselectedOptions([...MenuCheck, menu]);
  }
}

 // update Menu
 const updateMenu = async (menuArray,updateItemsmenu) => {
  setDisableBtn(true)
  try {
    const data = {
      user_token: user.token,
      data: {menu:menuArray},
    };
    const response = await postData(`/admin/nav-menu/update`, data);
    localStorage.setItem('MenuItems', JSON.stringify(updateItemsmenu));
  } catch (error) {
    console.log('error :>> ', error);
  } 
  finally{
    setDisableBtn(false)
  }
};

// apply sumbit menu
const onSubmitMenu = async () => {
  const filteredMenu = items.filter(item => MenuCheck.includes(item));
  const updatedMenuArray = MenuArray.map(item => {
  const visibility = MenuCheck.includes(item.name);
  const newPosition = visibility ? filteredMenu.indexOf(item.name) + 1 : 0;
  return { ...item, position: newPosition, is_visible: visibility };
});
  await updateMenu(updatedMenuArray,items)
  await getMenuList(items)
};
  // const Type = [
  //   {
  //     id: 1,
  //     label: 'Fixture',
  //   },
  //   {
  //     id: 2,
  //     label: 'Loaction',
  //   },
  //   {
  //     id: 3,
  //     label: 'League',
  //   },
  // ];
  // handle filter type Change
  // const onchangeType = (event) => {
  //   setType(event.target.value);
  // };
  
   //  handle Tab Change 
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

    // Drag and Drop Menu Start
    // const onSelect = (menu) => {
    //   const isSelected = MenuCheck.includes(menu);
    //   if (isSelected) {
    //     setselectedOptions(MenuCheck.filter((item) => item !== menu));
    //   } else {
    //     if (selectedOptions.length === 0) {
    //       MenuCheck.push(menu);
    //   } else {
    //       setselectedOptions([...selectedOptions, menu]);
    //   }
    //   }
     
    //   // setItems(menu)
    // }
   
  
    // const onFilter = (filteredData) => {
    //   console.log('filteredData', filteredData);
    // }
    // const tabs =  MenuArray
    // .filter(item => item.is_visible) // Filter out items that are not visible
    // .sort((a, b) => a.position - b.position) // Sort items based on position
    // .map(item =>  {
    //   if(!item.is_visible){
    //     return null
    //   }
    //   return (
    //     <Tab
    //       key={item.id}
    //       icon={<Icon icon={getIconName(item.name)} fontSize="24" />}
    //       iconPosition="start"
    //       label={item.name}
    //       value={item.position}
    //     />
    //   );
    // },[MenuArray]);

    // const getPosition = (menuName) => {
    //   const pos = MenuArray.find((route) => route.name === menuName && route.is_visible === true)?.position;
    //   return pos;
    // };
  
    // Drag and Drop Menu End


   // handle Sports Switch Button  Change
  // const handleChangeSportsSwitch = (event, newValue, sports) => {
  //   console.log("sports =>",sports);
  //   const updatedSportsExpanded = { ...sportsSwitchBtn };
  //   updatedSportsExpanded[sports.id] = newValue;
  //   if (newValue)setExpandSports(null);
  //   setSportsSwitchBtn(updatedSportsExpanded);
  // };
  
  // handle Locations Switch Button  Change
  // const handleChangeLocationSwitch = (event, newValue, locations) => {
  //   console.log("locations =>",locations);
  //   const updatedLocationExpanded = { ...locationsSwitchBtn };
  //   updatedLocationExpanded[locations.id] = newValue;
  //   if (newValue)setExpandLocations(null);
  //   setLocationsSwitchBtn(updatedLocationExpanded);
  // };

  // handle Leagues Switch Button  Change
  // const handleChangeLeagueSwitch = (event, newValue, league) => {
  //   console.log("league =>",league);
  //   const updatedLeagueExpanded = { ...leaguesSwitchBtn };
  //   updatedLeagueExpanded[league.id] = newValue;
  //   if (newValue)setExpandLeagues(null);
  //   setLeaguesSwitchBtn(updatedLeagueExpanded);
  // };

  
  

// handle Change Sports Accordian
// const handleChangeSportsAccordion = (sports, index) => {
//   if (expandSports !== `panel_${index}`) {
//     // setSportsSwitchBtn({ [sports.id]: true });
//     setExpandSports(`panel_${index}`);
//   } else {
//     // setSportsSwitchBtn({})
//     setExpandSports(null);
//   }
// };

 // handle Change Locations Accordian
  // const handleChangelocationAccordian = (locations, index) => {
  //   if (expandlocations !== `panel_${index}`) {
  //     setExpandLocations(`panel_${index}`);
  //   } else {
  //     setExpandLocations(null);
  //   }
  // };

  // handle Change Leagues Accordian
  // const handleChangeleaguesAccordian = (leagues, index) => {
  //   if (expandleagues !== `panel_${index}`) {
  //     setExpandLeagues(`panel_${index}`);
  //   } else {
  //     setExpandLeagues(null);
  //   }
  // };

// const handleChangeFixture = (newValue,fixture, index) =>{
//    console.log('newValue',newValue);
//    console.log('fixture',fixture);
//    console.log('index',index);
// }

 
   // live tab inside section start
  // const handleChangelive = (event) => {
  //   setInLiveTab({
  //     ...inLiveTab,
  //     [event.target.name]: event.target.checked,
  //   });
  // };

  // const handleLiveSubmit = () =>{
  //   console.log("submit live",inLiveTab)
  // }
  // live tab inside section end

  // virtuals tab inside section start
// const handleVirtualCheckChange = (event) =>{
//   setVirtualCheckBox(event.target.checked);
// }
// const handlevirtualsSubmit = () =>{
//  console.log("virtuals check box",virtualCheckBox);
// }
  // virtuals tab inside section end

  // slot tab inside section start
// const handleSlotCheckChange = (id) =>{
//   if (slotGameId.includes(id)) {
//     setSlotGameId(slotGameId.filter((checkedId) => checkedId !== id));
//   } else {
//     setSlotGameId([...slotGameId, id]);
//   }
  
// }
// const handleSlotSubmit = () =>{
//  console.log("Slot Id",slotGameId);
// }
  // slot tab inside section end
  

  // Aviator tab inside section start
// const handleAviatorCheckChange = (event) =>{
//   setAviatorCheckBox(event.target.checked);
// }
// const handleAviatorSubmit = () =>{
//  console.log("Aviator check box",aviatorCheckBox);
// }
  // aviator tab inside section end
  return (
    <>
      <Helmet>
        <title> Feed Management | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>
      <Container maxWidth="xl" className="dashboardPage cardDesign">
        <Typography variant="h4" sx={{ mb: 3 }}>
          Feed Management
        </Typography>

        {/* <Stack
          display="flex"
          flexDirection="row"
          justifyContent={'flex-end'}
          mb={2}
          className="clearBtnStck filterBorderClass"
          component={'form'}
          onSubmit={handleSubmit(onFilter)}
        >
          <Stack flexDirection="row" sx={{ marginRight: 'auto', '& .MuiBox-root': { margin: '8px' } }}>
            <Box>
              <TextField
                {...register('search')}
                placeholder="Search..."
                id="outlined-basic"
                label="Search"
                variant="outlined"
              />
            </Box>
            <Box>
              <FormControl sx={{ minWidth: 150 }} fullWidth className="selectInput">
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  name="type"
                  {...register('type')}
                  // name="bet_place_type"
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={type}
                  onChange={onchangeType}
                >
                  {Type.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Stack>

          <Stack display="flex" flexDirection="row" className="mrgTop">
            <Box margin={1} className="pd0_mrg0Right mrgBtm_0">
              <Button
                onClick={() => {
                  reset();
                  setType('');
                }}
                sx={{ padding: '27px' }}
                color="secondary"
                variant="outlined"
                size="large"
                disableElevation
              >
                Clear
              </Button>
            </Box>
            <Box margin={1} className="pd0_mrg0Right mrgBtm_0">
              <Button
                type="submit"
                color="secondary"
                sx={{ padding: '27px' }}
                variant="contained"
                size="large"
                disableElevation
              >
                Search
              </Button>
            </Box>
          </Stack>
        </Stack> */}

        <Card>
          <CardContent>
            {!DisableBtn && (
              <Stack>
                <Typography variant="h4" ml={1.5}>
                  Menu{' '}
                </Typography>
                <Stack className="tabFilters" flexDirection="row" sx={DragDropStyle}>
                  <DragAndDropArray
                    selectedOptions={selectedOptions}
                    onSelect={onSelect}
                    items={items}
                    MenuArray={MenuArray}
                    storeMenuItems={storeMenuItems}
                    itemsMenu={itemsMenu}
                    setItems={setItems}
                  />
                  <Button
                    type="submit"
                    color="secondary"
                    sx={{ padding: '27px' }}
                    variant="contained"
                    size="large"
                    disableElevation
                    disabled={DisableBtn}
                    onClick={onSubmitMenu}
                  >
                    Apply Menu
                  </Button>
                </Stack>

                {/* tabs context */}
                {/* <TabContext
                sx={{ padding: '0', margin: '0', '& .MuiTabs-root': {} }}
                value={value}
                className="feedManagementTab"
              >
                <TabList
                  sx={{
                    '& .MuiTab-root': { fontSize: '18px', fontWeight: '400', marginRight: '20px', minHeight: '50px' },
                    '& :hover': {},
                  }}
                  onChange={handleChange}
                  className="tabs-sub"
                >
                  {tabs}
                </TabList>
                <TabPanel
                  sx={{
                    paddingLeft: '0',
                    paddingRight: '0',
                    '& .MuiTypography-root': {
                      fontSize: '25px',
                      fontWeight: 500,
                    },
                  }}
                  className="panels"
                  value={getPosition('sportsbook')}
                >
                  <Box
                    className="accordianHolder"
                    sx={{
                      '.MuiTabPanel-root': { paddingLeft: '0', paddingRight: '0' },
                      '& .MuiBox-root': { color: '#000' },
                      '& .MuiAccordionSummary-content': { marginTop: '5px', marginBottom: '5px' },
                    }}
                  >
                    {data[0].sports.map((sports, index) => (
                      <Accordion
                        key={sports.id}
                        id={sports.id}
                        expanded={expandSports === `panel_${index}`}
                      >
                        <AccordionSummary
                          className="accord_level_1"
                          expandIcon={<ExpandMoreIcon onClick={() => handleChangeSportsAccordion(sports, index)} /> }
                          aria-controls={`panel_${index}-content`}
                          id={`panel_${index}-header`}
                        >
                          <Stack flexDirection="row" alignItems="center">
                            <Box className="switch_arroc">
                              <Switch
                                color='success'
                                onChange={(event, newValue) => handleChangeSportsSwitch(event, newValue, sports)}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />{' '}
                            </Box>
                            <Box>{sports.name}</Box>
                          </Stack>
                        </AccordionSummary>
                        { expandSports === `panel_${index}` && (
                          <AccordionDetails>
                           { data[0].locations.map((locations, index) => (
                             <Accordion 
                             key={locations.id}
                             id={locations.id}
                             expanded={expandlocations === `panel_${index}`}
                             >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon  onClick={() => handleChangelocationAccordian(locations, index)}/>}
                                aria-controls="panel1-content"
                                id="panel1-header"
                              >
                                <Stack flexDirection="row" alignItems="center">
                                  <Box>
                                    <Switch
                                      color='success'
                                      onChange={(event, newValue) => handleChangeLocationSwitch(event, newValue, locations)}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                    />{' '}
                                  </Box>
                                  <Box>{locations.name}</Box>
                                </Stack>
                              </AccordionSummary>
                              {expandlocations === `panel_${index}` &&
                              <AccordionDetails>
                                {data[0].leagues.map((leagues, index) => (
                                <Accordion
                                key={leagues.id}
                                id={leagues.id}
                                expanded={expandleagues === `panel_${index}`}
                                >
                                  <AccordionSummary   
                                    expandIcon={<ExpandMoreIcon onClick={() => handleChangeleaguesAccordian(leagues, index)} />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                  >
                                    <Stack flexDirection="row" alignItems="center">
                                      <Box>
                                        {' '}
                                        <Switch
                                          color='success'
                                          onChange={(event, newValue) => handleChangeLeagueSwitch(event, newValue, leagues)}
                                          inputProps={{ 'aria-label': 'controlled' }}
                                        />{' '}
                                      </Box>
                                      <Box>{leagues.name}</Box>
                                    </Stack>
                                  </AccordionSummary>
                                 {expandleagues === `panel_${index}` && <AccordionDetails>
                                    <TableContainer className="feedTable">
                                      <Table aria-label="simple table">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>Start Date</TableCell>
                                            <TableCell>ID</TableCell>
                                            <TableCell>Fixtures</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                         {data[0].markets.map((fixture, index) => (
                                          <TableRow>
                                            <TableCell>Mar 19, 19:00</TableCell>
                                            <TableCell>{fixture.id}</TableCell>
                                            <TableCell>
                                              <Stack flexDirection="row" alignItems="center">
                                                <Box> {fixture.name}</Box>
                                                <Box sx={{ marginLeft: 'auto' }}>
                                                  {' '}
                                                  <Switch
                                                    color='success'
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    onChange={(event,newValue)=>handleChangeFixture(newValue,fixture, index)}
                                                  />
                                                </Box>
                                              </Stack>
                                            </TableCell>
                                          </TableRow>))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </AccordionDetails>}
                                </Accordion>))}
                              </AccordionDetails>}
                            </Accordion>))}
                          </AccordionDetails>
                        )}
                      </Accordion>
                    ))}
                  </Box>
                </TabPanel>
                <TabPanel
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: '20px',
                      fontWeight: 700,
                    },
                  }}
                  className="panels"
                  value={getPosition('live')}
                >
                  <Stack className="tech-name-box">
                    <Box  className="tech-detail-box">
                      <Box display='flex' className="symbol-box" mr={5}>
                      <Checkbox sx={{ fontSize: 28 }} checked={inLiveTab.playing_now} onChange={handleChangelive} name="playing_now" />
                      <Typography mt={0.5} color={inLiveTab.playing_now ? '#757575' : '#212B36'}>Playing now</Typography>
                      </Box>
                    </Box>
                    <Box display='flex' className="tech-detail-box" >
                      <Box className="symbol-box">
                      <Checkbox  sx={{ fontSize: 28 }} checked={inLiveTab.schedule} onChange={handleChangelive} name="schedule" />
                      </Box>
                      <Typography mt={0.5} color={inLiveTab.schedule ? '#757575' : '#212B36'}>Schedule</Typography>
                    </Box>
                    <Box margin={1} className="pd0_mrg0Right mrgBtm_0">
                       <Button
                         type="submit"
                         color="secondary"
                         variant="contained"
                         size="medium"
                         disableElevation
                         onClick={handleLiveSubmit}
                       >
                         Submit
                       </Button>
                </Box>
                  </Stack>
                 
                </TabPanel>
                <TabPanel
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: '20px',
                      fontWeight: 700,
                    },
                  }}
                  className="panels"
                  value={getPosition('virtuals')}
                >
                  <Stack   className="tech-name-box">
                  <Box display='flex' className="tech-detail-box" >
                      <Box className="symbol-box">
                      <Checkbox
                        checked={virtualCheckBox}
                        onChange={handleVirtualCheckChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      </Box>
                      <Typography mt={0.7} color={virtualCheckBox ? '#757575' : '#212B36'}>Login To Play</Typography>
                    </Box>
                    <Box margin={1} className="pd0_mrg0Right mrgBtm_0">
                       <Button
                         type="submit"
                         color="secondary"
                         variant="contained"
                         size="medium"
                         disableElevation
                         onClick={handlevirtualsSubmit}
                       >
                         Submit
                       </Button>
                    </Box>
                  </Stack>
                </TabPanel>
                <TabPanel
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: '20px',
                      fontWeight: 700,
                    },
                  }}
                  className="panels"
                  value={getPosition('slot')}
                >
                  <Stack direction="column" justifyContent="space-between" className="tech-name-box">
                { slotGameList.map((item)=>
                 <Box display='flex' className="tech-detail-box" >
                      <Box className="symbol-box">
                      <Checkbox
                        checked={slotGameId.includes(item.id)}
                        onChange={()=>handleSlotCheckChange(item.id)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      </Box>
                      <Typography mt={0.5} color={slotGameId.includes(item.id) ? '#757575' : '#212B36'}>{item.gameName}</Typography>
                    </Box>)}
                    <Box margin={1} className="pd0_mrg0Right mrgBtm_0">
                       <Button
                         type="submit"
                         color="secondary"
                         variant="contained"
                         size="medium"
                         disableElevation
                         onClick={handleSlotSubmit}
                       >
                         Submit
                       </Button>
                    </Box>
                  </Stack>
                </TabPanel>
                <TabPanel
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: '20px',
                      fontWeight: 700,
                    },
                  }}
                  className="panels"
                  value={getPosition('aviator')}
                >
                  <Stack  className="tech-name-box">
                  <Box display='flex' className="tech-detail-box" >
                      <Box className="symbol-box">
                      <Checkbox
                        checked={aviatorCheckBox}
                        onChange={handleAviatorCheckChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      </Box>
                      <Typography mt={0.7} color={aviatorCheckBox ? '#757575' : '#212B36'}>Login To Play</Typography>
                    </Box>
                    <Box margin={1} className="pd0_mrg0Right mrgBtm_0">
                       <Button
                         type="submit"
                         color="secondary"
                         variant="contained"
                         size="medium"
                         disableElevation
                         onClick={handleAviatorSubmit}
                       >
                         Submit
                       </Button>
                    </Box>
                  </Stack>
                </TabPanel>
              </TabContext> */}
              </Stack>
            )}
            {DisableBtn && (
              <Typography textAlign="center" margin="20px">
                <CircularProgress />
              </Typography>
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default FeedManagement;