import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
// components
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { UserListToolbar } from '../../sections/@dashboard/user';
import TableHeadSection from '../../sections/TableHeadSection';
// sections
// mock
import { UserContext } from '../../contexts/UserContext';
import { deleteDataWithAuth, postData } from '../../utils/api';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { ToastError, ToastSuccess, formatDate } from '../../utils/helper';
import PermissionDialog from './PermissionDialog';
/* eslint-disable react/no-unknown-property */
// ----------------------------------------------------------------------

const MenuPage = ({ model }) => {
  const [open, setOpen] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);

  const [currentId, setCurrentId] = useState(null);

  const [page, setPage] = useState(0);

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [permissions, setPermissions] = useState([]);

  const [extra_data, setExtraData] = useState([]);
  const [sortData, setSortData] = useState('DESC');
  const [sortBy, setSortBY] = useState('');
  const [loading, SetLoading] = useState(true);

  const { user } = useContext(UserContext);

  const handleDeleteClickOpen = () => {
    setDialogOpen(true);
    setOpen(null);
  };

  const handleOpenPermission = (id, event) => {
    setOpen(event.currentTarget);
    setCurrentId(id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setCurrentId(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchPermissions(sortBy, sortBy ? sortData : '', newPage, rowsPerPage, filterName);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    fetchPermissions(sortBy, sortBy ? sortData : '', 0, event.target.value, filterName);
  };
  const handleFilterByName = async (event) => {
    setPage(0);
    setFilterName(event.target.value);
    fetchPermissions(sortBy, sortBy ? sortData : '', 0, rowsPerPage, event.target.value);
  };

  const isNotFound = !permissions.length && !!filterName;
  const isNotFoundData = !permissions.length;

  const fetchPermissions = async (sortBy, sortData, page, rowsPerPage, filterName) => {
    try {
      const data = {
        user_token: user.token,
        data: {
          role_name: filterName !== null ? filterName : '',
          order_key: sortBy !== '' ? sortBy : '',
          order_value: sortData,
        },
      };
      SetLoading(true);
      const response = await postData(`/admin/${model}/list?page=${page + 1}&size=${rowsPerPage}`, data);
      setPermissions(response.data);
      setExtraData(response.extra_data);
      SetLoading(false);
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  useEffect(() => {
    fetchPermissions(sortBy, '', page, rowsPerPage, filterName);
  }, []);

  const handleDeletePermission = async (confirm) => {
    toast.dismiss();
    if (confirm) {
      try {
        const data = {
          user_token: user.token,
          data: {},
        };
        const ShopData = await deleteDataWithAuth(`/admin/${model}/delete/${currentId}`, data);
        if (ShopData.status_code === 200) {
          ToastSuccess(ShopData.message);
        } else {
          toast.warning(ShopData.response.data.message, {
            position: 'top-center',
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            theme: 'colored',
          });
        }
        setTimeout(() => {
          toast.dismiss();
          setDialogOpen(false);
          setOpen(null);
          setCurrentId(null);
          // setPage(0);
          fetchPermissions(sortBy, sortBy ? sortData : '', page, rowsPerPage, filterName);
        }, 800);
      } catch (error) {
        console.log('error2 :>> ', error);
        ToastError(error.response.data.message);
      }
    } else {
      setDialogOpen(false);
    }
  };

  const TABLE_HEAD = [
    { id: 'id', label: 'ID', alignRight: false },
    { id: 'role_name', label: 'Role', alignRight: false },
    { id: 'created_at', label: 'Created At', alignRight: false },
    { id: '' },
  ];

  const handleSort = (sortBy) => {
    if (!isNotFoundData && !loading) {
      setSortBY(sortBy);
      setSortData(sortData === 'ASC' ? 'DESC' : 'ASC');
      fetchPermissions(sortBy, sortData === 'ASC' ? 'DESC' : 'ASC', page, rowsPerPage);
    }
  };

  return (
    <>
      <PermissionDialog setDialog={setDialogOpen} open={dialogOpen} handleDeletePermission={handleDeletePermission} />
      <Helmet>
        <title> Permission | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} className="shopbalnceStack">
          <Box display="flex">
            <Icon
              icon="icon-park-outline:permissions"
              style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }}
            />
            <Typography variant="h4" gutterBottom>
              Permissions
            </Typography>
          </Box>
          <Button
            to={`/admin/${model}/create`}
            variant="contained"
            component={Link}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Permission
          </Button>
        </Stack>

        <Card sx={{ padding: '5px 0 5px 5px' }}>
          <UserListToolbar
            numSelected={0}
            filterName={filterName}
            onFilterName={handleFilterByName}
            placeHolder={'Search Permissions...'}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, marginTop: 1 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadSection
                      // isNotFound={isNotFoundData}
                      sortData={sortData}
                      handleSort={handleSort}
                      tableHead={TABLE_HEAD}
                    />
                  </TableRow>
                </TableHead>
                {!isNotFound && !loading && (
                  <TableBody>
                    {permissions.map((row) => (
                      <TableRow hover key={row.id} tabIndex={-1}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell align="left">{row.role_name}</TableCell>
                        <TableCell align="left">{formatDate(row.created_at, 'ticket datetime')}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(e) => {
                              handleOpenPermission(row.id, e);
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
                {isNotFound && !loading && (
                  <TableRow>
                    <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph textTransform={'capitalize'}>
                          data not found !
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typing or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                )}
                {loading && !isNotFound && (
                  <TableRow sx={{ borderBottom: '0' }}>
                    <TableCell align="center" colSpan={12}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                {!isNotFound && !loading && (
                  <TableFooter sx={{}}>
                    <TableRow>
                      <TableCell colSpan={8} align="left" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                        Total Entries : {extra_data ? extra_data.current_page_items : 0}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          {!isNotFound && !loading && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={extra_data ? extra_data.total_items : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem component={Link} to={`/admin/${model}/edit/${currentId}`}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteClickOpen}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
};

export default MenuPage;
