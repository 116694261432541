import { Icon } from '@iconify/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { UserContext } from '../../contexts/UserContext';
import { fetchDataWithAuth, postData } from '../../utils/api';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { generateHash, getTimeStamp, ToastError, ToastSuccess } from '../../utils/helper';

const TopLeaguesPage = ({ model }) => {
  const { user } = useContext(UserContext);
  const [topleagues, setTopLeagues] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [loading, SetLoading] = useState(true);

  const getLeagues = async (values) => {
    const data = {
      user_token: user.token,
      data: {
        search: values,
      },
    };
    SetLoading(true);
    const response = await postData(`/admin/leagues/get`, data);
    setLeagues(response.data);
    SetLoading(false);
  };

  const getSeletedTopLeagues = async () => {
    const data = {
      user_token: user.token,
      data: {},
    };
    const response = await fetchDataWithAuth(`/admin/topleagues/selected`, data);
    setTopLeagues(response.data[0].top_leagues);
  };

  useEffect(() => {
    getLeagues('');
    getSeletedTopLeagues();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm({
    defaultValues: {
      permissions: [{ access: '' }],
    },
  });

  const onSubmit = async (TopLeaguesData) => {
    SetLoading(true);
    // Perform form submission logic here

    toast.dismiss();
    let data = null;
    let PermissionData = null;
    if (topleagues.length) {
      try {
        data = {
          user_token: user.token,
          data: {
            top_leagues: topleagues,
            date_time: getTimeStamp(),
          },
        };
        const hashSignature = generateHash(data.data);
        data.data.hash = hashSignature;
        PermissionData = await postData('/admin/topleagues/update', data);
        SetLoading(false);
        ToastSuccess(PermissionData.message);
      } catch (error) {
        console.log('error2 :>> ', error);
        ToastError(error.response.data.message);
      }
    } else {
      ToastError('Please select at least one top league');
    }
  };

  const handleCheckboxChange = (id) => {
    if (topleagues.includes(id)) {
      setTopLeagues(topleagues.filter((selectedId) => selectedId !== id));
    } else if (topleagues.length < 7) {
      setTopLeagues([...topleagues, id]);
    }
  };

  const onSearch = (searchdata) => {
    if (searchdata.searchtext.length) {
      setFilterName(searchdata.searchtext);
      getLeagues(searchdata.searchtext);
    }
  };
  const clearAll = () => {
    setTopLeagues([]);
    setFilterName('');
    setValue('searchtext', '');
    if (filterName) {
      getLeagues('');
      getSeletedTopLeagues();
    }
  };
  const totalSelectedLeaguage = (id) => {
    const filteredLeagues = id.filter((item) => topleagues.includes(item.id));
    return filteredLeagues.length > 0 ? `(${filteredLeagues.length})` : '';
  };
  const totalSelectedLeaguages = (id) => {
    const filteredLeagues = id.filter((item) => topleagues.includes(item.id)).length;
    return filteredLeagues;
  };

  return (
    <>
      <Helmet>
        <title> Top Leagues | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>
      <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon
              icon="material-symbols:all-match-outline"
              style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }}
            />
            <Typography variant="h4" gutterBottom>
              Top Leagues
            </Typography>
          </Box>
        </Stack>
        <Card className="topleaguseHolderAdmin">
          {leagues.length > 0 ? (
            <CardHeader
              title={
                <Typography variant="h4" gutterBottom>
                  Selected Leagues ({topleagues.length ? topleagues.length : 0}/7)
                </Typography>
              }
            />
          ) : (
            <>
              <CardHeader
                title={
                  <Typography variant="h4" gutterBottom>
                    Top Leagues
                  </Typography>
                }
              />
            </>
          )}

          <Stack ml={3} direction="row" className="stakeColumn">
            <Box component={'form'} onSubmit={handleSubmit(onSearch)} className="stakeColumn">
              <TextField type="text" {...register('searchtext')} placeholder="Search....." className="width_80" />
              <Button
                variant="contained"
                size="small"
                sx={{ height: '57px', marginLeft: '10px', width: '100px' }}
                type="submit"
                className="searchBtn"
                disabled={loading}
              >
                search
              </Button>
            </Box>
            <Box padding={2} ml="auto" sx={{ mr: 2 }} className="searchBtn justiFyContent">
              <Button
                onClick={clearAll}
                variant="outlined"
                sx={{ marginRight: '10px' }}
                disabled={loading}
                color="primary"
              >
                {leagues.length > 0 ? 'Clear' : 'Clear search value'}
              </Button>

              {leagues.length > 0 ? (
                <Button onClick={onSubmit} variant="contained" color="primary" disabled={loading}>
                  Update Top Leagues
                </Button>
              ) : (
                ''
              )}
            </Box>
          </Stack>

          <CardContent className="leagueCardHolder">
            <Box container spacing={2}>
              {leagues.length > 0 ? (
                leagues?.map((item, index) => (
                  <>
                    <Accordion defaultExpanded className="topLvlAccordain">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="sportName">
                          {item.name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {item.locations.filter((location, index) => totalSelectedLeaguages(location.leagues)).length}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack className="locationStack" direction="row" flexWrap="wrap" alignItems="flex-start">
                          {item.locations?.map((location, index) => (
                            <>
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  className={
                                    location.leagues?.some((league) => topleagues.includes(league.id)) ? 'topclass' : ''
                                  }
                                >
                                  <Typography className="locationTitle">
                                    {location.name}&nbsp;&nbsp;&nbsp; {totalSelectedLeaguage(location.leagues)}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {location.leagues?.map((league, index) => (
                                    <Grid item xs={12} md={3} key={index}>
                                      <ListItem key={index} disablePadding sx={{}} className="leagueNameChk">
                                        <ListItemIcon>
                                          <Checkbox
                                            checked={topleagues.includes(league.id)}
                                            onChange={() => handleCheckboxChange(league.id)}
                                          />
                                        </ListItemIcon>
                                        <ListItemText primary={`${league.name}`} />
                                      </ListItem>
                                    </Grid>
                                  ))}
                                </AccordionDetails>
                              </Accordion>
                            </>
                          ))}
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </>
                ))
              ) : (
                <>
                  {' '}
                  <Paper
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    {loading && (
                      <Typography variant="h6" paragraph>
                        <CircularProgress />
                      </Typography>
                    )}
                    {!leagues.length && !loading && (
                      <Typography padding={'20px'} textAlign={'center'} variant="h6" textTransform={'capitalize'}>
                        data not found !
                      </Typography>
                    )}
                    {filterName && (
                      <Typography variant="body2">
                        No Top Leagues found for &nbsp;
                        <strong>&quot;{filterName}&quot;</strong>.
                        <br /> Try checking for typing or using complete words.
                      </Typography>
                    )}
                  </Paper>
                </>
              )}
            </Box>
            {leagues.length > 2 ? (
              <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
                <Button
                  onClick={clearAll}
                  variant="outlined"
                  sx={{ marginRight: '10px' }}
                  color="primary"
                  disabled={loading}
                >
                  Clear
                </Button>

                <Button onClick={onSubmit} variant="contained" disabled={loading} color="primary">
                  Update Top Leagues
                </Button>
              </Box>
            ) : (
              ''
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default TopLeaguesPage;
