import { useContext, useState } from 'react';
// @mui
import { Avatar, Box, Divider, IconButton, MenuItem, Popover, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
// mocks_
import { Link, useNavigate } from 'react-router-dom';
import account from '../../../_mock/account';
import { UserContext } from '../../../contexts/UserContext';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const { user, logout } = useContext(UserContext);
  const navigate = useNavigate();
  // const MENU_OPTIONS = [
  //   {
  //     label: 'Profile',
  //     icon: 'eva:person-fill',
  //     path: `/${user.type}/profile`
  //   },
  //   {
  //     label: 'Settings',
  //     icon: 'eva:settings-2-fill',
  //     path: `/admin/setting`
  //   },
  // ];

  const handelLogout = () => {
    logout();
    navigate('/login');
  };

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user.username}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {user.type === 'admin' ? (
            <MenuItem onClick={() => setOpen(null)} key="Setting" component={Link} to="/admin/setting">
              Configuration
            </MenuItem>
          ) : (
            <MenuItem onClick={() => setOpen(null)} key="Profile" component={Link} to={`/${user.type}/profile`}>
              Profile
            </MenuItem>
          )}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handelLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
