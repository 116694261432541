import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, Collapse, List, ListItemText } from '@mui/material';
//
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useState } from 'react';
import Iconify from '../iconify';
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const [open, setOpen] = useState(false);
  const { title, path, icon, info } = item;
  const handleClick = () => {
    setOpen(!open);
  };
  const active = {
    color: 'text.primary',
    bgcolor: 'action.selected',
    fontWeight: 'fontWeightBold',
  };

  if (Object.prototype.hasOwnProperty.call(item, 'info') && info === 'collapse') {
    return (
      <>
     
          <StyledNavItem sx={open ? active : {}} onClick={handleClick}>
            <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

            <ListItemText disableTypography primary={title} />

            {open ? <ExpandMore /> : <ExpandLess />}
          </StyledNavItem>
        
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.childrens.map((item) => (
              <NavItem key={item.title} item={item} />
            ))}
          </List>
        </Collapse>
      </>
    );
  }
  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      {/* <StyledNavItemIcon>{title === 'dashboard' ? icon : <Iconify icon={'radix-icons:dot-filled'} sx={{ width: 1, height: 1 }} />}</StyledNavItemIcon> */}
      <StyledNavItemIcon>{icon || <Iconify icon={'radix-icons:dot-filled'} sx={{ width: 1, height: 1 }} />}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
