import * as yup from 'yup';

export const AdminUserSchema = (action) => yup.object().shape({
      username: yup
        .string()
        .required('Username is required')
        .matches(/^[^\s].*/, 'starting spaced is not allowed'),
      first_name: yup
        .string()
        .required('First name is required')
        .matches(/^[^\s].*/, 'starting spaced is not allowed'),
      last_name: yup
        .string()
        .required('Last name is required')
        .matches(/^[^\s].*/, 'starting spaced is not allowed'),
      email: yup
        .string()
        .required('Email is required')
        .matches(/^[^\s].*/, 'starting spaced is not allowed')
        .email('Invalid email'),
      phone: yup
        .string()
        .required('Phone Number is required')
        .min(7, 'Please enter minimum 7 numbers')
        .max(12, 'Please enter maximum 12 numbers'),
      role_id: yup.string().required('Role is required'),
      password: yup.lazy((value) => {
        if (action === 'Create' || value) {
          return yup
            .string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required')
            .matches(/^[^\s].*/, 'starting spaced is not allowed')
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/,
              'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
            );
        } 
          return yup.string();
        
      }),
      dob: yup
        .string()
        .nullable()
        .required('Date of Birth is required')
        .max(new Date(), 'Date of Birth cannot be in the future')
        .test('is-adult', 'You must be at least 18 years old', (value) => {
          if (!value) return false; // Invalid date
          const today = new Date();
          const dob = new Date(value);
          const age = today.getFullYear() - dob.getFullYear();
          if (age > 18) return true;
          if (age === 18) {
            // Check month and day for 18th birthday
            if (today.getMonth() > dob.getMonth()) return true;
            if (today.getMonth() === dob.getMonth() && today.getDate() >= dob.getDate()) return true;
          }
          return false;
        }),
        vat_number: yup.lazy((value) => {
          if (value) {
            return yup
            .string()
            .matches(/^[^\s].*/, 'starting spaced is not allowed')
            .min(3, 'Please enter minimum 3 numbers')
            .max(12, 'Please enter maximum 12 numbers')
          } 
            return yup.string();
          
        }),
      address: yup
        .string()
        .required('Address is required')
        .matches(/^[^\s].*/, 'starting spaced is not allowed'),
      // balance: yup
      //   .string()
      //   .required('Balance is required')
      //   .matches(/^[^\s].*/, 'starting spaced is not allowed')
      //   .matches(/^\d+(\.\d+)?$/, 'Only numbers are allowed'),
      // vat_number: yup.string().matches(/^[^\s].*/, 'starting spaced is not allowed'),
    }) 
    