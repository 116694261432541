import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, CircularProgress, Grid, Paper, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import { fetchDataWithAuth } from '../../utils/api';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { amountFormatter } from '../../utils/helper';

<Helmet>
  <title> Recent Winning Users | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
</Helmet>;

const Item = styled(Paper)(({ color, theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  border: `1px solid ${color ? theme.palette[color].main : '#000'}`,
}));

const TypographyCustom = styled(Typography)(({ color, theme }) => ({
  color: theme.palette[color].main,
}));

const RecentWinningUsers = () => {
  const { user, updateBalance } = useContext(UserContext);
  const [list, setList] = useState(null);
  const minBalance = 1500;

  const FetchShopWithBalance = async () => {
    const data = {
      user_token: user.token,
      data: {},
    };
    const response = await fetchDataWithAuth(`/admin/reports/live/user/summary`, data);
    const fetchedBalances = await response.data;

    const updatedBalances = await Promise.all(
      fetchedBalances.map((balance) => {
        const previousBalance = list?.find((item) => item.user_id === balance.user_id).win_amount || balance.win_amount;

        switch (true) {
          case balance.win_amount <= minBalance:
            return { ...balance, flag: 'none' };
          case balance.win_amount > previousBalance:
            return { ...balance, flag: 'up' };
          case balance.win_amount < previousBalance:
            return { ...balance, flag: 'down' };
          case balance.win_amount === previousBalance:
            return { ...balance, flag: 'none' };
          default:
            return balance;
        }
      })
    );
    setList(updatedBalances);
  };

  const getLiveShopBalance = async () => {
    const data = {
      user_token: user.token,
      data: {},
    };
    const response = await fetchDataWithAuth(`/admin/reports/live/user/summary`, data);
    const fetchedBalances = await response.data;

    const updatedBalances = await Promise.all(
      fetchedBalances.map((balance) => {
        const previousBalance =
          list?.find((item) => item.user_id === balance.user_id)?.win_amount || balance.win_amount;

        switch (true) {
          case balance.win_amount <= minBalance:
            return { ...balance, flag: 'none' };
          case balance.win_amount > previousBalance:
            return { ...balance, flag: 'up' };
          case balance.win_amount < previousBalance:
            return { ...balance, flag: 'down' };
          case balance.win_amount === previousBalance:
            return { ...balance, flag: 'none' };
          default:
            return balance;
        }
      })
    );
    setList(updatedBalances);
  };

  useEffect(() => {
    if (!list) FetchShopWithBalance();
  }, [list]);

  useEffect(() => {
    const interval = setInterval(getLiveShopBalance, 10000);
    return () => {
      clearInterval(interval); // Clean up the interval when the component unmounts
    };
  });

  return (
    <>
      <Helmet>
        <title> Live Report | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>
      <Box sx={{ flexGrow: 1, px: 2 }}>
        <Box>
          <Typography sx={{ mb: 1 }} variant="h3" fontWeight="600" textAlign="left">
            Recent Highest Winning Alert - To track continuous winning users
          </Typography>
        </Box>
        {list && list.length > 0 ? (
          <Grid container sx={{}} spacing={2}>
            {list.map((item, index) => (
              <UserDetail key={index} item={item} />
            ))}
          </Grid>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  );
};

export default RecentWinningUsers;

const UserDetail = ({ item }) => {
  const navigate = useNavigate();

  const customeBox = (currentItem) => (
    <table className="">
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>
            <span>Total</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Bets Amount</td>
          <td style={{ color: '#000' }}>{amountFormatter(currentItem.bet_amount)}</td>
        </tr>
        <tr>
          <td>Winnings Amount</td>
          <td style={{ color: '#000' }}> {amountFormatter(currentItem.win_amount)}</td>
        </tr>
        <tr>
          <td>Current Balance</td>
          <td style={{ color: '#000' }}>{amountFormatter(currentItem.current_balance)}</td>
        </tr>
      </tbody>
    </table>
  );
  switch (item.flag) {
    case 'up':
      return (
        <>
          <Grid item sx={{ cursor: 'pointer', width: '25%' }}>
            <Item
              onClick={() => navigate(`/admin/report/userreport/${item.user_id}`)}
              color={'error'}
              elevation={2}
              textAlign="left"
            >
              <Stack direction="row" justifyContent="flex-start" sx={{ position: 'relative', width: '100%' }}>
                <Box className="borderWinngTable">
                  {' '}
                  <Typography variant="subtitle1" sx={{ color: '#000' }} fontWeight="600" textAlign="center">
                    {item.username}
                  </Typography>
                  {customeBox(item)}
                </Box>

                <Box>
                  <div id="cta">
                    <span className="arrow primera next bounceAlpha">
                      <KeyboardArrowUpIcon color={'error'} />
                    </span>
                    <span className="arrow segunda next  bounceAlpha">
                      {' '}
                      <KeyboardArrowUpIcon color={'error'} />
                    </span>
                  </div>
                </Box>
              </Stack>
            </Item>
          </Grid>
        </>
      );
    case 'down':
      return (
        <>
          <Grid item sx={{ cursor: 'pointer', width: '25%' }}>
            <Item
              onClick={() => navigate(`/admin/report/userreport/${item.user_id}`)}
              color={'warning'}
              elevation={2}
              textAlign="center"
            >
              <Stack direction="row" justifyContent="space-between" sx={{ position: 'relative' }}>
                <Box className="borderWinngTable">
                  {' '}
                  <Typography variant="subtitle1" sx={{ color: '#000' }} fontWeight="600" textAlign="center">
                    {item.username}
                  </Typography>
                  {customeBox(item)}
                </Box>

                <Box>
                  <div id="cta">
                    <span className="arrow primera next  bounceAlphaDown">
                      <KeyboardArrowDownIcon color="warning" />
                    </span>
                    <span className="arrow segunda next   bounceAlphaDown">
                      <KeyboardArrowDownIcon color="warning" />
                    </span>
                  </div>
                </Box>
              </Stack>
            </Item>
          </Grid>
        </>
      );
    case 'nones':
      return (
        <>
          {' '}
          <Grid item sx={{ cursor: 'pointer', width: '25%' }}>
            <Item
              onClick={() => navigate(`/admin/report/userreport/${item.user_id}`)}
              sx={{ border: 'none' }}
              elevation={2}
              textAlign="center"
            >
              <Box className="borderWinngTable">
                <Typography variant="subtitle1" sx={{ color: '#000' }} fontWeight="600" textAlign="center">
                  {item.username}
                </Typography>
                {customeBox(item)}
              </Box>
            </Item>
          </Grid>
        </>
      );
    default:
      return (
        <>
          {' '}
          <Grid item sx={{ cursor: 'pointer', width: '25%' }}>
            <Item
              onClick={() => navigate(`/admin/report/userreport/${item.user_id}`)}
              sx={{ border: 'none' }}
              elevation={2}
              textAlign="center"
            >
              <Box className="borderWinngTable">
                <Typography variant="subtitle1" sx={{ color: '#000' }} fontWeight="600" textAlign="center">
                  {item.username}
                </Typography>

                {customeBox(item)}
              </Box>
            </Item>
          </Grid>
        </>
      );
  }
};
