import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import Modal from '@mui/material/Modal';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { UserContext } from '../contexts/UserContext';

import Label from '../components/label/Label';
import { MY_GLOBAL_CONST } from '../utils/GlobalConstant';
import { postData } from '../utils/api';
import { ToastError, ToastSuccess, formatNumber, getPermissionActions, showCurrency } from '../utils/helper';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
};

const schema = yup.object().shape({
  amount: yup
    .string()
    // .moreThan(0, 'Amount must be greater than 0')
    .required('Balance is required')
    .test('greater-than-zero', 'Amount must be greater than 0', (value) => value > 0)
    .matches(/^\d+$/, 'Only numbers are allowed'),
  description: yup
    .string()
    .required('Description is required')
    .matches(/^[^\s].*/, 'starting spaced is not allowed'),
  request_type: yup.string().required('Status is required'),
});
const FundTransferRequest = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [open, setOpen] = useState(false);
  const [select, setSelect] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [list, setList] = useState([]);
  const [extra_data, setExtraData] = useState([]);
  const { user, fetchBalance } = useContext(UserContext);
  const [sortData, setSortData] = useState('DESC');
  const [sortBy, setSortBY] = useState('');
  const [loading, SetLoading] = useState(true);
  const [disable, setDisable] = useState(false);
  const [loaderMain, SetLoaderMain] = useState(false);
  // const [disableReject, setDisableReject] = useState(null);

  const handleChange = (event) => {
    setSelect(event.target.value);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    reset();
  };
  const isNotFound = !list.length;

  const actions = getPermissionActions(user.role_responsibility, 'Fund Transfer Request');

  const shopBalance = [
    {
        id: 1,
        label: 'Return money to Admin',
    },
    {
        id: 2,
        label: 'Recieve money from Admin',
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchDataFromApi(sortBy, sortBy ? sortData : '', newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    fetchDataFromApi(sortBy, sortBy ? sortData : '', 0, event.target.value);
  };

  useEffect(() => {
    fetchDataFromApi(sortBy, '', page, rowsPerPage);
  }, []);

  const fetchDataFromApi = async (sortBy, sortData, page, rowsPerPage) => {
    const data = {
      user_token: user.token,
      data: {
        status: '',
        order_key: sortBy !== '' ? sortBy : '',
        order_value: sortData,
      },
    };
    try {
      const fetchedData = await postData(`/${user.type}/manage/cash/request/list?page=${page + 1}&size=${rowsPerPage}`, data);
      setList(fetchedData.data);
      setExtraData(fetchedData.extra_data);
      SetLoading(false);
    } catch (error) {
      console.log('fetchedData error :>> ', error);
    }
  };

  const onSubmit = async (requestVal) => {
    SetLoaderMain(true)
    setDisable(true)
    toast.dismiss();
    const data = {
      user_token: user.token,
      data: {
        request_type: requestVal.request_type,
        amount: requestVal.amount,
        description: requestVal.description,
      },
    };
    try {
      const BalanceRequest = await postData(`/${user.type}/manage/cash/request`, data);
      if (BalanceRequest.status_code === 200) {
        setOpen(false);
        reset();
      }
      ToastSuccess(BalanceRequest.message);
      setDisable(false)
    } catch (error) {
      console.log('BalanceRequest error :>> ', error);
      ToastError(error.response.data.message);
      setDisable(false)
    } finally{
      fetchBalance();
     await  fetchDataFromApi(sortBy, '', page, rowsPerPage);
     SetLoaderMain(false)
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case 1:
        return <Label color={'warning'}>Pending</Label>;
      case 2:
        return <Label color={'success'}>Approved</Label>;
      case 3:
        return <Label color={'error'}>Rejected</Label>;
      default:
        return <></>;
    }
  };

  const handleSort = (sortBy) => {
    setSortBY(sortBy);
    setSortData(sortData === 'ASC' ? 'DESC' : 'ASC');
    fetchDataFromApi(sortBy, sortData === 'ASC' ? 'DESC' : 'ASC', page, rowsPerPage);
  };
  return (
    <>
      <Helmet>
        <title> Fund Transfer Request | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component={'form'} onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h5">Fund Transfer Request</Typography>

            <IconButton aria-label="close">
              <CloseIcon onClick={handleClose} />
            </IconButton>
          </Stack>

          <Stack direction="row">
            <Box sx={{ width: '60%', mr: 1 }}>
              <FormControl sx={{}} fullWidth className="slectInput">
                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                <Select
                  {...register('request_type')}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  onChange={handleChange}
                >
                  <MenuItem value="">Select Type</MenuItem>
                  {shopBalance.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box width="40%">
              <TextField
                {...register('amount')}
                name="amount"
                fullWidth
                type="number"
                variant="outlined"
                label="Amount"
                placeholder="Amount"
                disableUnderline
              />
              <Typography variant="body2" color="error">
                {errors.amount?.message}
              </Typography>
            </Box>
          </Stack>

          <Stack mt={2} alignItems="flex-end">
            <Box width="100%">
              <TextField
                fullWidth
                multiline
                rows={3}
                placeholder="Add description"
                {...register('description')}
                name="description"
                label="Description"
              />
              <Typography variant="body2" color="error">
                {errors.description?.message}
              </Typography>
            </Box>
            <Box mt={2}>
              <Button disabled={!select || disable} type="submit" size="large" sx={{ ml: 2 }} variant="contained">
                {select === 1 ? 'Return' : 'Receive'}
              </Button>
            </Box>
          </Stack>
        </Box>
      </Modal>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon icon="hugeicons:money-add-02" style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }} />
            <Typography variant="h4" gutterBottom>
              Fund Transfer Request
            </Typography>
          </Box>
          {actions.find((action) => action.name === 'request') ? (
            <Button onClick={handleOpen} variant="contained">
              Add Request
            </Button>
          ) : (
            <></>
          )}
        </Stack>

        {loaderMain ? (
          <Box
            sx={{
              display: 'flex',
              height: '300px',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#f9fafb',
            }}
          >
            <CircularProgress size={35} />
          </Box>
        ) : (
          <Card>
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell onClick={() => handleSort('id')} sx={{ cursor: 'pointer' }} align="center">
                      ID
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell
                      onClick={() => handleSort('requestBy.full_name')}
                      sx={{ cursor: 'pointer' }}
                      align="center"
                    >
                      Request By
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>

                    <TableCell onClick={() => handleSort('request_type')} sx={{ cursor: 'pointer' }} align="center">
                      Request Type
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell
                      onClick={() => handleSort('requestTo.full_name')}
                      sx={{ cursor: 'pointer' }}
                      align="center"
                    >
                      Request To
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell onClick={() => handleSort('amount')} sx={{ cursor: 'pointer' }} align="center">
                      Amount
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell onClick={() => handleSort('description')} sx={{ cursor: 'pointer' }} align="center">
                      Description
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell onClick={() => handleSort('status')} sx={{ cursor: 'pointer' }} align="center">
                      Status
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{item.id}</TableCell>
                      <TableCell align="center">{item.request_by.username}</TableCell>
                      <TableCell align="center">Receive</TableCell>
                      <TableCell align="center">{item.request_to.username}</TableCell>
                      <TableCell align="center">
                        {formatNumber(item.amount)} {showCurrency()}
                      </TableCell>
                      <TableCell align="center">{item.description}</TableCell>
                      <TableCell align="center">{getStatus(item.status)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                {loading && (
                  <TableRow>
                    <TableCell align="center" colSpan={12}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                {isNotFound && !loading && (
                  <TableRow>
                    <TableCell align="center" colSpan={6}>
                      <Typography variant="h6"> data not found</Typography>
                    </TableCell>
                  </TableRow>
                )}
                {!isNotFound && !loading && (
                  <TableFooter sx={{}}>
                    <TableRow>
                      <TableCell colSpan={4} align="left" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                        Total Entries : {extra_data ? extra_data.current_page_items : 0}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000', marginRight: '10px' }}
                      >
                        {list.length > 0
                          ? formatNumber(
                              list
                                .reduce(
                                  (accumulator, object) =>
                                    parseFloat(accumulator) +
                                    (object.amount ? parseFloat(object.amount) : parseFloat(0.0)),
                                  0
                                )
                                .toFixed(2)
                            )
                          : 0.0}{' '}
                        {showCurrency()}
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }} />
                    </TableRow>
                  </TableFooter>
                )}
              </Table>
            </CardContent>
            {list.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={extra_data ? extra_data.total_items : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Card>
        )}
      </Container>
    </>
  );
};

export default FundTransferRequest;
