import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserContext } from '../../contexts/UserContext';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { fetchDataWithAuth, postData } from '../../utils/api';
import { ToastError, ToastSuccess, formatDate, generateHash, getTimeStamp } from '../../utils/helper';
import { AdminUserSchema } from './ValidationSchema';

const AdminUserForm = ({ action }) => {
  const Redirect = useNavigate();
  const params = useParams();
  const [date, setDate] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const { user } = useContext(UserContext);
  const [roles, setRoles] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [selectValue, setSelectValue] = useState(null);
  const [disable, setDisable] = useState(false);
  const [loading,setLoading] = useState(false)
  const schema = AdminUserSchema(action);
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (userData) => {
    // Handle form submission here (e.g., send data to server)
    // setValue('dob', date);
    setLoading(true)
    setDisable(true)
    toast.dismiss();
    let data = null;
    let AdminUserData = null;
    try {
      if (params.id) {
        data = {
          user_token: user.token,
          data: {
            id: params.id,
            first_name: userData.first_name,
            last_name: userData.last_name,
            username: userData.username,
            email: userData.email,
            country_code: userData.country_code,
            phone: userData.phone,
            // balance: userData.balance,
            address: userData.address,
            vat_number: userData.vat_number,
            dob: userData.dob,
            role_id: selectValue,
            date_time: getTimeStamp(),
            password: userData.password,
          },
        };
        const hashSignature = generateHash(data.data);
      data.data.hash = hashSignature;
        AdminUserData = await postData('/admin/adminuser/update', data);
      } else {
        data = {
          user_token: user.token,
          data: {
            first_name: userData.first_name,
            last_name: userData.last_name,
            username: userData.username,
            email: userData.email,
            country_code: userData.country_code,
            phone: userData.phone,
            // balance: userData.balance,
            address: userData.address,
            vat_number: userData.vat_number,
            dob: userData.dob,
            role_id: userData.role_id,
            date_time: getTimeStamp(),
            password: userData.password,
          },
        };
        const hashSignature = generateHash(data.data);
        data.data.hash = hashSignature;
        AdminUserData = await postData('/admin/adminuser/add', data);
      }
      ToastSuccess(AdminUserData.message);
      setTimeout(() => {
        toast.dismiss();
        Redirect('/admin/adminuser');
      }, 800);
      setDisable(false)
      setLoading(false)
    } catch (error) {
      console.log('error2 :>> ', error);
      ToastError(error.response.data.message);
      setDisable(false)
      setLoading(false)
    }
  };

  const getRoles = async () => {
    const data = {
      user_token: user.token,
      data: {
        status: '',
        name: '',
      },
    };
    const response = await postData(`/admin/role/list`, data);
    setRoles(response.data);
  };

  useEffect(() => {
    const getUser = async () => {
      setLoading(true)
      if (params.id) {
        // Fetch user data from the API based on the userId
        const data = {
          user_token: user.token,
          data: {},
        };
        const response = await fetchDataWithAuth(`/admin/adminuser/get/${params.id}`, data);
        const userData = await response.data[0];
        // Set form values using setValue
        setValue('first_name', userData.first_name);
        setValue('last_name', userData.last_name);
        setValue('username', userData.username);
        setValue('email', userData.email);
        setValue('country_code', userData.country_code);
        setValue('phone', userData.phone);
        // setValue('balance', userData.balance);
        setValue('address', userData.address);
        setValue('vat_number', userData.vat_number);
        setValue('role_id', userData.role_id);
        setValue('dob', userData.dob);
        setPhoneNumber(userData.phone);
        setCountryCode(userData.country_code);
        setDate(dayjs(formatDate(userData.dob, 'datepicker')));
        setSelectValue(userData.role_id);
        // setValue('dob', userData.dob);
      }
      setLoading(false)
    };
    getRoles();
    getUser();
  }, [setValue]);
  const handleOnChange = (value, data) => {
    const country_code = data.dialCode;
    const phone_no = value.slice(data.dialCode.length);
    setValue('phone', phone_no, true);
    setValue('country_code', country_code);
  };
  const today = new Date();
  const todaydate = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear() - 18;

  const onchangedate = async (e) => {
    if (e !== null) {
      const start_date = new Date(e);
      const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
      await trigger('dob');
      setValue('dob', utcstartDate);
      setDate(dayjs(formatDate(e, 'datepicker')));
    }
  };
  
  return (
    <>
      <Helmet>
        <title>
          {' '}
          Admin User {action} | {`${MY_GLOBAL_CONST.main_title}`} Admin{' '}
        </title>
      </Helmet>

      <Container maxWidth="lg">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Admin User {action}
          </Typography>
        </Stack>
        <Card>
          <CardContent>
            {loading ? (
              <Stack display="flex" flexDirection="row" justifyContent="center">
                <CircularProgress size={35} />
              </Stack>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack direction="row">
                  <Box padding={2} width="50%">
                    <TextField
                      {...register('username')}
                      label="Username"
                      fullWidth
                      placeholder="Username"
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{ classes: { root: 'input-field' } }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.username?.message}
                    </Typography>
                  </Box>

                  <Box padding={2} width="50%">
                    <TextField
                      {...register('first_name')}
                      label="First Name"
                      placeholder="First Name"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{ classes: { root: 'input-field' } }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.first_name?.message}
                    </Typography>
                  </Box>
                </Stack>
                <Stack direction="row">
                  <Box padding={2} width="50%">
                    <TextField
                      {...register('last_name')}
                      label="Last Name"
                      fullWidth
                      placeholder="Last Name"
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{ classes: { root: 'input-field' } }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.last_name?.message}
                    </Typography>
                  </Box>

                  <Box padding={2} width="50%">
                    <TextField
                      {...register('email')}
                      label="Email"
                      placeholder="Email"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{ classes: { root: 'input-field' } }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.email?.message}
                    </Typography>
                  </Box>
                </Stack>
                <Stack direction="row">
                  <Box sx={{}} padding={2} width="50%">
                    <PhoneInput
                      inputProps={{
                        name: 'phone',
                        required: true,
                        autoFocus: true,
                        ref: { ...register('phone') },
                      }}
                      variant="outlined"
                      value={`${countryCode}${phoneNumber}`}
                      placeholder="Phone No"
                      country={`${MY_GLOBAL_CONST.country_short_code}`}
                      onChange={handleOnChange}
                      InputLabelProps={{
                        height: '56px',
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{ classes: { root: 'input-field' } }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.phone?.message}
                    </Typography>
                  </Box>

                  <Box padding={2} width="50%">
                    <TextField
                      placeholder="Enter Password"
                      {...register('password')}
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      fullWidth
                      variant="outlined"
                      label="Password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.password?.message}
                    </Typography>
                  </Box>
                </Stack>
                <Stack direction="row">
                  <Box padding={2} width="50%">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Controller
                        name="dob"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            sx={{ width: '100%' }}
                            maxDate={dayjs(`${month}-${todaydate}-${year}`)}
                            value={date}
                            onChange={(newValue) => {
                              field.onChange(newValue);
                              onchangedate(newValue);
                            }}
                            format="DD-MM-YYYY"
                            slotProps={{
                              textField: {
                                error: false,
                              },
                            }}
                            // label="Date Of Birth"
                            renderInput={(params) => <TextField {...params} size="medium" marginBottom="20px" />}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <Typography variant="body2" color="error">
                      {errors.dob?.message}
                    </Typography>
                  </Box>

                  <Box padding={2} width="50%">
                    <TextField
                      {...register('vat_number')}
                      label="VAT Number"
                      placeholder="VAT Number"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{ classes: { root: 'input-field' } }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.vat_number?.message}
                    </Typography>
                  </Box>
                </Stack>
                <Stack direction="row" className="slectInput">
                  <Box display="flex" padding={2} width="50%">
                    <FormControl fullWidth sx={{ textAlign: 'left' }}>
                      <InputLabel id="role_id-label">{!params.id ? 'Role' : ''}</InputLabel>
                      <Select
                        labelId="role_id-label"
                        id="role_id"
                        name="role_id"
                        {...register('role_id')}
                        value={selectValue}
                        onChange={(event) => setSelectValue(event.target.value)}
                        // error={!!errors.role_id}
                      >
                        <MenuItem key={0} value={null}>
                          Select Role
                        </MenuItem>

                        {roles.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography variant="body2" color="error">
                        {errors.role_id?.message}
                      </Typography>
                    </FormControl>
                  </Box>

                  {/* <Box padding={2} width="50%">
                  <TextField
                    {...register('balance')}
                    label="Balance"
                    placeholder="Balance"
                    type="text"
                    fullWidth
                    // error={!!errors.balance}
                    // helperText={errors.balance?.message}
                    InputLabelProps={{
                      shrink: true,
                      classes: { root: 'input-label' },
                    }}
                    InputProps={{ classes: { root: 'input-field' } }}
                  />
                  <Typography variant="body2" color="error">
                    {errors.balance?.message}
                  </Typography>
                </Box> */}
                  <Box padding={2} width="50%">
                    <TextField
                      multiline
                      rows={4}
                      {...register('address')}
                      label="Address"
                      placeholder="Address"
                      // error={!!errors.address}
                      fullWidth
                      // helperText={errors.address?.message}
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{ classes: { root: 'input-field' } }}
                    />
                    <Typography variant="body2" color="error">
                      {errors.address?.message}
                    </Typography>
                  </Box>
                </Stack>

                <Box display="flex" padding={2} width="50%">
                  <Button type="submit" variant="contained" size="large" disabled={disable}>
                    {action} Admin User
                  </Button>
                </Box>
              </form>
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default AdminUserForm;
