import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './contexts/UserContext';

const PrivateRoute = ({ children, type }) => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  if (!user || (type && user.type !== type)) {
    navigate('/login');
    return null;
  }

  return children;
};

export default PrivateRoute;
