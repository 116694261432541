import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import Modal from '@mui/material/Modal';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { UserContext } from '../contexts/UserContext';

import Label from '../components/label/Label';
import { MY_GLOBAL_CONST } from '../utils/GlobalConstant';
import { postData } from '../utils/api';
import { ToastError, ToastSuccess, formatNumber, generateHash, getPermissionActions, getTimeStamp, showCurrency } from '../utils/helper';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
};

const schema = yup.object().shape({
  amount: yup
    .string()
    // .moreThan(0, 'Amount must be greater than 0')
    .required('Amount is required')
    .matches(/^\d+$/, 'Only numbers are allowed'),
  // description: yup
  //   .string()
  //   .required('Description is required')
  //   .matches(/^[^\s].*/, 'starting spaced is not allowed'),
  // request_type: yup.string().required('Status is required'),
});
const CreditRequest = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [RowId, setRowId] = useState(0);
  const [list, setList] = useState([]);
  const [extra_data, setExtraData] = useState([]);
  const { user, fetchBalance } = useContext(UserContext);
  const [sortData, setSortData] = useState('DESC');
  const [sortBy, setSortBY] = useState('');
  const [loading, SetLoading] = useState(true);
  const [disable, setDisable] = useState(false);
  const [disableReject, setDisableReject] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    reset();
  };
  const isNotFound = !list.length;

  const actions = getPermissionActions(user.role_responsibility, 'Credit Request');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchDataFromApi(sortBy, sortBy ? sortData : '', newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    fetchDataFromApi(sortBy, sortBy ? sortData : '', 0, event.target.value);
  };

  useEffect(() => {
    fetchDataFromApi(sortBy, '', page, rowsPerPage);
  }, []);

  const fetchDataFromApi = async (sortBy, sortData, page, rowsPerPage) => {
    const data = {
      user_token: user.token,
      data: {
        status: '',
        order_key: sortBy !== '' ? sortBy : '',
        order_value: sortData,
      },
    };
    try {
      const fetchedData = await postData(`/${user.type}/manage/credit/request/list?page=${page + 1}&size=${rowsPerPage}`, data);
      setList(fetchedData.data);
      setExtraData(fetchedData.extra_data);
      SetLoading(false);
    } catch (error) {
      console.log('fetchedData error :>> ', error);
    }
  };

  const onSubmit = async (requestVal) => {
    setDisable(true)
    toast.dismiss();
    const data = {
      user_token: user.token,
      data: {
        // request_type: requestVal.request_type,
        amount: requestVal.amount,
        // description: requestVal.description,
        date_time: getTimeStamp(),
      },
    };
    const hashSignature = generateHash(data.data);
    data.data.hash = hashSignature;
    try {
      const BalanceRequest = await postData(`/${user.type}/manage/credit/request`, data);

      // setList(BalanceRequest.data);
      // setExtraData(BalanceRequest.extra_data);
      // updateBalance(BalanceRequest.data[0].balance);
      if (BalanceRequest.status_code === 200) {
        setOpen(false);
        reset();
        fetchBalance();
        fetchDataFromApi(sortBy, '', page, rowsPerPage);
      }
      ToastSuccess(BalanceRequest.message);
      setDisable(false)
    } catch (error) {
      console.log('BalanceRequest error :>> ', error);
      ToastError(error.response.data.message);
      setDisable(false)
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case 1:
        return <Label color={'warning'}>Pending</Label>;
      case 2:
        return <Label color={'success'}>Approved</Label>;
      case 3:
        return <Label color={'error'}>Rejected</Label>;
      default:
        return <></>;
    }
  };

  const handleSort = (sortBy) => {
    setSortBY(sortBy);
    setSortData(sortData === 'ASC' ? 'DESC' : 'ASC');
    fetchDataFromApi(sortBy, sortData === 'ASC' ? 'DESC' : 'ASC', page, rowsPerPage);
  };

  const handleRequestType = async (type, id) => {
    setRowId(id)
    setDisableReject(type === 1 ? 'Approve' :'Reject')
    const data = {
      user_token: user.token,
      data: {
        transfer_request_id: id,
        action_type: type === 1 ? 1 : 2,
        // description: type === 1 ? 1 : 2,
        date_time: getTimeStamp(),
      },
    };
    const hashSignature = generateHash(data.data);
    data.data.hash = hashSignature;
    try {
      const BalanceRequest = await postData(`/${user.type}/manage/credit/request/approval`, data);
      fetchDataFromApi(sortBy, '', page, rowsPerPage);
      ToastSuccess(BalanceRequest.message);
    } catch (error) {
      console.log('BalanceRequest error :>> ', error);
      ToastError(error.response.data.message);
    }
    fetchBalance();
    setDisableReject(null)
  };

  return (
    <>
      <Helmet>
        <title> Credit Request | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component={'form'} onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h5">Credit Request</Typography>

            <IconButton aria-label="close">
              <CloseIcon onClick={handleClose} />
            </IconButton>
          </Stack>
          <Box width="100%">
            <TextField
              {...register('amount')}
              name="amount"
              fullWidth
              type="number"
              variant="outlined"
              label="Amount"
              placeholder="Amount"
              disableUnderline
            />
            <Typography variant="body2" color="error">
              {errors.amount?.message}
            </Typography>
          </Box>
          <Box width="100%" pt={1}>
            <Typography variant="body2" sx={{
              fontWeight: '700'
            }}>
              Note: Credit Request can only be made for 90% of balance at a time.
            </Typography>
          </Box>
          <Stack mt={2} alignItems="flex-end">
            <Box mt={2}>
              <Button disabled={disable} type="submit" size="large" sx={{ ml: 2 }} variant="contained">
                Request
              </Button>
            </Box>
          </Stack>
        </Box>
      </Modal>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon
              icon="mdi:credit-card-plus-outline"
              style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }}
            />
            <Typography variant="h4" gutterBottom>
              Credit Request
            </Typography>
          </Box>
          {actions.find((action) => action.name === 'request') ? (
            <Button onClick={handleOpen} variant="contained">
              Add Request
            </Button>
          ) : (
            <></>
          )}
        </Stack>

        <Card>
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell onClick={() => handleSort('id')} sx={{ cursor: 'pointer' }} align="center">
                    ID
                    <IconButton aria-label="toggle password visibility">
                      {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    onClick={() => handleSort('requestBy.full_name')}
                    sx={{ cursor: 'pointer' }}
                    align="center"
                  >
                    Request By
                    <IconButton aria-label="toggle password visibility">
                      {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    onClick={() => handleSort('requestTo.full_name')}
                    sx={{ cursor: 'pointer' }}
                    align="center"
                  >
                    Request To
                    <IconButton aria-label="toggle password visibility">
                      {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell onClick={() => handleSort('amount')} sx={{ cursor: 'pointer' }} align="center">
                    Amount
                    <IconButton aria-label="toggle password visibility">
                      {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell onClick={() => handleSort('credit')} sx={{ cursor: 'pointer' }} align="center">
                    Credits
                    <IconButton aria-label="toggle password visibility">
                      {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                    </IconButton>
                  </TableCell>
                  {/* <TableCell onClick={() => handleSort('description')} sx={{ cursor: 'pointer' }} align="center">
                    Description
                    <IconButton aria-label="toggle password visibility">
                      {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                    </IconButton>
                  </TableCell> */}
                  <TableCell onClick={() => handleSort('status')} sx={{ cursor: 'pointer' }} align="center">
                    Status
                    <IconButton aria-label="toggle password visibility">
                      {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                    </IconButton>
                  </TableCell>
                  {/* <TableCell onClick={() => handleSort('status')} sx={{ cursor: 'pointer' }} align="center">
                    Action
                    <IconButton aria-label="toggle password visibility">
                      {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                    </IconButton>
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {list?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{item.id}</TableCell>
                    <TableCell align="center">{item.request_by.username}</TableCell>
                    <TableCell align="center">{item.request_to.username}</TableCell>
                    <TableCell align="center">
                      {formatNumber(item.amount)} {showCurrency()}
                    </TableCell>
                    <TableCell align="center">{formatNumber(item.credit)}</TableCell>
                    {/* <TableCell align="center">{item.description}</TableCell> */}
                    <TableCell align="center">{getStatus(item.status)}</TableCell>
                    {/* <TableCell align="center">
                      {actions.find((action) => action.name === 'approve') &&
                      item.request_by.type.toLowerCase() !== user.type ? (
                        item.status === 1 ? (
                          <>
                            <Button
                              variant="contained"
                              color="error"
                              size="small"
                              sx={{ fontWeight: 'normal' }}
                              startIcon={<CloseIcon />}
                              disabled={disableReject === 'Reject' && RowId === item.id}
                              onClick={() => handleRequestType(2, item.id)}
                            >
                              Reject
                            </Button>

                            <Button
                              variant="contained"
                              size="small"
                              startIcon={<CheckIcon />}
                              sx={{ marginLeft: '8px', fontWeight: 'normal' }}
                              onClick={() => handleRequestType(1, item.id)}
                              disabled={disableReject === 'Approve' && RowId === item.id}
                            >
                              Approve
                            </Button>
                          </>
                        ) : (
                          'N/A'
                        )
                      ) : (
                        'N/A'
                      )}
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
              {loading && (
                <TableRow>
                  <TableCell align="center" colSpan={12}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
              {isNotFound && !loading && (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <Typography variant="h6"> data not found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </CardContent>
          {list.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={extra_data ? extra_data.total_items : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>
    </>
  );
};

export default CreditRequest;
