import { Button, Container } from '@mui/material';
import { useContext, useState } from 'react';

import config from '../config';
import { UserContext } from '../contexts/UserContext';
import { fNumber } from '../utils/formatNumber';
import { formatDate, formatNumber, getBaseImageFromUrl, showCurrency } from '../utils/helper';

const PrintCashierSummary = ({ error, printReport,disable, data, date, minDate }) => {
  const admin_Url = config.apiAdminUrl;
  const { user } = useContext(UserContext);
  const [imageurl, setImage] = useState('');
  getBaseImageFromUrl(`${admin_Url}/assets/betmonkey.png`)
    .then((base64Image) => {
      setImage(base64Image);
    })
    .catch((error) => {
      console.error('Error:', error);
    });

  // Render the modified HTML
  const renderHTML = () => (
    <html lang="en" id="summaryreportprint">
      <head>
        <style>
          {`
            body {
              font-family: Arial, Helvetica, sans-serif;
            }

            * {
              margin: 0;
              padding: 0;
            }

            .logoRow {
              clear: both;
            }

            .logoLeft {
              float: left;
              width: 40px;
            }

            .clearboth {
              clear: both;
            }

            .clearboth:after {
              display: table;
              clear: both;
            }

            .loopRow {
              border-bottom: 1px solid #ccc;
            }

            @media print and (width: 80mm) and (min-height: 297mm) {
              body {
                font-size: 12pt; /* Adjust font size for printing */
              }

              h1 {
                font-size: 16pt; /* Adjust heading font size for printing */
              }

              table {
                width: 100%;
              }

              th,
              td {
                padding: 6px; /* Adjust padding for printing */
              }
            }
            @media print {
              /* Hide header and footer */
              body {
                  margin: 15;
              }
              @page {
                  size: auto;
                  margin: 0;
              }
          }
          `}
        </style>
        <title>Print Summary Report</title>
      </head>
      <body style={{ backgroundColor: '#ccc' }}>
        <div style={{ backgroundColor: '#fff', width: '200px', padding: '10px' }}>
          <div className="logoRow">
            <div className="logoLeft">
              <img crossOrigin="anonymous" src={imageurl} width="100" alt="logo" />
            </div>
            <div className="bettktrow" style={{ float: 'right', textAlign: 'right', marginTop: '10px' }}>
              <p style={{ fontSize: '9px', fontWeight: '600', marginBottom: '2px', marginRight: '8px' }}>
                {user.shop[0].name}
              </p>
              <p style={{ fontSize: '9px', fontWeight: '600', marginBottom: '2px', marginRight: '8px' }}>
                {user?.username}
              </p>
              <p style={{ fontSize: '8.5px', fontWeight: '400', marginRight: '8px' }} id="date-time">
                {formatDate(minDate, 'datepicker')} {formatDate(minDate, 'clocktime')}
              </p>
            </div>
            <div className="clearboth" />
          </div>
          <div style={{ textAlign: 'center', marginTop: '5px' }}>
            <p style={{ fontSize: '9px', fontWeight: '600', marginBottom: '1px', marginRight: '8px' }}>
              Summary ({user?.username})
            </p>
            {date[1] === '' && (
              <p style={{ fontSize: '8.5px', fontWeight: '400', marginRight: '8px' }} id="date-time">
                {formatDate(minDate, 'datepicker')} {formatDate(minDate, 'fixturetime')}
              </p>
            )}
            {date[1] !== '' && (
              <div>
                {date[0] === date[1] ? (
                  <p style={{ fontSize: '8.5px', fontWeight: '400', marginRight: '8px' }} id="date-time">
                    {formatDate(minDate, 'datepicker')} {formatDate(minDate, 'fixturetime')}
                  </p>
                ) : (
                  <p style={{ fontSize: '8.5px', fontWeight: '400', marginRight: '8px' }} id="date-time">
                    {` ${formatDate(date[0], 'datepicker')} ${formatDate(date[0], 'fixturetime')}  - ${formatDate(
                      date[1],
                      'datepicker'
                    )} ${formatDate(date[1], 'fixturetime')} `}
                  </p>
                )}
              </div>
            )}
          </div>
          <div style={{ backgroundColor: '#f4f4f4', padding: '5px' }} id="balance-table">
            <table style={{ width: '100%' }}>
              <tr>
                <th
                  style={{
                    textAlign: 'left',
                    width: '50%',
                    fontSize: '9px',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                  }}
                >
                  starting balance
                </th>
                <td
                  style={{
                    textAlign: 'right',
                    width: '50%',
                    fontSize: '9px',
                    fontWeight: 'bold',
                  }}
                >
                  {formatNumber(data.start_balance)} {showCurrency()}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: 'left',
                    width: '50%',
                    fontSize: '9px',
                  }}
                >
                  Deposits
                </td>
                <td
                  style={{
                    textAlign: 'right',
                    width: '50%',
                    fontSize: '9px',
                    fontWeight: 'bold',
                  }}
                >
                  {formatNumber(data.deposit)} {showCurrency()}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: 'left',
                    width: '50%',
                    fontSize: '9px',
                  }}
                >
                  Bets
                </td>
                <td
                  style={{
                    textAlign: 'right',
                    width: '50%',
                    fontSize: '9px',
                  }}
                >
                  {fNumber(data.bets)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: 'left',
                    width: '50%',
                    fontSize: '9px',
                  }}
                >
                  Cancellations
                </td>
                <td
                  style={{
                    textAlign: 'right',
                    width: '50%',
                    fontSize: '9px',
                  }}
                >
                  {formatNumber(data.cancelled_amount)} {showCurrency()}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: 'left',
                    width: '50%',
                    fontSize: '9px',
                  }}
                >
                  Redeemed
                </td>
                <td
                  style={{
                    textAlign: 'right',
                    width: '50%',
                    fontSize: '9px',
                  }}
                >
                  {formatNumber(data.redeemed_win_amount)} {showCurrency()}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: 'left',
                    width: '50%',
                    fontSize: '9px',
                  }}
                >
                  Withdraws
                </td>
                <td
                  style={{
                    textAlign: 'right',
                    width: '50%',
                    fontSize: '9px',
                    fontWeight: 'bold',
                  }}
                >
                  {formatNumber(data.withdrawals)} {showCurrency()}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: 'left',
                    width: '50%',
                    fontSize: '9px',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                  }}
                >
                  End Balance
                </td>
                <td
                  style={{
                    textAlign: 'right',
                    width: '50%',
                    fontSize: '9px',
                    fontWeight: 'bold',
                  }}
                >
                  {formatNumber(data.end_balance)} {showCurrency()}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </body>
    </html>
  );

  return (
    <>
      <Container maxWidth="md" className="betTktCodePage">
        <iframe
          id="ifmsummaryreportprint"
          title="summary report"
          style={{ height: '0px', width: '0px', display: 'none', position: 'absolute' }}
        >
          {renderHTML()}
        </iframe>
        <Button disabled={error !== '' || disable } onClick={printReport} variant="contained">
          Print Cashier Summary 
        </Button>
      </Container>
    </>
  );
};

export default PrintCashierSummary;
