import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Stack,
  Typography
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Label from '../../components/label';
import { UserContext } from '../../contexts/UserContext';
import { fetchDataWithAuth, postData } from '../../utils/api';
import { ToastError, ToastSuccess, TransformMenuArray, generateHash, getTimeStamp } from '../../utils/helper';

const PermissionModal = ({ role_id, open, handleClose }) => {
  const [access, setAccess] = useState([]);
  const [menus, setMenus] = useState([]);
  const { user } = useContext(UserContext);
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(true);
  const getMenus = async () => {
    const data = {
      user_token: user.token,
      data: {
        name: '',
        slug: '',
      },
    };
    const response = await postData(`/admin/menu/list`, data);
    setLoading(false);
    setMenus(TransformMenuArray(response.data));
  };

  const getPermission = async () => {
    if (role_id) {
      const data = {
        user_token: user.token,
        data: {},
      };
      const response = await fetchDataWithAuth(`/admin/permission/get/${role_id}`, data);
      const permissionData = response.data[0];
      setAccess(permissionData.access);
    }
  };
  useEffect(() => {
    getMenus();
    getPermission();
  }, [role_id]);


  const onSubmit = async () => {
    // Perform form submission logic here
    toast.dismiss();
    setDisable(true);
    let data = null;
    let PermissionData = null;
    setLoading(true);
    try {
      data = {
        user_token: user.token,
        data: {
          date_time: getTimeStamp(),
          role_id,
          access,
        },
      };
      const hashSignature = generateHash(data.data);
      data.data.hash = hashSignature;
      PermissionData = await postData('/admin/permission/update', data);
      ToastSuccess(PermissionData.message);
      setTimeout(() => {
        toast.dismiss();
      }, 800);
    } catch (error) {
      console.log('error2 :>> ', error);
      ToastError(error.response.data.message);
    } finally {
      setDisable(false);
      setLoading(false);
      handleClose();
    }
  };

  const handleCheckboxChange = (menuId, actionIndex, checked) => {
    if (checked) {
      // Add the action to the access state
      setAccess((prevAccess) => {
        const updatedAccess = [...prevAccess];
        const menu = updatedAccess.find((item) => item.id === menuId);
        if (menu) {
          menu.action.push({ name: menus.find((item) => item.id === menuId).action[actionIndex].name });
        } else {
          updatedAccess.push({
            id: menuId,
            name: menus.find((item) => item.id === menuId).name,
            slug: menus.find((item) => item.id === menuId).slug,
            icon: menus.find((item) => item.id === menuId).icon,
            action: [{ name: menus.find((item) => item.id === menuId).action[actionIndex].name }],
          });
        }
        return updatedAccess;
      });
    } else {
      // Remove the action from the access state
      setAccess((prevAccess) => {
        const updatedAccess = [...prevAccess];
        const menu = updatedAccess.find((item) => item.id === menuId);
        if (menu) {
          menu.action = menu.action.filter(
            (action) => action.name !== menus.find((item) => item.id === menuId).action[actionIndex].name
          );
          if (menu.action.length === 0) {
            // Remove the menu if it has no action
            const menuIndex = updatedAccess.findIndex((item) => item.id === menuId);
            updatedAccess.splice(menuIndex, 1);
          }
        }
        return updatedAccess;
      });
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1100,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    padding: '20px',
    borderRadius: '8px',
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography
              variant="h5"
              sx={{
                textTransform: 'capitalize',
              }}
            >
              Modify Role Permissions
            </Typography>
            <IconButton
              aria-label="Cancel"
              onClick={() => {
                handleClose();
              }}
            >
              <CancelIcon />
            </IconButton>
        </Stack>
        <Card>
          <CardContent>
            {loading ? (
              <Stack display="flex" flexDirection="row" justifyContent="center">
                <CircularProgress size={35} />
              </Stack>
            ) : (
              <form onSubmit={onSubmit} className="formRolePermision">
                <Box display="flex" 
                sx={{
                  overflow: 'auto',
                  maxHeight: 700,
                  padding: 2
                }}>
                  <Grid 
                  container 
                  spacing={3} 
                  justifyContent="stretch"
                  sx={{
                    '& .MuiGrid-root': {
                      margin: 1,
                      border: '3px solid ',
                      borderRadius: 2,
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: '#fff',
                    },
                    // '& .MuiTypography-root': { color: '#061B64' },
                  }}>
                    {menus.length &&
                      menus.map((menu, menuIndex) => (
                        <Grid
                          sx={{ mt: 1, borderRadius: 1, padding: 2, width: 'max-content' }}
                          item 
                          xs={12} 
                          sm={6} 
                          md={5}
                          key={menuIndex}
                        >
                          <List disablePadding>
                            <ListItem disablePadding>
                              <ListItemText className="rolePermissionText" primary={menu.name} />
                            </ListItem>
                            <List disablePadding>
                              {menu.action.map((action, index) => (
                                <ListItem key={index} disablePadding>
                                  <ListItemIcon>
                                    <Checkbox
                                      checked={access?.some(
                                        (item) =>
                                          item.id === menu.id && item.action?.some((a) => a.name === action.name)
                                      )}
                                      onChange={(e) => handleCheckboxChange(menu.id, index, e.target.checked)}
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={<Label color={'info'}>{action.name}</Label>} />
                                </ListItem>
                              ))}
                            </List>
                          </List>
                        </Grid>
                      ))}
                  </Grid>
                </Box>
                <Box padding={2}>
                  <Button type="submit" variant="contained" color="primary" disabled={disable}>
                    Update Role Permission
                  </Button>
                </Box>
              </form>
            )}
          </CardContent>
        </Card>
        </Box>
      </Modal>
    </div>
  );
};

export default PermissionModal;
