// component
import Iconify from '../../../components/iconify/Iconify';
import SvgColor from '../../../components/svg-color';
import { commonAgentLogic } from '../../../utils/helper';

// ----------------------------------------------------------------------

const icon = (name) =>
  name.includes(':') ? (
    <Iconify icon={name} sx={{ width: 1, height: 1 }} />
  ) : (
    <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  );

export const roleNavConfig = (user) => {
  const user_routes = user.role_responsibility.sort((a, b) => a.id - b.id);
  // Common Logic
  const [allNull,allSame] = commonAgentLogic(user);

  // Operator Logic
  const is_operator_supervisor = user.type === 'supervisor' ? (!!(allSame === true && allNull === false)) : false;
  // const is_operator_cashier = user.type === 'cashier' && user.shop[0].operator_id !== null;
  // const is_operator = user.type === 'operator';
  // const is_operator_without_shop = user.type === 'operator' && user.shop.length === 0; 
  let filteredUserRoutes = user_routes; 
  // check for operator linked supervisor
  if(user.type === 'supervisor' && is_operator_supervisor === false) {
    filteredUserRoutes = user_routes.filter((route) => route.name !== "Balance Control Center");
  }
  // check for operator role or operator linked cashier
  // if(is_operator_cashier || is_operator) {
  //   filteredUserRoutes = user_routes.filter((route) => route.name !== "Create Player");
  // }

  // if(is_operator_without_shop) {
  //   filteredUserRoutes = user_routes.filter((route) => route.name !== "Balance Transfer");
  // }
  
  // Agent Logic
  const is_agent_supervisor = user.type === 'supervisor' ? (!!(allSame === true && allNull === false)) : false;
  const is_agent_cashier = user.type === 'cashier' && user.shop[0].operator_id !== null && user.shop[0].role_name === 'Agent';
  // const is_agent = user.type === 'agent';
  // const is_agent_without_shop = user.type === 'agent' && user.shop.length === 0;
  
  // check for agent linked supervisor
  if(user.type === 'supervisor' && is_agent_supervisor === false) {
    filteredUserRoutes = user_routes.filter((route) => 
      route.name !== "Balance Control Center" &&
      route.name !== "Credit Request" &&
      route.name !== "Credit Transactions" &&
      route.name !== "Credit Return"
    );
  }
  
  // check for agent linked cashier
  if(user.type === 'cashier' && is_agent_cashier === false) {
    filteredUserRoutes = user_routes.filter((route) => 
      route.name !== "Credit Request" &&
      route.name !== "Credit Transactions"
    );
  }

  // check for agent role or agent linked cashier
  // if(is_agent_cashier || is_agent) {
  //   filteredUserRoutes = user_routes.filter((route) => route.name !== "Create Player");
  // }

  // if(is_agent_without_shop) {
  //   filteredUserRoutes = user_routes.filter((route) => route.name !== "Balance Transfer");
  // }

  const routes =  filteredUserRoutes.map((route) => ({
    title: route.name,
    path: `/${user.type}/${route.slug}`,
    icon: route.icon ? icon(route.icon) : <></>,
    extra: route.action,
  }));
  // const routes = [
  //   {
  //     title: 'Dashboard',
  //     path: '/cashier/dashboard',
  //     icon: icon('ic_analytics'),
  //   },
  //   {
  //     title: 'Create Player ',
  //     path: '/cashier/createPlayer',
  //     icon: icon('ic_user'),
  //   },
  //   {
  //     title: 'Balance Transfer',
  //     path: '/cashier/balanceTransfer',
  //     icon: icon('ic_transfer-alt'),
  //   },
  //   {
  //     title: 'Bet Ticket Code',
  //     path: '/cashier/betTicketCode',
  //     icon: icon('ic_code'),
  //   },
  //   {
  //     title: 'Bet Slip Check',
  //     path: '/cashier/betSlipCheck',
  //     icon: icon('ic_reciept'),
  //   },
  //   {
  //     title: 'Payout',
  //     path: '/cashier/payout',
  //     icon: icon('ic_cash'),
  //   },
  //   {
  //     title: 'Transactions',
  //     path: '/cashier/transaction',
  //     icon: icon('ic_transaction'),
  //   },
  //   {
  //     title: 'Shop Balance Transfer',
  //     path: '/cashier/shopBalanceTransfer',
  //     icon: icon('ic_shop'),
  //   },
  //   {
  //     title: 'Betting Report',
  //     path: '/cashier/bettingReport',
  //     icon: icon('ic_file-report-outline'),
  //   },
  //   {
  //     title: 'Profile',
  //     path: '/cashier/profile',
  //     icon: icon('ic_profile'),
  //   },
  // ];

  return routes;
};

export const supervisorNavConfig = (user) => {
  const user_routes = user.role_responsibility.sort((a, b) => a.id - b.id);
  // const routes = [
  //   {
  //     title: 'Dashboard',
  //     path: '/supervisor/dashboard',
  //     icon: icon('ic_analytics'),
  //   },
  //   {
  //     title: 'Transactions',
  //     path: '/supervisor/transaction',
  //     icon: icon('ic_transaction'),
  //   },
  //   {
  //     title: 'Shop Balance Transfer',
  //     path: '/supervisor/shopBalanceTransfer',
  //     icon: icon('ic_shop'),
  //   },
  //   {
  //     title: 'Profile',
  //     path: '/supervisor/profile',
  //     icon: icon('ic_profile'),
  //   },
  // ];
  const routes = user_routes.map((route) => ({
    title: route.name,
    path: `/supervisor/${route.slug}`,
    icon: route.icon ? icon(route.icon) : <></>,
    extra: route.action,
  }));

  return routes;
};

export const adminNavConfig = [
  {
    title: 'dashboard',
    path: '/admin/dashboard',
    icon: icon('material-symbols-light:dashboard-outline'),
  },

  {
    title: 'Live Reports',
    path: '',
    icon: icon('ic:round-query-stats'),
    info: 'collapse',
    childrens: [
      {
        title: 'Shop with Low Balance',
        path: '/admin/live/shopwithlowbalance',
        // icon: icon('pajamas:sort-highest'),
      },
      {
        title: 'Recent Winning Users',
        path: '/admin/live/usersummary',
        // icon: icon('carbon:report-data'),
      },
      // {
      //   title: 'Shop Stats',
      //   path: '/admin/live/shopstats',
      //   icon: icon('iconoir:reports'),
      // },
    ],
  },
  {
    title: 'Payment Gateway',
    path: '',
    icon: icon('fluent:payment-20-regular'),
    info: 'collapse',
    childrens: [
      {
        title: 'Chapa',
        path: '/admin/paymentgateway/chapa',
        // icon: icon('mdi:account-payment-outline'),
      },
      // {
      //   title: 'Santim Pay',
      //   path: '/admin/paymentgateway/santimpay',
      //   icon: icon('permissions'),
      // },
    ],
  },
  {
    title: 'Reports',
    path: '',
    icon: icon('nimbus:stats'),
    info: 'collapse',
    childrens: [
      {
        title: 'Sportsbook Reports',
        path: 'report/sportsbook/bettingreport',
        //  icon: icon('mdi:passport-check')
      },
      {
        title: 'Virtuals Reports',
        path: 'report/virtuals/bettingreport',
        // icon: icon('oui:app-reporting'),
      },
      {
        title: 'Shop Report',
        path: '/admin/report/shopreport',
        // icon: icon('icon-park-outline:shop'),
      },
      {
        title: 'Cashier Report',
        path: '/admin/report/cashierreport',
        // icon: icon('game-icons:cash'),
      },
      {
        title: 'Supervisor Report',
        path: '/admin/report/supervisorreport',
        // icon: icon('tabler:report'),
      },
      {
        title: 'User Report',
        path: '/admin/report/userreport',
        // icon: icon('icon-park-twotone:permissions'),
      },
    ],
  },
  {
    title: 'Risk Management',
    path: '',
    icon: icon('ph:asterisk-light'),
    info: 'collapse',
    childrens: [
      {
        title: 'User RTP',
        path: '/admin/riskmanagement/userrtp',
        // icon: icon('uil:user-square'),
      },
    ],
  },

  {
    title: 'Operator',
    path: '',
    icon: icon('ri:admin-line'),
    info: 'collapse',
    childrens: [
      {
        title: 'Overview',
        path: '/admin/operator/overview',
        // icon: icon('role-binding-outlined'),
      },
      {
        title: 'Balance Management',
        path: '/admin/operator/balancemanagement',
        // icon: icon('role-binding-outlined'),
      },
    ],
  },
  {
    title: 'Agent',
    path: '',
    icon: icon('la:user-tie'),
    info: 'collapse',
    childrens: [
      {
        title: 'Overview',
        path: '/admin/agent/overview',
        // icon: icon('role-binding-outlined'),
      },
      {
        title: 'Balance Management',
        path: '/admin/agent/balancemanagement',
        // icon: icon('role-binding-outlined'),
      },
      {
        title: 'Credit Management',
        path: '/admin/agent/creditmanagement',
        // icon: icon('role-binding-outlined'),
      },
    ],
  },
  {
    title: 'Settings',
    path: '',
    icon: icon('lets-icons:setting-line-light'),
    info: 'collapse',
    childrens: [
      {
        title: 'Data Feeds Management',
        path: 'feedmanagement',
        // icon: icon('role-binding-outlined'),
      },
      {
        title: 'Roles & Permissions',
        path: '/admin/role',
        // icon: icon('role-binding-outlined'),
      },
      // {
      //   title: 'Permissions',
      //   path: '/admin/permission',
      //   // icon: icon('permissions'),
      // },
      {
        title: 'Admin Users',
        path: '/admin/adminuser',
        // icon: icon('security'),
      },
      {
        title: 'Users',
        path: '/admin/user',
        // icon: icon('users-outline'),
      },
      {
        title: 'Shops',
        path: '/admin/shop',
        // icon: icon('store'),
      },
      {
        title: 'Side Menus',
        path: '/admin/menu',
        // icon: icon('tabler:menu-order'),
      },
      {
        title: 'Top Leagues',
        path: '/admin/top_leagues',
        // icon: icon('material-symbols:all-match-outline'),
      },
      {
        title: 'Configuration',
        path: '/admin/setting',
        // icon: icon('uil:setting'),
      },
    ],
  },
];

export default adminNavConfig;
