import { Icon } from '@iconify/react';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
// import { fShortenNumber } from 'src/utils/formatNumber';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Iconify from '../../components/iconify';
import Label from '../../components/label';
import { UserContext } from '../../contexts/UserContext';
import { fetchDataWithAuth, postData } from '../../utils/api';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { ToastError, ToastSuccess, amountFormatter, formatDateTimeUTC, formatNumber, showCurrency } from '../../utils/helper';

const DepositAndWithdrawal = ({ type }) => {
  const [userReportList, setUserReportList] = useState([]);
  const [extra_data, setExtraData] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(['', '']);
  const [users, setUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectUser, setSelectUser] = useState(null);
  const [status, setStatus] = useState(3);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [sortData, setSortData] = useState('ASC');
  const [sortBy, setSortBY] = useState('');
  const [loading, SetLoading] = useState(true);
  const [open, SetOpen] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [selectedValues, setSelectedValues] = useState([1, 2]);
  const [actionLoader, SetActionLoader] = useState(false);
  const [settledLoader, SetSettledLoader] = useState(false);
  const [RowId, setRowId] = useState(0);
  const [value, setValues] = useState(1);

  const [CardCounts, setCardCounts] = useState({
    total_deposits: 0,
    total_withdrawals: 0,
  });
  // const checkboxes = [
  //   { label: 'Deposit IN', value: 1 },
  //   { label: 'Withdrawals OUT', value: 2 },
  // ];
  const { user } = useContext(UserContext);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const fetchDepositAndWithdraw = async (
    selectuser,
    selectedValues,
    start_Date,
    end_Date,
    sortBy,
    sortData,
    status,
    page,
    rowsPerPage
  ) => {
    setTransactionData([]);
    setPaymentStatus('');
    setRowId(0);
    const start_date = new Date(start_Date === '' ? '' : start_Date);
    const endDate = new Date(end_Date === '' ? '' : end_Date);
    // setSelectedDateRange([dayjs(start_date), dayjs(endDate)]);
    const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
    try {
      const data = {
        user_token: user.token,
        data: {
          user_id: selectuser !== '' && selectuser !== null ? selectuser?.id : '',
          type: selectedValues,
          order_key: sortBy !== '' ? sortBy : '',
          order_value: sortData !== '' ? sortData : '',
          status: status !== '' ? status : '',
          start_date: start_Date === '' ? '' : utcstartDate.toISOString(),
          end_date: end_Date === '' ? '' : utcEndDate.toISOString(),
        },
      };
      SetLoading(true);
      const response = await postData(`/admin/reports/${type}?page=${page + 1}&size=${rowsPerPage}`, data);
      setUserReportList(response.data);
      setExtraData(response.extra_data);
      SetLoading(false);
    } catch (error) {
      console.log('error :>> ', error);
      SetLoading(false);
    }
  };

  const getUserCardsCount = async (selectUserId, selectedValues, status, start_Date, end_Date) => {
    const start_date = new Date(start_Date === '' ? '' : start_Date);
    const endDate = new Date(end_Date === '' ? '' : end_Date);
    // setSelectedDateRange([dayjs(start_date), dayjs(endDate)]);
    const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);

    const data = {
      user_token: user.token,
      data: {
        user_id: selectUserId !== '' && selectUserId !== null ? selectUserId?.id : '',
        type: selectedValues,
        status: status !== '' ? status : '',
        start_date: start_Date === '' ? '' : utcstartDate.toISOString(),
        end_date: end_Date === '' ? '' : utcEndDate.toISOString(),
      },
    };
    const response = await postData(`/admin/reports/${type}/summary`, data);
    const userData = response.data[0];
    setCardCounts(userData);
  };
  const getUsers = async () => {
    const data = {
      user_token: user.token,
      data: {},
    };
    const response = await fetchDataWithAuth(`/admin/user/list`, data);
    const userData = response.data;
    setUsers(userData);
  };

  useEffect(() => {
    setPage(0)
    setStatus(3);
    getUsers();
    setTransactionData([]);
    setPaymentStatus('');
    setRowId(0);
    getUserCardsCount('', [value], 3, '', '');
    fetchDepositAndWithdraw('', [value], '', '', '', '', 3, 0, 10);
  }, [value]);

  const Status = [
    {
      id: 2,
      label: 'Pending',
    },
    {
      id: 3,
      label: 'Closed',
    },
    {
      id: 4,
      label: 'Cancel',
    },
    {
      id: 5,
      label: 'Failed',
    },
  ];

  const onSearch = async (betFilter) => {
    setPage(0);
    setValue('type', selectedValues);
    getUserCardsCount(selectUser, [value], status, selectedDateRange[0], selectedDateRange[1]);
    fetchDepositAndWithdraw(
      selectUser,
      [value],
      selectedDateRange[0],
      selectedDateRange[1],
      sortBy,
      sortData,
      status,
      0,
      rowsPerPage
    );
  };

  const handleDateChange = (newValue) => {
    setSelectedDateRange([newValue[0] !== null ? newValue[0] : '', newValue[1] !== null ? newValue[1] : '']);
    if (newValue[0] && newValue[1]) {
      const start_date = new Date(newValue[0]);
      // start_date.setHours(12, 0, 0);
      const endDate = new Date(newValue[1]);
      // endDate.setHours(23, 59, 59);
      const hours = endDate.getHours();
      const minutes = endDate.getMinutes();
      const seconds = endDate.getSeconds();
      if (hours === 0 || minutes === 0 || seconds === 0) {
        endDate.setHours(23, 59, 59);
      }
      setSelectedDateRange([dayjs(start_date), dayjs(endDate)]);
      const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
      const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
      setValue('start_date', utcstartDate.toISOString());
      setValue('end_date', utcEndDate.toISOString());
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchDepositAndWithdraw(
      selectUser,
      [value],
      selectedDateRange[0],
      selectedDateRange[1],
      sortBy,
      sortData,
      status,
      newPage,
      rowsPerPage
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    fetchDepositAndWithdraw(
      selectUser,
      [value],
      selectedDateRange[0],
      selectedDateRange[1],
      sortBy,
      sortData,
      status,
      page,
      event.target.value
    );
  };

  const getType = (type) => {
    switch (type) {
      case 1:
        return 'Deposit IN';
      case 2:
        return 'Withdrawals OUT';
      case 3:
        return 'Bet Placed IN';
      case 4:
        return 'Winnings OUT';
      case 5:
        return 'Refund';
      case 6:
        return 'Bonus IN';

      default:
        return '-';
    }
  };
  const getPaymentStatus = (payment_status) => {
    switch (payment_status) {
      case 0:
        return <Label color={'secondary'}>PENDING</Label>;
      case 1:
        return <Label color={'success'}>SUCCESS</Label>;
      case 2:
      case 3:
        return <Label color={'error'}>FAILED/CANCELLED</Label>;
      default:
        return 'N/A';
    }
  };
  const getCheckStatus = (status) => {
    switch (status) {
      case 'pending':
        return <Label color={'secondary'}>PENDING</Label>;
      case 'success':
        return <Label color={'success'}>SUCCESS</Label>;
      case 'failed/cancelled':
        return <Label color={'error'}>FAILED/CANCELLED</Label>;
      default:
        return 'N/A';
    }
  };
  const getStatus = (status) => {
    switch (status) {
      case 2:
        return 'Pending';
      case 3:
        return 'Closed';
      case 4:
        return 'Cancel';
      case 5:
        return 'Failed';
      default:
        return 'Pending';
    }
  };

  const handleSort = (sortBy) => {
    setSortBY(sortBy);
    setSortData(sortData === 'ASC' ? 'DESC' : 'ASC');
    fetchDepositAndWithdraw(
      selectUser,
      [value],
      selectedDateRange[0],
      selectedDateRange[1],
      sortBy,
      sortData === 'ASC' ? 'DESC' : 'ASC',
      status,
      page,
      rowsPerPage
    );
  };

  const clearFilter = async () => {
    reset({
      type: '',
    });
    setTransactionData([]);
    setPaymentStatus('');
    setRowId(0);
    setValue(('type', ''));
    setValue(('user_id', ''));
    setSelectedDateRange(['', '']);
    setSelectUser(null);
    setSortBY('');
    setStatus(3);
    setPage(0);
    setExtraData(null);
    setSelectedValues([1]);
    getUserCardsCount('', [value], 3, '', '');
    fetchDepositAndWithdraw('', [value], '', '', '', '', 3, 0, rowsPerPage);
  };
  const onchangeUser = (event, newValue) => {
    setValue('user_id', newValue?.id);
    setSelectUser(newValue);
  };
  const onchangeStatus = (event) => {
    setStatus(event.target.value);
  };

  // useEffect(() => {
  //   if (selectedValues.includes('')) {
  //     setSelectedValues([1, 2]);
  //   }
  // }, [selectedValues]);

  // const handleCheckboxChange = (value) => {
  //   if (value === '') {
  //     if (selectedValues.length === 2) {
  //       setSelectedValues([]);
  //     } else {
  //       setSelectedValues([1, 2]);
  //     }
  //   } else {
  //     const valueIndex = selectedValues.indexOf(value);
  //     if (valueIndex !== -1) {
  //       setSelectedValues(selectedValues.filter((v) => v !== value));
  //     } else {
  //       setSelectedValues([...selectedValues, value]);
  //     }
  //   }
  // };

  // const isCheckboxChecked = (value) => {
  //   if (selectedValues.length === 2) {
  //     return selectedValues;
  //   }
  //   if (value === '') {
  //     return selectedValues.length === 2;
  //   }
  //   return selectedValues.includes(value);
  // };

  const isNotFound = !userReportList.length;

  const min_date = new Date();
  const minDate = dayjs(min_date);

  const CheckChapaTransaction = async (id) => {
    setTransactionData([]);
    setRowId(id);
    setPaymentStatus('');
    try {
      const data = {
        user_token: user.token,
        data: {
          user_id: id,
        },
      };
      SetActionLoader(true);
      const response = await fetchDataWithAuth(`/admin/chapa/transaction/check/${id}`, data);
      if (response.status_code === 200) {
        setPaymentStatus(response.data[0].status);
        setTransactionData(response.data[0]);
        SetActionLoader(false);
      }
    } catch (error) {
      console.log('error :>> ', error);
      ToastError(error.response.data.message);
      SetActionLoader(false);
    }
  };
  const settleTransaction = async (userId) => {
    SetOpen(true);
    SetSettledLoader(true);
    try {
      const data = {
        user_token: user.token,
        data: {
          id: userId,
          status: transactionData !== null ? transactionData.status : '',
          chapa_data: transactionData.chapa_data,
        },
      };

      const response = await postData(`/admin/chapa/transaction/settle`, data);
      if (response.status_code === 200) {
        SetSettledLoader(false);
        SetOpen(false);
        ToastSuccess(response.message);
        getUserCardsCount(selectUser, [value], status, selectedDateRange[0], selectedDateRange[1]);
        fetchDepositAndWithdraw(
          selectUser,
          [value],
          selectedDateRange[0],
          selectedDateRange[1],
          sortBy,
          sortData,
          status,
          page,
          rowsPerPage
        );
      }
    } catch (error) {
      console.log('error :>> ', error);
      ToastError(error.response.data.message);
      SetSettledLoader(false);
    }
  };
  const handleChange = (event, newValue) => {
    setSelectUser(null);
    setSelectedDateRange(['', '']);
    setValues(newValue);
  };

  return (
    <>
      <Helmet>
        <title> Deposit and Withdraws | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>

      <Container maxWidth="xl" className="dashboardPage cardDesign">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Box display="flex">
            <Icon
              icon="mdi:account-payment-outline"
              style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }}
            />
            <Typography textTransform={'capitalize'} variant="h4" gutterBottom>
              {type} deposit and Withdrawals
            </Typography>
          </Box>
        </Stack>

        <Grid
          container
          className="boxGrid nomarginLR"
          spacing={3}
          justifyContent="stretch"
          sx={{
            '& .MuiGrid-root': {
              margin: 1,
              border: '3px solid ',
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#fff',
            },
            // '& .MuiTypography-root': { color: '#061B64' },
          }}
        >
          {value === 1 ? (
            <Grid item xs={12} sm={6} md={2} className="coffy_300">
              <Card>
                <CardContent>
                  <Stack>
                    <Typography sx={{ fontSize: '15px' }}>Total Deposits</Typography>
                    <Typography variant="h5">
                      {CardCounts.total_deposits !== undefined
                        ? amountFormatter(CardCounts.total_deposits).slice(0, -3)
                        : '00.00'}
                      <Typography variant="caption"> {showCurrency()}</Typography>
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ) : (
            <Grid item xs={12} sm={6} md={2} className="purple_500">
              <Card>
                <CardContent>
                  <Stack color="#2F0192">
                    <Typography sx={{ fontSize: '15px' }}>Total Withdrawals</Typography>
                    <Typography variant="h5">
                      {CardCounts.total_withdrawals !== undefined
                        ? amountFormatter(CardCounts.total_withdrawals).slice(0, -3)
                        : '00.00'}
                      <Typography variant="caption"> {showCurrency()}</Typography>
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>

        {/* </Stack> */}
        <Card sx={{ padding: '5px 10px 5px 10px' }} className="transtionCard">
          <CardContent sx={{ padding: 0 }}>
            <form onSubmit={handleSubmit(onSearch)}>
              <Stack display="flex" flexDirection="row" className="temptranstaionStack pd0_mrg0">
                <Box padding={2} sx={{ flexBasis: '25%' }} className="slectInput width_100 pd0_mrg0">
                  <FormControl sx={{ minWidth: 150 }} fullWidth>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                      name="status"
                      {...register('status')}
                      id="status"
                      value={status || 3}
                      onChange={onchangeStatus}
                    >
                      {Status.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box padding={2} sx={{ flexBasis: '25%' }} className="slectInput width_100 pd0_mrg0">
                  <FormControl sx={{ minWidth: 150 }} fullWidth>
                    {/* {users && users.length > 0 && ( */}
                    <Autocomplete
                      fullWidth
                      name="id"
                      disablePortal
                      id="id"
                      {...register('user_id')}
                      options={users}
                      autoHighlight
                      value={selectUser}
                      onChange={onchangeUser}
                      getOptionLabel={(option) => option.username}
                      renderInput={(params) => <TextField {...params} value={selectUser} selectUser label="Users" />}
                    />
                  </FormControl>
                </Box>

                <Box sx={{ flexBasis: '50%', mt: '10px' }} className="slectsInput width_100">
                  {/* <Typography variant="body1">Date Range</Typography> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DesktopDateRangePicker']}>
                      <DemoItem component="DesktopDateRangePicker">
                        <DesktopDateRangePicker
                          localeText={{ start: 'Start UTC Date', end: 'End UTC Date' }}
                          size="small"
                          maxDate={minDate}
                          value={selectedDateRange}
                          onChange={handleDateChange}
                          calendars={1}
                          format="DD-MM-YYYY HH:mm"
                          slotProps={{
                            textField: {
                              error: false,
                            },
                          }}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
              </Stack>
              <Stack display="flex" flexDirection="row" alignItems="self-end" className="stakeColumn">
                {/* <Box sx={{ flexBasis: '100%' }} padding={2}>
                  {checkboxes.map((checkbox) => (
                    <FormControlLabel
                      key={checkbox.value}
                      label={checkbox.label}
                      control={
                        <Checkbox
                          checked={isCheckboxChecked(checkbox.value)}
                          onChange={() => handleCheckboxChange(checkbox.value)}
                        />
                      }
                    />
                  ))}
                </Box> */}

                <Stack marginTop={2} width={'100%'} display="flex" flexDirection="row" justifyContent="flex-end">
                  <Box marginRight={2} className="pd0_mrg0Right">
                    <Button
                      onClick={clearFilter}
                      color="secondary"
                      variant="outlined"
                      size="large"
                      disableElevation
                      disabled={loading}
                    >
                      Clear
                    </Button>
                  </Box>
                  <Box className="pd0_mrg0Right">
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      size="large"
                      disabled={loading}
                      disableElevation
                    >
                      Search
                    </Button>
                  </Box>
                </Stack>
              </Stack>
            </form>

            {/* <Stack justifyContent="end" display="flex" flexDirection="row" margin={3}>
                <Box marginRight={2}>
                  <FormControl size="small" sx={{ minWidth: 180 }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectVal}
                      defaultValue={1}
                      onChange={handleSelectChange}
                    >
                      <MenuItem value={1}>Select All</MenuItem>
                      <MenuItem value={2}>Current Page</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <Button
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                  >
                    Export
                  </Button>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open1}
                    onClose={() => setAnchorEl(null)}
                  >
                    <MenuItem onClick={() => handleFileTypeDownload(1)} disableRipple>
                      <Icon icon="bxs:file-json" style={{ color: '#323436db', fontSize: '23px', marginRight: '5px' }} />
                      JSON
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem onClick={() => handleFileTypeDownload(2)} disableRipple>
                      <Icon
                        icon="material-symbols:csv-outline"
                        style={{ color: '#323436db', fontSize: '23px', marginRight: '5px' }}
                      />
                      CSV
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />

                    <MenuItem onClick={() => handleFileTypeDownload(3)} disableRipple>
                      <Icon
                        icon="file-icons:microsoft-excel"
                        style={{ color: '#323436db', fontSize: '20px', marginRight: '5px' }}
                      />
                      EXCEL
                    </MenuItem>
                  </StyledMenu>
                </Box>
              </Stack> */}
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Deposit" value={1} />
                  <Tab label="Withdrawals" value={2} />
                </TabList>
              </Box>
              <TabPanel value={1}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table" margin={1}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('txn_id')}>
                          Transaction ID
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                          </IconButton>
                        </TableCell>

                        <TableCell
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleSort('user.username')}
                          align="center"
                        >
                          Username
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('user.phone')} align="center">
                          Phone No
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                          </IconButton>
                        </TableCell>

                        <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('type')} align="center">
                          Type
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('debit')} align="center">
                          Debit
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('credit')} align="center">
                          Credit
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('created_at')} align="center">
                          Date
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('status')} align="center">
                          Status
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                          </IconButton>
                        </TableCell>
                        {userReportList[0]?.status !== 3 &&
                          userReportList[0]?.status !== 4 &&
                          userReportList[0]?.status !== 5 && <TableCell align="center">Check</TableCell>}
                        <TableCell
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleSort('payment_status')}
                          align="center"
                        >
                          Status From Chapa
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                          </IconButton>
                        </TableCell>
                        {userReportList[0]?.status !== 3 &&
                          userReportList[0]?.status !== 4 &&
                          userReportList[0]?.status !== 5 && <TableCell align="center">Action</TableCell>}
                      </TableRow>
                    </TableHead>
                    {loading && (
                      <TableRow>
                        <TableCell align="center" colSpan={12}>
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}
                    {/* {userReportList.length > 0 ? ( */}
                    {!isNotFound && !loading && (
                      <TableBody>
                        {userReportList.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell align="left">{item?.txn_id}</TableCell>

                            <TableCell align="center">
                              {item.user?.username ? item.user?.username : 'Offline User'}
                            </TableCell>
                            <TableCell align="center">
                              {item.user !== null
                                ? `+ ${item.user?.country_code}
                          ${item.user?.phone}`
                                : 'N/A'}
                            </TableCell>
                            <TableCell align="center">{getType(item.type)}</TableCell>
                            <TableCell align="center">
                              {item.debit !== null ? `${formatNumber(item.debit)} ${showCurrency()}` : '-'}{' '}
                            </TableCell>
                            <TableCell align="center">
                              {item.credit !== null ? `${formatNumber(item.credit)} ${showCurrency()}` : '-'}{' '}
                            </TableCell>
                            <TableCell align="center">
                              {formatDateTimeUTC(item?.created_at, 'MM/DD/YYYY HH:mm')}
                            </TableCell>
                            <TableCell align="center">{getStatus(item?.status)}</TableCell>
                            {item?.status === 3 || item?.status === 4 || item?.status === 5 ? (
                              ''
                            ) : (
                              <TableCell align="center">
                                {actionLoader && RowId === item?.id ? (
                                  <CircularProgress size={30} />
                                ) : (
                                  <Button
                                    onClick={() => {
                                      CheckChapaTransaction(item?.id);
                                    }}
                                    color="secondary"
                                    variant="contained"
                                    size="small"
                                    disabled={item?.status === 3 || item?.status === 4}
                                  >
                                    Check
                                  </Button>
                                )}
                              </TableCell>
                            )}
                            <TableCell align="center">
                              {paymentStatus !== '' && RowId === item.id
                                ? getCheckStatus(transactionData.status)
                                : getPaymentStatus(item?.payment_status)}
                            </TableCell>
                            {item?.status === 3 || item?.status === 4 || item?.status === 5 ? (
                              ''
                            ) : (
                              <TableCell align="center">
                                <Button
                                  onClick={() => SetOpen(true)}
                                  color="secondary"
                                  variant="contained"
                                  size="small"
                                  disableElevation
                                  disabled={
                                    paymentStatus !== ''
                                      ? paymentStatus === 'pending' || Number(transactionData.id) !== Number(item?.id)
                                      : item?.status === 2
                                  }
                                >
                                  Settle
                                </Button>
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                    {userReportList.length <= 0 && !loading && (
                      <TableRow>
                        <TableCell align="center" colSpan={12}>
                          <Typography variant="h6" textTransform={'capitalize'}>
                            {' '}
                            data not found !
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {!isNotFound && !loading && (
                      <TableFooter sx={{}}>
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            align="left"
                            sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}
                          >
                            Total Entries : {extra_data ? extra_data.current_page_items : 0}
                          </TableCell>
                          {/* <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                        {userReportList.length > 0
                          ? userReportList
                              .reduce((accumulator, object) => parseFloat(accumulator) + parseFloat(object.balance), 0)
                              .toFixed(2)
                          : 0.0}{' '}
                        {showCurrency()}
                      </TableCell> */}
                          <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                            {userReportList.length > 0
                              ? formatNumber(
                                  userReportList
                                    .reduce(
                                      (accumulator, object) =>
                                        parseFloat(accumulator) +
                                        (object.debit !== null ? parseFloat(object.debit) : 0),
                                      0
                                    )
                                    .toFixed(2)
                                )
                              : 0.0}{' '}
                            {showCurrency()}
                          </TableCell>
                          <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                            {userReportList.length > 0
                              ? formatNumber(
                                  userReportList
                                    .reduce(
                                      (accumulator, object) =>
                                        parseFloat(accumulator) +
                                        (object.credit !== null ? parseFloat(object.credit) : 0),
                                      0
                                    )
                                    .toFixed(2)
                                )
                              : 0.0}{' '}
                            {showCurrency()}
                          </TableCell>
                          <TableCell
                            colSpan={3}
                            align="left"
                            sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}
                          />
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                  {userReportList.length > 0 && !loading && (
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      component="div"
                      count={extra_data ? extra_data.total_items : 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  )}
                </TableContainer>
              </TabPanel>
              <TabPanel value={2}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table" margin={1}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('txn_id')}>
                          Transaction ID
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                          </IconButton>
                        </TableCell>

                        <TableCell
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleSort('user.username')}
                          align="center"
                        >
                          Username
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('user.phone')} align="center">
                          Phone No
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                          </IconButton>
                        </TableCell>

                        <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('type')} align="center">
                          Type
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('debit')} align="center">
                          Debit
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('credit')} align="center">
                          Credit
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('created_at')} align="center">
                          Date
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('status')} align="center">
                          Status
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                          </IconButton>
                        </TableCell>
                        {userReportList[0]?.status !== 3 &&
                          userReportList[0]?.status !== 4 &&
                          userReportList[0]?.status !== 5 && <TableCell align="center">Check</TableCell>}
                        <TableCell
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleSort('payment_status')}
                          align="center"
                        >
                          Status From Chapa
                          <IconButton aria-label="toggle password visibility">
                            {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                          </IconButton>
                        </TableCell>
                        {userReportList[0]?.status !== 3 &&
                          userReportList[0]?.status !== 4 &&
                          userReportList[0]?.status !== 5 &&
                          userReportList[0]?.status !== 2 && <TableCell align="center">Action</TableCell>}
                      </TableRow>
                    </TableHead>
                    {loading && (
                      <TableRow>
                        <TableCell align="center" colSpan={12}>
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}
                    {/* {userReportList.length > 0 ? ( */}
                    {!isNotFound && !loading && (
                      <TableBody>
                        {userReportList.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell align="left">{item?.txn_id}</TableCell>

                            <TableCell align="center">
                              {item.user?.username ? item.user?.username : 'Offline User'}
                            </TableCell>
                            <TableCell align="center">
                              {item.user !== null
                                ? `+ ${item.user?.country_code}
                        ${item.user?.phone}`
                                : 'N/A'}
                            </TableCell>
                            <TableCell align="center">{getType(item.type)}</TableCell>
                            <TableCell align="center">
                              {item.debit !== null ? `${formatNumber(item.debit)} ${showCurrency()}` : '-'}{' '}
                            </TableCell>
                            <TableCell align="center">
                              {item.credit !== null ? `${formatNumber(item.credit)} ${showCurrency()}` : '-'}{' '}
                            </TableCell>
                            <TableCell align="center">
                              {formatDateTimeUTC(item?.created_at, 'MM/DD/YYYY HH:mm')}
                            </TableCell>
                            <TableCell align="center">{getStatus(item?.status)}</TableCell>
                            {item?.status === 3 || item?.status === 4 || item?.status === 5 ? (
                              ''
                            ) : (
                              <TableCell align="center">
                                {actionLoader && RowId === item?.id ? (
                                  <CircularProgress size={30} />
                                ) : (
                                  <Button
                                    onClick={() => {
                                      CheckChapaTransaction(item?.id);
                                    }}
                                    color="secondary"
                                    variant="contained"
                                    size="small"
                                    disabled={item?.status === 3 || item?.status === 4}
                                  >
                                    Check
                                  </Button>
                                )}
                              </TableCell>
                            )}
                            <TableCell align="center">
                              {paymentStatus !== '' && RowId === item.id
                                ? getCheckStatus(transactionData.status)
                                : getPaymentStatus(item?.payment_status)}
                            </TableCell>
                            {item?.status === 3 || item?.status === 4 || item?.status === 5 || item?.status === 2 ? (
                              ''
                            ) : (
                              <TableCell align="center">
                                <Button
                                  onClick={() => SetOpen(true)}
                                  color="secondary"
                                  variant="contained"
                                  size="small"
                                  disableElevation
                                  disabled={
                                    paymentStatus !== ''
                                      ? paymentStatus === 'pending' || Number(transactionData.id) !== Number(item?.id)
                                      : item?.status === 2
                                  }
                                >
                                  Settle
                                </Button>
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                    {userReportList.length <= 0 && !loading && (
                      <TableRow>
                        <TableCell align="center" colSpan={12}>
                          <Typography variant="h6" textTransform={'capitalize'}>
                            {' '}
                            data not found !
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {!isNotFound && !loading && (
                      <TableFooter sx={{}}>
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            align="left"
                            sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}
                          >
                            Total Entries : {extra_data ? extra_data.current_page_items : 0}
                          </TableCell>
                          {/* <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                      {userReportList.length > 0
                        ? userReportList
                            .reduce((accumulator, object) => parseFloat(accumulator) + parseFloat(object.balance), 0)
                            .toFixed(2)
                        : 0.0}{' '}
                      {showCurrency()}
                    </TableCell> */}
                          <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                            {userReportList.length > 0
                              ? formatNumber(
                                  userReportList
                                    .reduce(
                                      (accumulator, object) =>
                                        parseFloat(accumulator) +
                                        (object.debit !== null ? parseFloat(object.debit) : 0),
                                      0
                                    )
                                    .toFixed(2)
                                )
                              : 0.0}{' '}
                            {showCurrency()}
                          </TableCell>
                          <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                            {userReportList.length > 0
                              ? formatNumber(
                                  userReportList
                                    .reduce(
                                      (accumulator, object) =>
                                        parseFloat(accumulator) +
                                        (object.credit !== null ? parseFloat(object.credit) : 0),
                                      0
                                    )
                                    .toFixed(2)
                                )
                              : 0.0}{' '}
                            {showCurrency()}
                          </TableCell>
                          <TableCell
                            colSpan={3}
                            align="left"
                            sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}
                          />
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                  {userReportList.length > 0 && !loading && (
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      component="div"
                      count={extra_data ? extra_data.total_items : 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  )}
                </TableContainer>
              </TabPanel>
            </TabContext>
          </CardContent>
        </Card>
      </Container>

      <Dialog
        open={open}
        // onClose={() => SetOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>Username:- {transactionData.username} </Typography>
          {paymentStatus !== 'failed/cancelled' && (
            <Typography>Current Balance:- {transactionData.current_balance} {showCurrency()}</Typography>
          )}
          {paymentStatus !== 'failed/cancelled' && (
            <Typography>
              {transactionData.deposit_amount ? 'Deposit' : 'Withdrawals'}:-
              {transactionData.deposit_amount ? transactionData.deposit_amount : transactionData.withdraw_amount} {showCurrency()}
            </Typography>
          )}
          {paymentStatus !== 'failed/cancelled' && (
            <Typography>New Balance:- {transactionData.new_balance} {showCurrency()}</Typography>
          )}

          {<Typography>Are You sure you want to change ?</Typography>}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={(e) => {
              if (!settledLoader) SetOpen(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={(e) => {
              settleTransaction(transactionData.id);
            }}
            disabled={settledLoader}
          >
            {settledLoader ? (
              <>
                {' '}
                Loading <Iconify icon={'eos-icons:three-dots-loading'} width={30} />
              </>
            ) : (
              'Yes'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DepositAndWithdrawal;
