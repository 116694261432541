import { yupResolver } from '@hookform/resolvers/yup';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
// import { DatePicker } from '@mui/x-date-pickers';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
// import dayjs from 'dayjs';
import { Icon } from '@iconify/react';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import { alpha, styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import config from '../config';
import { UserContext } from '../contexts/UserContext';
import { MY_GLOBAL_CONST } from '../utils/GlobalConstant';
import { fetchDataWithAuth, postData } from '../utils/api';
import { ToastError, formatDate, formatDateTimeUTC, formatNumber, showCurrency } from '../utils/helper';

const schema = yup.object().shape({
  // receiver: yup.string().required('Receiver is required'),
  // transaction_id: yup
  // .string()
  //  .required('This field is required')
  // .matches(/^[^\s].*/, 'starting spaced is not allowed')
  // .matches(/^[0-9]+$/, 'Only numbers are allowed'),
  // sender: yup.string().required('sender is required'),
  // status: yup.string().required('displays Value is required'),
});
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const Transaction = () => {
  const REACT_APP_API_URL = config.apiUrl.slice(0, -4);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [list, setList] = useState([]);
  const [sortData, setSortData] = useState('DESC');
  const { user } = useContext(UserContext);
  const [extra_data, setExtraData] = useState([]);
  const [role, setRole] = useState([]);
  const [selectVal, setSelectVal] = useState(1);
  const [sortBy, setSortBY] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [checkBoxValue, setCheckBox] = useState('');
  const [statusvalue, setStatus] = useState('');
  const [receive_value, setReceive_value] = useState('');
  const [sender_value, SetSender_value] = useState('');
  const [loading, SetLoading] = useState(true);
  const [DirectMovement, setType] = useState(false);
  const [disable, setDisabled] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(['', '']);
  const [filterData, setFilterData] = useState([]);
  const [filter, setFilters] = useState({
    status: '',
    type: false,
    transaction_id: '',
    start_date: '',
    end_date: '',
    sender_type: '',
    sender_role_id: '',
    receiver_type: '',
    receiver_role_id: '',
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const status = [
    {
      status: 1,
      lable: 'Open',
    },
    {
      status: 2,
      lable: 'Pending',
    },
    {
      status: 3,
      lable: 'Closing',
    },
  ];

  const onSearch = async (searchValues) => {
    const startDate = new Date(selectedDateRange[0] !== '' ? selectedDateRange[0] : '');
    const endDate = new Date(selectedDateRange[1] !== '' ? selectedDateRange[1] : '');
    const utcstartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);

    setPage(0);
    setFilterData(searchValues);
    setType(searchValues.type);
    // if (
    //   searchValues.transaction_id !== '' ||
    //   searchValues.receiver_role_id !== '' ||
    //   searchValues.sender_role_id !== '' ||
    //   searchValues.end_date ||
    //   searchValues.start_date ||
    //   searchValues.status !== '' ||
    //   searchValues.type !== false
    // ) {
      if (searchValues.receiver_role_id !== 0) {
        if (searchValues.receiver_role_id === '') {
          setValue('receiver_type', '');
        } else {
          setValue('receiver_type', 'AdminUsers');
        }
      } else {
        setValue('receiver_type', 'Users');
      }
      if (searchValues.sender_role_id !== 0) {
        if (searchValues.sender_role_id === '') {
          setValue('sender_type', '');
        } else {
          setValue('sender_type', 'AdminUsers');
        }
      } else {
        setValue('sender_type', 'Users');
      }
      SetLoading(true);
      const data = {
        user_token: user.token,
        data: {
          status: searchValues.status ? searchValues.status : '',
          type: searchValues.type,
          transaction_id: searchValues.transaction_id ? searchValues.transaction_id : '',
          start_date: selectedDateRange[0] === '' ? '' : formatDate(utcstartDate, 'datepicker'),
          end_date: selectedDateRange[1] === '' ? '' : formatDate(utcEndDate, 'datepicker'),
          sender_type: searchValues.sender_type,
          sender_role_id: searchValues.sender_role_id ? searchValues.sender_role_id : '',
          receiver_type: searchValues.receiver_type,
          receiver_role_id: searchValues.receiver_role_id ? searchValues.receiver_role_id : '',
        },
      };
      try {
        const searchData = await postData(`/${user.type}/transaction/list?page=${1}&size=${rowsPerPage}`, data);
        setFilters(data.data);
        setList(searchData.data);
        setExtraData(searchData.extra_data);
        SetLoading(false);
      } catch (error) {
        console.log('error2 :>> ', error);
        SetLoading(false);
      }
    // }
    // }
  };

  const handleDateChange = (newValue) => {
    setSelectedDateRange([newValue[0] !== null ? newValue[0] : '', newValue[1] !== null ? newValue[1] : '']);
    // if (newValue[0] && newValue[1]) {
    //   const start_date = new Date(newValue[0]);
    //   const endDate = new Date(newValue[1]);
    //   const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
    //   const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
    //   setValue('start_date', utcstartDate);
    //   setValue('end_date', utcEndDate);
    // }
  };
  const onHandleChange = (event) => {
    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/\D/g, '');
    setSearchValue(sanitizedValue);
    // if (searchValue.length < 3) {
    //   FatchDataApiSortData('', '', page, rowsPerPage);
    // }
  };
  // const fetchDataFromApi = async (page) => {

  //   const data = {
  //     user_token: user.token,
  //     data: filter,
  //   };
  //   try {
  //     const fetchedData = await postData(`/${user.type}/transaction/list?page=${page + 1}&size=${rowsPerPage}`, data);
  //     setList(fetchedData.data);
  //     setExtraData(fetchedData.extra_data);
  //   } catch (error) {
  //     console.log('fetchedData error :>> ', error);
  //   }
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    FatchDataApiSortData(sortBy, sortBy ? sortData : '', newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    FatchDataApiSortData(sortBy, sortBy ? sortData : '', 0, event.target.value);
  };

  useEffect(() => {
    RoleFetchData();
    FatchDataApiSortData(sortBy, '', page, rowsPerPage, '');
  }, []);

  const RoleFetchData = async () => {
    const data = {
      user_token: user.token,
      data: {},
    };
    try {
      const roleData = await fetchDataWithAuth(`/${user.type}/role/list`, data);
      const updated = [];
      if(user.type === 'operator') {
        const filterRoles = roleData.data.filter((role) => ["Operator","Supervisor","Cashier"].includes(role.name));
        updated.push(...filterRoles);
      } else {
        updated.push(...roleData.data);
        // const updated = [...roleData.data, { id: 0, name: 'Users' }];
      } 
      updated.push({ id: 0, name: 'Users' });
      setRole(updated);
    } catch (error) {
      console.log('error2 :>> ', error);
    }
  };
  // const debitsort = (a, b, sortType) => {
  //   let aAmount = 0;
  //   let bAmount = 0;
  //   if (a.type === 2 || a.type === 3) {
  //     aAmount = a.amount;
  //   }
  //   if (b.type === 2 || b.type === 3) {
  //     bAmount = b.amount;
  //   }

  //   let Flag = -1;
  //   if (aAmount === 0 && bAmount === 0) Flag = 0;
  //   else
  //     Flag =
  //       sortType === 'dsc'
  //         ? parseFloat(aAmount) < parseFloat(bAmount)
  //           ? 0
  //           : -1
  //         : parseFloat(aAmount) > parseFloat(bAmount)
  //         ? 0
  //         : -1;

  //   return Flag;
  // };
  // const creditsort = (a, b, sortType) => {
  //   let aAmount = 0;
  //   let bAmount = 0;
  //   if (a.type === 1 || a.type === 4) {
  //     aAmount = a.amount;
  //   }
  //   if (b.type === 1 || b.type === 4) {
  //     bAmount = b.amount;
  //   }

  //   let Flag = -1;
  //   if (aAmount === 0 && bAmount === 0) Flag = 0;
  //   else
  //     Flag =
  //       sortType === 'dsc'
  //         ? parseFloat(aAmount) < parseFloat(bAmount)
  //           ? 0
  //           : -1
  //         : parseFloat(aAmount) > parseFloat(bAmount)
  //         ? 0
  //         : -1;

  //   return Flag;
  // };

  const FatchDataApiSortData = async (sortBy, sortData, page, rowsPerPage) => {
    SetLoading(true);
    const startDate = new Date(selectedDateRange[0] !== '' ? selectedDateRange[0] : '');
    const endDate = new Date(selectedDateRange[1] !== '' ? selectedDateRange[1] : '');
    const utcstartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);

    const data = {
      user_token: user.token,
      data: {
        status: filterData.status ? filterData.status : '',
        type: DirectMovement,
        transaction_id: filterData.transaction_id ? filterData.transaction_id : '',
        start_date: selectedDateRange[0] === '' ? '' : formatDate(utcstartDate, 'datepicker'),
        end_date: selectedDateRange[1] === '' ? '' : formatDate(utcEndDate, 'datepicker'),
        sender_type: filterData.sender_type ? filterData.sender_type : '',
        sender_role_id: filterData.sender_role_id ? filterData.sender_role_id : '',
        receiver_type: filterData.receiver_type ? filterData.receiver_type : '',
        receiver_role_id: filterData.receiver_role_id ? filterData.receiver_role_id : '',
        order_key: sortBy !== '' ? sortBy : '',
        order_value: sortData,
      },
    };

    try {
      const fetchedData = await postData(`/${user.type}/transaction/list?page=${page + 1}&size=${rowsPerPage}`, data);
      setList(fetchedData.data);
      setExtraData(fetchedData.extra_data);
      SetLoading(false);
    } catch (error) {
      console.log('fetchedData error :>> ', error);
    }
  };
  const isNotFound = !list.length;

  const HandleSort = async (sortBy) => {
    setSortBY(sortBy);
    setSortData(sortData === 'ASC' ? 'DESC' : 'ASC');
    FatchDataApiSortData(sortBy, sortData === 'ASC' ? 'DESC' : 'ASC', page, rowsPerPage);
  };

  // export start
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const saveBlob = (blob, filename) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };
  const handleFileTypeDownload = async (filetype) => {
    setDisabled(true);
    const startDate = new Date(selectedDateRange[0] !== '' ? selectedDateRange[0] : '');
    const endDate = new Date(selectedDateRange[1] !== '' ? selectedDateRange[1] : '');
    const utcstartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);

    const data = {
      user_token: user.token,
      data: {
        page: selectVal === 2 ? page + 1 : '',
        row_per_page: selectVal === 2 ? rowsPerPage : '',
        file_type: filetype,
        order_key: sortBy !== '' ? sortBy : '',
        order_value: sortData,
        status: filterData.status ? filterData.status : '',
        type: DirectMovement,
        transaction_id: filterData.transaction_id ? filterData.transaction_id : '',
        start_date: selectedDateRange[0] === '' ? '' : formatDate(utcstartDate, 'datepicker'),
        end_date: selectedDateRange[1] === '' ? '' : formatDate(utcEndDate, 'datepicker'),
        sender_type: filterData.sender_type,
        sender_role_id: filterData.sender_role_id ? filterData.sender_role_id : '',
        receiver_type: filterData.receiver_type,
        receiver_role_id: filterData.receiver_role_id ? filterData.receiver_role_id : '',
        headers: [
          'Transaction ID',
          'Date',
          'Movement',
          'Sender',
          'Receiver',
          'Description',
          'Debit',
          'Credit',
          'Balance',
        ],
      },
    };
    try {
      const Transaction = await postData(`/cashier/transaction/export`, data);
      let mimeType;
      let fileExtension;

      if (filetype === 2) {
        mimeType = 'text/csv';
        fileExtension = '.csv';
      } else if (filetype === 3) {
        mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        fileExtension = '.xlsx';
      } else if (filetype === 1) {
        mimeType = 'application/json';
        fileExtension = '.json';
      } else {
        // Handle unsupported file types
        return;
      }

      const blob = await fetch(`${REACT_APP_API_URL}${Transaction.data[0].file_path}`, {
        type: mimeType,
      }).then((response) => response.blob());

      const filename = `TransactionReport${fileExtension}`;
      saveBlob(blob, filename);
      setDisabled(false);
    } catch (error) {
      console.log('error :>> ', error);
      ToastError(error.response.data.message);
      setDisabled(false);
    }
    setAnchorEl(null);
  };
  const handleSelectChange = (event) => {
    setSelectVal(event.target.value);
  };
  // export end

  const getType = (type) => {
    switch (type) {
      case 1:
        return 'Deposit IN';
      case 2:
        return 'Withdrawal OUT';
      case 3:
        return 'Transferred TO';
      case 4:
        return 'Received FROM';
      case 5:
        return 'Signup Bonus IN';
      case 6:
        return 'Refund Transferred TO';
      default:
        return '-';
    }
  };

  const clearFilter = async () => {
    reset();
    setSearchValue('');
    setReceive_value('');
    SetSender_value('');
    setSelectedDateRange(['', '']);
    setCheckBox('');
    setStatus('');
    setPage(0);
    setType(false);
    setFilterData([])
    setSelectVal(1);
    // if (
    //   searchValue !== '' ||
    //   receive_value !== '' ||
    //   sender_value !== '' ||
    //   statusvalue !== '' ||
    //   selectedDateRange[0] !== '' ||
    //   selectedDateRange[1] !== '' ||
    //   checkBoxValue
    //   ) {
        const data = {
          user_token: user.token,
          data: {
          status: '',
          type: false,
          transaction_id: '',
          start_date: '',
          end_date: '',
          sender_type: '',
          sender_role_id: '',
          receiver_type: '',
          receiver_role_id: '',
          order_key: '',
          order_value: '',
        },
      };
      
      try {
        SetLoading(true)
        const fetchedData = await postData(`/${user.type}/transaction/list?page=${1}&size=${rowsPerPage}`, data);
        setList(fetchedData.data);
        setExtraData(fetchedData.extra_data);
        SetLoading(false);
      } catch (error) {
        console.log('fetchedData error :>> ', error);
      }
    // }
  };

  const min_date = new Date();
  const minDate = dayjs(min_date);
  return (
    <>
      <Helmet>
        <title> Balance Transactions | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>
      <Container maxWidth="xl" className="transitionPage">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon
              icon="icon-park-outline:transaction"
              style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }}
            />
            <Typography variant="h4" gutterBottom>
              Balance Transactions
            </Typography>
          </Box>
        </Stack>

        <Card
          className="transtionCard"
          sx={{
            minWidth: 275,
            height: 'auto',
          }}
        >
          <CardContent>
            <form onSubmit={handleSubmit(onSearch)}>
              <Stack display="flex" flexDirection="row" px={2} className="temptranstaionStack">
                <Box sx={{ flexBasis: '25%' }} padding={2} className="slectInput">
                  <FormControl fullWidth sx={{}}>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                      {...register('status')}
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={statusvalue}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <MenuItem value="">Select Status</MenuItem>
                      {status.map((option) => (
                        <MenuItem key={option.status} value={option.status}>
                          {option.lable}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography variant="body2" color="error">
                      {errors.status?.message}
                    </Typography>
                  </FormControl>
                </Box>
                <Box sx={{ flexBasis: '25%' }} padding={2}>
                  <FormControl sx={{}} fullWidth className="slectInput">
                    <Box>
                      <TextField
                        name="transaction_id"
                        fullWidth
                        variant="outlined"
                        label="Transaction ID"
                        {...register('transaction_id')}
                        value={`${searchValue}`}
                        onChange={onHandleChange}
                        placeholder="Transaction Id"
                        disableUnderline
                      />
                      <Typography variant="body2" color="error">
                        {errors.transaction_id?.message}
                      </Typography>
                    </Box>
                  </FormControl>
                </Box>

                <Box sx={{ flexBasis: '25%' }} padding={2}>
                  <FormControl sx={{}} fullWidth className="slectInput">
                    <InputLabel id="demo-simple-select-label">Receiver</InputLabel>
                    <Select
                      {...register('receiver_role_id')}
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      // defaultValue={null}
                      value={receive_value}
                      onChange={(e) => setReceive_value(e.target.value)}
                    >
                      <MenuItem value="">Select Type</MenuItem>
                      {role.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography variant="body2" color="error">
                      {errors.receiver?.message}
                    </Typography>
                  </FormControl>
                </Box>
                <Box sx={{ flexBasis: '25%' }} padding={2}>
                  <FormControl sx={{}} fullWidth className="slectInput">
                    <InputLabel id="demo-simple-select-label">Sender</InputLabel>
                    <Select
                      {...register('sender_role_id')}
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={sender_value}
                      onChange={(e) => SetSender_value(e.target.value)}
                    >
                      <MenuItem value="">Select Type</MenuItem>
                      {role.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography variant="body2" color="error">
                      {errors.sender?.message}
                    </Typography>
                  </FormControl>
                </Box>
              </Stack>
              <Stack
                px={2}
                display="flex"
                flexDirection="row"
                alignItems="center"
                className="temptranstaionStack alignStart"
              >
                {user.type === 'cashier' && (
                  <Box sx={{ flexBasis: '25%' }} padding={2}>
                    <FormControlLabel
                      label="Direct Movement"
                      control={
                        <Checkbox
                          {...register('type')}
                          checked={checkBoxValue}
                          onChange={(e) => setCheckBox(!checkBoxValue)}
                        />
                      }
                    />
                  </Box>
                )}
                <Box sx={{ flexBasis: '50%' }} padding={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DesktopDateRangePicker']}>
                      <DemoItem component="DesktopDateRangePicker">
                        <DesktopDateRangePicker
                          localeText={{ start: 'Start Date', end: 'End Date' }}
                          className=""
                          size="small"
                          value={selectedDateRange}
                          onChange={handleDateChange}
                          calendars={1}
                          maxDate={minDate}
                          format="DD-MM-YYYY"
                          slotProps={{
                            textField: {
                              error: false,
                            },
                          }}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
                <Box display="flex" flexDirection="row" mt={1} marginLeft={2.2}>
                  <Box marginRight={2} className="pdtop0">
                    <Button onClick={clearFilter} variant="outlined" size="large" disabled={loading} disableElevation>
                      Clear
                    </Button>
                  </Box>
                  <Button sx={{}} size="large" fullWidth variant="contained" type="submit" disabled={loading}>
                    Search
                  </Button>
                </Box>
              </Stack>
            </form>

            <Stack justifyContent="end" display="flex" flexDirection="row" margin={3} className="spaceBt">
              <Box marginRight={2}>
                <FormControl size="small" sx={{ minWidth: 180 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectVal}
                    defaultValue={1}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value={1}>Select All</MenuItem>
                    <MenuItem value={2}>Current Page</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Button
                  id="demo-customized-button"
                  aria-controls={open ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleClick}
                >
                  Export
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem onClick={() => handleFileTypeDownload(1)} disabled={disable} disableRipple>
                    <Icon icon="bxs:file-json" style={{ color: '#323436db', fontSize: '23px', marginRight: '5px' }} />
                    JSON
                  </MenuItem>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem onClick={() => handleFileTypeDownload(2)} disabled={disable} disableRipple>
                    <Icon
                      icon="material-symbols:csv-outline"
                      style={{ color: '#323436db', fontSize: '23px', marginRight: '5px' }}
                    />
                    CSV
                  </MenuItem>
                  <Divider sx={{ my: 0.5 }} />

                  <MenuItem onClick={() => handleFileTypeDownload(3)} disabled={disable} disableRipple>
                    <Icon
                      icon="file-icons:microsoft-excel"
                      style={{ color: '#323436db', fontSize: '20px', marginRight: '5px' }}
                    />
                    EXCEL
                  </MenuItem>
                </StyledMenu>
              </Box>
            </Stack>
            <TableContainer component={Paper} sx={{ marginTop: '15px' }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => HandleSort('txn_id')}>
                      Transaction ID
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => HandleSort('updated_at')} align="center">
                      Date
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>

                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => HandleSort('sender_full_name')} align="center">
                      Sender
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => HandleSort('type')} align="center">
                      Movement
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell
                      sx={{ cursor: 'pointer' }}
                      onClick={() => HandleSort('receiver_full_name')}
                      align="center"
                    >
                      Receiver
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => HandleSort('note')} align="center">
                      Description
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => HandleSort('debit')} align="center">
                      Debit
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => HandleSort('credit')} align="center">
                      Credit
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => HandleSort('balance')} align="center">
                      Balance
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>

                {loading && (
                  <TableRow>
                    <TableCell align="center" colSpan={10}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                {!isNotFound && !loading && (
                  <TableBody>
                    {list?.map((item) => (
                      <TableRow key={item.txn_id}>
                        <TableCell align="left">{item.txn_id}</TableCell>
                        <TableCell align="center">{formatDateTimeUTC(item?.updated_at, 'MM/DD/YYYY HH:mm')}</TableCell>
                        <TableCell align="center">
                          {item.sender_full_name ? item.sender_full_name : 'Offline users'}
                        </TableCell>
                        <TableCell align="center">{getType(item.type)}</TableCell>
                        <TableCell align="center">
                          {item.receiver_full_name ? item.receiver_full_name : 'Offline users'}
                        </TableCell>
                        <TableCell align="center">
                          {item.note} {item.pbet_uid ? ` (${item.pbet_uid})` : ''}
                        </TableCell>
                        <TableCell align="center">
                          {item.type === 2 || item.type === 3 || item.type === 5 || item.type === 6
                            ? `${formatNumber(item.amount)} ${showCurrency()}`
                            : '-'}
                        </TableCell>
                        <TableCell align="center">
                          {item.type === 1 || item.type === 4 ? `${formatNumber(item.amount)} ${showCurrency()}` : '-'}
                        </TableCell>
                        <TableCell align="center">{formatNumber(item.balance)} {showCurrency()}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
                {isNotFound && !loading && (
                  <TableRow>
                    <TableCell align="center" colSpan={10} textTransform={'capitalize'}>
                      <Typography variant="h6"> data not found !</Typography>
                    </TableCell>
                  </TableRow>
                )}
                {!isNotFound && !loading && (
                  <TableFooter sx={{}}>
                    <TableRow>
                      <TableCell colSpan={6} align="left" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                        Total Entries : {extra_data ? extra_data.current_page_items : 0}
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                        {list.length > 0
                          ? formatNumber(
                              list
                                .reduce(
                                  (accumulator, object) =>
                                    parseFloat(accumulator) +
                                    (object.type === 2 || object.type === 3
                                      ? parseFloat(object.amount)
                                      : parseFloat(0.0)),
                                  0
                                )
                                .toFixed(2)
                            )
                          : 0.0}{' '}
                        {showCurrency()}
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '15px', color: '#000' }}>
                        {list.length > 0
                          ? formatNumber(
                              list
                                .reduce(
                                  (accumulator, object) =>
                                    parseFloat(accumulator) +
                                    (object.type === 1 || object.type === 4
                                      ? parseFloat(object.amount)
                                      : parseFloat(0.0)),
                                  0
                                )
                                .toFixed(2)
                            )
                          : 0.0}{' '}
                        {showCurrency()}
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }} />
                    </TableRow>
                  </TableFooter>
                )}
              </Table>
              {list.length > 0 && !loading && (
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={extra_data ? extra_data.total_items : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </TableContainer>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};
export default Transaction;
