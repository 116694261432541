import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { UserContext } from '../contexts/UserContext';
import { MY_GLOBAL_CONST } from '../utils/GlobalConstant';
import { postData } from '../utils/api';
import { fNumber } from '../utils/formatNumber';
import { ToastError, ToastSuccess, generateHash, getTimeStamp, showCurrency } from '../utils/helper';

// components

// ----------------------------------------------------------------------
const schema = yup.object().shape({
  // pbet_uid: yup
  //   .string()
  //   .required('This field is required')
  //   .matches(/^[^\s].*/, 'starting spaced is not allowed')
  //   .matches(/^[0-9]+$/, 'Only numbers are allowed'),
  // .length(10, 'Must be exactly 10 digits'),
});
const Payout = () => {
  const [BetDetail, setBetDetail] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const { user, updateBalance, fetchBalance } = useContext(UserContext);
  const [disable, setDisable] = useState(false);
  const [payBtnDisable, setPayBtnDisable] = useState(false);
  const { betslip } = useParams();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const getBetSlip = async () => {
    if (betslip) {
      const data = {
        user_token: user.token,
        data: {
          pbet_uid: betslip,
        },
      };
      toast.dismiss();
      try {
        const response = await postData(`/${user.type}/bet/check`, data);
        const tempBetData = response.data[0];
        setSearchValue(betslip);
        setBetDetail(tempBetData);
      } catch (error) {
        console.log('error :>> ', error);
        ToastError(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    getBetSlip();
  }, [betslip]);

  const handleBetSearch = async () => {
    setDisable(true)
    if (searchValue.length >= 10) {
      const data = {
        user_token: user.token,
        data: {
          pbet_uid: searchValue,
        },
      };
      toast.dismiss();
      try {
        const response = await postData(`/${user.type}/bet/check`, data);
        const tempBetData = response.data[0];
        setBetDetail(tempBetData);
        setDisable(false)
      } catch (error) {
        console.log('error :>> ', error);
        ToastError(error.response.data.message);
        setDisable(false)
      }
    }
  };

  const handlePayout = async () => {
    setPayBtnDisable(true)
    const data = {
      user_token: user.token,
      data: {
        pbet_uid: searchValue,
        date_time: getTimeStamp(),
      },
    };
    const hashSignature = generateHash(data.data);
    data.data.hash = hashSignature;
    toast.dismiss();
    try {
      const response = await postData(`/${user.type}/bet/payout`, data);
      await updateBalance(response.data[0].balance);
      ToastSuccess(response.message);
      setPayBtnDisable(false)
    } catch (error) {
      console.log('error :>> ', error);
      ToastError(error.response.data.message);
      setPayBtnDisable(false)
    }
    fetchBalance();
    handleClear();
  };

  const onHandleChange = async (event) => {
    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/\D/g, '');
    setSearchValue(sanitizedValue);
  };

  const handleClear = () => {
    setSearchValue('');
    setBetDetail(null);
  };

  return (
    <>
      <Helmet>
        <title> Payout | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>

      <Container maxWidth="md" className="payoutPage">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon icon="nimbus:cash" style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }} />
            <Typography variant="h4" gutterBottom>
              Payout
            </Typography>
          </Box>
        </Stack>

        <Card className="betslipCardMb">
          <CardContent>
            <Box
              display="flex"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className="betslipBx"
            >
              <Stack sx={{ width: '60%' }} className="betStackMobile">
                <Typography variant="h6">Betslip Number</Typography>
                <Stack
                  sx={{ mt: 2 }}
                  direction="row"
                  alignItems="center"
                  component={'form'}
                  onSubmit={handleSubmit(handleBetSearch)}
                >
                  <TextField
                    {...register('pbet_uid')}
                    variant="outlined"
                    sx={{ width: '80%', marginRight: '10px' }}
                    label="Enter Your Betslip Number"
                    value={searchValue}
                    type="text"
                    placeholder="Enter  Betslip Number"
                    onChange={onHandleChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">BP</InputAdornment>,
                    }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    type="submit"
                    disabled={searchValue.length < 10 || disable}
                  >
                    Search
                  </Button>
                </Stack>
                <Typography variant="body2" color="error">
                  {errors.pbet_uid?.message}
                </Typography>
              </Stack>

              <Divider sx={{}} orientation="vertical" flexItem>
                Or
              </Divider>

              <Stack sx={{}}>
                <Stack alignItems="center" sx={{}}>
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    Scan QR Code
                  </Typography>
                  <img src="/assets/qr.png" alt="qrCode" />
                </Stack>
              </Stack>
            </Box>

            {BetDetail ? (
              <>
                <Stack direction="row" alignItems="center" className="mrg_2">
                  <Typography>Bet Slip Number</Typography>

                  <Typography sx={{ ml: 2 }} variant="h6">
                    {BetDetail.pbet_uid}
                  </Typography>
                </Stack>
                <Divider sx={{ pt: 4, mb: 4 }} />

                <Stack alignItems="center" justifyContent="center">
                  {BetDetail.settled_with_user ? (
                    <Typography variant="body1" color='error' fontWeight="bold">
                      This Bet is already Settled/Refund
                    </Typography>
                  ) : (
                    <>
                      <Typography variant="body1" fontWeight="bold">
                      {BetDetail.playerbet_status === 3 ? 'Refund': 'Win'} Amount
                      </Typography>

                      <Typography variant="h3" sx={{ py: 1 }}>
                        {BetDetail.playerbet_status === 3 ? fNumber(BetDetail.stake_amount) : fNumber(BetDetail.win_amount) } {showCurrency()}
                      </Typography>

                      <Button
                        variant="contained"
                        size="large"
                        sx={{ px: 6 }}
                        onClick={() => handlePayout()}
                        disabled={BetDetail.is_live === 1 || BetDetail.settled_with_user || BetDetail.playerbet_status === 0 || payBtnDisable}
                      >
                        {BetDetail.playerbet_status === 3 ? 'Refund': 'Pay'}
                      </Button>
                    </>
                  )}
                </Stack>
              </>
            ) : (
              <></>
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default Payout;
