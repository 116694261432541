import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import Timer from '../utils/Timer';

const OtpModel = (props) => {
  const { user } = useContext(UserContext);

  function matchIsString(text) {
    return typeof text === 'string' || text instanceof String;
  }

  function matchIsNumeric(text) {
    const isNumber = typeof text === 'number';
    const isString = matchIsString(text);
    return (isNumber || (isString && text !== '')) && !Number.isNaN(Number(text));
  }

  const validateChar = (value, index) => {
    // Handle backspace
    if (value === '') {
      return true; // Allow backspace
    }

    return matchIsNumeric(value);
  };
  return (
    <>
      <Dialog
        className="otpDilogue"
        open={props.open}
        disableEscapeKeyDown
        // onClose={props.handleClose}
        sx={{ maxWidth: '600px', margin: '0 auto' }}
      >
        <Typography textAlign="right" padding={3}>
          <CloseIcon sx={{ cursor: 'pointer' }} onClick={props.handleClose} />
        </Typography>
        <DialogContent className="pd_0">
          <Box sx={{ width: '70px', margin: '0 auto', my: 4 }}>
            <img src="/assets/otp.png" alt="otpImage" />
          </Box>
          <Typography variant="h4" sx={{ lineHeight: '26px', mb: 1 }} textAlign="center">
            Please check your phone
          </Typography>

          <Stack sx={{ mb: 2 }}>
            <Typography variant="body1" fontWeight="400" textAlign="center">
              We have sent a 6-digit confirmation code to your mobile number, please enter the code in below box to
              verify.
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="center" alignItems="center">
            <MuiOtpInput
              width="350px"
              autoFocus
              length={6}
              validateChar={validateChar}
              value={props.otpVal}
              onChange={props.handleOtpChange}
              className={props.error ? 'error' : ''}
            />
          </Stack>
          {props.error && (
            <Typography className="error-message" variant="body2" color="error" textAlign="center">
              {props.error}
            </Typography>
          )}
          <Typography variant="body1" fontWeight="bold" textAlign="center" mt={2}>
            <Timer resetTimer={props.resetTimer} setSeconds={props.setSeconds} seconds={props.seconds} />
          </Typography>
        </DialogContent>

        <DialogActions sx={{ flexDirection: 'column', display: 'flex' }}>
          <Button
            variant="contained"
            size="medium"
            fullWidth
            color="primary"
            disabled={props.OtpBtn}
            onClick={props.onSubmitOtp}
          >
            Verify OTP
          </Button>
          <Typography variant="body2" mt={2}>
            Don't have a code{' '}
            <Button onClick={props.onResendOtp} disabled={props.disable}>
              Resend OTP
            </Button>
          </Typography>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OtpModel;
