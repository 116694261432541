import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Box, Button, Card, CardContent, CircularProgress, Container, Grid, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

// components
// sections
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { useForm } from 'react-hook-form';
import { UserContext } from '../contexts/UserContext';
import { postData } from '../utils/api';
import { fNumber } from '../utils/formatNumber';
import { MY_GLOBAL_CONST } from '../utils/GlobalConstant';
import { ToastError, formatDate, formatNumber, showCurrency } from '../utils/helper';
// ----------------------------------------------------------------------

export default function AgentDashboardAppPage() {
  // const [SportBookCollapsed, setSportBookCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showText, setShowText] = useState(false);
  // const [sportBookLoader, setsportBookLoader] = useState(false);
  const [dashboardCounts, setDashboardCounts] = useState({
number_of_shops: 0,
number_of_cashiers: 0,
total_cashiers_balance: 0,
total_collected_balance:0,
total_use_credit_for_placebet:0,
total_use_credit_for_deposit:0,
overall_use_credit: 0,
credit_use_for_deposit: 0,
credit_use_for_placebet: 0,
total_user: 0,
total_user_balance: 0,
total_deposit_amount: 0,
total_withdraw_amount: 0,
total_vat_amount: 0,
total_income_tax_amount: 0,
total_bet_amount: 0,
total_bet_count: 0,
total_open_bet_count: 0,
total_open_bet_possible_winning_amount: 0,
total_net_bet_amount: 0,
total_net_win_amount: 0,
profit: 0,
total_rtp: 0,
  });
  // const [sportBookDashboardCounts, setSportBookDashboardCounts] = useState({
  //   total_bet_count: 0,
  //   total_credit_used: 0,
  //   total_remaining_credit:0,
  //   total_circulated_count:0,
  //   total_circulated_credit:0,
  //   total_bet_winning_count: 0,
  //   total_bet_winning_amount: 0,
  //   total_vat_amount: 0,
  //   total_income_tax_amount: 0,
  //   total_user: 0,
  //   total_user_balance: 0,
  //   total_deposit_count: 0,
  //   total_deposit_amount: 0,
  //   total_withdraw_count: 0,
  //   total_withdraw_amount: 0,
  //   total_signup_bonus: 0,
  //   total_rtp: 0,
  //   total_net_bet_amount: 0,
  //   total_net_win_amount: 0,
  //   profit: 0,
  //   net_profit: 0,
  // });
  const [selectedDateRange, setSelectedDateRange] = useState(['', '']);
  const [changeText, setChangeText] = useState(['', '']);
  const {
    handleSubmit,
    setValue,
  } = useForm();
  const { user } = useContext(UserContext);

  const TodayDate = new Date();
  TodayDate.setHours(0, 0, 0, 0);
  const lastSevenDays = new Date(TodayDate);
  lastSevenDays.setDate(TodayDate.getDate() - 7);
  lastSevenDays.setHours(0, 0, 0, 0);


  const getDashboard = async (type = '', start_Date, end_Date) => {
    setChangeText([start_Date, end_Date]);
    const start_date = new Date(start_Date);
    const endDate = new Date(end_Date);

    const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
    setLoading(true);
    try {
      const data = {
        user_token: user.token,
        data: {
          provider_id: type !== undefined ? type : '',
          start_date: start_Date ? formatDate(utcstartDate, 'datepicker') : '',
          end_date: end_Date ? formatDate(utcEndDate, 'datepicker') : '',
        },
      };
      const response = await postData(`/${user.type}/dashboard`, data);
      if (response.status_code === 200) {
        setShowText(true);
        const userData = response.data[0];
        setDashboardCounts(userData);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    } finally{
      setLoading(false);
    }
    
  };

  // const getsportbookDashboard = async (type = '', start_Date, end_Date) => {
  //   const start_date = new Date(start_Date);
  //   const endDate = new Date(end_Date);

  //   const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
  //   const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
  //   const data = {
  //     user_token: user.token,
  //     data: {
  //       provider_id: 1,
  //       start_date: start_Date ? formatDate(utcstartDate, 'datepicker') : '',
  //       end_date: end_Date ? formatDate(utcEndDate, 'datepicker') : '',
  //     },
  //   };
  //   setsportBookLoader(true);
  //   const response = await postData(`/${user.type}/dashboard`, data);
  //   setsportBookLoader(false);
  //   const userData = response.data[0];
  //   setSportBookDashboardCounts(userData);
  // };

  useEffect(() => {
    setLoading(true);
    getDashboard();
    // getsportbookDashboard();
  }, []);
  const handleDateChange = (newValue) => {
    setSelectedDateRange(newValue);
    if (newValue[1] && newValue[0]) {
      const start_date = new Date(newValue[0]);
      // start_date.setHours(12, 0, 0);
      const endDate = new Date(newValue[1]);
      // endDate.setHours(23, 59, 59);
      setSelectedDateRange([dayjs(start_date), dayjs(endDate)]);
      const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
      const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
      setValue('start_date', formatDate(utcstartDate.toISOString(), 'datepicker'));
      setValue('end_date', formatDate(utcEndDate.toISOString(), 'datepicker'));
    }
  };

  const min_date = new Date();
  const minDate = dayjs(min_date);
  const onSearch = () => {
    getDashboard('', selectedDateRange[0], selectedDateRange[1]);
    // getsportbookDashboard(1, selectedDateRange[0], selectedDateRange[1]);
  };
  const clearFilter = () => {
    setSelectedDateRange(['', '']);
    setChangeText(['', '']);
    getDashboard();
    // getsportbookDashboard('', '', '');
  };

  const disabledBtn =
  selectedDateRange[1] === null ||
  selectedDateRange[1] === '' ||
  selectedDateRange[0] === null ||
  selectedDateRange[0] === '' || 
  loading

  return (
    <>
      <Helmet>
        <title> Dashboard | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>
      <Container maxWidth="xl" className="dashboardPage cardDesign">
        <Typography variant="h4" sx={{ mb: 3 }}>
          Hi, Welcome back
        </Typography>
        <form onSubmit={handleSubmit(onSearch)}>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent={'flex-end'}
            mb={2}
            className="clearBtnStck filterBorderClass"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DesktopDateRangePicker']}>
                <DemoItem component="DesktopDateRangePicker">
                  <DesktopDateRangePicker
                    localeText={{ start: 'Start Date', end: 'End Date' }}
                    className=""
                    size="small"
                    maxDate={minDate}
                    value={selectedDateRange}
                    onChange={handleDateChange}
                    calendars={1}
                    format="DD-MM-YYYY"
                    slotProps={{
                      textField: {
                        error: false,
                      },
                    }}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
            <Stack display="flex" flexDirection="row" className="mrgTop">
              <Box margin={1} className="pd0_mrg0Right mrgBtm_0">
                <Button
                  onClick={clearFilter}
                  sx={{ padding: '27px' }}
                  color="secondary"
                  variant="outlined"
                  size="large"
                  disableElevation
                  disabled={disabledBtn}
                >
                  Clear
                </Button>
              </Box>
              <Box margin={1} className="pd0_mrg0Right mrgBtm_0">
                <Button
                  type="submit"
                  color="secondary"
                  sx={{ padding: '27px' }}
                  variant="contained"
                  size="large"
                  disableElevation
                  disabled={disabledBtn}
                >
                  Search
                </Button>
              </Box>
            </Stack>
          </Stack>
          {!loading ? (
            <>
              <Typography mt={4} variant="h4" fontWeight="700">
                Cash Usage
              </Typography>
              <Grid
                container
                className="boxGrid nomarginLR"
                spacing={3}
                justifyContent="stretch"
                sx={{
                  '& .MuiGrid-root': {
                    margin: 1,
                    border: '3px solid ',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                  },
                  // '& .MuiTypography-root': { color: '#061B64' },
                }}
              >
                {/* <Grid container spacing={3}> */}

                <Grid item xs={12} sm={6} md={2} className="blue_900">
                  <Card>
                    <CardContent>
                      <Stack sx={{}}>
                        <Typography sx={{ fontSize: '15px' }}>No. Of Shop</Typography>
                        <Typography variant="h5">{fNumber(dashboardCounts.number_of_shops)}</Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="pink_500">
                  <Card>
                    <CardContent>
                      <Stack sx={{}}>
                        <Typography sx={{ fontSize: '15px' }}>No. Of Cashier</Typography>
                        <Typography variant="h5">{fNumber(dashboardCounts.number_of_cashiers)}</Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="purple_500">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Cashier Balance</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_cashiers_balance)}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="green_900">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Collected Balance</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_collected_balance)}{' '}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Typography mt={4} variant="h4" fontWeight="700">
                Credit Usage
              </Typography>
              <Grid
                container
                className="boxGrid nomarginLR"
                spacing={3}
                justifyContent="stretch"
                mb={4}
                sx={{
                  '& .MuiGrid-root': {
                    margin: 1,
                    border: '3px solid ',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                  },
                  // '& .MuiTypography-root': { color: '#061B64' },
                }}
              >
                <Grid item xs={12} sm={6} md={2} className="orange_111">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Overall Credit Used</Typography>
                        <Typography variant="h5">{fNumber(dashboardCounts.overall_use_credit)} </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="coffy_300">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Credit Used For Place Bet</Typography>
                        <Typography variant="h5">{fNumber(dashboardCounts.total_use_credit_for_placebet)} </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="green_100">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Credit Used For Deposit</Typography>
                        <Typography variant="h5">{fNumber(dashboardCounts.total_use_credit_for_deposit)} </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Typography mt={4} variant="h4" fontWeight="700">
                Player Overview
              </Typography>
              <Grid
                container
                className="boxGrid nomarginLR"
                spacing={3}
                mb={4}
                justifyContent="stretch"
                sx={{
                  '& .MuiGrid-root': {
                    margin: 1,
                    border: '3px solid ',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                  },
                  // '& .MuiTypography-root': { color: '#061B64' },
                }}
              >
                <Grid item xs={12} sm={6} md={2} className="pink_300">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Users</Typography>
                        <Typography variant="h5">{fNumber(dashboardCounts.total_user)}</Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="User_Balance">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Users Balance</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_user_balance)}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="Deposit">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Deposit Amounts</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_deposit_amount)}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="lossRed">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Withdraws Amounts</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_withdraw_amount)}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="Bets">
                  <Card>
                    <CardContent>
                      <Stack sx={{}}>
                        <Typography sx={{ fontSize: '15px' }}>Total Bets Count</Typography>
                        <Typography variant="h5">{fNumber(dashboardCounts.total_bet_count)}</Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="blue_500">
                  <Card>
                    <CardContent>
                      <Stack sx={{}}>
                        <Typography sx={{ fontSize: '15px' }}>Total Bets Amounts</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_bet_amount)}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="purple_500">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Bets Winning Amounts</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_bet_winning_amount)}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="purple_800">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Vat Amounts</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_vat_amount)}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="purple_900">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Income Tax Amounts</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_income_tax_amount)}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Typography mt={4} variant="h4" fontWeight="700">
                Statistics
              </Typography>
              <Grid
                container
                className="boxGrid nomarginLR"
                spacing={3}
                mb={4}
                justifyContent="stretch"
                sx={{
                  '& .MuiGrid-root': {
                    margin: 1,
                    border: '3px solid ',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                  },
                  // '& .MuiTypography-root': { color: '#061B64' },
                }}
              >
                <Grid item xs={12} sm={6} md={2} className="pink_300">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Net Bets Amounts</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_net_bet_amount)}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="orange_112">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Net Win Amounts</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_net_win_amount)}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="profitGreen">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Profit</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.profit)}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="theme_6">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>RTP</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_rtp)}
                          <Typography variant="caption">%</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="green_500">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Open Bet Count</Typography>
                        <Typography variant="h5">{fNumber(dashboardCounts.total_open_bet_count)}</Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="coffy_500">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Possible Win Amounts</Typography>
                        <Typography variant="h5">
                          {formatNumber(dashboardCounts.total_open_bet_possible_winning_amount)}
                          <Typography variant="caption"> {showCurrency()}</Typography>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              {/* <Box
                sx={{
                  backgroundColor: '#f3f3f3',
                  borderTop: '1px solid #f1f1f1',
                  borderRadius: '10px 10px 0 0',
                  // marginBottom: 2,
                  marginTop: 4,
                }}
              >
                <IconButton
                  disableRipple
                  sx={{ margin: '10px' }}
                  size="small"
                  onClick={() => {
                    setSportBookCollapsed(!SportBookCollapsed);
                  }}
                >
                  Sports Books {SportBookCollapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Box> */}

              {/* {sportBookLoader ? (
                <Collapse in={SportBookCollapsed} sx={{ backgroundColor: '#f3f3f3' }} className="collaps_row">
                  <Box
                    sx={{
                      display: 'flex',
                      height: '300px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#f3f3f3',
                    }}
                  >
                    <CircularProgress size={35} />
                  </Box>
                </Collapse>
              ) : (
                <Collapse
                  in={SportBookCollapsed}
                  sx={{ backgroundColor: '#f3f3f3', padding: 3, paddingTop: 1 }}
                  className="collaps_row"
                >
                  <Grid
                    container
                    className="boxGrid nomarginLR"
                    spacing={3}
                    justifyContent="stretch"
                    sx={{
                      '& .MuiGrid-root': {
                        margin: 1,
                        border: '3px solid ',
                        borderRadius: 2,
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#fff',
                      },
                      // '& .MuiTypography-root': { color: '#061B64' },
                    }}
                  >
                    <Grid item xs={12} sm={6} md={2} className="blue_900">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>Total Bets Counts</Typography>
                            <Typography variant="h5">{fNumber(sportBookDashboardCounts.total_bet_count)}</Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} className="coffy_300">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Credit Used</Typography>
                        <Typography variant="h5">
                          {fNumber(dashboardCounts.total_credit_used)}{' '}
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                  </Grid>
                    <Grid item xs={12} sm={6} md={2} className="coffy_300">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Possible Win Amounts</Typography>
                        <Typography variant="h5">
                          {fNumber(dashboardCounts.total_credit_used)}{' '}
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="Credit_100">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Remaining Credit </Typography>
                        <Typography variant="h5">
                          {fNumber(dashboardCounts.total_remaining_credit)}{' '}
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="orange_111">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Circulated Counts</Typography>
                        <Typography variant="h5">
                          {fNumber(dashboardCounts.total_circulated_count)}{' '}
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="orange_112">
                  <Card>
                    <CardContent>
                      <Stack>
                        <Typography sx={{ fontSize: '15px' }}>Total Circulated Credit</Typography>
                        <Typography variant="h5">
                          {fNumber(dashboardCounts.total_circulated_credit)}{' '}
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>

                    <Grid item xs={12} sm={6} md={2} className="coffy_500">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>Total Bets Winning Counts</Typography>
                            <Typography variant="h5">
                              {fNumber(sportBookDashboardCounts.total_bet_winning_count)}
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} className="purple_500">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>Total Bets Winning Amounts</Typography>
                            <Typography variant="h5">
                              {formatNumber(sportBookDashboardCounts.total_bet_winning_amount)}
                              <Typography variant="caption"> {showCurrency()}</Typography>
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} className="purple_800">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>Total Vat Amounts</Typography>
                            <Typography variant="h5">
                              {formatNumber(sportBookDashboardCounts.total_vat_amount)}
                              <Typography variant="caption"> {showCurrency()}</Typography>
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} className="purple_900">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>Total Income Tax Amounts</Typography>
                            <Typography variant="h5">
                              {formatNumber(sportBookDashboardCounts.total_income_tax_amount)}
                              <Typography variant="caption"> {showCurrency()}</Typography>
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} className="green_100">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>Total Net Bets Amounts</Typography>
                            <Typography variant="h5">
                              {formatNumber(sportBookDashboardCounts.total_net_bet_amount)}
                              <Typography variant="caption"> {showCurrency()}</Typography>
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} className="green_500">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>Total Net Win Amounts</Typography>
                            <Typography variant="h5">
                              {formatNumber(sportBookDashboardCounts.total_net_win_amount)}
                              <Typography variant="caption"> {showCurrency()}</Typography>
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} className="green_900">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>Profit</Typography>
                            <Typography variant="h5">
                              {formatNumber(sportBookDashboardCounts.profit)}
                              <Typography variant="caption"> {showCurrency()}</Typography>
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} className="pink_300">
                      <Card>
                        <CardContent>
                          <Stack>
                            <Typography sx={{ fontSize: '15px' }}>RTP</Typography>
                            <Typography variant="h5">
                              {formatNumber(sportBookDashboardCounts.total_rtp)}{' '}
                              <Typography variant="caption">%</Typography>
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Collapse>
              )} */}
            </>
          ) : (
            <Container
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '75vh',
              }}
              maxWidth="xl"
              className="dashboardPage"
            >
              <CircularProgress />
            </Container>
          )}
        </form>
      </Container>
    </>
  );
}
